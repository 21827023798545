import { useTheme } from "@emotion/react";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/close_icon.svg";
import { B300, WHITEBG } from "../../../ui-components/CustomButton";
import { FormikProvider, useFormik } from "formik";
import { useParams } from "react-router-dom";
import { styled } from "@mui/styles";
import CustomLabel from "../../../ui-components/CustomLabel";
import { Download, Upload } from "@mui/icons-material";
import sampledata from "../../../assets/sampledata.xlsx";
import { FetchImportInvestorList } from "../../../api"; // Ensure correct import path
import { ReactComponent as UploadDocumentsIcon } from "../../../assets/rounds_icons/upload_documents_icon.svg";
import { downloadFile } from "utils/index";
import toast from "react-hot-toast";


const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ImportInvestorPopup = ({ handleClose, refetchListing = () => {} }) => {
  const theme = useTheme();
  const { id: round_id } = useParams();
  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const { mutate: importInvestors, isLoading } = FetchImportInvestorList();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      setSelectedFile(file);
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = sampledata;
    link.download = "SampleTemplate.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      message: "",
    },
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        importInvestors(formData, {
          onSuccess: (response) => {
            toast.success(response?.data?.message)
            // console.log("Import successful:", response.data);
            handleClose(); 
            refetchListing();
          },
          onError: (error) => {
            console.error("Import failed:", error.response?.data);
          },
        });
      } else {
        console.error("No file selected");
      }
      setSubmitting(false);
    },
  });

  return (
    <Paper
      sx={{
        background: theme.palette.customColors.whiteBG,
        borderRadius: "8px",
      }}
    >
      <IconButton sx={{ position: "absolute", top: 20, right: 20, padding: 0 }}>
        <CloseIcon width={40} height={40} onClick={handleClose} />
      </IconButton>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid display={"grid"} container padding={"1.5rem"} gap={"1.5rem"}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  lineHeight: "28px",
                  color: theme.palette.customColors.g300,
                }}
              >
                Import Investors
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomLabel>UPLOAD FILE</CustomLabel>
              <WHITEBG
                component="label"
                fullWidth
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: `1px solid ${theme.palette.customColors.g75}`,
                  borderRadius: "8px",
                  height: "52px",
                  padding: "10px, 14px",
                  color: "#7A7D83",
                  textTransform: "none",
                }}
              >
                {fileName ? fileName : "Upload"}
                <UploadDocumentsIcon
                  style={{
                    width: 15,
                    height: 15,
                    stroke: "#344054",
                    padding: "8px",
                    borderRadius: "8px",
                    border: `1px solid ${theme.palette.customColors.gray300}`,
                  }}
                />
                <VisuallyHiddenInput
                  accept=".xlsx,.csv"
                  type="file"
                  onChange={handleFileChange}
                />
              </WHITEBG>
            </Grid>
            <Grid item xs={12}>
            <Typography
                sx={{
                  fontSize: "1.2rem",
                  fontWeight: 700,
                  lineHeight: "28px",
                  color: theme.palette.customColors.g300,
                }}
              >
                Instructions
              </Typography>
              <Typography
    component="div"
    sx={{
      fontSize: "1rem",
      lineHeight: "24px",
      color: theme.palette.customColors.g200,
      marginTop: "8px", // Add spacing between heading and list
    }}
  >
    <ul style={{ paddingLeft: "1.5rem", margin: 0 }}>
      <li>Click <a href="https://docs.google.com/spreadsheets/d/14cygvaI2yYCHvqWHXMoUGpELHZJGImtx1ei6Rd9w4mo/edit?usp=sharing" target="_blank">here</a> to download the import file template.</li>
      <li>
        Copy the first two rows from the template into a new Google Sheet.
        <ul>
          <li>The first row contains field names.</li>
          <li>The second row provides example data and dropdown options.</li>
        </ul>
      </li>
      <li>
        Add your data starting from the third row, ensuring all fields are correctly filled, and dropdowns are selected.
      </li>
      <li>Remove the second row (example row) before saving.</li>
      <li>Download the sheet in XLSX format.</li>
      <li>Upload the file and click the Import button.</li>
    </ul>
  </Typography>
            </Grid>
            <Grid item xs={12}>
              <B300
                type="submit"
                fullWidth
                disabled={isLoading}
                sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem" }}
              >
                {isLoading ? "Importing..." : "Import"}
              </B300>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Paper>
  );
};

export default ImportInvestorPopup;
