import { useTheme } from "@emotion/react";
import { Box, Card, CircularProgress, Divider, FormControlLabel, Grid, IconButton, Paper, RadioGroup, Rating, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as CloseIcon } from "assets/close_icon.svg";
import { FormikContext, FormikProvider, useFormik } from "formik";
import CustomInputField from "ui-components/formik/CustomInputField";
import { B300 } from "ui-components/CustomButton";
import * as Yup from "yup";
import AsyncTableListing from "ui-components/AsyncTableListing";
import { Delete } from "@mui/icons-material";
import { DeleteManageNotes, FetchManageNotes, SaveManageNotes } from "api/startups";
import CustomToast from "ui-components/CustomToast";
import toast from "react-hot-toast";
import { ReactComponent as DeleteIcon } from "../../../assets/rounds_icons/delete_icon.svg";
import DeleteConfirmationDialog from "ui-components/popups/DeleteConfirmationPopup";
import { useSelector } from "react-redux";
import placeHolderImagefeedback from 'assets/no_data_images/Startups/new_startups.svg'
import { DeleteAdminNotes, FetchAllAdminNotes, SaveAdminNotes } from "api/backrr_startups";

const columns = [
    { Header: 'Notes', accessor: 'notes', minWidth: 400,align: 'start', fixed: true },
    { Header: 'Created by', accessor: 'created_by', minWidth: 50,align: 'center', },
    { Header: 'Date', accessor: 'date', minWidth: 50, align: 'center' },
    { Header: 'Actions', accessor: 'actions', minWidth: 50, align: 'center' },

]
const CardHeader = ({ title, actionText, action: Actions }) => {
    return <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={"1rem"}>
        <Typography fontWeight={700} fontSize={"1.25rem"}>
            {title}
        </Typography>
        {/* {<Actions />} */}
    </Box>
}

const AdminNotes = ({ handleClose, handleSubmit, data: startupDetail , startup_id}) => {
    const theme = useTheme();
    const userDetails = useSelector((state) => state?.investorDetails?.data);
      const { mutateAsync: fetchAllAdminNotes, isLoading: isLoadingfetchAllAdminNotes, status: fetchAllAdminNotesStatus, error: fetchAllAdminNotesError } = FetchAllAdminNotes();
    const {
        mutateAsync: saveAdminNotesAsync, 
        isLoading:isSavingNotes,
    } = SaveAdminNotes();
    const [currentRow, setCurrentRow] = React.useState(null);
      const handleAdminNotesSubmit = ({ row, values }) => {
        let request = {
            startup_id: row?.startup_id,
            investor_id: userDetails?._id,
            notes: String(values?.notes)?.trim()
        }
        saveAdminNotesAsync(request).then(() => {
            refetchListing();
        })
        // handleManageNotesClose();
    }
    const {
        mutateAsync: deleteAdminNotesAsync,
        isLoading: isdeletingReportDocument,
        status: deleteReportDocumentStatus,
        error: deleteReportDocumentError,
        data: deleteReportDocumentResponse
      } = DeleteAdminNotes();
      
    const RowActions = ({row}) => {
        // console.log(row,"row")
        return <React.Fragment>
          <IconButton onClick={() =>{
            handleOpenDeletePopup({row: row?.original})
          }}>
            <DeleteIcon />
          </IconButton>
        </React.Fragment>
      }
      const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
      const handleOpenDeletePopup = ({row}) => {
          setOpenDeletePopup(true);
          setCurrentRow(row)
      }
      const handleCloseDeletePopup = () => {
          setOpenDeletePopup(false);
          setCurrentRow(null)
      }
      const handleDeletion = ({values}) => {
        const payload = {
            "note_id": currentRow?._id,
        }
        deleteAdminNotesAsync(payload, {
              onSuccess: (succ) => {
                  toast.success(
                      <CustomToast message={"Notes Deleted!"} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                      {
                          duration: 2000,
                          position: "top-right",
                      }
                  );
                  refetchListing();
              },
              onError: (err) => {
                  toast.error(
                      <CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                      {
                          duration: 2000,
                          position: "top-right",
                      }
                  );
              }
          });
          handleCloseDeletePopup();
      }
        const [allAdminNotes, setAllAdminNotes] = React.useState(null);
        React.useEffect(() => {
            refetchListing();
        }, [userDetails?._id]);
      const refetchListing = () => {
        userDetails?._id &&  fetchAllAdminNotes(
          {
            payload: {
                startup_id: startup_id,
                investor_id: userDetails?._id,
            },
          },
          {
            onSuccess: (res) => {
              if (res.data.data) {
                setAllAdminNotes(res.data.data?.getNotes)
              }
              else {
                setAllAdminNotes([])
              }    
            },
            onError: (err) => {
              console.error("API Error:", err);
            },
          })
      };
      const useFormattedData = (transformedData) => {
        return React.useMemo(() => {
          return transformedData && transformedData?.length > 0
            ? transformedData?.map((item) => {
              return ({
                ...item,
                notes:item?.notes,
                date:item?.formatted_date,
                created_by:item?.first_name + " " + item?.last_name,
              })
            })
            : [];
        }, [transformedData]);
      };
    const FormattedData = useFormattedData(allAdminNotes);
    const validations = Yup.object(
        {
            notes: Yup.string().required("Notes Required"),
            
        }
    )
    const formik = useFormik({
        initialValues: {
            notes: "",
        },
        validateOnMount: false,
        validationSchema: validations,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values, { setSubmitting , resetForm}) => {
            handleAdminNotesSubmit({ row:{startup_id}, values })
            resetForm();
        },
    });

    return (
        <>
        <DeleteConfirmationDialog open={openDeletePopup} handleClose={handleCloseDeletePopup} handleSubmission={handleDeletion} subheading={"Are you sure you want to delete this notes?"}  />
        <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden'}}>
        <CardHeader title={`Manage Admin Notes for ${startupDetail?.startup_name}`}/>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid display={"grid"} container padding={"0rem"} gap={"1.5rem"}>
                        <Grid item container gap={1.5}>
                            <Grid item xs={12}>
                                <CustomInputField
                                    name={`notes`}
                                    labelStyle={{ marginTop: '0rem', fontWeight: 500, fontSize: '0.8rem', color: 'rgba(0, 0, 0, 0.87) !important' }} onChange={(e) => {
                                        const value = e.target.value;
                                        if (value.length <= 250) {
                                            formik.setFieldValue("notes", e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))
                                        }
                                        else {
                                            formik.setFieldValue("notes", String(e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))?.substring(0, 250))
                                        }
                                    }} multiline minRows={4} maxRows={4} maxLength={250} fullWidth data-problem-solving={250 - formik.values?.notes?.length + `${(250 - formik.values?.notes?.length) > 1 ? " characters" : " character"} left`}
                                    sx={{
                                        "::after": {
                                            content: 'attr(data-problem-solving)',
                                            position: "absolute",
                                            bottom: formik?.errors?.notes ? 30 : 5,
                                            // bottom: 5,
                                            right: 5,
                                            background: 'white',
                                            color: 'graytext',
                                            padding: "0 5px",
                                            fontSize: "0.75rem"
                                        },
                                        marginBottom: 0
                                    }}
                                    value={formik.values?.notes}
                                    placeholder=""
                                />

                            </Grid>

                            <Grid item xs={12}>
                            <B300

                                type="submit"
                                // disabled
                                sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem" , height:"54px" , width:'77px' }}
                               
                            >
                                { isSavingNotes
                                ?
                                <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.4rem'} /> 
                                : "Save"}
                            </B300>
                        
                            </Grid>
                            <Divider sx={{ width: '100%' ,marginBottom:2, marginTop:2 }}  />
                           {!allAdminNotes ? <Grid item xs={12} height={"100vh"} sx={{ width: '100%', padding: "2rem", paddingLeft: "2rem !important", paddingTop: "0rem !important", bgColor: theme.palette.customColors.yellow_bg }} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }}>
                                                    <Box className='skeleton' minHeight={"70vh"} sx={{ borderRadius: "8px", border: `1px solid ${theme.palette.customColors.b50}` }} marginTop={0}>
                            
                                                    </Box>
                                                </Grid> :
                            <Box width={"100%"} height="auto" maxWidth={"100%"} overflow={"hidden"}>
                                    <AsyncTableListing
                                        columns={columns}
                                        tabValue={[]}
                                        data={FormattedData}
                                        RowActions={RowActions}
                                        noDataTitle="No Notes"
                                        noDataSubTitle="It seems like there are no notes."
                                        noDataPlaceHolderImage={placeHolderImagefeedback}
                                        pagination={false}
                                        sx={{ borderRadius: '8px', border: `1px solid ${theme.palette.customColors.b50}` }}
                                    />
                                </Box>
}
                        </Grid>
                    </Grid>
                </form>
            </FormikProvider>
        </Card>
        </>
    );
};

export default AdminNotes;
