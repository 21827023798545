import {  FormControlLabel, Grid, RadioGroup,} from "@mui/material";
import React from "react";
import CustomAutoComplete from "ui-components/CustomAutoComplete";
import CustomPhone from "ui-components/CustomPhone";
import CustomUrlField from "ui-components/CustomUrlField";
import CustomInputField from "ui-components/formik/CustomInputField";
import { AddStartup, FetchAllBusinessModel, FetchAllCategories, FetchAllCities, FetchAllCountries, FetchAllCountriesWithPhoneCodes, FetchAllStages, FetchAllStates, FetchAllSubCategories, FetchStartupDetails } from "api";
import { ReactComponent as gpsIcon } from "assets/gps.svg"
import { FieldArray, useFormikContext } from "formik";
import { useTheme } from "@emotion/react";
import CustomLogoButton from 'ui-components/formik/CustomLogoButton'
// import placeHolderImage from "assets/placeholder_image.png"
import placeHolderImage from "assets/no_data_images/placeholder_startup.png"
import CustomLabel from "ui-components/CustomLabel";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import CustomValidationText from "ui-components/CustomValidationText";
import CustomRadio from "ui-components/CustomRadio";
import { LocalMallOutlined, MemoryOutlined, MiscellaneousServicesOutlined, StorefrontOutlined } from "@mui/icons-material";
import { FetchViewStartupDetails } from "api/backrr_startups";




const StartupInformation = ({isStartupDetailsLoading = false}) => {
    const formik =useFormikContext()
    const theme = useTheme()
    const [currentSubCategory, setCurrentSubCategory] = React.useState('');
    const [searchSubCategory, setSearchSubCategory] = React.useState('');
    const [selectedCategory, setSelectedCategory] = React.useState("");
    const [currentBusinessModel, setCurrentBusinessModel] = React.useState('');
    const [searchBusinessModel, setSearchBusinessModel] = React.useState('');
    // const [selectedBusinessModel, setSelectedBusinessModel] = React.useState("");


  const { data: startup_categories, isRefetching: isRefetchingCategories, isLoading: isLoadingCategories, refetch: refetchCategories } = FetchAllCategories()
  const { data: startup_stages, isRefetching: isRefetchingRoundTypes, isLoading: isLoadingRoundTypes, refetch: refetchRoundTypes } = FetchAllStages("round");
  const { data: startup_sub_categories, isRefetching: isRefetchingSubCategories, isLoading: isLoadingSubCategories, refetch: refetchSubCategories } = FetchAllSubCategories({ category: selectedCategory })
  // const { data: startupDetailsData, isLoading: isStartupDetailsLoading, refetch: refetchStartupDetails } = FetchViewStartupDetails()
  const { data: startup_business_model , isRefetching: isRefetchingBusinessModel, isLoading: isLoadingBusinessModel, refetch: refetchBusinessModel } = FetchAllBusinessModel({  })

  const isLoading = (isStartupDetailsLoading) || isLoadingRoundTypes;
  // Effect to set selected category based on formik values
  React.useEffect(() => {
    if (!isStartupDetailsLoading && !isLoadingCategories && formik.values && Array.isArray(startup_categories)) {
      const category = startup_categories.find(item => item?._id === formik?.values?.category_id);
      if (category) {
        setSelectedCategory(category.name);  // Or whichever property you want to use
      }
    }
  }, [isStartupDetailsLoading, isLoadingCategories, formik.values, startup_categories, formik?.values?.category_id]);

  // Effect to refetch subcategories when selectedCategory changes
  React.useEffect(() => {
    if (!isRefetchingCategories && !isLoadingCategories && selectedCategory) {
      refetchSubCategories();
    }
  }, [selectedCategory, isRefetchingCategories || isLoadingCategories,]);

  React.useEffect(() => {
    if (formik.values.sector_id && startup_sub_categories) {
      const matchedSubCategory = startup_sub_categories.find(subCat => subCat._id === formik.values.sector_id);
      if (matchedSubCategory) {
        setCurrentSubCategory({ label: matchedSubCategory?.sub_category, value: matchedSubCategory?._id, description: matchedSubCategory?.description });
      }
    }
  }, [formik.values.sector_id, startup_sub_categories]);
  React.useEffect(() => {
    if (!isLoading && !isLoadingBusinessModel && formik?.values && Array.isArray(startup_business_model) && Array.isArray(formik?.values.business_model_id)) {
      const businessModel = startup_business_model?.filter(item => formik?.values?.business_model_id?.includes(item?._id) );
      if (businessModel.length) {
          setCurrentBusinessModel(
              businessModel.map(item => ({
                  label: item?.name,
                  value: item?._id,
                  description: item?.description,
              }))
          );
      }
    }
}, [isLoading, isLoadingBusinessModel, formik?.values, startup_business_model, formik?.values?.business_model_id ]);

  const handleSearchSubCategoryChange = (event, value) => {
    // console.log(event.target.value, value,"ca")
    setSearchSubCategory(value);
    // refetchCities();
  };
  const handleSelectionSubCategoryChange = (event, sector) => {
    // console.log(value, "value")
    formik.setFieldValue("sector_id", sector ? sector?.value : '');
    setCurrentSubCategory(sector)
    event.preventDefault();
  };
  const handleSearchUpdateSubCategory = () => {
    refetchSubCategories();
  }
  const handleSearchBusinessModelChange = (event, value) => {
    // console.log(event.target.value, value,"ca")
    setSearchBusinessModel(value);
    // refetchCities();
};
const handleSelectionBusinessModelChange = (event, businessModel) => {
    formik.setFieldValue("business_model_id", businessModel ? businessModel?.map(item => item?.value) : []);
    setCurrentBusinessModel(businessModel)
    event.preventDefault();
};
const handleSearchUpdateBusinessModel = () => {
    refetchBusinessModel();
}
const availableOptions = startup_business_model?.filter(
  item =>
    !(currentBusinessModel || []).some(selected => selected.value === item._id)
) || [];
  return (
    <>
       <Grid item xs={12} sm={12} mb={2}>
                              <CustomLogoButton label="LOGO" handleUpdateFile={(file) => { formik.setFieldValue("startup_logo", file) }} name={"startup_logo"} value={formik.values?.startup_logo} placeholder={placeHolderImage} Pheight={100} Pwidth={100} />
                              {/* <CustomUploadButton label="LOGO" handleUpdateFile={(evt) => { formik.setFieldValue("startup_logo", evt.target.files[0]) }} selectedFile={formik.values.startup_logo} /> */}
                            </Grid>
                            <Grid item xs={12} sm={12} height={"min-content"}>
                              <CustomInputField
                                name={`startup_name`}
                                label={"STARTUP NAME"}
                                placeholder={"Enter startup name"}
                                value={formik.values?.startup_name}
                                onChange={(e) =>
                                  (e.target.value?.length <= 30) && formik.setFieldValue(
                                    `startup_name`,
                                    e.target.value?.charAt(0)?.toUpperCase() +
                                    e.target.value?.slice(1)
                                  )
                                }
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <CustomInputField value={formik.values.entity_name} label={"LEGAL ENTITY NAME"} placeholder={"Enter legal entity name"} name="entity_name" onChange={(e) => formik.setFieldValue("entity_name", e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
                            </Grid>
                            <Grid item xs={12} paddingBottom={2}>
                              {/* {console.log(formik.values?.formation_date,"formik.values?.formation_date")} */}
                              <CustomLabel>FORMATION DATE</CustomLabel>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                  label=""
                                  sx={{ width: "100%", background: "#fff", marginBottom: 0 }}
                                  // Parse the ISO string to a Moment object
                                  value={formik.values?.formation_date ? moment(formik.values.formation_date) : null}
                                  disableFuture={true}
                                  onChange={(date) => {
                                    // if (date?.isValid()) {
                                    // Convert Moment object back to ISO string
                                    formik.setFieldValue('formation_date', date != null ? date.toISOString() : null);
                                    // }
                                  }}
                                  renderInput={(params) => (
                                    <CustomInputField
                                      sx={{ padding: "10px 14px" }}
                                      disabled
                                      fullWidth
                                      {...params}
                                    />
                                  )}
                                  inputFormat="DD/MM/YYYY"
                                  autoComplete="off"
                                />
                              </LocalizationProvider>
                              {formik.errors?.formation_date && <CustomValidationText value={formik.errors.formation_date} />}
                              {/* )
                                                    } */}
                            </Grid>
                            <Grid item container xs={12} gap={1} display={"flex"} paddingBottom={2}>
                              <Grid item xs={12}>
                                <CustomLabel sx={{ marginBlock: "0rem" }}>WHAT IS THE STAGE OF YOUR STARTUP? {formik.errors.stage_id && (
                                  <CustomValidationText value={formik.errors.stage_id} />
                                )}</CustomLabel>
                              </Grid>

                              <Grid
                                display={"flex"}
                                justifyContent={"space-between"}
                                // flexWrap={"wrap"}
                                item
                                container
                                xs={12}
                                gap={"1rem"}
                              >
                                {startup_stages?.map((type, index) => {
                                  return (
                                    <Grid
                                      role={"button"}
                                      item
                                      sx={{ height: { xs: "7rem", sm: "7rem", md: "7rem", lg: "7rem", xl: "6.5rem" } }}
                                      xs={5.5}
                                      sm={3.7}
                                      md={3.6}
                                      xl={2.5}
                                    >
                                      <button
                                        type="button"
                                        onClick={() => {
                                          formik.setFieldValue(
                                            "stage_id",
                                            type?.value
                                          );
                                        }}
                                        style={{
                                          background:
                                            formik.values?.stage_id == type?.value
                                              ? theme?.palette?.customColors?.y50
                                              : theme?.palette?.customColors
                                                ?.whiteBG,
                                          padding: "12px 12px 0 12px",
                                          paddingRight: 0,
                                          minHeight: 100,
                                          height: "100%",
                                          borderRadius: "8px",
                                          border: `1px solid ${formik.values?.stage_id == type?.value
                                            ? theme?.palette?.customColors?.y300
                                            : theme?.palette?.customColors?.g75
                                            }`,
                                          boxShadow: "0px 1px 2px 0px #1018280D",
                                          cursor: "pointer",
                                          fontWeight: "0.875rem",
                                          color: theme.palette.customColors.g200,
                                          width: "100%",
                                          display: "flex",
                                          position: 'relative',
                                          overflow: 'hidden',
                                        }}
                                      >
                                        <span style={{ fontSize: '0.875rem', position: 'absolute', left: 5, top: 5 }}>{type?.name}</span>
                                        <img rel='preload' src={type?.image_url} style={{ ...type?.size, marginLeft: 'auto' }} />
                                      </button>
                                    </Grid>
                                  );
                                })}
                                <Grid
                                  // role={"button"}
                                  item
                                  sx={{ height: { xs: "7rem", sm: "7rem", md: "7rem", lg: "7rem", xl: "6.5rem" } }}
                                  xs={5.5}
                                  sm={3.7}
                                  md={3.6}
                                  xl={2.5}
                                >

                                </Grid>
                              </Grid>

                              {/* <Grid item xs={12} lg={12} mt={1} height={"min-content"}>
                                <CustomLabel>
                                  HAVE YOU RAISED FUNDS EARLIER?
                                </CustomLabel>
                                <RadioGroup
                                  sx={{ flexDirection: "row", paddingBottom: 0 }}
                                  name="raised_round"
                                  value={formik.values?.raised_round}
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      `raised_round`,
                                      e.target.value
                                    )
                                  }
                                >
                                  <FormControlLabel
                                    value={true}
                                    control={<CustomRadio disableRipple />}
                                    label={"Yes"}
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={<CustomRadio disableRipple />}
                                    label={"No"}
                                  />
                                </RadioGroup>
                              </Grid> */}
                            </Grid>
                            <Grid item xs={12}>
                              <CustomLabel sx={{ marginTop: "0rem" }}>Select Category {formik.errors?.category_id && (
                                <CustomValidationText value={formik.errors?.category_id} />
                              )}</CustomLabel>
                            </Grid>
                            <Grid item container xs={12} display={"flex"} gap={2} paddingBottom={{ xs: 1 }} sx={{ justifyContent: 'space-between' }}>
                              <FieldArray
                                name="category_id"
                                render={arrayHelpers => (
                                  <>

                                    {Array.isArray(startup_categories) && startup_categories?.map((category, index) => {
                                      const isSelected = formik?.values?.category_id?.includes(category?._id);
                                      return (
                                        <Grid
                                          item
                                          xs={12}
                                          sm={5.8}
                                          md={5.75}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 0,
                                            background: isSelected ? "#FFFAE6" : "#FFF",
                                            fontSize: "0.875rem",
                                            border: `1px solid ${isSelected ? "#FFC900" : "#A1A3A7"}`,
                                            borderRadius: "8px",
                                            paddingRight: "1rem",
                                            paddingLeft: "1rem",
                                            paddingBlock: '1.5rem',
                                            height: "2.5rem",
                                            cursor: "pointer",
                                            textTransform: 'none',
                                            color: 'inherit',
                                            // opacity: formik?.values?.category_id?.length >= 1 && !isSelected ? 0.5 : 1, // Dims unselected options when max is reached
                                          }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            formik.setFieldValue("category_id", category?._id)
                                            setSelectedCategory(category?.name);
                                            formik.setFieldValue("sector_id", '');
                                            setCurrentSubCategory('')
                                            // if (isSelected) {
                                            // arrayHelpers.remove(formik.values?.category_id.indexOf(category?._id));
                                            // } else if (formik.values?.category_id?.length < 1) {
                                            // arrayHelpers.push(category?._id);
                                            // }
                                          }}
                                          key={category?._id}
                                        >
                                          {/* <img
                                                                            src={sector?.image_url}
                                                                            rel='preload'
                                                                            style={{
                                                                                minHeight: "1rem",
                                                                                maxHeight: "1rem",
                                                                                height: "100%",
                                                                            }}
                                                                            loading='lazy'
                                                                        /> */}
                                          {/* <i style={{ ...sector?.style }}>

                                                                                    </i> */}

                                          {index === 0 && <MemoryOutlined sx={{ fontSize: '1.6rem', marginRight: '0.5rem' }} />}
                                          {index === 1 && <StorefrontOutlined sx={{ fontSize: '1.4rem', marginRight: '0.5rem' }} />}
                                          {index === 2 && <LocalMallOutlined sx={{ fontSize: '1.4rem', marginRight: '0.5rem' }} />}
                                          {index === 3 && <MiscellaneousServicesOutlined sx={{ fontSize: '1.4rem', marginRight: '0.5rem' }} />}

                                          <span> {category?.name} </span>
                                          {/* <span> {index == 0 ? "Technology  (Product / Software)" : index == 1 ? 'Consumer Brand' : index === 2 ? "Marketplace" : 'Services'} </span> */}
                                        </Grid>
                                      );
                                    })}
                                  </>
                                )}
                              />
                            </Grid>
                            {/* </Grid> */}
                            <Grid item xs={12} mt={1}>
                              {<CustomAutoComplete
                                options={!isLoadingSubCategories && !isRefetchingSubCategories && Array.isArray(startup_sub_categories) ? startup_sub_categories?.map(item => ({ label: item?.sub_category, value: item?._id, description: item?.description })) : []}
                                label="Select Sector"
                                name={"sector_id"}
                                noOptionsText={!isLoadingSubCategories && !isRefetchingSubCategories ? "No Options" : "Searching..."}
                                placeholder="Type a sector name"
                                icon={null}
                                handleSearchChange={handleSearchSubCategoryChange}
                                handleSelectionChange={handleSelectionSubCategoryChange}
                                search={searchSubCategory}
                                renderOption={(props, option) => (
                                  <li {...props} style={{ display: 'grid' }}>
                                    <span style={{ fontWeight: 600 }}>{option.label}</span>
                                    <p style={{ fontSize: '0.8rem' }}>{option?.description}</p>
                                    {/* {option.isSpecial && <span className="special-badge">Special</span>} */}
                                  </li>
                                )}
                                value={currentSubCategory}
                                defaultValue={startup_sub_categories?.find(item => item?._id == formik?.values?.sector_id)}
                                loading={isRefetchingSubCategories}
                                handleSearchUpdate={handleSearchUpdateSubCategory}
                              />}
                            </Grid>
                            <Grid item xs={12}>
                {<CustomAutoComplete
                    options={!isLoadingBusinessModel && !isRefetchingBusinessModel && Array.isArray(availableOptions) ? availableOptions?.map(item => ({ label: item?.name, value: item?._id, description: item?.description })) : []}
                    label="SELECT BUSINESS MODEL (MULTI SELECT)"
                    name={"business_model_id"}
                    multiple={true}
                    noOptionsText={!isLoadingBusinessModel && !isRefetchingBusinessModel ? "No Options" : "Searching..."}
                    placeholder="Business model"
                    icon={null}
                    handleSearchChange={handleSearchBusinessModelChange}
                    handleSelectionChange={handleSelectionBusinessModelChange}
                    search={searchBusinessModel}
                    renderOption={(props, option) => (
                        <li {...props} style={{ display: 'grid' }}>
                            <span style={{ fontWeight: 600 }}>{option.label}</span>
                            <p style={{ fontSize: '0.8rem' }}>{option?.description}</p>
                            {/* {option.isSpecial && <span className="special-badge">Special</span>} */}
                        </li>
                    )}
                    value={currentBusinessModel || []}
                    defaultValue={startup_business_model?.filter(item => item?._id == formik?.values?.business_model_id )}
                    loading={isRefetchingBusinessModel}
                    handleSearchUpdate={handleSearchUpdateBusinessModel}
                />}
            </Grid>
    </>
  )
}

export default StartupInformation
