import { useTheme } from '@emotion/react';
import { capitalize, Grid, Typography, useMediaQuery } from '@mui/material';
import { useConfig } from 'dynamic-configuration/configContext';
import { useFormikContext } from 'formik';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import CustomLabel from 'ui-components/CustomLabel';
import CustomValidationText from 'ui-components/CustomValidationText';
import CustomInputField from 'ui-components/formik/CustomInputField';
import { currencyInputProps } from '../../../utils';
import CustomPhone from 'ui-components/CustomPhone';
import { FetchAllCountriesWithPhoneCodes } from 'api/utils';
import CustomUrlField from 'ui-components/CustomUrlField';

const OrganizationDetails = ({ investor_type }) => {
    const shiftSteps = investor_type ? String(investor_type)?.toLowerCase()?.includes("angel") : true;
    const formik = useFormikContext();
    const { config } = useConfig()
    const theme = useTheme();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const isTablet = useMediaQuery(theme.breakpoints.up('sm'))
    const { data: countriesWithPhoneCodes, isRefetching: isRefetchingCountriesPC, isLoading: isLoadingCountriesPC, refetch: refetchCountriesPC } = FetchAllCountriesWithPhoneCodes({})
    return (
        <Grid container xs={12} gap={0} marginTop={0} display={"flex"} alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"space-between"}>
            <Grid height={"min-content"} item xs={12} sm={12}>
                <CustomInputField name={`organization_name`} label={"Organization Name"} placeholder={"Enter organization name"} onChange={(e) => formik.setFieldValue("organization_name", capitalize(e.target.value))} fullWidth />
            </Grid>
            {/* {formik.values?.country_code &&  */}
            <Grid height={"min-content"} item xs={12}>
                <CustomPhone name={`phone_number`} selectedCode={formik.values?.country_code ? formik.values?.country_code : ""} handleChange={(event) => formik.setFieldValue("country_code", event.target.value)} countryCodes={countriesWithPhoneCodes ? countriesWithPhoneCodes : []} label='Office Contact Number' placeholder="Enter your office contact number" onChange={(e) => formik.setFieldValue(`phone_number`, e.target.value)} />
            </Grid>
            {/* } */}
            <Grid height={"min-content"} item xs={12} sm={12} md={12} lg={12}>
                <CustomUrlField
                    label={"Website URL"}
                    name={'organization_website'}
                    value={
                        String(formik?.values?.organization_website)?.startsWith("https://")
                            ? String(formik?.values?.organization_website)?.replace("https://", "")
                            : formik?.values?.organization_website
                    }
                    placeholder={"Enter website URL"}
                    onChange={(evt) => {
                        formik.setFieldValue("organization_website", String(evt.target.value)?.startsWith("https://")
                            ? String(evt.target.value)?.replace("https://", "")
                            : evt.target.value)
                    }}
                />
            </Grid>
            {!shiftSteps && <Grid height={"min-content"} item xs={12} sm={12} md={12} lg={12}>
                <CustomInputField name={`bio`} placeholder={"A brief overview of your organization's mission or tagline."} label={<>{"BIO"}<span style={{ color: theme?.palette?.customColors?.g100, textTransform: "none" }}> (Optional)</span></>} fullWidth />
            </Grid>}
        </Grid>
    )
}

export default OrganizationDetails