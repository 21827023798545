import { useTheme } from '@emotion/react';
import { Grid, MenuItem, Select, Slider } from '@mui/material';
import { FetchAllInvestmentSize } from 'api/utils';
import { useConfig } from 'dynamic-configuration/configContext';
import { useFormikContext } from 'formik';
import React from 'react';
import CustomLabel from 'ui-components/CustomLabel';
import { getMarks } from 'utils/index';

const InvestmentRange = ({ isLoading }) => {
  const { config } = useConfig();
  const formik = useFormikContext();
  const theme = useTheme();

  const {
    data: investmentSizeList,
    isLoading: isLoadingInvestmentSizeList,
  } = FetchAllInvestmentSize();

  const [currency, setCurrency] = React.useState("INR");
  const [currencyOptions, setCurrencyOptions] = React.useState([]);
  const [value1, setValue1] = React.useState([0, 3]);

  React.useEffect(() => {
    if (!isLoading && !isLoadingInvestmentSizeList && investmentSizeList) {
      setCurrencyOptions(investmentSizeList.currencies || []);
      if (!formik.values?.investment_amount_type) {
        const defaultCurrency = investmentSizeList.currencies[0]?.name || "INR";
        setCurrency(defaultCurrency);
        formik.setFieldValue("investment_amount_type", defaultCurrency);
      } else {
        setCurrency(formik.values.investment_amount_type);
      }
    }
  }, [investmentSizeList, isLoading, isLoadingInvestmentSizeList, formik.values?.investment_amount_type]);

  React.useEffect(() => {
    if (!isLoading && formik.values?.min && formik.values?.max) {
      const minIndex = getMarks(currency).findIndex(item => item.selectedValue === formik.values.min) || 0;
      const maxIndex = getMarks(currency).findIndex(item => item.selectedValue === formik.values.max) || 3;
      setValue1([minIndex, maxIndex]);
    }
  }, [currency, formik.values, isLoading]);

  const handleCurrencyChange = (event) => {
    const newCurrency = event.target.value;
    setCurrency(newCurrency);
    formik.setFieldValue("investment_amount_type", newCurrency);
    setValue1([0, 3]);
    formik.setFieldValue('min', getMarks(newCurrency)[0].selectedValue);
    formik.setFieldValue('max', getMarks(newCurrency)[3].selectedValue);
  };

  const handleChange1 = (event, newValue) => {
    if (Array.isArray(newValue)) {
      const minValue = Math.min(newValue[0], value1[1] - 1);
      const maxValue = Math.max(newValue[1], value1[0] + 1);
      setValue1([minValue, maxValue]);
      formik.setFieldValue('min', getMarks(currency)[minValue].selectedValue);
      formik.setFieldValue('max', getMarks(currency)[maxValue].selectedValue);
    }
  };

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between" marginTop="-0.75rem">
        <Grid item>
          <CustomLabel sx={{ marginBottom: 0 }}>
            {config?.validationFields?.size?.label}
          </CustomLabel>
        </Grid>
        <Grid item display="flex" alignItems="center">
          <Select
            value={!isLoadingInvestmentSizeList ? currency : "INR"}
            onChange={handleCurrencyChange}
            sx={{
              border: 'none',
              color: '#344054',
              fontSize: '0.875rem',
              borderRadius: 0,
              padding: 0,
              minWidth: '80px',
              boxShadow: 'none',
              '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
              '&:focus': { outline: 'none' },
            }}
          >
            {currencyOptions?.map((item) => (
              <MenuItem key={item.name} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="space-between" flexWrap="wrap" container gap="1rem">
        <Slider
          sx={{
            paddingBlock: '3rem 3rem',
            marginLeft: currency === "INR" ? '1rem' : "1.5rem",
            marginRight: '2rem',
            color: theme?.palette?.customColors.indigo600,
            '& .MuiSlider-thumb': {
              width: 20,
              height: 20,
              backgroundColor: '#ffffff',
              border: `2px solid ${theme?.palette?.customColors.indigo600}`,
              '&:hover, &.Mui-focusVisible, &.Mui-active': {
                boxShadow: '0px 0px 0px 8px rgba(25, 118, 210, 0.16)',
              },
            },
            '& .MuiSlider-track': {
              height: 8,
              borderRadius: 4,
            },
            '& .MuiSlider-rail': {
              height: 8,
              borderRadius: 4,
              opacity: 0.3,
              backgroundColor: '#bfbfbf',
            },
            '& .MuiSlider-markLabel': {
              fontSize: '0.875rem',
              color: '#333',
              top: '15px !important'
            },
            '& .MuiSlider-valueLabel': {
              backgroundColor: '#1976d2',
              fontSize: '0.75rem',
              top: -10,
              display: "none",
            },
          }}
          value={value1}
          min={0}
          max={getMarks(currency)?.length - 1}
          step={1}
          marks={getMarks(currency)}
          onChange={handleChange1}
          valueLabelDisplay="auto"
          disableSwap
        />
      </Grid>
    </>
  );
};

export default InvestmentRange;
