import React, { useRef } from "react";
import { ReactComponent as NameTypeIcon } from "assets/rounds_icons/name_type_round_icon.svg";
import { ReactComponent as RoundSizeIcon } from "assets/rounds_icons/round_size_val_round_icon.svg";
import { ReactComponent as CurrentInvestorsIcon } from "assets/rounds_icons/current_investors_round_icon.svg";
import { ReactComponent as FundUtilizationIcon } from "assets/rounds_icons/fund_util_round_icon.svg";
import { ReactComponent as CurrentTractionIcon } from "assets/rounds_icons/current_traction_round_icon.svg";
import { ReactComponent as DocumentsIcon } from "assets/rounds_icons/documents_round_icon.svg";
import { ReactComponent as FAQIcon } from "assets/rounds_icons/faq_round_icon.svg";
import { ReactComponent as ExitStrategyIcon } from "assets/rounds_icons/exit_strategy_round_icon.svg";
import { ReactComponent as AddIcon } from "assets/rounds_icons/plus_icon.svg";
import { ReactComponent as DeleteIcon } from "assets/rounds_icons/delete_icon.svg";
import { ReactComponent as EditIcon } from "assets/rounds_icons/edit_icon.svg";
import EditFounderIcon from "assets/edit.png"
import DeleteFounderIcon from "assets/delete.png"
import { ReactComponent as UploadDocumentsIcon } from "assets/rounds_icons/upload_documents_icon.svg";
import { ReactComponent as SavingIcon } from "assets/saving_icon.svg";
import linkedInIcon from 'assets/linkedin_icon.png'
import TopIcon from "assets/rounds_icons/TopIcon.svg";
// import TopIcon from "assets/rounds_icons/top_icon.svg";
import BottomIcon from "assets/rounds_icons/BottomIcon.svg";
import AddCoFounderIcon from "assets/add_button.png"
// import BottomIcon from "assets/rounds_icons/bottom_icon.svg";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Select,
  styled,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  IconButton,
  Tooltip
} from "@mui/material";
import {
  BLUEBORDER,
  SECONDARY,
  B300 as CustomButton,
  WHITEBG,
} from "ui-components/CustomButton";
import { useTheme } from "@emotion/react";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import CustomInputField from "ui-components/formik/CustomInputField";
import CustomLabel from "ui-components/CustomLabel";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  FetchAllCurrencyLists,
  FetchAllRoundsSteps,
  CreateRound as CreateRoundAPI,
  UploadRoundDocuments,
  FetchAllStages,
  FetchAllSecurityTypes,
  FetchAllRoundStatus,
  FetchRoundDetails,
  DeleteRoundDocument,
  FetchAllDocumentTypes,
} from "api";
import {
  Close,
  Done,
  Edit,
  ForkLeft,
  KeyboardArrowDownRounded,
  Upload,
  Warning,
  WarningOutlined,
  WarningRounded,
} from "@mui/icons-material";

// import docxImage from "assets/Docx.png";
import CustomMenu from "ui-components/CustomMenu";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import PieChart from "ui-components/PieChart";
import CustomRichTextEditor from "ui-components/formik/CustomRichTextEditor";
import * as Yup from "yup";
import ThemeBGWrapper from "ui-components/ThemeBGWrapper";
import toast from "react-hot-toast";
import moment from "moment";
// import Round1 from "assets/rounds_icons/Component 1.png";
// import Round2 from "assets/rounds_icons/Component 2.png";
// import Round3 from "assets/rounds_icons/Component 3.png";
// import Round4 from "assets/rounds_icons/Component 4.png";
// import Round5 from "assets/rounds_icons/Component 5.png";
import Round1 from "assets/stage_icons/preSeed.png";
import Round2 from "assets/stage_icons/seed.png";
import Round3 from "assets/stage_icons/seriesA.png";
import Round4 from "assets/stage_icons/seriesB.png";
import Round5 from "assets/stage_icons/ipo.png";
import CustomInfoIcon from "ui-components/CustomInfoIcon";
import { TabContext } from "@mui/lab";
import CustomDatePicker from "ui-components/formik/CustomDatePicker";
import CustomToast from "ui-components/CustomToast";
import { containerRef, getFileIcon, handleFormSubmit, linkedInTestURL, websiteTestURL } from "utils";
import ClearIcon from 'assets/doc_icons/close_icon.svg'
import { ReactComponent as InfoIcon } from 'assets/help-circle.svg';
import CustomValidationText from "ui-components/CustomValidationText";
import CustomRadio from "ui-components/CustomRadio";
import CustomPhone from "ui-components/CustomPhone";
import { UpdateStartup, FetchAllCategories, FetchAllCities, FetchAllCountries, FetchAllCountriesWithPhoneCodes, FetchAllStates, FetchAllSubCategories, FetchStartupDetails } from "api";
import CustomAutoComplete from "ui-components/CustomAutoComplete";
import { ReactComponent as gpsIcon } from "assets/gps.svg"
import placeHolderImage from "assets/placeholder_image.png"
import CustomLogoButton from 'ui-components/formik/CustomLogoButton'
import { Build, LocalMallOutlined, MemoryOutlined, MiscellaneousServicesOutlined, StorefrontOutlined } from '@mui/icons-material'
import CustomUrlField from "ui-components/CustomUrlField";
import DeleteCoFounderIcon from "assets/delete.png"
import VerticalScrollableTabs from "ui-components/VerticalScrollableTabs";
import ContactInformation from "./ContactInformation";
import StartupInformation from "./StartupInformation";
import StartupDetails from "./StartupDetails";
import FounderInformation from "./FounderInformation";
import CoFounderInformation from "./CoFounderInformation";
import Cookies from "js-cookie";
import { FetchEditStartupDetails } from "api/backrr_startups";
import NeedHelpButton from "ui-components/NeedHelpButton";
import BreadcrumbHeader from "layout/BreadcrumbsHeader";
const referral_types = [
  {
    value: "Friends",
    label: "Friends",
  },
  {
    value: "Incubator",
    label: "Incubator",
  },
  {
    value: "LinkedIn",
    label: "LinkedIn",
  },
  {
    value: "Google",
    label: "Google",
  },
  {
    value: "Other",
    label: "Other",
  },
];


const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const roundImages = [
  Round1, Round2, Round3, Round4, Round5
]


const UpdateProfile = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const matchesUpXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchUpXl = useMediaQuery(theme.breakpoints.up('xl'));
  const navigate = useNavigate();
  let stepIndex = new URLSearchParams(window.location.search).get("page");
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentIndex, setCurrentIndex] = React.useState(Number(stepIndex) || 0);
  // const { id: round_id } = useParams();
  let cookieStartupId = Cookies.get("startup_id");
  // const [roundID, setRoundID] = React.useState(round_id || null);

  const { data: startupDetailsData, isLoading: isStartupDetailsLoading, refetch: refetchStartupDetails } = FetchEditStartupDetails({ startup_id: cookieStartupId })

  const { data: currencyList, isLoading: isLoadingCurrency } =
    FetchAllCurrencyLists();
  // const { data: roundSteps, isLoading: isLoadingRoundsSteps } =
  //   FetchAllRoundsSteps();
  const {
    mutateAsync: updateStartupAsync,
    isLoading: isUpdatingStartup,
    status: updatingStartupStatus,
    error: updatingStartupError,
    data: UpdateStartupResponse
  } = UpdateStartup({ setProgress: () => { }, startup_id: cookieStartupId });
  const [isPressed, setIsPressed] = React.useState(true)

  const { data: startup_stages, isRefetching: isRefetchingRoundTypes, isLoading: isLoadingRoundTypes, refetch: refetchRoundTypes } = FetchAllStages("round");
  const [search, setSearch] = React.useState("")
  const [searchState, setSearchState] = React.useState("")
  const [searchCountry, setSearchCountry] = React.useState("")
  const validationObj =
    currentIndex == 1
      ? {
        first_name: Yup.string("Enter First Name")
          .required("First name is required")
          .trim(),
        last_name: Yup.string("Enter Last Name")
          .required("Last name is required")
          .trim(),
        phone_code: Yup.string("Enter Phone Code")
          .required("Phone code is required")
          .trim(),
        mobile_number: Yup.string("Enter Mobile Number")
          .min(5, 'Mobile number must be at least 5 characters long')
          .max(15, 'Mobile number cannot be more than 15 characters long').required("Mobile number is required"),
        country_id: Yup.string("Select a Country")
          .required("Country is required")
          .trim(),
        city_id: Yup.string("Select a City")
          .required("City is required")
          .trim(),
        state_id: Yup.string("Select a State")
          .required("State is required")
          .trim(),
        website_url: Yup.string("Enter WEBSITE URL")
          .required("Website url is required")
          .trim().matches(websiteTestURL, "Please enter a valid website URL"),
        linkedin_url: Yup.string("Enter a LinkedIn URL")
          .trim().matches(linkedInTestURL, "Please enter a valid linkedIn URL"),
      }
      : currentIndex == 0
        ? {
          stage_id: Yup.string().required("Stage is required").min(1),
          startup_name: Yup.string("Enter startup name")
            .required("Startup name is required")
            .trim(),

          startup_logo: Yup.mixed()
            .test("file-type", "Only PNG, JPG, or JPEG files are allowed", (value) => {
              if (value && typeof value === 'object') {
                const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg'];
                return allowedTypes.includes(value.type);
              }
              return true; // If value is not an object, skip type validation
            }).nullable(),
          // startup_name: Yup.string("Enter Startup Name")
          //   .required("Startup name is required")
          //   .trim(),
          // entity_name: Yup.string("Enter Legal Entity Name"),
          // .required("Legal entity name is required")
          // .trim(),
          // formation_date: Yup.string("Enter Formation Date")
          //   // .required("Formation date is required")
          //   .trim(),
          category_id: Yup.string().required("Category is required."),
          sector_id: Yup.string().required('Sector is required.').matches(/^[a-fA-F0-9]{24}$/, 'Sector is required.'),
          business_model_id: Yup.array().required("Business Model is required.").min(1, "Business Model is required."),

        }
        : currentIndex == 2
          ? {
            pitch_deck: Yup.mixed()
              .test("file-upload", "Please upload pitch deck", (value) => {
                return value !== null;
              })
              .test("file-type", "Only PDF files are allowed", (value) => {
                if (value && typeof value === 'object') {
                  return value.type === 'application/pdf';
                }
                return true; // If value is not an object, skip type validation
              })
              .test("file-size", "File size should not exceed 5MB", (value) => {
                if (value && typeof value === 'object') {
                  return value.size <= 50 * 1024 * 1024; // 100MB in bytes
                }
                return true; // If value is not an object, skip size validation
              })
              .required("Pitch deck is required"),
            description: Yup.string().required('Startup overview is required')
              .test(
                'richText-not-empty',
                'Startup overview is required',
                value => {
                  // Check if the value is empty or just a new line
                  const strippedValue = value.replace(/<[^>]+>/g, '').trim();
                  return strippedValue !== '';
                }
              ),
            elevator_pitch: Yup.mixed()
              .test("file-size", "File size should not exceed 100MB", (value) => {
                if (value && typeof value === 'object') {
                  return value.size <= 100 * 1024 * 1024; // 100MB in bytes
                }
                return true; // If value is not an object, skip size validation
              })
              .test("file-type", "Only MP4 or M4V files are allowed", (value) => {
                if (value && typeof value === 'object') {
                  const allowedTypes = ['video/mp4', 'video/x-m4v'];
                  return allowedTypes.includes(value.type);
                }
                return true; // If value is not an object, skip type validation
              })
              .nullable(),
            problem_solving: Yup.string("Enter problem statement")
              .required("Problem statement is required")
              .trim(),
          }
          : currentIndex == 3 ? {
            founder: Yup.object().shape({
              name: Yup.string().required("Founder name is required"),
              bio: Yup.string().required("Founder bio is required"),
              title: Yup.string().required("Founder title is required"),
              image: Yup.mixed()
                .test("file-upload", "Please upload founder pic", (value) => {
                  return value !== null;
                })
                .required("Founder photo is required"),
              linkedin_url: Yup.string().required("Founder linkedin url is required")
            })
          } : currentIndex == 4
            ? {
              coFoundersArray: Yup.array().of(Yup.object().shape({
                name: Yup.string().required("Co-founder name is required"),
                linkedin_url: Yup.string().required("Co-founder linkedin url is required"),
                title: Yup.string().required("Co-Founder title is required"),
                bio: Yup.string().required("Co-Founder bio is required"),
                // image: Yup.mixed()
                //   .test("file-upload", "Please upload co-founder image", (value) => {
                //     return value !== null;
                //   })
                //   .required("Co-founder Image is required"),
                edit: Yup.boolean().notRequired(),
              }))
            }
            : currentIndex == 5
              ? {
                referral_type: Yup.string("Select referral medium").required("Referral medium is required"),
              }
              : {};

  const { data: countries, isRefetching: isRefetchingCountries, isLoading: isLoadingCountries, refetch: refetchCountries } = FetchAllCountries({ search: searchCountry })

  const validationSchema = Yup.object({ ...validationObj });
  const formik = useFormik({
    initialValues: !isStartupDetailsLoading && !isLoadingCountries && startupDetailsData ? startupDetailsData?.data : {
      // step 2
      first_name: "",
      last_name: "",
      mobile_number: "",
      phone_code: "91",
      country_id: null,
      state_id: null,
      city_id: null,
      linkedin_url: "",

      // step 1
      startup_logo: null,
      startup_name: "",
      entity_name: "",
      formation_date: null,
      stage_id: "",
      category_id: "",
      sector_id: "",
      business_model_id: [],
      pitch_deck: null,
      elevator_pitch: null,
      problem_solving: "",
      website_url: "",
      founder: {
        name: "",
        bio: "",
        title: "",
        image: null,
        linkedin_url: ""
      },
      coFoundersArray: [
        {
          name: "",
          title: "",
          bio: "",
          image: null,
          linkedin_url: "",
          edit: true,
        },
      ],
      referral_type: "",
      referral_name: "",
      step: 1
    },
    validationSchema: validationSchema,
    // validate: (values) => { console.log(values, "valueseferfe"); console.log(formik.errors, "err") },
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();

      switch (currentIndex) {
        case 1:
          formData.append("first_name", values.first_name)
          formData.append("last_name", values.last_name);
          formData.append("mobile_number", values?.mobile_number);
          formData.append("phone_code", values?.phone_code);
          formData.append("country_id", values.country_id);
          formData.append("state_id", values.state_id);
          formData.append("city_id", values.city_id);
          formData.append("website_url", values.website_url);
          formData.append("linkedin_url", values.linkedin_url);
          break;

        case 0:
          formData.append("stage_id", values.stage_id);
          // formData.append("raised_round", true);
          if (typeof values.startup_logo != 'string' && values.startup_logo != null) {
            formData.append("startup_logo", values.startup_logo);
          }
          values.startup_name && formData.append("startup_name", values.startup_name);
          if (values?.entity_name != "") { formData.append("entity_name", values.entity_name); }
          // formData.append("formation_date", formationDate?.toISOString());
          if (values?.formation_date != null) { formData.append("formation_date", moment(values.formation_date)?.toISOString()) };
          // values.sector_id?.length > 0 && values.sector_id?.forEach((current, index) => {
          //   formData.append("sector_id", current);
          // })
          // formData.append("startup_name", values.startup_name);
          formData.append("sector_id", [values.sector_id])
          formData.append("category_id", values.category_id);
          values.business_model_id?.forEach((current) => {
            formData.append("business_model_id", current)
          })
          break;

        case 2:
          if (typeof values.pitch_deck != 'string') {
            formData.append("pitch_deck", values.pitch_deck);
          }
          formData.append("description", values.description);
          if (typeof values.elevator_pitch != 'string') { formData.append("elevator_pitch", values.elevator_pitch); }
          formData.append("problem_solving", values.problem_solving);
          break;

        case 3:
          formData.append("founder[name]", values?.founder?.name)
          formData.append("founder[bio]", values?.founder?.bio)
          formData.append("founder[title]", values?.founder?.title)
          formData.append("founder_logo", values?.founder?.image);
          formData.append("founder[linkedin_url]", values?.founder?.linkedin_url)
          break;

        case 4:
          values.coFoundersArray?.forEach((current, index) => {
            if (current?.name != "") {
              if (current?.image?.size) {
                formData.append(`cofounder${index}`, current?.image);
              } else {
                formData.append(`cofounder[${index}][cofounder_image]`, current?.image_name);
              }
              formData.append(`cofounder[${index}][name]`, current?.name);
              formData.append(`cofounder[${index}][title]`, current?.title);
              formData.append(`cofounder[${index}][bio]`, current?.bio);
              formData.append(
                `cofounder[${index}][linkedin_url]`, 'https://' + current?.linkedin_url
              );
            }
          });
          break;

        // case 5:
        //   formData.append("referral_type", values?.referral_type)
        //   formData.append("referral_name", values?.referral_name)
        //   formData.append("is_profile_completed", true)
        //   break;

        default:
          break;
      }

      if (currentIndex >= values?.step || values?.step == undefined) {
        formData.append('step', currentIndex)
      }
      // formData.append("startup_name", values.startup_name);

      updateStartupAsync(formData).catch(err => {
        // console.log(err?.response?.data?.message, "response herer")

        toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
          duration: 4000,
          position: "top-right",
          style: {
            padding: "15px 30px",
          },
        });
      });

      // console.log(values, "values");
      // setCurrentIndex(currentIndex + 1)
    },
  });
  const { data: countriesWithPhoneCodes, isRefetching: isRefetchingCountriesPC, isLoading: isLoadingCountriesPC, refetch: refetchCountriesPC } = FetchAllCountriesWithPhoneCodes({})
  // const [savedCountriesList,setSavedCountriesList] = React.useState([])
  const [currentCountry, setCurrentCountry] = React.useState(countries?.find(item => item?._id == formik?.values?.country_id))
  const { data: states, isRefetching: isRefetchingStates, isLoading: isLoadingStates, refetch: refetchStates } = FetchAllStates({ search: searchState, countryID: currentCountry !== null ? countries?.find(item => item?._id == formik.values?.country_id)?.value : currentCountry?.value })
  const [currentState, setCurrentState] = React.useState(states?.find(item => item?.value === formik?.values?.state_id)?.label || '')
  const { data: cities, isRefetching: isRefetchingCities, isLoading: isLoadingCities, refetch: refetchCities } = FetchAllCities({ search, stateID: currentState !== null ? states?.find(item => item?._id == formik.values?.state_id)?.value : currentState?.value, isPopular: 0 });
  // const [savedCitiesList,setSavedCitiesList] = React.useState([])
  const [currentCity, setCurrentCity] = React.useState(cities?.find(item => item?.value === formik?.values?.city_id)?.label || '');
  // console.log(cities?.find(item => item?.value === formik?.values?.city_id),"cities?.find(item => item?.value === formik?.values?.city_id)")
  // const { data: startup_stages, isRefetching: isRefetchingRoundTypes, isLoading: isLoadingRoundTypes, refetch: refetchRoundTypes } = FetchAllStages()
  const handleUpdateCityName = (city) => {
    formik.setFieldValue("city_id", city?.value)
    // console.log(city,"city")
    setCurrentCity({ label: city?.label, value: city?.value })
    setSearch(city?.label)
  }
  const handleUpdateCountryName = (country) => {
    formik.setFieldValue("country_id", country?._id)
    // console.log(country, "country")
    setCurrentCountry(country)
  }
  const handleUpdateStateName = (state) => {
    // formik.setFieldValue("state_id", state?._id)
    // console.log(state, "state")
    // setCurrentState({ label: country?.name, value: country?._id, id: country?.id })
  }
  const handleSearchCityChange = (event, value) => {
    // console.log(event.target.value, value,"ca")
    setSearch(value);
    // refetchCities();
  };
  const handleSearchCountryChange = (event, value) => {
    // console.log(event.target.value, value,"ca")
    setSearchCountry(value);
    // refetchCities();
  };
  const handleSearchStateChange = (event, value) => {
    // console.log(event.target.value, value,"ca")
    setSearchState(value);
    // refetchCities();
  };
  const handleSelectionCityChange = (event, value) => {
    // console.log(value, "value")

    formik.setFieldValue("city_id", value ? value?._id : '');
    setCurrentCity(value)
    event.preventDefault();
  };
  const handleSelectionCountryChange = (event, value) => {
    // console.log(value, "value")
    formik.setFieldValue("country_id", value ? value?._id : '');
    setCurrentCountry(value)
    formik.setFieldValue("state_id", null);
    setCurrentState(null)
    setCurrentCity(null);
    formik.setFieldValue("city_id", null)
    setSearchState("")
    setSearch("")
    refetchData()
    event.preventDefault();
  };
  const handleSelectionStateChange = (event, value) => {
    // console.log(value, "value")
    formik.setFieldValue("state_id", value ? value?._id : null);
    setCurrentState(value)
    setCurrentCity(null);
    formik.setFieldValue("city_id", null)
    setSearch("")
    refetchData('city')
    event.preventDefault();
  };
  const handleSearchUpdateCity = () => {
    refetchCities();
  }
  const handleSearchUpdateCountry = () => {
    refetchCountries();
  }
  const handleSearchUpdateState = () => {
    refetchStates();
  }
  const refetchData = (type) => {
    if (type === "city") {
      refetchCities().then(response => {
        setTimeout(() => {
          setCurrentCity(response.data?.find(item => item?._id == formik?.values?.city_id)?.label || '')
          setSearch("");
          // setSearch(response.data?.find(item => item?.value == formik?.values?.city_id)?.label || '')
        }, 0)
      })
    }
    else {
      formik.values?.country_id != undefined && refetchStates().then(res => {
        setCurrentState(res?.data?.filter(item => item?._id == formik.values.state_id)[0]?.label || '');
        setSearchState("");
        // setSearchState(res?.data?.find(item => item?.value == formik.values.state_id)?.label || '');
      })

      formik.values?.state_id != undefined && refetchCities().then(response => {
        setTimeout(() => {
          setCurrentCity(response?.data?.find(item => item?._id == formik?.values?.city_id)?.label || '')
          setSearch("");
          // setSearch(response.data?.find(item => item?.value == formik?.values?.city_id)?.label || '')
        }, 200)
      })

    }
    // refetchPopularCities()
  }

  React.useEffect(() => {
    refetchCountries().then(respo => {
      if (formik.values?.country_id != undefined) {
        refetchStates().then(res => {
          setCurrentState(res?.data?.filter(item => item?._id == formik.values?.state_id)[0])
        }).then(() => {
          if (formik.values.state_id != undefined) {
            refetchCities().then(response => {
              setCurrentCity(response?.data?.find(item => item?._id == formik.values?.city_id)[0])
            })
          }
        })
      }
    })
  }, [isStartupDetailsLoading])

  // React.useEffect(() => {
  //   formik.setFieldValue("raised_round", startupDetailsData?.data?.raised_round)
  // }, [isStartupDetailsLoading])

  // const { data: document_types, isRefetching: isRefetchingDocumentTypes, isLoading: isLoadingDocumentTypes, refetch: refetchDocumentTypes } = FetchAllDocumentTypes()
  const { data: security_types, isRefetching: isRefetchingSecurityTypes, isLoading: isLoadingSecurityTypes, refetch: refetchSecurityTypes } = FetchAllSecurityTypes()
  const { data: round_statuses, isRefetching: isRefetchingRoundStatuses, isLoading: isLoadingRoundStatuses, refetch: refetchRoundStatuses } = FetchAllRoundStatus()
  const {
    mutateAsync: uploadDocumentsAsync,
    isLoading: isUploadingDocuments,
    status: uploadDocumentsStatus,
    error: uploadDocumentsError,
    data: uploadDocumentsResponse
  } = UploadRoundDocuments();

  const round_steps = [
    {
      name: "Startup Information",
      sub_title: "Provide name, logo, stage and sector.",
      image_url: FundUtilizationIcon,
    },
    {
      name: "CONTACT INFORMATION",
      sub_title: "This information will be used to contact you.",
      image_url: NameTypeIcon,
    },
    // {
    //   name: "Select your Country",
    //   sub_title: "Select country where startup is registered.",
    //   image_url: RoundSizeIcon,
    // },
    // {
    //   name: "Select your State & City",
    //   sub_title: "Select the state and city where the startup is registered.",
    //   image_url: CurrentInvestorsIcon,
    // },
    {
      name: "Pitch Deck & Problem Statement",
      sub_title: "Upload pitch deck & describe what problem you are solving.",
      image_url: CurrentTractionIcon,
    },
    {
      name: "Founder Information",
      sub_title: 'Provide details about founder(s).',
      image_url: DocumentsIcon,
    },
    {
      name: "Co-founder Information",
      sub_title: 'Provide details about co-founder(s).',
      image_url: DocumentsIcon,
    },
    // {
    //   name: "Referral Details",
    //   sub_title: "Tell us how did you hear about Backrr.",
    //   image_url: ExitStrategyIcon,
    // }
  ];

  const scrollbarRef = useRef(null);

  const scrollToBottom = () => {
    let timeoutId;
    clearTimeout(timeoutId)

    timeoutId = setTimeout(() => {
      if (scrollbarRef.current) {
        const ps = scrollbarRef.current._container;
        const scrollDistance = ps.scrollHeight - ps.scrollTop; // Distance to scroll
        const step = 10; // The amount to scroll each frame
        const duration = 300; // Duration of the scroll in milliseconds
        const interval = duration / (scrollDistance / step);

        const smoothScroll = setInterval(() => {
          if (ps.scrollTop + ps.clientHeight < ps.scrollHeight) {
            ps.scrollTop += step;
          } else {
            clearInterval(smoothScroll); // Stop scrolling once the bottom is reached
          }
        }, interval);
      }
    }, 100);
  }

  // const scrollTo = (fieldName) => {
  //   setTimeout(() => {
  //     if (scrollbarRef.current) {
  //       const targetRef = document.getElementsByName(fieldName)[0];

  //       if (!targetRef) {
  //         console.error(`Element with name "${fieldName}" not found`);
  //         return;
  //       }

  //       // Scroll the target element into view within the custom scrollbar
  //       targetRef.scrollIntoView({
  //         behavior: 'smooth',
  //         block: 'center',
  //       });
  //     }
  //   }, 100);
  // };

  // React.useEffect(() => {
  //   if (formik.isSubmitting && Object.keys(formik.errors).length > 0) {
  //     const firstErrorKey = Object.keys(formik.errors).find(key => formik.touched[key]);
  //     if (firstErrorKey) {
  //       // Delay the scroll slightly to ensure DOM has updated
  //       setTimeout(() => {
  //         scrollTo(firstErrorKey);
  //       }, 50);
  //     }
  //   }
  // }, [formik.isSubmitting, formik.errors]);

  const Actions = () => {
    return (
      <>
        {(isUpdatingStartup || isUploadingDocuments) && matchesUpMd && <Typography sx={{ marginRight: "1rem" }} display={"flex"} alignItems={'center'} gap={1} fontSize={"14px"} fontWeight={600} color={theme.palette.customColors.g200}>
          <SavingIcon width={24} height={24} className="saving-icon" />
          <span>Saving...</span>
        </Typography>}
        <NeedHelpButton />
      </>
    );
  };


  // const fund_allocation_percentages_length =
  //   formik.values?.fund_allocation_percentages?.length;
  const fundAllocationValues = formik.values?.fund_allocation_percentages || [];

  // Calculate the total percentage
  const totalPercentage = fundAllocationValues.reduce(
    (total, item) => total + Number(item.percentage || 0),
    0
  );

  // Handle change for percentage input
  const handlePercentageChange = (index, value) => {
    const newPercentage = Number(value);

    // Calculate the new total if the current input is changed
    const newTotal =
      totalPercentage - Number(fundAllocationValues[index].percentage) + newPercentage;

    // Allow the change only if the new total does not exceed 100%
    if (newTotal <= 100) {
      formik.setFieldValue(`fund_allocation_percentages.${index}.percentage`, newPercentage);
    }
  };
  const key_metrics_length = formik.values?.key_metrics?.length;

  // const totalPercentage = formik.values?.fund_allocation_percentages && fund_allocation_percentages_length > 0  ? formik.values?.fund_allocation_percentages?.reduce((total, item) => {
  //   return total + Number(item?.percentage || 0);
  // }, 0) : 0;

  // console.log(currentIndex, "currentIndex")

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    formik.setFieldValue(`documents.${index}.document`, file);
  };

  // const fileExtensionSwitch = (key) => {
  //   console.log(key, "key");
  //   switch (key) {
  //     // case "pdf":
  //     // case "":

  //     default:
  //       return docxImage;
  //   }
  // };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (current) => {
    formik.setFieldValue("documents", [
      ...formik.values?.documents,
      {
        title: current?.name,
        document: null,
        editable: false,
        type: "",
        document_type: current?.value,
      },
    ]);
    window.document
      .getElementById("scrollable-form-profile")
      .scrollTo(0, window.document.getElementById("scrollable-form-profile")?.firstChild?.scrollHeight);
    scrollToBottom();
  };

  // ...document_types
  const document_types = (!formik.values?.documents?.find(item => item?.title == "Pitch Deck")) ? [
    { name: "Pitch Deck", value: "pitch_deck" },
    { name: "Other", value: "other" },
  ] : [
    { name: "Other", value: "other" },
  ];

  const [openFundModal, setOpenFundModal] = React.useState(false);

  React.useEffect(() => {
    if (UpdateStartupResponse && UpdateStartupResponse?.data?.statusCode == 200) {
      if (currentIndex === round_steps?.length - 1) {
        // navigate(-1)
        Cookies.remove("startup_id")
        navigate(`/backrr_startups`);
      }
      else {
        refetchStartupDetails()

        setCurrentIndex(currentIndex + 1);
        navigate(`/backrr_startups/edit?page=${currentIndex + 1}`, { replace: true });
        // setSearchParams({ page: currentIndex + 1 });
      }
    }
    let currentDocument = window.document.getElementById("scrollable-form-profile");
    if (currentDocument) {
      currentDocument.scrollTo(0, 0);
    }
    // window.document.getElementById("scrollable-form-profile").scrollTo(0, 0);
  }, [UpdateStartupResponse?.data?.statusCode]);

  const handleChangeValue = (newValue) => {
    navigate(`/backrr_startups/edit?page=${newValue}`, { replace: true });
  }

  const [currentSubCategory, setCurrentSubCategory] = React.useState('');
  const [searchSubCategory, setSearchSubCategory] = React.useState('');

  const [selectedCategory, setSelectedCategory] = React.useState("");
  const { data: startup_categories, isRefetching: isRefetchingCategories, isLoading: isLoadingCategories, refetch: refetchCategories } = FetchAllCategories()
  const { data: startup_sub_categories, isRefetching: isRefetchingSubCategories, isLoading: isLoadingSubCategories, refetch: refetchSubCategories } = FetchAllSubCategories({ category: selectedCategory })
  const isLoading = (isStartupDetailsLoading) || isLoadingRoundTypes || isLoadingCountries;
  // Effect to set selected category based on formik values
  React.useEffect(() => {
    if (!isStartupDetailsLoading && !isLoadingCategories && formik.values && Array.isArray(startup_categories)) {
      const category = startup_categories.find(item => item?._id === formik?.values?.category_id);
      if (category) {
        setSelectedCategory(category.name);  // Or whichever property you want to use
      }
    }
  }, [isStartupDetailsLoading, isLoadingCategories, formik.values, startup_categories, formik?.values?.category_id]);

  // Effect to refetch subcategories when selectedCategory changes
  React.useEffect(() => {
    if (!isRefetchingCategories && !isLoadingCategories && selectedCategory) {
      refetchSubCategories();
    }
  }, [selectedCategory, isRefetchingCategories || isLoadingCategories,]);

  React.useEffect(() => {
    if (formik.values.sector_id && startup_sub_categories) {
      const matchedSubCategory = startup_sub_categories.find(subCat => subCat._id === formik.values.sector_id);
      if (matchedSubCategory) {
        setCurrentSubCategory({ label: matchedSubCategory?.sub_category, value: matchedSubCategory?._id, description: matchedSubCategory?.description });
      }
    }
  }, [formik.values.sector_id, startup_sub_categories]);

  const handleSearchSubCategoryChange = (event, value) => {
    // console.log(event.target.value, value,"ca")
    setSearchSubCategory(value);
    // refetchCities();
  };
  const handleSelectionSubCategoryChange = (event, sector) => {
    // console.log(value, "value")
    formik.setFieldValue("sector_id", sector ? sector?.value : '');
    setCurrentSubCategory(sector)
    event.preventDefault();
  };
  const handleSearchUpdateSubCategory = () => {
    refetchSubCategories();
  }
  // const scrollToFirstVisibleValidation = () => {
  //   // Query all elements with the class "validation-message"
  //   const validationMessages = document.querySelectorAll('.validation-message');
  //   // console.log(validationMessages, "dddd")

  //   // Find the first visible validation message
  //   for (const message of validationMessages) {
  //     if (message && message.offsetParent !== null) {
  //       message.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //       break;
  //     }
  //   }
  // };

  return (
    <ThemeBGWrapper>
      <Modal
        open={openFundModal}
        onClose={() => {
          setTimeout(() => setOpenFundModal(false), 200);
        }}
      >
        <Paper
          sx={{
            background: theme?.palette?.customColors?.whiteBG,
            position: "fixed",
            top: 0,
            right: 0,
            height: "100%",
            borderRadius: "24px 0 0 24px",
            padding: "1rem 2rem",
          }}
        >
          <Typography
            onClick={() => {
              setTimeout(() => setOpenFundModal(false), 200);
            }}
            sx={{
              color: theme?.palette?.customColors?.b300,
              fontWeight: 700,
              paddingRight: 1,
              ":focus": { opacity: "0.8" },
              cursor: "pointer",
              position: "absolute",
              top: 20,
              right: 5,
              textTransform: "none",
            }}
            variant="button"
          >
            Back
          </Typography>
          <Box marginBottom={"0.5rem"}>
            <Typography
              variant="h6"
              // fontSize="1rem"
              color={theme.palette.customColors.g300}
              fontWeight={700}
              textAlign={"start"}
            >
              Key Metrics in Graphs
            </Typography>
            <Typography
              variant="subtitle2"
              color={theme.palette.customColors.g200}
              fontSize="0.875rem"
            >
              Detailed key metrics about fund utilization.
            </Typography>
          </Box>
          <Divider />
          <PieChart graphData={formik.values?.fund_allocation_percentages?.filter(item => item?.name != "" && item?.percentage != "" && item?.percentage != 0)} />
        </Paper>
      </Modal>
      <BreadcrumbHeader
        title={isStartupDetailsLoading ? "Loading..." : "Edit (" + startupDetailsData?.data?.startup_name + ")"}
        // breadcrumbTitle={isStartupDetailsLoading ? "Profile" : startupDetailsData?.data?.startup_name}
        Actions={Actions}
      />
      {isLoading ?
        <Grid container xs={12} display={"flex"} height={"100%"} maxHeight={"85vh"} sx={{ padding: { xs: "0 1rem", sm: '0 1rem 1rem 1rem', md: "0 2rem 1rem 2rem" }, width: '100%' }}>
          <Grid item xs={12} className="skeleton" height={"100%"} width={"100%"} borderRadius={"24px"}>

          </Grid>
        </Grid> :
        <>
          <Grid
            container
            xs={12}
            sx={{
              // height: "calc(100vh - 118px)",
              height: '100%',
              maxHeight: { xs: "90%", sm: "93%", md: '86vh' },
              overflow: "hidden",
              padding: { xs: "0 1rem", sm: '0 1rem 1rem 1rem', md: "0 2rem 1rem 2rem" },
              paddingBottom: 0
            }}
          >
            <Grid
              xs={12}
              item
              container
              sx={{
                backgroundColor: "#FFFAF5",
                borderRadius: { xs: "24px", sm: "24px" },
                height: "100%",
                paddingBottom: "2rem"
              }}
            >
              <Grid
                item
                sx={{
                  position: "relative",
                  backgroundColor: "#ADA2ED",
                  borderRadius: `24px ${currentIndex === 0 ? "0" : "24px"} 24px 24px`,
                  overflow: "hidden",
                  height: "100%",
                  width: { xs: '0%', md: '40%', lg: '40%', xl: '37.9%' },
                  // display: {xs : 'none', md: 'block'}
                }}
              // xs={12}
              // // sm={5}
              // md={6}
              // lg={4.6}
              >
                <VerticalScrollableTabs data={round_steps} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} setSearchParams={setSearchParams} handleChangeValue={handleChangeValue} />
              </Grid>
              <Grid
                item
                container
                // xs={12}
                // // sm={5}
                // md={6}
                // lg={7.4}
                sx={{ width: { xs: "100%", md: '60%', lg: '60%', xl: '62.1%' }, padding: { xs: '1rem', md: '2rem', lg: "2.5rem" } }}
                // gap={3}
                // gap={'1rem'}
                position={"relative"}
                marginTop={0}
                display={"flex"}
                alignItems={"flex-start"}
                alignContent={"space-around"}
                justifyContent={"space-between"}
                // padding={"2.5rem"}
                paddingBottom={0}
              >
                <Box sx={{ position: 'absolute', right: 0, top: 0, }}>
                  <Tooltip title="Close">
                    <IconButton onClick={() => {
                      navigate("/backrr_startups")
                    }}>
                      <Close />
                    </IconButton>
                  </Tooltip>
                </Box>
                <FormikProvider value={formik}>
                  <Form
                    onSubmit={(event) => event.preventDefault()}
                    style={{ width: "100%", height: "85%", maxHeight: matchUpLg ? "calc(100vh - 290px)" : matchesUpMd ? "calc(100vh - 285px)" : "calc(-220px + 100vh)", paddingBottom: '1rem' }}
                  // style={{ width: "100%", height: "90%", maxHeight: matchUpXl ? "calc(100vh - 295px)" : matchUpLg ? "calc(100vh - 290px)" : matchesUpMd ? "calc(100vh - 285px)" : "calc(-220px + 100vh)", overflow: "hidden", paddingBottom: '1rem' }}
                  >
                    {!matchesUpMd &&
                      <Box width={"100%"}>
                        {round_steps &&
                          round_steps?.length > 0 ?
                          <>
                            <Typography marginInline={"auto"} flexWrap={"wrap"} variant="h6"
                              fontSize="1rem"
                              color={theme.palette.customColors.g300}
                              fontWeight={700} textAlign={"center"} marginBottom={1}>
                              <span>{round_steps[currentIndex]?.name} ({`${currentIndex + 1}/${round_steps?.length}`})</span>
                              <Divider sx={{ marginTop: 1, marginBottom: 2, marginInline: 'auto', maxWidth: '50%' }} />
                            </Typography>
                          </>
                          : ''}
                      </Box>
                    }
                    <Box
                      ref={scrollbarRef}
                      // containerRef={containerRef}
                      style={{
                        // minHeight: currentIndex == 5 ? "56vh" : "60vh",
                        // height: "100%",
                        width: "98%",
                        // maxHeight: currentIndex == 5 ? "calc(-330px + 100vh)" : "calc(100vh - 285px)",
                        paddingRight: "1rem",
                        marginTop: 0,
                        paddingBottom: { xs: "1rem", md: 0 },
                        minHeight: '55vh',
                        maxHeight: '65vh',
                        // paddingLeft: "1rem",
                        overflow: 'hidden auto',
                      }}
                      className="scrollbar-container"
                      id="scrollable-form-profile"
                    >
                      {/* {console.log(formik.values?.round_highlights?.replace(/<[^>]*>/g, ""),"round")} */}
                      <Grid
                        item
                        container
                        xs={12}
                        paddingBottom={{ xs: 1, md: currentIndex == 1 || currentIndex == 7 ? 2 : 0 }}
                        // sx={{ width: "100%" }}
                        justifyContent={"space-between"}
                        columnSpacing={"0.5rem"}
                      >
                        {currentIndex == 1 && (
                          <>
                            <ContactInformation isStartupDetailsLoading={isStartupDetailsLoading} />
                          </>
                        )}
                        {currentIndex == 0 && (
                          <>
                            <StartupInformation isStartupDetailsLoading={isStartupDetailsLoading} />
                          </>
                        )}
                        {currentIndex == 2 && (
                          <>

                            <StartupDetails />
                          </>

                        )}
                        {currentIndex == 3 && (
                          <>
                            <FounderInformation />
                          </>
                        )}
                        {currentIndex == 4 && (
                          <>
                            <CoFounderInformation />
                          </>
                        )}
                        {/* {currentIndex === 5 && (
                          <>
                            <Grid item container xs={12} display={"flex"} gap={0} justifyContent={"space-between"} flexDirection={"row"}>
                              <CustomLabel>SELECT REFERRAL MEDIUM  {formik.errors.referral_type && formik.touched.referral_type && (
                                <CustomValidationText value={formik.errors.referral_type} />
                              )}</CustomLabel>
                              <Grid
                                display={"flex"}
                                flexWrap={"wrap"}
                                justifyContent={"center"}
                                item
                                container
                                xs={12}
                                gap={"1rem"}
                              >
                                {referral_types?.map((referral, index) => {
                                  return (
                                    <Grid
                                      role={"button"}
                                      item
                                      xs={12}
                                      sm={referral_types?.length % 2 != 0 && index === referral_types?.length - 1 ? 12 : 5.75}
                                      md={referral_types?.length % 2 != 0 && index === referral_types?.length - 1 ? 12 : 5.65}
                                      lg={referral_types?.length % 2 != 0 && index === referral_types?.length - 1 ? 12 : 5.8}
                                      marginInline={referral_types?.length - 1 === index ? { xs: 0, sm: 0.75, md: 0.5, lg: 1.5, xl: 0.5 } : 0}
                                      onClick={() => {
                                        formik.setFieldValue(
                                          "referral_type",
                                          referral?.value
                                        );
                                      }}
                                      sx={{
                                        background:
                                          formik.values.referral_type ==
                                            referral?.value
                                            ? theme?.palette?.customColors?.y50
                                            : theme?.palette?.customColors?.whiteBG,
                                        padding: "12px 16px",
                                        height: 55,
                                        borderRadius: "8px",
                                        display: "grid",
                                        placeItems: "center",
                                        border: `1px solid ${formik.values.referral_type ==
                                          referral?.value
                                          ? theme?.palette?.customColors?.y300
                                          : theme?.palette?.customColors?.g75
                                          }`,
                                        boxShadow: "0px 1px 2px 0px #1018280D",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        color={theme.palette.customColors.g200}
                                        fontSize="0.875rem"
                                      >
                                        {referral?.value}
                                      </Typography>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                              {formik.values.referral_type === "Other" && <Grid item xs={12} display={"flex"} gap={0} marginTop={2}>
                                <CustomInputField value={formik.values.referral_name} name="referral_name" label={"ENTER REFERRER NAME"} placeholder="Enter referrer name" onChange={(e) => formik.setFieldValue("referral_name", e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} />
                              </Grid>}
                            </Grid>
                          </>
                        )} */}
                      </Grid>
                    </Box>
                  </Form>
                </FormikProvider>
                {/* Content for the selected item goes here */}
                <Grid
                  item
                  container
                  display={"flex"}
                  xs={12}
                  sm={12}
                  height={"min-content"}
                  gap={"1.5rem"}
                  justifyContent={"center"}
                  padding={"1rem 1rem 1rem 0rem"}
                >
                  {/* {currentIndex !== 1 &&  */}
                  {currentIndex !== 0 && <Grid xs={5} sm={5} md={5.5} lg={5.7} item>
                    <BLUEBORDER
                      sx={{
                        textTransform: "none",
                        padding: 2,
                        borderWidth: 1,
                        borderRadius: "8px",
                      }}
                      type="no usetr"
                      onClick={() => {
                        if (currentIndex === 0) {
                          // navigate(-1);
                        } else {
                          setCurrentIndex(currentIndex - 1);
                          handleChangeValue(currentIndex - 1);
                        }
                        window.document
                          .getElementById("scrollable-form-profile")
                          .scrollTo(0, 0);
                        // window.scrollTo(0, 0);
                      }}
                      fullWidth
                    >
                      Back
                    </BLUEBORDER>
                  </Grid>}
                  {/* } */}
                  <Grid xs={5} sm={5} md={5.5} lg={5.7} item>
                    <CustomButton
                      sx={{ padding: 2, borderRadius: "8px", ":disabled": { color: '#d9d9d9' } }}
                      onClick={(e) => {
                        handleFormSubmit(e, formik)
                      }}
                      disabled={currentIndex === 5 && formik.values?.documents?.filter(item => item?.title != "Pitch Deck")?.length > 1 || isUpdatingStartup || isUploadingDocuments}
                      // onClick={() => { setCurrentIndex(currentIndex + 1) }}
                      fullWidth
                    >
                      {(isUpdatingStartup || isUploadingDocuments) ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.5rem'} /> : currentIndex == round_steps?.length - 1 ? "Submit" : "Next"}
                    </CustomButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      }
    </ThemeBGWrapper>
  );
};

export default UpdateProfile;
