import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { RetryOnError, verifyToken } from "../utils/VerifyToken"


export const FetchPermissionsTemplate = () => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-FetchPermissiAonsTemplate-template-all-FetchPermissionsTemplate-fetch-all'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings?config_type=investor_permission`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            const permissions = {}
            data.data.data[0]?.config_values?.forEach((item, index) => {
                permissions[item.code] = {
                    label: item.name,
                    actions: item?.actions,
                    _id: item?._id
                }
            });
            return permissions
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchUserAssociatedInvestorAccounts = () => {
    // console.log(id,"id")
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['FetchUserAssociatedInvestorAccounts-FetchUserAssociatedInvestorAccounts-template-for-all-FetchUserAssociatedInvestorAccounts-FetchUserAssociatedInvestorAccounts-fetch'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/get-investor-accounts`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data,'values new')
            return data?.data?.data
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchUserPermissionsForCurrentInvestor = ({ investor_id }) => {
    // console.log(id,"id")
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['FetchUserPermissionsForAssociatedinvestors-FetchUserPermissionsForAssociatedinvestors-template-for-all-FetchUserPermissionsForAssociatedinvestors-FetchUserPermissionsForAssociatedinvestors-fetch' + investor_id + Cookies.get('jwt'), investor_id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/get-user-permission/${investor_id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data,'values new')
            return data?.data?.data
        },
        enabled: !!investor_id,
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}