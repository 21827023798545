import toast, { Toaster } from 'react-hot-toast';
import ThemeRoutes from "./routes";
import { ThemeProvider } from "@mui/material";
import theme from "./themes/theme";
import { ConfigProvider } from './dynamic-configuration/configContext';
import { GetConfigurations } from './api';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import GoogleAnalytics from 'google-analytics';
// for latest version popup
import { FetchLatestVersion } from 'api/version';
import ReloadPopup from 'ui-components/ReloadPopup';
import { useToast } from 'context/ToastContext';

function App() {
  const [config, setConfig] = useState(null)
  const [isReady, setIsReady] = useState(false)
  // const { toasts, Toast, removeToast } = useToast()

  // useEffect(() => {
  //   console.log(window.history.length, 'history')
  // },[window.history.length])

  // const [params] = useSearchParams()
  // console.log("params", params.get("token"))

  useEffect(() => {
    // console.log("App.js")
    // if(params.get("token")?.length > 0) {
    //   if(params.get("token")) {
    //     Cookies.set("jwt", params.get("token"))
    //   }
    //   if(params.get("is_profile_completed")) {
    //     Cookies.set("is_profile_completed", params.get("is_profile_completed"))
    //   }
    //   let currentUrl = window.location?.search
    //   let newUrl = window.location.href
    //   console.log("currentUrl", currentUrl)
    //   newUrl = newUrl.replace(currentUrl, "")
    //   console.log("currentUrl", newUrl)
    //   window.location.replace(newUrl)
    // }
    setIsReady(false)
    fetchConfig()
  }, [])

  window.onload = function () {
    setIsReady(false)
    fetchConfig()
  }

  const fetchConfig = () => {
    // const { data } = GetConfigurations();
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/validation?validation_type=investor_details`
    }).then((data) => {
      let response = data?.data?.data
      // console.log("data", data)
      setConfig(response)
      setIsReady(true)
    }).catch(error => {
      console.log("error", error)
    })
  }

  const { data: latest_version_data, isLoading: isLoadingLatestVersion, isRefetching } = FetchLatestVersion()

  return (
    <>
      {/* <div className="toast-container">
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            message={toast.message}
            type={toast.type}
            duration={toast.duration}
            onClose={() => removeToast(toast.id)}
          />
        ))}
      </div> */}
      {
        isReady && <ConfigProvider config={config}>
          <GoogleAnalytics />
          <ReloadPopup newVersion={latest_version_data} />
          <Toaster position='top-right' />
          <ThemeProvider theme={theme}>

            <ThemeRoutes />
          </ThemeProvider>
        </ConfigProvider>
      }

    </>
  );
}

export default App;
