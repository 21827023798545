import React from 'react';
import { Helmet } from 'react-helmet';

function GoogleAnalytics() {
  if (process.env.REACT_APP_MODE !== "production") {
    return null;
  }

  return (
    <Helmet>
      <script 
        async 
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS}`} 
      />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "${process.env.REACT_APP_GOOGLE_ANALYTICS}");
        `}
      </script>
    </Helmet>
  );
  return null
}

export default GoogleAnalytics;