import React from 'react'
import NavItem from './index'
import NavGroup from './NavGroup'

const NavItemType = ({ item, isActive, open, drawerToggle }) => {
    switch (item?.type) {
        case "item":
            return <NavItem item={item} isActive={isActive} open={open} drawerToggle={drawerToggle} />;

        case "group":
            return <NavGroup item={item} isActive={isActive} open={open} drawerToggle={drawerToggle} />;

        default:
            return <></>;
    }

}

export default NavItemType