import React, { createContext, useContext, useState } from "react";
import "./Toast.scss"; // Import SCSS styles
import { IconButton } from "@mui/material";

const ToastContext = createContext();

const Toast = ({ message, type = "info", duration = 3000, onClose }) => {
    const [isVisible, setIsVisible] = useState(true);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
            setTimeout(onClose, 300); // Delay removal for animation
        }, duration);

        return () => clearTimeout(timer);
    }, [duration, onClose]);

    if (!isVisible) return null;

    return (
        <div className={`toast ${type} animate-in`}>
            <div className="toast-icon">
                {type === "success" && "✔"}
                {type === "error" && "✖"}
                {type === "warning" && "⚠"}
                {type === "info" && "i"}
            </div>
            <div className="toast-message">{message}</div>
            <IconButton className="toast-close" onClick={() => setIsVisible(false)}>✖</IconButton>
        </div>
    );
};

export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);

    const addToast = (message, type = "info", duration = 3000) => {
        const newToast = { id: Date.now(), message, type, duration };
        setToasts((prev) => [...prev, newToast]);

        setTimeout(() => removeToast(newToast.id), duration);
    };

    const removeToast = (id) => {
        setToasts((prev) => prev.filter((toast) => toast.id !== id));
    };

    const resetToast = () => {
        setToasts([]);
    };

    return (
        <ToastContext.Provider value={{ addToast, resetToast }}>
            {children}
            <div className="toast-container">
                {toasts.map((toast) => (
                    <Toast
                        key={toast.id}
                        {...toast}
                        onClose={() => removeToast(toast.id)}
                    />
                ))}
            </div>
        </ToastContext.Provider>
    );
};

// Custom Hook to use Toast
export const useToast = () => useContext(ToastContext);
