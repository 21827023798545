import { Avatar, Box, Card, CardContent, Grid, IconButton, Switch, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as EditIcon } from "../../assets/rounds_icons/edit_icon.svg";
import PieChart from '../../ui-components/PieChart'
import { useTheme } from '@emotion/react';
import { colorArray, consumerBrandColor, convertCurrencyAmountRange, handleCopyClick, handleDateValues, hasPermission, investor_types, isTitleToShow, marketplaceColor, sectorAgnostic, servicesColor, stringAvatar, technologyColor } from '../../utils';
import { WHITEBG } from '../../ui-components/CustomButton';
import moment from 'moment';
import marketplaceIcon from '../../assets/marketplace.png'
import mobileShoppingIcon from '../../assets/mobile-shopping.png'
import digitalServiceIcon from '../../assets/digital-services.png'
import laptopScreenIcon from '../../assets/laptop-screen.png'
import { ContentCopyOutlined, Edit, FileCopy, Language, LinkedIn, Lock, LockOpen, Public } from '@mui/icons-material';
import indiaFlag from '../../assets/india_flag.png'
import avatar from '../../assets/avatar.jpg'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CheckIcon from '@mui/icons-material/Check';
import categoryIcon from '../../assets/category.png'
import stageIcon from '../../assets/stage.png'
import sectorIcon from '../../assets/sector.png'
import chequeSizeIcon from '../../assets/cheque_size.png'
import AlertDialog from 'ui-components/AlertPopup';
import CardContentList from 'ui-components/CardContentList';
import Cookies from 'js-cookie';
import CustomSwitch from 'ui-components/CustomSwitch';
import { useSelector } from 'react-redux';

const privateCardStyle = {
    border: "2px solid #d4d4d4",
    borderRadius: "12px",
    padding: "16px",
    marginBottom: "24px",
}

const CardHeader = ({ title, action = null, sx = {} }) => {
    const userPermissions = useSelector((state) => state?.permissions?.userPermissions);
    return <Box display={"flex"} justifyContent={"space-between"} alignItems={"start"} mb={"0.75rem"}>
        <Typography variant='h1' fontWeight={700} fontSize={"1rem"} lineHeight={"1rem"} sx={{ ...sx }}>
            {title}
        </Typography>
        {action && hasPermission("profile", "edit", userPermissions) && <WHITEBG
            component="label"
            role={undefined}
            tabIndex={-1}
            sx={{
                minWidth: "0 !important",
                display: "flex",
                gap: '0.5rem',
                border: "1px solid #D0D5DD !important",
                paddingInline: '0.5rem 0.875rem'
            }}
            onClick={action}
        >
            <EditIcon sx={{ fontSize: 20 }} />
            <Typography>
                Edit
            </Typography>
        </WHITEBG>}
    </Box>
}

const RichTextDisplay = ({ htmlContent, color = "", textAlign = "start" }) => {
    const [open, setOpen] = useState(false);

    let content = open ? htmlContent : htmlContent?.substring(0, 300);
    return (
        <>
            {content && content?.length > 0 ?
                <div className='row rich-text'>
                    <div dangerouslySetInnerHTML={{ __html: content }} className='row rich-text' style={{ color: color, textAlign: textAlign }} />
                    {
                        htmlContent?.length > 300 && <Typography display={"flex"} variant='title' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1, justifyContent: 'flex-end', marginRight: "1rem", cursor: 'pointer' }} onClick={() => setOpen(!open)}>{!open ? '...more' : 'See less'}</Typography>
                    }
                </div>
                : "-"
            }
        </>
    );
};

const PersonDetails = ({ theme, data, index, length }) => {

    return <Grid xs={12} sm={12} sx={{
        padding: '0rem', boxShadow: 'none', borderRadius: "8px",
        // border: `1px solid ${theme.palette.customColors.gray300}`,
        overflow: 'hidden', paddingTop: 1, display: 'flex', flexDirection: "row", gap: { xs: "1rem", sm: "1.5rem" },
    }}>
        <Box style={{ flex: 0.3, width: "30%", maxWidth: '5.5rem' }}>
            <img src={data?.image_url + `?cacheBuster=${new Date().getTime()}`} onError={(ev) => { ev.target.src = avatar }} style={{ width: "100%", aspectRatio: 1, padding: '0', objectFit: 'cover', borderRadius: "20px", marginInline: 'auto', display: 'block', border: `1px solid ${theme.palette.customColors.g75}` }} />
        </Box>
        <Box display={"grid"} gap={{ xs: 0.7, sm: 1 }} sx={{ placeContent: 'flex-start', flex: 1 }}>
            <Grid display={"flex"} alignItems={"center"} justifyContent={"flex-start"} gap={1}>
                {data?.name && <Typography textAlign={"start"} sx={{ fontWeight: 600, marginLeft: 0, fontSize: { xs: "0.9rem", sm: "1rem" }, }}>{data?.name}{data?.type && ","} {data?.type}</Typography>}
                {data?.linkedin_url && <LinkedInIcon sx={{ fontSize: 20 }} style={{ display: 'inline-block', marginInlineEnd: 'auto', cursor: 'pointer' }} onClick={() => { window.open(data?.linkedin_url?.includes("https | http") ? data?.linkedin_url : "https://" + data?.linkedin_url) }} />}
            </Grid>
            {data?.bio && <Typography textAlign={"start"} sx={{ marginRight: 'auto', marginLeft: 0 }}>{data?.bio}</Typography>}
        </Box>
    </Grid>
}

const PersonalDetails = ({ data: personalDetails, avatarList: avatarList, setLoading = () => { } }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
    const [shiftSteps, setShiftSteps] = useState(false);
    const userAccess = useSelector((state) => state?.permissions);

    const [name, setName] = useState(null)
    const [website, setWebsite] = useState(null)
    const [profileImage, setProfileImage] = useState(null)
    const [sectors, setSectors] = useState([])
    const [businessModel, setBusinessModel] = useState([])

    const [alertImage, setAlertImage] = useState(null)
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState(null)
    const [alertHeading, setAlertHeading] = useState(null)

    const [templateArray, setTemplateArray] = useState(`"first" "second" "fourth" "fifth"`)

    const [isOverflowCategory, setIsOverflowCategory] = useState(false);
    const [isOverflowSectors, setIsOverflowSectors] = useState(false);
    const [isOverflowStages, setIsOverflowStages] = useState(false);
    const [isOverflowBusinessModel, setIsOverflowBusinessModel] = useState(false);
    const categoryRef = useRef(null);
    const sectorRef = useRef(null);
    const stagesRef = useRef(null);
    const modelRef = useRef(null);
    const [showInitialLogo, setShowInitialLogo] = useState(false)

    useEffect(() => {
        // Run the overflow check
        checkOverflow();
        // Optional: Re-check on window resize
        window.addEventListener('resize', checkOverflow);
        window.addEventListener('load', checkOverflow);
        return () => {
            window.removeEventListener('resize', checkOverflow);
            window.removeEventListener('load', checkOverflow);
        }
    });

    const checkOverflow = () => {
        if (categoryRef.current) {
            // Check if scrollHeight is greater than clientHeight
            const hasOverflow = categoryRef.current.scrollHeight > categoryRef.current.clientHeight;
            setIsOverflowCategory(hasOverflow);
        }
        if (sectorRef.current) {
            // Check if scrollHeight is greater than clientHeight
            const hasOverflow = sectorRef.current.scrollHeight > sectorRef.current.clientHeight;
            setIsOverflowSectors(hasOverflow);
        }
        if (stagesRef.current) {
            // Check if scrollHeight is greater than clientHeight
            const hasOverflow = stagesRef.current.scrollHeight > stagesRef.current.clientHeight;
            setIsOverflowStages(hasOverflow);
        }
        if (modelRef.current) {
            // Check if scrollHeight is greater than clientHeight
            const hasOverflow = modelRef.current.scrollHeight > modelRef.current.clientHeight;
            setIsOverflowBusinessModel(hasOverflow);
        }
    };

    useEffect(() => {
        if (personalDetails != null) {
            checkOverflow()
            let configArray = personalDetails?.sector_info?.map(item => {
                return item.config_values?.map(sector => {
                    return sector.sub_category
                })
            })
            setSectors(configArray.flat(1))

            // console.log(personalDetails?.business_model_info, "personalDetails?.business_model_info")

            let configArrayBusinessModel = personalDetails?.business_model_info?.map(model => {
                return model.name
            })
            setBusinessModel(configArrayBusinessModel.flat(1))
        }
    }, [personalDetails])

    useEffect(() => {
        setShiftSteps(personalDetails?.investor_type ? String(personalDetails?.investor_type)?.toLowerCase()?.includes("angel") : true)
        if (personalDetails?.investor_type) {
            if (String(personalDetails?.investor_type)?.toLowerCase()?.includes("angel")) {
                setName(personalDetails?.first_name + " " + personalDetails?.last_name)
                setProfileImage(personalDetails?.profile_path)
                setWebsite(null)
            } else {
                setName(personalDetails?.organization_name)
                setProfileImage(personalDetails?.website_logo)
                setWebsite(personalDetails?.organization_website)
                // if (personalDetails?.partners?.length > 0 && personalDetails?.partners[0]?.name?.trim()?.length > 0) {
                //     setTemplateArray(`${templateArray} "fourth"`)
                // }
            }
        } else {
            setName(personalDetails?.first_name + " " + personalDetails?.last_name)
            setWebsite(null)
            setProfileImage(personalDetails?.profile_path)
        }

    }, [personalDetails?.investor_type, personalDetails])


    const getCategoryIcon = (category) => {
        switch (category?.name) {
            case "Technology": {
                return laptopScreenIcon
                // return <img src={laptopScreenIcon} style={{ height: "4.15rem", width: "4.15rem", paddingTop: "1rem", marginLeft: 'auto' }} alt='icon' />
            }
            case "Consumer Brand": {
                return mobileShoppingIcon
                // return <img src={mobileShoppingIcon} style={{ height: "4.15rem", width: "4.15rem", paddingTop: "1rem", marginLeft: 'auto' }} alt='icon' />

            }
            case "Marketplace": {
                return marketplaceIcon
                // return <img src={marketplaceIcon} style={{ height: "4.15rem", width: "4.15rem", paddingTop: "1rem", marginLeft: 'auto', paddingRight: "0.6rem" }} alt='icon' />
            }

            case "Services": {
                return digitalServiceIcon
                // return <img src={digitalServiceIcon} style={{ height: "4.15rem", width: "4.15rem", paddingTop: "1rem", marginLeft: 'auto', transform: 'rotateY(180deg)' }} alt='icon' />
            }
        }
    }

    const getStageIcon = (stage) => {
        switch (stage.name) {
            case "Pre-Seed": {
                return <img src={stage.image_path} rel='preload' style={{ width: "5rem", height: "1.8rem", paddingTop: "4rem", marginLeft: 'auto' }} />
            }
            case "Seed": {
                return <img src={stage.image_path} rel='preload' style={{ width: "5rem", height: "2.649rem", paddingTop: "3.5rem", marginLeft: 'auto' }} />
            }
            case "Early Stage": {
                return <img src={stage.image_path} rel='preload' style={{ width: "5rem", height: "3.649rem", paddingTop: "2.5rem", marginLeft: 'auto' }} />
            }
            case "Growth": {
                return <img src={stage.image_path} rel='preload' style={{ width: "5rem", height: "5.2rem", paddingTop: "1rem", marginLeft: 'auto' }} />
            }
            case "Expansion": {
                return <img src={stage.image_path} rel='preload' style={{ width: "5rem", paddingTop: "1rem", marginLeft: 'auto' }} />
            }
        }
    }

    const startup_sub_categories = [{
        "name": "Alcohol & Beverages",
        "description": "Direct-to-consumer alcohol brands, including craft beer, wine, and spirits.",
        "_id": "66f553d50450954f2c2f959f"
    },
    {
        "name": "Art & Home Décor",
        "description": "Customizable artwork, furniture, and décor for homes and offices.",
        "_id": "66f553d50450954f2c2f95a0"
    },
    {
        "name": "Athleisure",
        "description": "Apparel that combines athletic performance with casual, everyday wear.",
        "_id": "66f553d50450954f2c2f95a1"
    }]

    const getAvatarProps = (name) => {
        const initials = name
            ? name
                ?.split(' ')
                ?.map((n) => n[0])
                ?.join('')
                ?.toUpperCase()
            : '';
        return {
            sx: {
                bgcolor: '#F9F5FF',
                color: '#3538CD',
                width: "7.8rem",
                height: "7.8rem",
                fontSize: "4rem",
                fontWeight: 500,
                borderRadius: "8px",
            },
            children: (initials)?.slice(0, 2),
        };
    };


    // const commonCardStyles = {
    //     padding: '1rem',
    //     // paddingBottom: '1rem',
    //     boxShadow: 'none',
    //     // background: 'transparent',
    //     backgroundColor: "#FFFAF5",
    //     overflow: 'hidden',
    //     border: `1px solid ${theme.palette.customColors.gray300}`,
    //     borderRadius: '15px',
    // };

    const commonCardStyles = {
        padding: '1rem',
        boxShadow: 'none',
        background: 'transparent',
        // backgroundColor: "#FFFAF5",
        overflow: 'hidden',
        // border: `1px solid ${theme.palette.customColors.indigo600}`,
        // border: `1px solid #FFF`,
        border: `1px solid ${theme.palette.customColors.indigo600}`,
        borderRadius: '15px',
    };

    const commonInvestmentFocusStyles = {
        borderRadius: '8px',
        backgroundColor: 'white',
        padding: '1rem',
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex',
        border: `1px solid ${theme.palette.customColors.indigo600}`,
    };

    const commonInvestmentFocusIconStyles = {
        height: '2.5rem',
        width: '2.5rem',
        objectFit: 'contain',
    };

    const getCategoryColor = (category) => {
        switch (category) {
            case "Technology": { return technologyColor }
            case "Consumer Brand": { return consumerBrandColor }
            case "Marketplace": { return marketplaceColor }
            case "Services": { return servicesColor }
            default: { return sectorAgnostic }
        }
    }

    const getRandomColor = () => {
        var copy = colorArray.slice(0);
        return function () {
            if (copy.length < 1) { copy = colorArray.slice(0); }
            var index = Math.floor(Math.random() * copy.length);
            var item = copy[index];
            copy.splice(index, 1);
            return item;
        };
    }

    const [isPubliclyDiscoverable, setIsPubliclyDiscoverable] = React.useState(personalDetails?.linkedin_public_profile || false)


    return (
        <Grid item container xs={12} gap={"1.25rem"} display={"grid"}
            //  gridTemplateAreas={`"first" "aside" "third" "aside2" "third" "forth" "fifth" "sixth"`}
            // gridTemplateAreas={`"first" "aside" "third"`}
            sx={{
                maxWidth: '900px !important',
                marginInline: 'auto',
                paddingBottom: '2rem',
                position: "relative"
                // height: '100%',
                // paddingTop: '1.5rem !important'
            }}
            gridTemplateAreas={templateArray}
            gridTemplateColumns={"1fr"} justifyContent={"space-between"}>
            {/* <Grid sx={{ position: 'absolute', top: 0, right: 0, display: "grid", alignItems: "center", justifyContent: 'center' }}>
                <Typography sx={{ marginInline: "auto" }}>
                    {isPubliclyDiscoverable ? "Public" : "Private"}
                </Typography>
                <Tooltip title="Enable to Discover Publicly">
                    <CustomSwitch checked={isPubliclyDiscoverable} onClick={() => setIsPubliclyDiscoverable(prev => !prev)} />
                </Tooltip>
            </Grid> */}

            <Grid sx={{ gridArea: 'first', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item >
                <Card sx={{ padding: '1rem', boxShadow: 'none', overflow: 'hidden', backgroundColor: 'transparent', paddingTop: '2rem' }}>
                    {/* <CardHeader title={"Personal Info"} /> */}
                    <Grid container xs={12} alignItems={"center"} spacing={2} marginInline={"auto"}>
                        <Grid sx={{ width: '8rem', height: '8rem', marginInline: 'auto', alignItems: 'center', justifyContent: 'center', border: `0.1rem solid ${theme.palette.customColors.gray300}`, borderRadius: "8px" }}>
                            {!showInitialLogo ? <Box position={"relative"}>
                                <IconButton sx={{
                                    position: 'absolute', top: 0, right: 0, transform: 'translate(35%, -35%)',
                                    background: "white !important",
                                    border: "2px solid #d0d5dd"
                                }}
                                    onClick={() => {
                                        hasPermission("profile", "edit", userAccess?.userPermissions) && navigate(`/profile/edit_profile?page=0`);
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                                <img
                                    src={profileImage + `?cacheBuster=${new Date().getTime()}`}
                                    onLoad={() => { setShowInitialLogo(false) }}
                                    onError={() => { setShowInitialLogo(true) }}
                                    style={{
                                        width: '7.8rem',
                                        height: '7.8rem',
                                        borderRadius: "6px",
                                        marginInline: 'auto',
                                        display: "block",
                                        objectFit: 'contain'
                                    }}
                                />
                            </Box> : <Avatar
                                sx={{}}
                                {...getAvatarProps(name)} onClick={() => {
                                    hasPermission("profile", "edit", userAccess?.userPermissions) && navigate(`/profile/edit_profile?page=0`);
                                }} />}
                        </Grid>
                        <Grid xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                            <Typography variant='title' sx={{ fontSize: "1.3rem", textAlign: 'center', marginInline: 'auto', display: "flex", alignItems: "center" }}>{personalDetails?.title && String(personalDetails?.investor_type)?.toLowerCase()?.includes("angel") ? isTitleToShow(personalDetails?.title) ? personalDetails?.title : "" : ""} {name} <Tooltip title="Public"> <LockOpen sx={{ marginLeft: "0.5rem", width: '1rem', color: "#333" }} /> </Tooltip></Typography>
                        </Grid>
                        <Grid xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                            <Typography variant="body2">{investor_types?.find(item => item?.value === personalDetails?.investor_type)?.label + (shiftSteps ? " Investor" : "")}</Typography>
                        </Grid>
                        <Typography variant='bodyTextRegularInvestor' sx={{ fontSize: { xs: "0.8rem", md: "0.8rem" }, textAlign: 'center', marginInline: 'auto' }}>{personalDetails?.city_name}{personalDetails?.city_name && personalDetails?.state_name && ", "}{personalDetails?.state_name}{((personalDetails?.state_name && personalDetails?.country_name) || (personalDetails?.city_name && personalDetails?.country_name)) && ", "}{personalDetails?.country_name}
                            {personalDetails?.country_name == "India" && <span>
                                <img src={indiaFlag} rel='preload' style={{ width: "1.2rem", marginLeft: '5px' }} />
                            </span>}
                        </Typography>

                        <Grid spacing={2} marginTop={1} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                            <Tooltip title="Linkedin"><button type='button' style={{ backgroundColor: 'transparent', padding: 0 }}><LinkedInIcon sx={{ fontSize: 30, borderRadius: 10 }} style={{ display: 'inline-block' }} onClick={() => { window.open(personalDetails?.profile_url?.includes("https | http") ? personalDetails?.profile_url : "https://" + personalDetails?.profile_url) }} /></button></Tooltip>
                            {website != null && <Tooltip title="Website"><button type='button' style={{ backgroundColor: 'transparent', padding: 0 }}><Language sx={{ fontSize: 30, borderRadius: 10 }} style={{ display: 'inline-block' }} onClick={() => { window.open(website?.includes("https | http") ? website : "https://" + website) }} /></button></Tooltip>}
                        </Grid>
                    </Grid>
                </Card>
                <Card sx={{ ...commonCardStyles }}>
                    <CardHeader title={<span style={{ display: 'flex', alignItems: "center" }} >Overview  <Tooltip title="Public"><LockOpen sx={{ marginLeft: "0.5rem", width: '1rem', color: "#333" }} /></Tooltip> </span>} action={() => { navigate(`/profile/edit_profile?page=0`) }} />
                    <RichTextDisplay htmlContent={personalDetails?.bio} textAlign='justify' />
                </Card>
            </Grid>
            {/* <Grid sx={{ gridArea: 'first', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item >
                <Card sx={{ padding: '1rem', boxShadow: 'none', overflow: 'hidden', backgroundColor: 'transparent' }}>
                    <Grid container xs={12} alignItems={"center"} spacing={2} marginInline={"auto"}>
                        <Card sx={{ ...commonCardStyles }}>
                            <CardHeader action={() => { navigate(`/profile/edit_profile?page=0`) }} />
                            <Grid container xs={12} alignItems={"center"} spacing={2} marginInline={"auto"}>
                                <Grid sx={{ borderRadius: '5rem', width: '8rem', height: '8rem', marginInline: 'auto', backgroundColor: "#FFF", alignItems: 'center', justifyContent: 'center', border: `1px solid ${theme.palette.customColors.gray300}`, padding: "1rem" }}>
                                    <img src={profileImage} style={{ width: '6rem', height: '6rem', borderRadius: '5rem', marginInline: 'auto', display: "block", objectFit: 'cover' }} />
                                </Grid>
                                <Grid xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                                    <Typography variant='title' sx={{ fontSize: "1.3rem", textAlign: 'center', marginInline: 'auto' }}>{personalDetails?.title ? isTitleToShow(personalDetails?.title) ? personalDetails?.title : "" : ""} {name}</Typography>
                                </Grid>
                                {personalDetails?.date_of_birth != null && <Grid container xs={12} alignItems={"center"} marginInline={"auto"} display={"flex"}>
                                    <Typography fontSize={"0.9rem"} variant='bodyTextRegular' sx={{ textAlign: 'center', marginInline: 'auto', padding: "0 1rem 0 1rem", marginTop: '0.1rem' }}>{String(personalDetails?.date_of_birth)}</Typography>
                                </Grid>}
                                {personalDetails?.bio != null && <Grid container xs={12} alignItems={"center"} marginInline={"auto"} display={"flex"} padding={'0rem 1rem 0.5rem 1rem'}>
                                    <Typography fontSize={"0.9rem"} variant='bodyTextRegular' sx={{ textAlign: 'center', marginInline: 'auto', padding: "0 1rem 0 1rem", marginTop: '0.1rem' }}>{personalDetails?.bio}</Typography>
                                </Grid>}
                                <Typography variant='bodyTextRegular' sx={{ fontSize: { xs: "0.8rem", md: "0.8rem" }, textAlign: 'center', marginInline: 'auto' }}>{personalDetails?.city_name}{personalDetails?.city_name && personalDetails?.state_name && ", "}{personalDetails?.state_name}{((personalDetails?.state_name && personalDetails?.country_name) || (personalDetails?.city_name && personalDetails?.country_name)) && ", "}{personalDetails?.country_name}
                                    {personalDetails?.country_name == "India" && <span>
                                        <img src={indiaFlag} rel='preload' style={{ width: "1.2rem", marginLeft: '5px' }} />
                                    </span>}
                                </Typography>

                                <Grid spacing={2} marginTop={1} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                                    <Tooltip title="Linkedin"><button type='button' style={{ backgroundColor: 'transparent', padding: 0 }}><LinkedInIcon sx={{ fontSize: 30, borderRadius: 10 }} style={{ display: 'inline-block' }} onClick={() => { window.open(personalDetails?.profile_url) }} /></button></Tooltip>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Card>
            </Grid> */}
            <Grid sx={{ gridArea: 'second', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                <Card sx={{ ...commonCardStyles }}>
                    <CardHeader title={<span style={{ display: 'flex', alignItems: "center" }} > Investment Focus  <Tooltip title="Public"><LockOpen sx={{ marginLeft: "0.5rem", width: '1rem', color: "#333" }} /> </Tooltip></span>} action={() => { navigate(`/profile/edit_profile?page=${shiftSteps ? 1 : 2}`) }} />
                    <Grid
                        container
                        display={'flex'}
                        xs={12}
                        sx={{
                            overflow: "hidden",
                            justifyContent: 'start',
                            alignItems: 'center'
                        }}
                    >
                        <Grid item width={{ xs: "100%", sm: "50%" }} padding={"0.5rem"}>
                            <Grid sx={{ ...commonInvestmentFocusStyles }} className='investment-focus' >
                                <Grid flex={0.7}>
                                    <img src={categoryIcon} style={{ ...commonInvestmentFocusIconStyles }} />
                                    <Grid mt={'5px'}>
                                        <Typography variant='title' sx={{ fontSize: "0.8rem", marginInline: 'auto', lineHeight: '1rem' }}>Categories</Typography>
                                    </Grid>
                                </Grid>
                                <Grid flex={1} alignItems={'start'}>
                                    <Grid ref={categoryRef} display={'flex'} flex={1} maxHeight={'3.5rem'} overflow={'hidden'} alignItems={'start'}>
                                        <Typography variant='title' sx={{ fontSize: "0.8rem", textAlign: 'left', lineHeight: 1.4 }}>{personalDetails?.category_info?.map(item => { return item.name })?.join(", ")}</Typography>
                                    </Grid>
                                    <span>
                                        {
                                            isOverflowCategory && <Typography display={"flex"} variant='title' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1, justifyContent: 'flex-end', marginRight: "1rem", cursor: 'pointer' }} onClick={() => {
                                                setAlertImage(categoryIcon)
                                                setAlertHeading('Categories')
                                                setAlertMessage(personalDetails?.category_info?.map(item => { return item.name })?.join(", "))
                                                setShowAlert(true)
                                            }}>...more</Typography>
                                        }
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item width={{ xs: "100%", sm: "50%" }} padding={"0.5rem"}>
                            <Grid sx={{ ...commonInvestmentFocusStyles }} className='investment-focus'>
                                <Grid flex={0.7}>
                                    <img src={stageIcon} style={{ ...commonInvestmentFocusIconStyles }} />
                                    <Grid mt={'5px'}>
                                        <Typography variant='title' sx={{ fontSize: "0.8rem", marginInline: 'auto', lineHeight: '1rem' }}>Stages</Typography>
                                    </Grid>
                                </Grid>
                                <Grid flex={1} alignItems={'start'}>
                                    <Grid ref={stagesRef} display={'flex'} flex={1} maxHeight={'3.5rem'} overflow={'hidden'} alignItems={'start'}>
                                        <Typography variant='title' sx={{ fontSize: "0.8rem", textAlign: 'left', lineHeight: 1.4 }}>{personalDetails?.stage_info?.map(item => { return item.name })?.join(", ")}</Typography>
                                    </Grid>
                                    <span>
                                        {
                                            isOverflowStages && <Typography display={"flex"} variant='title' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1, justifyContent: 'flex-end', marginRight: "1rem", cursor: 'pointer' }} onClick={() => {
                                                setAlertImage(stageIcon)
                                                setAlertHeading('Stages')
                                                setAlertMessage(personalDetails?.stage_info?.map(item => { return item.name })?.join(", "))
                                                setShowAlert(true)
                                            }}>...more</Typography>
                                        }
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item width={{ xs: "100%", sm: "50%" }} padding={"0.5rem"}>
                            <Grid sx={{ ...commonInvestmentFocusStyles }} className='investment-focus' >
                                <Grid flex={0.7}>
                                    <img src={sectorIcon} style={{ ...commonInvestmentFocusIconStyles }} />
                                    <Grid mt={'5px'}>
                                        <Typography variant='title' sx={{ fontSize: "0.8rem", marginInline: 'auto', lineHeight: '1rem' }}>Sectors</Typography>
                                    </Grid>
                                </Grid>
                                <Grid flex={1} alignItems={'start'}>
                                    <Grid ref={sectorRef} display={'flex'} flex={1} maxHeight={'3.5rem'} overflow={'hidden'} alignItems={'start'}>
                                        <Typography variant='title' sx={{ fontSize: "0.8rem", textAlign: 'left', lineHeight: 1.4 }}>{personalDetails?.sector_type !== "All" ? sectors?.join(", ") : "Sector Agnostic"}</Typography>
                                    </Grid>

                                    <span>
                                        {
                                            isOverflowSectors && <Typography display={"flex"} variant='title' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1, justifyContent: 'flex-end', marginRight: "1rem", cursor: 'pointer' }} onClick={() => {
                                                setAlertImage(sectorIcon)
                                                setAlertHeading('Sectors')
                                                setAlertMessage(personalDetails?.sector_type !== "All" ? sectors?.join(", ") : "Sector Agnostic")
                                                setShowAlert(true)
                                            }}>...more</Typography>
                                        }
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item width={{ xs: "100%", sm: "50%" }} padding={"0.5rem"}>
                            <Grid sx={{ ...commonInvestmentFocusStyles }} className='investment-focus' >
                                <Grid flex={0.7}>
                                    <img src={chequeSizeIcon} style={{ ...commonInvestmentFocusIconStyles }} />
                                    <Grid mt={'5px'}>
                                        <Typography variant='title' sx={{ fontSize: "0.8rem", marginInline: 'auto', lineHeight: '1rem' }}>Checque Size</Typography>
                                    </Grid>
                                </Grid>
                                <Grid display={'flex'} flex={1} maxHeight={'4rem'} overflow={'hidden'} alignItems={'start'}>
                                    <Typography variant='title' sx={{ fontSize: "0.8rem", textAlign: 'left', lineHeight: 1.4 }}>{personalDetails?.investment_amount_type === "INR" ? "₹" : personalDetails?.investment_amount_type === "USD" ? "$" : null}{convertCurrencyAmountRange(personalDetails?.min, personalDetails?.investment_amount_type, 0)} TO {personalDetails?.investment_amount_type === "INR" ? "₹" : personalDetails?.investment_amount_type === "USD" ? "$" : null}{convertCurrencyAmountRange(personalDetails?.max, personalDetails?.investment_amount_type, 0)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item width={{ xs: "100%", sm: "50%" }} padding={"0.5rem"}>
                            <Grid sx={{ ...commonInvestmentFocusStyles }} className='investment-focus' >
                                <Grid flex={0.7}>
                                    <img src={sectorIcon} style={{ ...commonInvestmentFocusIconStyles }} />
                                    <Grid mt={'5px'}>
                                        <Typography variant='title' sx={{ fontSize: "0.8rem", marginInline: 'auto', lineHeight: '1rem' }}>Business Models</Typography>
                                    </Grid>
                                </Grid>
                                <Grid flex={1} alignItems={'start'}>
                                    <Grid ref={sectorRef} display={'flex'} flex={1} maxHeight={'3.5rem'} overflow={'hidden'} alignItems={'start'}>
                                        <Typography variant='title' sx={{ fontSize: "0.8rem", textAlign: 'left', lineHeight: 1.4 }}>{personalDetails?.business_model_type !== "All" ? businessModel?.join(", ") : "All"}</Typography>
                                    </Grid>

                                    <span>
                                        {
                                            isOverflowSectors && <Typography display={"flex"} variant='title' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1, justifyContent: 'flex-end', marginRight: "1rem", cursor: 'pointer' }} onClick={() => {
                                                setAlertImage(sectorIcon)
                                                setAlertHeading('Business Models')
                                                setAlertMessage(personalDetails?.business_model_type !== "All" ? businessModel?.join(", ") : "All")
                                                setShowAlert(true)
                                            }}>...more</Typography>
                                        }
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            {!(String(personalDetails?.investor_type)?.toLowerCase()?.includes("angel")) && (personalDetails?.partners?.length > 0 && personalDetails?.partners[0]?.name?.trim()?.length > 0) && <Grid sx={{ gridArea: 'fourth', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                <Card sx={{ ...commonCardStyles }}>
                    <CardHeader title={<span style={{ display: 'flex', alignItems: "center" }} > Team <Tooltip title="Public"><LockOpen sx={{ marginLeft: "0.5rem", width: '1rem', color: "#333" }} /></Tooltip></span>} action={() => { navigate(`/profile/edit_profile?page=4`) }} />
                    <Grid
                        container
                        display={'flex'}
                        gap={2}
                        xs={12}
                        sx={{
                            overflow: "hidden",
                            justifyContent: 'start',
                            alignItems: 'center'
                        }}
                    >
                        {
                            personalDetails?.partners?.map((person, index) => {
                                return <PersonDetails theme={theme} data={person} key={index} index={index} length={personalDetails?.partners?.length} />
                            })
                        }
                    </Grid>
                </Card>
            </Grid>}
            <Grid sx={{ width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 }, gridArea: 'fifth', marginTop: shiftSteps ? "-1rem" : 0 }} item>
                <Card sx={commonCardStyles}>
                    <CardHeader title={<span style={{ display: "flex", alignItems: "center", justifyContent: 'center' }} > Other Information <Lock sx={{ color: "transparent" }} /> </span>} action={() => { navigate(`/profile/edit_profile?page=${shiftSteps ? 0 : 1}`) }} />

                    <CardContentList data={[
                        {
                            label: <span style={{ display: 'flex', alignItems: "center", gap: "0.5rem", color: 'grey' }}>
                                Email <Tooltip title="Private"> <Lock sx={{ width: "1rem" }} /></Tooltip>
                            </span>, value: <span style={{ display: 'flex', alignItems: "center", gap: "0.5rem", color: 'grey' }}>{personalDetails?.email}</span>
                        },
                        {
                            label: <span style={{ display: 'flex', alignItems: "center", gap: "0.5rem", color: 'grey' }}>
                                Office Contact No. <Tooltip title="Private"> <Lock sx={{ width: "1rem" }} /></Tooltip>
                            </span>, value: <span style={{ display: 'flex', alignItems: "center", gap: "0.5rem", color: 'grey' }}>{personalDetails?.phone_number ? personalDetails?.country_code + "-" + personalDetails?.phone_number : "-"}</span>
                        },
                        {
                            label: <span style={{ display: 'flex', alignItems: "center", gap: "0.5rem", color: 'grey' }}>
                                WhatsApp No. <Tooltip title="Private"> <Lock sx={{ width: "1rem" }} /></Tooltip>
                            </span>, value: <span style={{ display: 'flex', alignItems: "center", gap: "0.5rem", color: 'grey' }}>{personalDetails?.whatsapp_number ? personalDetails?.phone_code + "-" + personalDetails?.whatsapp_number : "-"} </span>
                        },
                        {
                            label: <span style={{ display: 'flex', alignItems: "center", gap: "0.5rem", color: 'grey' }}>
                                No. of Startups Invested In <Tooltip title="Private"> <Lock sx={{ width: "1rem" }} /></Tooltip>
                            </span>, value: <span style={{ display: 'flex', alignItems: "center", gap: "0.5rem", color: 'grey' }}> {personalDetails?.investment_count || 0}</span>
                        },
                        // {
                        //     label: "Enabled to Discover Publicly", value: <Switch checked={personalDetails?.linkedin_public_profile} />
                        // },
                    ]} style={{ backgroundColor: "white" }} />
                </Card>
            </Grid>
            {/* <Grid sx={{ gridArea: 'second', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                <Card sx={{ ...commonCardStyles }}>
                    <CardHeader title={"Categories"} action={() => { navigate(`/profile/edit_profile?page=${shiftSteps ? 1 : 2}`) }} />
                    <Grid
                        container
                        xs={12}
                        gap={1}
                        sx={{
                            overflow: "hidden",
                            justifyContent: 'start',
                            alignItems: 'center'
                        }}
                    >
                        {
                            personalDetails?.category_info?.map((item, index) => {
                                return (
                                    <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, borderRadius: '8px', padding: "12px", justifyContent: 'center', alignItems: 'center', backgroundColor: getCategoryColor(item.name) }}>
                                        <img src={getCategoryIcon(item)} style={{ width: "0.9rem", height: "0.9rem", objectFit: 'contain' }} />
                                        <Grid>
                                            <Typography variant='titleNormal' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1, marginLeft: "0.3rem" }}>{item.name}</Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Card>
            </Grid> */}
            {/* <Grid sx={{ gridArea: 'third', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                <Card sx={{ ...commonCardStyles }}>
                    <CardHeader title={"Sectors"} action={() => { navigate(`/profile/edit_profile?page=${shiftSteps ? 1 : 2}`) }} />
                    <Grid
                        container
                        xs={12}
                        gap={1}
                        sx={{
                            overflow: "hidden",
                            justifyContent: 'start',
                            alignItems: 'center',
                        }}
                    >
                        {personalDetails?.sector_type !== "All" ?
                            personalDetails?.sector_info?.map((item, index) => {
                                return (
                                    <>
                                        {
                                            item.config_values?.map(sector => {
                                                return (
                                                    <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, borderRadius: '8px', padding: "12px", justifyContent: 'center', alignItems: 'center', backgroundColor: getCategoryColor(item.config_type) }}>
                                                        <Grid>
                                                            <Typography variant='titleNormal' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1 }}>{sector.sub_category}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </>
                                )
                            }) :
                            <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, borderRadius: '8px', padding: "12px", justifyContent: 'center', alignItems: 'center', backgroundColor: getCategoryColor("") }}>
                                <Grid>
                                    <Typography variant='titleNormal' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1 }}>Sector Agnostic</Typography>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Card>
            </Grid> */}
            {/* <Grid sx={{ gridArea: 'fourth', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                <Card sx={{ ...commonCardStyles }}>
                    <CardHeader title={"Business Models"} action={() => { navigate(`/profile/edit_profile?page=${shiftSteps ? 1 : 2}`) }} />
                    <Grid
                        container
                        xs={12}
                        gap={1}
                        sx={{
                            overflow: "hidden",
                            justifyContent: 'start',
                            alignItems: 'center',
                        }}
                    >
                        {personalDetails?.business_model_type !== "All" ?
                            personalDetails?.business_model_info?.map((item, index) => {
                                return (
                                    <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, borderRadius: '8px', padding: "12px", justifyContent: 'center', alignItems: 'center', backgroundColor: getRandomColor() }}>

                                        <Grid>
                                            <Typography variant='titleNormal' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1 }}>{item.name}</Typography>
                                        </Grid>
                                    </Grid>
                                )
                            }) :
                            <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, borderRadius: '8px', padding: "12px", justifyContent: 'center', alignItems: 'center', backgroundColor: getCategoryColor("") }}>
                                <Grid>
                                    <Typography variant='titleNormal' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1 }}>All</Typography>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Card>
            </Grid> */}
            {/* <Grid sx={{ gridArea: 'fifth', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                <Card sx={{ ...commonCardStyles }}>
                    <CardHeader title={"Stages"} action={() => { navigate(`/profile/edit_profile?page=${shiftSteps ? 1 : 2}`) }} />
                    <Grid
                        container
                        display={'grid'}
                        gridTemplateColumns={'repeat(auto-fill, minmax(16rem, 1fr))'}
                        xs={12}
                        gap={2}
                        sx={{
                            overflow: "hidden",
                            justifyContent: 'start',
                            alignItems: 'center'
                        }}
                    >
                        {
                            personalDetails?.stage_info?.map(item => {
                                return (
                                    <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, borderRadius: '8px', justifyContent: 'start', alignItems: 'normal' }}>
                                        <Grid display={'flex'} sx={{ padding: "12px 10px", backgroundColor: item.disable ? theme.palette.customColors.disable : theme.palette.customColors.indigo600, borderRight: `1px solid ${theme.palette.customColors.g75}`, borderRadius: '7px', borderTopRightRadius: 0, borderBottomRightRadius: 0, alignItems: 'center' }}>
                                            <CheckIcon style={{ height: '1rem', width: '1.3rem', color: item.disable ? "grey" : "white" }} />
                                        </Grid>
                                        <Grid sx={{ padding: "12px" }}>
                                            <Typography variant='titleNormal' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1 }}>{item.name} {item.subtitle ? "(" + item.subtitle + ")" : null}</Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Card>
            </Grid> */}
            {/* <Grid sx={{ gridArea: 'sixth', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                <Card sx={{ ...commonCardStyles }}>
                    <CardHeader title={"Investment size"} action={() => { navigate(`/profile/edit_profile?page=${shiftSteps ? 1 : 2}`) }} />
                    <Grid
                        container
                        xs={12}
                        sx={{
                            overflow: "hidden",
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, padding: "0.4rem", borderRadius: "8px", width: '40%' }} alignItems={'center'} justifyContent={'center'}>
                            <Typography variant='title' sx={{ fontSize: "large", textAlign: 'center', marginInline: 'auto', paddingX: '0.2rem' }}>
                                {personalDetails?.investment_amount_type === "INR" ? "₹" : personalDetails?.investment_amount_type === "USD" ? "$" : null}{convertCurrencyAmountRange(personalDetails?.min, personalDetails?.investment_amount_type, 0)}
                            </Typography>
                        </Grid>
                        <Typography variant='title' sx={{ fontSize: "medium", textAlign: 'center', paddingX: '0.2rem', width: '10%' }}>
                            TO
                        </Typography>
                        <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, padding: "0.4rem", borderRadius: "8px", width: '40%' }} alignItems={'center'} justifyContent={'center'}>
                            <Typography variant='title' sx={{ fontSize: "large", textAlign: 'center', marginInline: 'auto', paddingX: '0.2rem' }}>
                                {personalDetails?.investment_amount_type === "INR" ? "₹" : personalDetails?.investment_amount_type === "USD" ? "$" : null}{convertCurrencyAmountRange(personalDetails?.max, personalDetails?.investment_amount_type, 0)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Grid> */}

            <AlertDialog open={showAlert} handleClose={() => { setShowAlert(false); setAlertMessage(null); setAlertHeading(null); setAlertImage(null) }} heading={alertHeading} subheading={alertMessage} image={alertImage} />
        </Grid>
    )
}

export default PersonalDetails