import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { RetryOnError, verifyToken } from "../utils/VerifyToken"
import { capitalize } from "@mui/material";

export const FetchLatestVersion = () => {
    return useQuery(
        ['master-FetchLatestVersion-version_id-get-latest-FetchLatestVersion-using-id-version_id'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/users/get-latest-version`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            return data?.data?.version
        },
        refetchOnWindowFocus: false,
        // refetchInterval: 60 * 60 * 1000,
        refetchInterval: 1 * 24 * 60 * 60 * 1000, // 24 hours
        // refetchInterval: 10000,
        refetchIntervalInBackground: true,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}
