import { useTheme } from "@emotion/react";
import { Autocomplete, Chip, Grid, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as CloseIcon } from "../../../assets/close_icon.svg";
import { B300 } from "../../../ui-components/CustomButton";
import { FormikContext, FormikProvider, useFormik } from "formik";
import CustomInputField from "../../../ui-components/CustomInputField";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { AutoComplete } from "rsuite";
import { FetchAllCategories, FetchAllStages, FetchAllSubCategories } from "api/utils";
import CustomLabel from "ui-components/CustomLabel";

const source_list = [
  { label: "Via Startup Profile", value: "startup_profile" },
  { label: "Via Pitch Deck", value: "pitch_deck" },
  { label: "Via Dashboard", value: "dashboard" },
  { label: "Via Investor Profile", value: "investor_profile" },
]

const FilterPopup = ({ queryParams, setQueryParams, handleClose, handleSubmit, stage, category, sector, handleReset, setStage, setCategory, setSector, setProfileSource }) => {
  const theme = useTheme();
  const { id: round_id } = useParams();
  const startup_id = useSelector((state) => state.investorDetails.data)?._id;
  const [filterQueryParams, setFilterQueryParams] = React.useState(queryParams);
  const { data: startup_stages, isRefetching: isRefetchingAllStages, isLoading: isLoadingAllStages, refetch: refetchAllStages } = FetchAllStages();
  const { data: startup_categories, isRefetching: isRefetchingAllCategories, isLoading: isLoadingAllCategories, refetch: refetchAllCategories } = FetchAllCategories();
  const { data: startup_sub_categories, isRefetching: isRefetchingAllSubcategories, isLoading: isLoadingAllSubcategories, refetch: refetchAllSubcategories } = FetchAllSubCategories({ category: filterQueryParams?.category });

  const stagesListing = startup_stages && startup_stages?.length > 0 ? startup_stages?.map(item => ({ label: item?.name, value: item?.name })) : []
  const categoriesListing = startup_categories && startup_categories?.length > 0 ? startup_categories?.map(item => ({ label: item?.name, value: item?.name })) : []
  const subCategoriesListing = startup_sub_categories && startup_sub_categories?.length > 0 ? startup_sub_categories?.map(item => ({ label: item?.sub_category, value: item?.sub_category })) : []

  const updateSector = (params) => {
    let removedSectorParamFromQuery = params;
    removedSectorParamFromQuery.sector && delete removedSectorParamFromQuery.sector;
    // Cookies.set("backrr_startups_query", JSON.stringify({ ...removedSectorParamFromQuery }));
    setFilterQueryParams({ ...removedSectorParamFromQuery });
    setSector([]);
  }

  const formik = useFormik({
    initialValues: {
      ...filterQueryParams
    },
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {
      // console.log(
      //   {
      //     invitations: [
      //       {
      //         startup_id: startup_id,
      //         startup_round_id: round_id,
      //         message: values?.message,
      //       },
      //     ],
      //     email: values?.email,
      //   },
      //   "values"
      // // );
      // handleSubmit({query});
      // console.log(filterQueryParams,"filterQueryParams")
      // console.log(values, "ffff")
      const result = Object.fromEntries(Object.entries(filterQueryParams).filter(([_, v]) => v !== undefined && v?.trim()?.length > 0))
      handleSubmit({ query: result })
    },
  });
  return (
    <Paper
      sx={{
        background: theme.palette.customColors.whiteBG,
        borderRadius: "8px",
      }}
    >
      <IconButton sx={{ position: "absolute", top: 20, right: 20, padding: 0 }}>
        <CloseIcon width={40} height={40} onClick={handleClose} />
      </IconButton>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid display={"grid"} container padding={"1.5rem"} gap={"1.5rem"}>
            <Grid item>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  lineHeight: "28px",
                  color: theme.palette.customColors.g300,
                }}
              >
                Filter
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  lineHeight: "24px",
                  color: theme.palette.customColors.g200,
                }}
              >
                Simply add their email addresses and send the invitation.
              </Typography> */}

            </Grid>
            {/* {console.log(stagesListing, "ededed")} */}
            <Grid xs={12} item container columnSpacing={2} rowSpacing={2} justifyContent={"space-between"}>
              <Grid item xs={12} sm={6}>
                <CustomLabel>Stage</CustomLabel>
                <Autocomplete
                  multiple
                  disablePortal
                  options={Object.keys(filterQueryParams)?.length && filterQueryParams?.stage && filterQueryParams?.stage?.split(",")?.length > 0 ? stagesListing?.filter(item => !filterQueryParams.stage.split(",")?.includes(item?.value)) : stagesListing}
                  fullWidth
                  value={Object.keys(filterQueryParams)?.length && filterQueryParams?.stage
                    ? filterQueryParams.stage.split(",").map(stage => ({ label: stage, value: stage }))
                    : []
                  }
                  autoHighlight
                  onChange={(e, newValue) => {
                    const selectedStages = newValue.map(option => option.label).join(",");
                    setFilterQueryParams({ ...filterQueryParams, stage: selectedStages });
                    setStage(selectedStages);
                  }}
                  renderTags={(selected, getTagProps) =>
                    selected.map((option, index) => (
                      <Chip key={option.value} label={option.label} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <CustomInputField
                      variant="outlined"
                      placeholder="Stage"
                      {...params}
                      sx={{ marginBottom: 0 }}
                      className="filter-autocomplete"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <CustomLabel>Category</CustomLabel>
                <Autocomplete
                  multiple
                  disablePortal
                  options={Object.keys(filterQueryParams)?.length && filterQueryParams?.category && filterQueryParams?.category?.split(",")?.length > 0 ? categoriesListing?.filter(item => !filterQueryParams.category.split(",")?.includes(item?.value)) : categoriesListing}
                  fullWidth
                  value={Object.keys(filterQueryParams)?.length && filterQueryParams?.category
                    ? filterQueryParams.category.split(",").map(category => ({ label: category, value: category }))
                    : []
                  }
                  autoHighlight
                  onChange={(e, newValue) => {
                    const selectedCategories = newValue.map(option => option.label).join(",");
                    setFilterQueryParams({ ...filterQueryParams, category: selectedCategories });
                    setCategory(selectedCategories);
                    setTimeout(() => {
                      updateSector({ ...filterQueryParams, category: selectedCategories });
                    }, 10);
                  }}
                  renderTags={(selected, getTagProps) =>
                    selected.map((option, index) => (
                      <Chip key={option.value} label={option.label} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <CustomInputField
                      variant="outlined"
                      placeholder="Category"
                      {...params}
                      sx={{ marginBottom: 0 }}
                      className="filter-autocomplete"
                    />
                  )}
                />
              </Grid>

              {startup_sub_categories && filterQueryParams?.category && filterQueryParams?.category !== null && (
                <Grid item xs={12} sm={6}>
                  <CustomLabel>Sector</CustomLabel>
                  <Autocomplete
                    multiple
                    disablePortal
                    options={
                      Object.keys(filterQueryParams)?.length && filterQueryParams?.sector && filterQueryParams?.sector?.split(",")?.length > 0 ?
                        subCategoriesListing?.filter(item => !filterQueryParams.sector.split(",")?.includes(item?.value)) : subCategoriesListing}
                    fullWidth
                    value={Object.keys(filterQueryParams)?.length && filterQueryParams?.sector
                      ? filterQueryParams.sector.split(",").map(sector => ({ label: sector, value: sector }))
                      : []
                    }
                    autoHighlight
                    onChange={(e, newValue) => {
                      const selectedSectors = newValue.map(option => option.label).join(",");
                      setFilterQueryParams({ ...filterQueryParams, sector: selectedSectors });
                      setSector(selectedSectors);
                    }}
                    renderTags={(selected, getTagProps) =>
                      selected.map((option, index) => (
                        <Chip key={option.value} label={option.label} {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={(params) => (
                      <CustomInputField
                        variant="outlined"
                        placeholder="Sector"
                        {...params}
                        sx={{ marginBottom: 0 }}
                        className="filter-autocomplete"
                      />
                    )}
                  />
                </Grid>
              )}

              {/* <Grid item xs={12} sm={6}>
                <CustomLabel>Source</CustomLabel>
                <Autocomplete
                  multiple
                  disablePortal
                  options={Object.keys(filterQueryParams)?.length && filterQueryParams?.profile_source && filterQueryParams?.profile_source?.split(",")?.length > 0 ? source_list?.filter(item => !filterQueryParams?.profile_source?.split(",")?.includes(item?.value)) : source_list}
                  fullWidth
                  value={Object.keys(filterQueryParams)?.length && filterQueryParams?.profile_source
                    ? filterQueryParams.profile_source.split(",").map(source => ({
                      label: source_list.find(item => item.value === source)?.label || source,
                      value: source
                    }))
                    : []
                  }
                  autoHighlight
                  onChange={(e, newValue) => {
                    const selectedSources = newValue.map(option => option.value).join(",");
                    setFilterQueryParams({ ...filterQueryParams, profile_source: selectedSources });
                    setProfileSource(selectedSources);
                  }}
                  renderTags={(selected, getTagProps) =>
                    selected.map((option, index) => (
                      <Chip key={option.value} label={option.label} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <CustomInputField
                      variant="outlined"
                      placeholder="Source"
                      {...params}
                      sx={{ marginBottom: 0 }}
                      className="filter-autocomplete"
                    />
                  )}
                />
              </Grid> */}
            </Grid>
            <Grid item container xs={12} gap={2} display={"flex"} flexWrap={"nowrap"} justifyContent={"center"}>
              <B300
                type="reset"
                onClick={() => {
                  handleSubmit({ query: {} })
                }}
                sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem", bgcolor: "#e9f5f8", ":hover": { bgcolor: "#e9f5f8" }, color: theme.palette.customColors.b300, minWidth: 120 }}
              >
                Reset
              </B300>
              <B300
                type="submit"
                sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem", bgcolor: theme.palette.customColors.b300, ":hover": { bgcolor: theme.palette.customColors.b400 }, minWidth: 120 }}
              >
                Filter
              </B300>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Paper>
  );
};

export default FilterPopup;
