import { useTheme } from '@emotion/react'
import { Check, Edit } from '@mui/icons-material'
import { Grid, InputAdornment, Switch, Tooltip, Typography } from '@mui/material'
import { VerifyPublicURL } from 'api/profile'
import { useConfig } from 'dynamic-configuration/configContext'
import { useFormikContext } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import CustomUrlField from 'ui-components/CustomUrlField'
import eye_icon from "assets/eye.png"
import { notEmpty } from 'utils/index'

const ProfileURLDetails = ({ currentStep }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const formik = useFormikContext()
  const { config } = useConfig()
  const { mutateAsync: verifyPublicURLAsync, data: verifyPublicURLResponse, isIdle, error: verifyPublicURLError } = VerifyPublicURL();
  const isPublicURLVerify = formik?.values?.isPublicURLVerify || false;
  const isWhatsAppEnabled = formik?.values?.isWhatsAppEnabled || false;
  const isPublicEnabled = formik?.values?.isPublicEnabled || false;
  const isUpdated = formik?.values?.isUpdated || false;
  // console.log("investorone1",formik.values)
  // const [isPublicURLVerify, setIsPublicURLVerify] = React.useState(false);
  // const [isWhatsAppEnabled, setIsWhatsAppEnabled] = React.useState(false);
  // const [isPublicEnabled, setIsPublicEnabled] = React.useState(false);

  const handleToggle = () => {
    formik.setFieldValue("isWhatsAppEnabled", !isWhatsAppEnabled);
    formik.setFieldValue("enabled", !isWhatsAppEnabled)
  };

  const handleTogglePublic = (event) => {
    formik.setFieldValue("isPublicEnabled", !isPublicEnabled);
    formik.setFieldValue("linkedin_public_profile", !isPublicEnabled)
    // formik.setFieldValue("isPublicEnabled",event.target.checked)
  };

  React.useEffect(() => {
    formik.setFieldValue("isPublicEnabled", formik?.values?.linkedin_public_profile)
  }, [])

  const handlePublicURLVerify = async (public_url_updated) => {
    const publicURL = public_url_updated ? public_url_updated : formik?.values?.public_url;


    if (!publicURL) {
      formik.setFieldTouched("public_url", true);
      formik.setFieldError("public_url", "Public URL cannot be empty.");
      return;
    }

    if (!isPublicURLVerify) {
      const payload = { slug: publicURL, investor_id: formik?.values?._id };
      try {
        await verifyPublicURLAsync(payload);
        formik.setFieldValue("isPublicURLVerify", true); // Set to verified if successful
        formik.setFieldError("public_url", null); // Clear any previous errors
        formik.setFieldValue("isUpdated", false);
        // console.log("success")
      } catch (err) {
        const errorMessage = err?.response?.data?.message || "Verification failed. Please try again.";
        formik.setFieldTouched("public_url", true);
        formik.setFieldError("public_url", errorMessage);
        formik.setFieldValue("isPublicURLVerify", false);
      }
    } else {
      formik.setFieldValue("isPublicURLVerify", false); // Allow editing if already verified
    }
  };

  const initialState = formik?.values?.first_name + formik?.values?.last_name

  // React.useEffect(() => {

  //   // console.log(formik?.values?.public_url ,'formik?.values?.public_url')
  //   const verifyWithRetries = async (initialPublicURL) => {

  //     // Exit if public_url is invalid
  //     if (!initialPublicURL || initialPublicURL.trim() === "" || (currentStep !== 9 && currentStep !== 10) || !formik?.values?.first_name || formik?.values?.first_name === "" || !formik?.values?.last_name || formik?.values?.last_name === "") {
  //       return;
  //     }

  //     let count = 0;
  //     let isVerified = false;
  //     let publicURL = initialPublicURL;

  //     while (!isVerified && count <= 10) {
  //       const payload = { slug: publicURL, investor_id: formik?.values?._id };
  //       try {
  //         await verifyPublicURLAsync(payload);
  //         formik.setFieldValue("isPublicURLVerify", true); // Mark as verified
  //         formik.setFieldError("public_url", null); // Clear any errors
  //         isVerified = true;
  //       } catch (err) {
  //         const errorMessage = err?.response?.data?.message || "Verification failed.";
  //         formik.setFieldError("public_url", errorMessage);
  //         formik.setFieldValue("isPublicURLVerify", false);

  //         // Update the slug with retry count
  //         count++;
  //         publicURL = initialPublicURL + `0${count}`;
  //         formik.setFieldValue("public_url", publicURL);
  //       }
  //     }
  //   };

  //   verifyWithRetries(notEmpty(formik?.values?.public_url) ? formik?.values?.public_url : initialState);
  // }, [initialState, currentStep, formik?.values?.first_name, formik?.values?.last_name]); // Depend on public_url changes


  return (
    <>
      <Grid
        container
        xs={12}
        gap={1}
        paddingBottom={2}

      >

        {/* <Grid item xs={12} position="relative" pt={1}>
          <CustomUrlField
            label={config?.validationFields?.linkedIn_profile_url?.label}
            name={'linkedIn_profile_url'}
            value={
              String(formik?.values?.linkedIn_profile_url)?.startsWith("https://")
                ? String(formik?.values?.linkedIn_profile_url)?.replace("https://", "")
                : formik?.values?.linkedIn_profile_url

            }
            placeholder={config?.validationFields?.linkedIn_linkedIn_profile_url?.placeholder}
            onChange={(evt) => {

              formik.setFieldValue("linkedIn_profile_url", String(evt.target.value)?.startsWith("https://")
                ? String(evt.target.value)?.replace("https://", "")
                : evt.target.value)
            }}
          />
        </Grid> */}
        <Grid item xs={12} position="relative" pb={1} mt={"0"}>
          {/* {console.log(formik?.values?.first_name + formik?.values?.last_name , "value")} */}
          <CustomUrlField
            sx={{
              '& .MuiInputBase-root .Mui-disabled': {
                backgroundColor: '#fff !important'
              },
              "& .MuiInputBase-root .Mui-disabled > .MuiInputAdornment-root": {
                backgroundColor: '#fff !important'
              }
            }}
            label={"PUBLIC URL"}
            startAdornmentText={formik?.values?.backrr_base_url?.substring(0, formik?.values?.backrr_base_url?.lastIndexOf('/') + 1 )}
            name={'public_url'}
            value={formik?.values?.public_url}
            // disabled={isPublicURLVerify}
            // placeholder={"Username"}
            defaultValue={String(formik?.values?.first_name + formik?.values?.last_name)?.toLowerCase()?.replaceAll(" ", "")?.replace(/[^a-zA-Z0-9]/g, '')}
            // sx={{marginBottom:1}}
            onMouseOut={(evt) => {
              isUpdated && handlePublicURLVerify();
            }}
            onChange={(evt) => {
              formik.setFieldValue("public_url", String(evt.target.value)?.replaceAll(" ", "")?.replaceAll(/[^a-zA-Z0-9]/g, ''));
              formik.setFieldValue("isUpdated", true);
              formik.setFieldValue("isPublicURLVerify", false);
            }}
            InputProps={
              {
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      marginLeft: "0px",
                      paddingInline: "8px",
                      height: "49px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => handlePublicURLVerify()}
                  >
                    {isPublicURLVerify ? <Tooltip title="Available" open>
                      <Check sx={{ color: 'green' }} />
                    </Tooltip> : <Check sx={{ color: "gray" }} />}
                  </InputAdornment>
                ),
                pattern: '[a-zA-Z0-9]*', // Optional: Validates only alphanumeric input
                maxLength: 30, // Optional: Limit the input length

              }
            }

          />

        </Grid>

        <Grid
          role="button"
          alignItems="center"
          display={'flex'}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}

          sx={{
            background: `${theme?.palette?.customColors?.y50}`,
            padding: "1px 16px",
            height: 55,
            borderRadius: "8px",
            display: "grid",
            gridTemplateColumns: "auto 1fr auto",
            border: `1px solid ${theme?.palette?.customColors?.y300}`,
            boxShadow: "0px 1px 2px 0px #1018280D",
            cursor: "pointer",
          }}
        >
          <img src={eye_icon} alt='whats' width={20} height={20} style={{ marginRight: '0.5rem' }} />

          <Typography
            variant="subtitle2"
            fontWeight={400}
            color={theme.palette.customColors.g300}
            fontSize="1rem"
          >
            {config?.dynamicText?.enableDiscover}
          </Typography>
          <Switch checked={isPublicEnabled} onChange={handleTogglePublic} />
        </Grid>
        <Typography variant='caption' fontWeight={400} pt={0} fontSize={'14px'}>
          {config?.dynamicText?.dicoverMessage}
        </Typography>
      </Grid>
    </>
  )
}

export default ProfileURLDetails