import { useTheme } from '@emotion/react'
import { Grid, Box, Typography, Tooltip, IconButton, Divider } from '@mui/material'
import { FieldArray, useFormikContext } from 'formik'
import React, { useState } from 'react'
import CustomUrlField from 'ui-components/CustomUrlField'
import CustomInputField from 'ui-components/formik/CustomInputField'
import CustomLogoButton from 'ui-components/formik/CustomLogoButton'
import DeleteCoFounderIcon from "assets/delete.png"
import EditFounderIcon from "assets/edit.png"
import DeleteFounderIcon from "assets/delete.png"
import linkedInIcon from 'assets/linkedin_icon.png'
import AddCoFounderIcon from "assets/add_button.png"



const CoFounderInformation = () => {
  const formik = useFormikContext()
  const theme = useTheme()
  const [isPressed, setIsPressed] = React.useState(true)
  let stepIndex = new URLSearchParams(window.location.search).get("page");
  const [currentIndex, setCurrentIndex] = React.useState(Number(stepIndex) || 0);


  return (
    <>
      <Grid
        item
        container
        xs={12}
        display="flex"
        gap={0}
        justifyContent="space-between"
        flexDirection="column"
        paddingBottom={2}
      >
        <FieldArray
          name="coFoundersArray"
          render={(arrayHelpers) => (
            <>
              {Array.isArray(formik.values?.coFoundersArray) && formik.values?.coFoundersArray?.length > 0 &&
                formik.values?.coFoundersArray?.map((current, index) => (
                  <Box key={index}>
                    {current?.edit ? (
                      <>
                        <Grid item xs={12} sx={{ marginBottom: 1, position: 'relative' }} pt={1}>
                          <CustomLogoButton
                            label="PHOTO"
                            handleUpdateFile={(file) => {
                              formik.setFieldValue(`coFoundersArray.${index}.image`, file);
                            }}
                            name={`coFoundersArray.${index}.image`}
                            value={current?.image}
                            Pwidth={90} Pheight={90}
                          />
                          {/* {formik.values?.coFoundersArray?.length > 1 && ( */}
                          <IconButton
                            sx={{ position: "absolute", top: "0rem", right: "1.2rem", padding: 0 }}
                            onClick={() => {
                              if (window.confirm("Are you sure you want to delete?") === true) {
                                arrayHelpers.remove(index);
                              }
                            }}
                          >
                            <img src={DeleteCoFounderIcon} rel="preload" width={30} height={30} />
                          </IconButton>
                          {/* )} */}
                        </Grid>
                        <Grid item xs={12} position="relative" pt={1}>
                          <CustomInputField
                            label="NAME"
                            name={`coFoundersArray.${index}.name`}
                            value={current?.name}
                            placeholder="Enter co-founder name"
                            onChange={(e) =>
                              formik.setFieldValue(
                                `coFoundersArray.${index}.name`,
                                e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1)
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CustomInputField
                            label="TITLE"
                            name={`coFoundersArray.${index}.title`}
                            value={current?.title}
                            placeholder="Enter co-founder title"
                            onChange={(e) =>
                              formik.setFieldValue(
                                `coFoundersArray.${index}.title`,
                                e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1)
                              )
                            }
                          />
                        </Grid>
                        {/* {console.log(formik.values?.coFoundersArray, "savedValues")} */}
                        <Grid xs={12}>
                          <CustomInputField
                            label={"BIO"}
                            name={`coFoundersArray.${index}.bio`}
                            labelStyle={{ marginTop: '0rem' }} onChange={(e) => {
                              const value = e.target.value;
                              if (value.length <= 250) {
                                formik.setFieldValue(`coFoundersArray.${index}.bio`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))
                              }
                              else {
                                formik.setFieldValue(`coFoundersArray.${index}.bio`, String(e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))?.substring(0, 250))
                              }
                            }} multiline minRows={4} maxRows={4} maxLength={250} fullWidth data-problem-solving={250 - formik.values?.coFoundersArray[index]?.bio?.length + `${(250 - formik.values?.coFoundersArray[index]?.bio?.length) > 1 ? " characters" : " character"} left`}
                            sx={{
                              "::after": {
                                content: 'attr(data-problem-solving)',
                                position: "absolute",
                                bottom: formik?.errors?.coFoundersArray?.length > 0 ? 30 : 5,
                                right: 5,
                                background: 'white',
                                color: 'graytext',
                                padding: "0 5px",
                                fontSize: "0.75rem"
                              },
                            }}
                            value={current?.bio}
                            placeholder="Enter founder bio"
                          />
                        </Grid>
                        <Box>
                          <Grid item xs={12} display="flex">
                            <CustomUrlField
                              label="LINKEDIN PROFILE URL"
                              placeholder="www.linkedin.com"
                              name={`coFoundersArray.${index}.linkedin_url`}
                              value={current?.linkedin_url}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `coFoundersArray.${index}.linkedin_url`,
                                  String(e.target.value)?.startsWith("https://")
                                    ? String(e.target.value)?.replace("https://", "")
                                    : e.target.value
                                )
                              }
                            />
                          </Grid>
                        </Box>
                      </>
                    ) : (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        border="1px solid #A1A3A7"
                        padding="7px 18px"
                        borderRadius="4px"
                      >
                        <Box display="flex" alignItems="center" color={theme?.palette?.customColors?.g100} gap={1}>
                          <span>{current?.name}</span>
                          {current?.linkedin_url && <img src={linkedInIcon} rel="preload" width={24} height={24} />}
                        </Box>
                        <Box display="flex" gap={1}>
                          <IconButton onClick={() => formik.setFieldValue(`coFoundersArray.${index}.edit`, true)}>
                            <img src={EditFounderIcon} rel="preload" width={30} height={30} />
                          </IconButton>
                          <IconButton onClick={() => arrayHelpers.remove(index)}>
                            <img src={DeleteFounderIcon} rel="preload" width={30} height={30} />
                          </IconButton>
                        </Box>
                      </Box>
                    )}
                    {formik.values?.coFoundersArray?.length - 1 === index && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              if (current?.name !== "" && current?.title !== "" && current?.image !== "" && current?.image !== null && current?.linkedin_url !== "") {
                                formik.setFieldValue(`coFoundersArray.${index}`, { ...current, edit: false }).then(() =>
                                  arrayHelpers.insert(index + 1, {
                                    name: "",
                                    title: "",
                                    image: null,
                                    linkedin_url: "",
                                    edit: true,
                                  })
                                );
                              } else {
                                formik.handleSubmit();
                                // toast.error(
                                //     <CustomToast
                                //         message="Write Founder's Name"
                                //         style={{ padding: "0px 15px" }}
                                //         closeIcon={true}
                                //         closeIconPosition="center"
                                //     />,
                                //     {
                                //         duration: 2000,
                                //     }
                                // );
                              }
                            }}
                          >
                            <IconButton>
                              <Tooltip title="Add another co-founder">
                                <img src={AddCoFounderIcon} rel="preload" width={32} height={32} />
                              </Tooltip>
                            </IconButton>
                            <Typography marginLeft="1rem" variant="caption" color="#000">
                              Add another co-founder
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                    {formik.values?.coFoundersArray?.length > 1 && <Divider sx={{ marginTop: 2, marginBottom: 2 }} />}
                  </Box>
                ))}

              {formik.values?.coFoundersArray?.length === 0 && (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent: "space-between" }}>
                  <Box sx={{ cursor: "pointer" }}>
                    <IconButton
                      onClick={() =>
                        arrayHelpers.insert(0, {
                          name: "",
                          title: "",
                          image: null,
                          linkedin_url: "",
                          edit: true,
                        })
                      }
                    >
                      <Tooltip title="Add user">
                        <img rel="preload" src={AddCoFounderIcon} width={32} height={32} />
                      </Tooltip>
                    </IconButton>
                    <Typography
                      onClick={() =>
                        arrayHelpers.insert(0, {
                          name: "",
                          title: "",
                          image: null,
                          linkedin_url: "",
                          edit: true,
                        })
                      }
                      marginLeft="1rem"
                      variant="caption"
                      color="#000"
                    >
                      Add more Co-founder details
                    </Typography>
                  </Box>
                  <Typography
                    onClick={() => setCurrentIndex(currentIndex + 1)}
                    sx={{ cursor: "pointer", opacity: isPressed ? 1 : 0.2 }}
                    onMouseDown={() => setIsPressed(false)}
                    onMouseUp={() => setIsPressed(true)}
                    color={theme?.palette.customColors.b300}
                    fontSize="1rem"
                  >
                    <span>Skip</span>
                  </Typography>
                </Box>
              )}
            </>
          )}
        />
      </Grid>
    </>
  )
}

export default CoFounderInformation

