import { useTheme } from '@emotion/react'
import { Add, RemoveRedEyeOutlined } from '@mui/icons-material'
import { Box, Card, Grid, IconButton, Rating, Tooltip, useMediaQuery } from '@mui/material'
import { FetchAllStartupFeedbacks, FetchAllStartupFeedbacksByInvestorID } from 'api/startups'
import React from 'react'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import AsyncTableListing from 'ui-components/AsyncTableListing'
import { B300 } from 'ui-components/CustomButton'
import FeedbackPopup from '../popups/FeedbackPopup'
import ModalComponent from 'ui-components/ModalComponent'
import { FetchAllStartupsListing, FetchInterestFeedbackStatus, FetchViewStartupDetails, SaveFeedback } from 'api/startups'
import CustomToast from 'ui-components/CustomToast'
import toast from 'react-hot-toast'
import placeHolderImagefeedback from 'assets/no_data_images/Startups/new_startups.svg'

const PNGIconComponent = (({ row }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <RemoveRedEyeOutlined style={{ width: 20, color: theme.palette.customColors.indigo600 }} />

  )

})

const SubmitFeedback = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let [investor_id, setInvestorID] = React.useState(Cookies.get("X-INV-ID") || null);
  const permissions = Cookies.get("permissions") ? JSON.parse(Cookies.get("permissions")) : null

  const details = useSelector((state) => state?.startupDetails?.data);
  const column = [
    { Header: 'Investor', accessor: 'name', minWidth: 150, align: 'start', fixed: true },
    { Header: 'Location', accessor: 'location', minWidth: 150, align: 'center' },
    { Header: 'Date', accessor: 'formatted_date', minWidth: 100, align: 'center' },
    { Header: 'Rating', accessor: 'rating', minWidth: 150, align: 'center' },
    // { Header: 'Market', accessor: 'market', minWidth: 150, align: 'center' },
    // { Header: 'Product', accessor: 'product', minWidth: 150, align: 'center' },
    // { Header: 'Team', accessor: 'team', minWidth: 150, align: 'center' },
    // { Header: 'Traction', accessor: 'traction', minWidth: 150, align: 'center' },
    { Header: 'Investible', accessor: 'investible', minWidth: 50, align: 'center' },
    { Header: 'Feedback', accessor: 'feedback', minWidth: 200, align: 'center', tooltip: 'feedback' },
    { Header: 'Actions', accessor: 'actions', minWidth: 100, align: 'center' },
  ];


  const [page, setPage] = React.useState(1);
  const userDetails = useSelector((state) => state?.investorDetails?.data);
  // console.log(userDetails,"nameee")
  const [limit, setLimit] = React.useState(10);
  const [sort, setSort] = React.useState(-1);
  const [search, setSearch] = React.useState(-1);
  const [isEnabledInterest, setIsEnabledInterest] = React.useState(false);
  const [isEnabledFeedback, setIsEnabledFeedback] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false)
  let cookieStartupId = Cookies.get("startup_id");
  const [alertMessage, setAlertMessage] = React.useState(null)
  const [alertHeading, setAlertHeading] = React.useState(null)
  const [currentRow, setCurrentRow] = React.useState(null);
  const [selectedPDF, setSelectedPDF] = React.useState('');
  const parsedCookieForQueryParams = Cookies.get("startups_query") ? JSON.parse(Cookies.get("startups_query")) : {}
  const [queryParams, setQueryParams] = React.useState(parsedCookieForQueryParams);
  const [value, setValue] = React.useState("new")
  // const [filledFeedback , setFilledFeedback] =React.useState('')
  const [feedbacksData, setFeedbacksData] = React.useState(null);


  // const { data: transformedData, isRefetching: isRefetchingStartupListing, isLoading: isLoadingStartupListing, refetch: refetchListing } = FetchAllStartupsListing({ page, limit, status: value, investor_id: userDetails?._id, queryParams, searchText: search })
  // const { data: feedbacksData, isLoading: isLoadingFeedbacks, refetch: refetchListing  } = FetchAllStartupFeedbacks({ id: cookieStartupId ? cookieStartupId : '', page, limit, sort });
  const {
    mutateAsync: feedbackMutateAsync,
    isLoading: isLoadingFeedbacks
  } = FetchAllStartupFeedbacksByInvestorID();

  const refetchListing = () => {
    if (investor_id) {
      // console.log(investor_id, 'investor_id', {
      //   investor_id
      // })
      const isAdminPayload =
        Boolean(permissions?.backrr_startups) && window.location.href?.includes("backrr_startups") ? {} : userDetails?.investor_type === "incubator" || userDetails?.investor_type === "accelerator" ? {} :
          {
            payload: {
              investor_id
            }
          };

      // console.log(isAdminPayload)

      cookieStartupId && feedbackMutateAsync({
        ...isAdminPayload,
        params: {
          startup_id: cookieStartupId ? cookieStartupId : '', page, limit, sort
        }
      }, {
        onSuccess: (response) => {
          setFeedbacksData(response?.data || {})
        }
      })
    }
  }

  React.useEffect(() => {
    refetchListing()
  }, [investor_id, cookieStartupId, page, limit, sort])

  const {
    mutateAsync: FetchStatusAsync,
    status: fetchStatusStatus,
    error: fetchStatusError,
    isLoading: isUpdatingFetchStatus,
    data: fetchStatusResponse,
  } = FetchInterestFeedbackStatus();
  const {
    mutateAsync: saveFeedbackAsync,
    status: saveFeedbackStatus,
    error: saveFeedbackError,
    isLoading: isSavingFeedback,
    data: saveFeedbackResponse,
  } = SaveFeedback();
  const { data: startupDetails, isLoading: isLoadingStartupDetails, refetch: refetchStartupDetails } = FetchViewStartupDetails(cookieStartupId)
  const formattedFeedbacksData = feedbacksData?.data && feedbacksData?.data?.length ? feedbacksData?.data?.map(item => {
    return ({
      // ...item,
      ...item?.startup_feedbacks?.ratings,
      name: item?.name ? item?.name?.length > 0 ? item?.name : item?.email : item?.email,
      feedback: item?.startup_feedbacks?.feedback,
      rating:
        <Box sx={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px', cursor: 'pointer'
        }} onClick={() => { handleFeedbackPopupOpen({ row: { original: { ...item?.startup_feedbacks, ...item?.startup_feedbacks?.ratings } } }) }} >
          <Rating value={item?.startup_feedbacks?.average_rating} precision={0.5} readOnly />
        </Box>
      ,
      investible: item?.startup_feedbacks?.investible ? "Yes" : "No",
      formatted_date: item?.formatted_date,
      location: `${item?.city ? item?.city + ', ' : ''}${item?.state ? item?.state + ', ' : ''}${item?.country ? item?.country : ''}`
    })
  }
  ) : []
  // formattedFeedbacksData = []
  const [openFeedbackPopup, setOpenFeedbackPopup] = React.useState(false)
  const [openShowInterestPopup, setOpenShowInterestPopup] = React.useState(false)
  // const [openManageNotes, setOpenManageNotes] = React.useState(false)


  const handleFeedbackPopupOpen = ({ row }) => {
    let request = {
      startup_id: row?.original?.startup_id,
      investor_id: userDetails?._id
    }

    // console.log(request, "row")

    FetchStatusAsync(request).then((response) => {
      // console.log(response,"resssss")
      // setIsEnabledInterest(response?.data?.investorInfo == null)
      setIsEnabledFeedback(response?.data?.feedback == null)
      if (response?.data?.feedback !== null) {
        setOpenFeedbackPopup(false);
        setAlertMessage(`You have already submitted feedback for ${row?.original?.startup_name}. Please wait to hear back from the startup.`)
        setAlertHeading("Feedback")
        setShowAlert(true);
        // setFeedbackData(row?.original); 
      }
      else {
        setOpenFeedbackPopup(true);
        setAlertMessage(null)
        setAlertHeading(null)
        setShowAlert(false);
        // setFeedbackData(null); 

      }
    }).catch(err => {
      toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
        duration: 4000,
        position: "top-right",
        style: {
          padding: "15px 30px",
        },
      });
    });


    setCurrentRow(row?.original);
  }

  const handleFeedbackPopupClose = () => {
    !Boolean(selectedPDF != null && selectedPDF?.length > 0) && setCurrentRow(null)
    setOpenFeedbackPopup(false)
  }

  const handleFeedbackSubmit = ({ row, values }) => {
    // console.log(row, "row")
    let request = {
      startup_id: cookieStartupId,
      investor_id: userDetails?._id,
      rating: values?.average_rating,
      ratings: {
        problem: values?.problem,
        market: values?.market,
        product: values?.product,
        team: values?.team,
        traction: values?.traction
      },
      investible: values?.invest,
      feedback: values?.feedback
    }
    // console.log(request, "request")
    saveFeedbackAsync(request).then(() => {
      refetchListing()
    })
    // setQueryParams(query);
    handleFeedbackPopupClose();
  }

  const Actions = ({ row }) => {
    return (
      <B300 onClick={() => handleFeedbackPopupOpen({ row })} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 600, minWidth: isSmallScreen ? "0" : '64px', '& > .MuiButton-icon.MuiButton-startIcon': { marginRight: isSmallScreen ? 0 : '8px', marginLeft: isSmallScreen ? 0 : '-4px' } }} startIcon={<Add fontSize='1.25rem' />}>
        Submit Feedback
      </B300>
    )
  }

  const noDataSubTitle = (
    <>
      It seems you haven't shared any feedback about <b>{startupDetails?.data?.startup_name}</b> yet.<br />
      We value your insights! Please provide your feedback by clicking the button below.
    </>
  );

  const RowActions = ({ row, iconsColor = theme.palette.customColors.indigo700, index }) => {
    // return <>

    // </>
    // console.log(row, "row")

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (event, index) => {
      setAnchorEl(event.currentTarget);
      // setCurrentIndex(index)
      // console.log(event.currentTarget,"re")
    };
    const handleCloseMenu = (event) => {
      // console.log(event,"event")
      setAnchorEl(null);
      // setCurrentIndex(null);
    };

    // switch (value) {
    //     case "new": return <>
    return <>
      <Tooltip title={'View'}>
        <IconButton
          // onClick={(e) => handleClickMenu(e)}
          onClick={() => { handleFeedbackPopupOpen({ row }) }}

        >
          <PNGIconComponent row={row} />
        </IconButton>
      </Tooltip>

    </>
  }
  const CustomLink = ({ row, children }) => {
    return <div onClick={() => { handleFeedbackPopupOpen({ row }) }}>
      {children}
    </div>
  }

  return (
    <>
      <ModalComponent open={openFeedbackPopup} onClose={handleFeedbackPopupClose} style={{ maxWidth: 640, width: "100%" }}>
        <FeedbackPopup handleClose={handleFeedbackPopupClose} handleSubmit={handleFeedbackSubmit} currentRow={currentRow} filledFeedback={currentRow} />
      </ModalComponent>
      {/* <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden'}}> */}

      <Grid container xs={12}>
        {isLoadingFeedbacks ?
          <Grid item xs={12} height={"100vh"} sx={{ width: '100%', padding: "2rem", paddingLeft: "1rem !important", paddingTop: "0rem !important", bgColor: theme.palette.customColors.yellow_bg }} paddingInline={{ xs: "0rem !important", sm: '0rem !important', md: '0rem !important', lg: '0rem !important' }}>
            <Box className='skeleton' minHeight={"70vh"} sx={{ borderRadius: "8px", border: `1px solid ${theme.palette.customColors.b50}` }} marginTop={0}>

            </Box>
          </Grid>
          :
          <Box width={"100%"} height="auto" maxWidth={"100%"} overflow={"hidden"}>
            <AsyncTableListing
              // isLoading={isLoadingFeedbacks}
              columns={column}
              // data={[]}
              noDataTitle='No Feedback Provided Yet'
              noDataSubTitle={noDataSubTitle}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              count={feedbacksData?.count}
              noDataPlaceHolderImage={placeHolderImagefeedback}
              // tabValue={value}
              data={formattedFeedbacksData}
              // title={"Feedbacks"}
              // tableCardHeaderStyle={{padding: "0.5rem 1rem",}}
              // chipText={`${data?.length}${isSmallScreen ? "" : " Investors"}`}
              actions={Actions}
              CustomLink={CustomLink}
              RowActions={RowActions}
              sx={{ borderRadius: '8px', border: formattedFeedbacksData?.length > 0 ? `1px solid ${theme.palette.customColors.b50}` : "none", bgcolor: formattedFeedbacksData?.length > 0 ? "white" : "transparent" }}


            // refetchListing={refetchListing}
            />
          </Box>
        }

        {/* </Grid>} */}
        {/* <Grid item xs={2} lg={2}> */}

      </Grid>
      {/* </Card> */}
    </>
  )
}

export default SubmitFeedback
