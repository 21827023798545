

// action - state management
import * as actionTypes from './actions';

export const initialState = {
    template: null,
    userPermissions: null,
    isOwner: false,
    userName: null
}

const permissionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PERMISSION_TEMPLATE:
            return {
                ...state,
                template: action?.data
            }
        case actionTypes.SET_USER_ACCESS:
            return {
                ...state,
                userPermissions: action?.permissions,
                isOwner: action?.isOwner,
                userName: action?.userName
            }
        default:
            return state;
    }
}

export default permissionsReducer
