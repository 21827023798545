import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import investorDetailsReducer from './investorDetailsReducer';
import roleDetailsReducer from './roleDetailsReducer';
import permissionsReducer from './permissionsReducer';
import { RESET_STATE } from './actions';

// ==============================|| COMBINE REDUCER ||============================== //

const appReducer = combineReducers({
    customization: customizationReducer,
    investorDetails: investorDetailsReducer,
    roleDetails: roleDetailsReducer,
    permissions: permissionsReducer
});

const reducer = (state, action) => {
    if (action.type === RESET_STATE) {
        state = {
            customization: { opened: false },
            investorDetails: { data: null },
            roleDetails: {
                role: null
            },
            permissions: { template: null, userPermissions: null },
        }; // Set state to undefined to reset all reducers
    }
    return appReducer(state, action);
};

export default reducer;
