import { Box, ListItem, ListItemIcon, Menu, MenuItem, Popover } from '@mui/material'
import React from 'react'
import { NavIconButton } from './NavIconButton'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import theme from 'themes/theme'
import { useToast } from 'context/ToastContext'

const NavGroup = ({ item, isActive, open, drawerToggle }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const { addToast } = useToast();
    const [anchorEl, setAnchorEl] = React.useState(null)
    const isMenuOpen = Boolean(anchorEl)

    // Check if current path matches any submenu path
    const isActiveRoute = React.useMemo(() => {
        if (!item?.subMenuItems?.length) return isActive

        return item.subMenuItems.some(subItem =>
            location.pathname.includes(subItem.path)
        )
    }, [location.pathname, item.subMenuItems, isActive])

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const SubMenu = () => {
        return item?.subMenuItems && item?.subMenuItems?.length > 0 && (
            <Popover
                open={isMenuOpen}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    "& .MuiPaper-root": {
                        minWidth: 200,
                        marginLeft: '0.1rem',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        padding: "12px",
                        borderRadius: "8px",
                        bgcolor: theme.palette.customColors.g400,
                        color: theme.palette.customColors.whiteBG,
                    }
                }}
            >
                {item?.subMenuItems?.map((element, index) => {
                    const useStrokeStyles = element?.useStroke ? { stroke: location.pathname.includes(element.path) ? theme.palette.customColors.whiteBG : theme.palette.customColors.g100 } : {};
                    const useFillStyles = element?.useFill ? { fill: location.pathname.includes(element.path) ? theme.palette.customColors.whiteBG : theme.palette.customColors.g100 } : {};

                    return (
                        <MenuItem
                            key={index}
                            sx={{
                                textAlign: 'center',
                                justifyContent: 'flex-start',
                                gap: "0.5rem",
                                opacity: "1 !important",
                                padding: "0.5rem",
                                // padding: '0.625rem 1rem',
                                marginBlock: "0.1rem",
                                // borderRadius: 1,
                                borderBottom: `${index === item?.subMenuItems?.length - 1 ? 0 : 1}px solid ${theme.palette.customColors.g700}`,
                                color: location.pathname.includes(element.path) ? theme.palette.customColors.whiteBG : theme.palette.customColors.g100,
                                // "&:hover": {
                                //     color: theme.palette.customColors.g200,
                                // },
                                // backgroundColor: location.pathname.includes(element.path) ? 'action.selected' : 'inherit'
                            }}
                            onClick={() => {
                                // addToast("Investor Added!", "info", 200000)
                                navigate(element.path)
                                handleMenuClose()
                            }}
                        >
                            <element.icon style={{ width: '24px', height: '24px', ...useStrokeStyles, ...useFillStyles }} />{element.title}
                        </MenuItem>
                    )
                })}
            </Popover>
        )
    }

    return (
        !!item?.permission && (
            <>
                <SubMenu />
                <ListItem
                    sx={{
                        display: 'flex',
                        justifyContent: "flex-start",
                        width: "100%"
                    }}
                    onClick={handleMenuOpen}
                >
                    <NavIconButton
                        item={item}
                        isActive={isActiveRoute || isMenuOpen}
                        open={open}
                    />
                </ListItem>
            </>
        )
    )
}

export default NavGroup