import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Box,
  Autocomplete,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  styled,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  B300,
  B400,
  BLUEBORDER,
  WHITEBG,
} from "../../../ui-components/CustomButton";
import { ReactComponent as gpsIcon } from "../../../assets/gps.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTheme } from "@emotion/react";
import CustomInputField from "../../../ui-components/formik/CustomInputField";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import { AddInvestmentForRound } from "../../../api";
import * as Yup from "yup";
import CustomLabel from "../../../ui-components/CustomLabel";
import CustomValidationText from "../../../ui-components/CustomValidationText";
import { ReactComponent as UploadDocumentsIcon } from "../../../assets/rounds_icons/upload_documents_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/rounds_icons/delete_icon.svg";
import { Add } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "../../../assets/close_icon.svg";
import { containerRef } from "../../../utils";

const currencyInputProps = {
  inputMode: "numeric", // Use numeric keyboard on mobile devices
  pattern: "[0-9]*", // Only allow digits 0-9
  // title: "Please enter a number between 0 and 9",
  style: {
    // textAlign: 'center', // Center the text in the input
  },
  min: 0,
  onKeyDown: (event) => {
    // Prevent the minus sign (-) from being entered
    if (event.key === "-") {
      event.preventDefault();
    }
  },
  onKeyPress: (event) => {
    // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
    if (event.key === "-") {
      event.preventDefault();
    }
  },
  onKeyPress: (event) => {
    // Get the input value
    const inputValue = event.key;

    // Allow only numeric characters (0-9) and certain control keys
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
    ];

    // Check if the pressed key is in the allowedKeys array
    if (!allowedKeys.includes(inputValue)) {
      // If not, prevent the key from being entered
      event.preventDefault();
    }
  },
  onInput: (event) => {
    // Get the input value
    const inputValue = event.target.value;

    // Remove non-numeric characters using a regular expression
    const numericValue = inputValue.replace(/[^0-9]/g, "");

    // Update the input field value with the filtered numeric value
    event.target.value = numericValue;
  },
  onKeyDown: (event) => {
    // Prevent the input of minus sign (key code 189)
    if (event.keyCode === 189 || event.key === "-") {
      event.preventDefault();
    }
  },
  disableUnderline: true,
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const CustomRadio = styled(Radio)(({ theme }) => ({
  color: "#e0e0e0",
  "&.Mui-checked": {
    color: "#7c4dff",
  },
  "& .MuiSvgIcon-root": {
    borderRadius: "50%",
  },
}));

const UpdateStatusPopup = ({ open, handleClose, handleSubmit, currency }) => {
  const { id: round_id } = useParams();
  const startup_id = useSelector((state) => state.startupDetails.data)?._id;
  // console.log(startup_id,"const {id: round_id} = useParams();")

  const {
    mutateAsync: addInvestmentForRoundAsync,
    isLoading: isAddingInvestmentForRound,
    status: addInvestmentForRoundStatus,
    error: addInvestmentForRoundError,
    data: addInvestmentForRoundResponse,
  } = AddInvestmentForRound();
  const [validationObj, setValidationObj] = React.useState({
    email: Yup.string().required("Email is required"),
  });
  const bankValidationSchema = Yup.object({ ...validationObj });
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      interested_amount: 0,
      allocated_amount: 0,
      sha_documents: [
        {
          document_name: "",
          document: null,
        },
      ],
      call_for_money: false,
      price_per_share: 0,
      no_of_shares: 0,
      status: "invited",
      // startup_id: '',
      // startup_round_id: ''
    },
    validationSchema: bankValidationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => {
      // const formData = new FormData();
      // formData.append("call_for_money[0]", values?.call_for_money);
      // formData.append("status[0]", values?.status);
      // // formData.append("startup_id",startup_id)
      // formData.append("startup_round_id", round_id);
      // addInvestmentForRoundAsync({ payload: formData })?.then(() => {
        handleClose();
      // });

      // console.log({...values, startup_id: startup_id, startup_round_id: round_id})
    },
  });

  const theme = useTheme();

  const handleChange = (e) => {
    const { name, value } = e.target;
    formik.setValues({ ...formik.values, [name]: value });
  };

  const placeholders = {
    // call_for_money: false,
    status: "Select status",

  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <IconButton
          sx={{ position: "absolute", top: 15, right: 20, padding: 0 }}
        >
          <CloseIcon width={40} height={40} onClick={handleClose} />
        </IconButton>
        <DialogTitle sx={{ marginInline: "auto" }}>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: 700,
              lineHeight: "28px",
              color: theme.palette.customColors.g300,
              paddingLeft: "0.5rem",
            }}
          >
            Update Status
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: 0, paddingInline: 0 }}>
          <PerfectScrollbar
          containerRef={containerRef}
            style={{
              maxHeight: "calc(-250px + 100vh)",
              paddingInline: "2rem",
            }}
          >
            <Grid item container xs={12} columnSpacing={2}>
              <Grid item xs={12}>
                {/* <CustomInputField
                                // margin="dense"
                                label="NO. OF SHARES"
                                name="no_of_shares"
                                value={formik.values?.no_of_shares}
                                onChange={handleChange}
                                fullWidth
                                placeholder={placeholders?.no_of_shares}
                                sx={{ marginBottom: '0.5rem' }}
                            /> */}
                <CustomLabel>STATUS</CustomLabel>
                <Select
                  name="status"
                  value={formik.values?.status}
                  onChange={handleChange}
                  placeholder={placeholders?.status}
                  sx={{ marginBottom: "0.5rem" }}
                  fullWidth
                >
                  {[
                                    // { value: "", label: 'All Investors', count: 100, read: false },
                                    { value: "invited", label: 'Invited', count: 20, read: true},
                                    { value: "interested", label: 'Interested', count: 50, read: true},
                                    { value: "in_progress", label: 'In Progress', count: 10, read: false},
                                    { value: "invested", label: 'Invested', count: 10, read: false},
                                ]?.map((option) => (
                    <MenuItem value={option?.value}>{option?.label}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </PerfectScrollbar>
        </DialogContent>
        <DialogActions
          sx={{ paddingInline: "1.5rem", paddingBottom: "1.5rem" }}
        >
          {/* <Button onClick={handleClose}>Cancel</Button> */}
          <B300
            type="submit"
            fullWidth
            sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem" }}
          >
            {isAddingInvestmentForRound ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.5rem'} /> : "Update"}
          </B300>
          {/* <B300 sx={{ background: theme.palette.customColors.indigo700, padding: "0.625rem 1rem", width: '100%' }} onClick={handleSave}>Save</B300> */}
        </DialogActions>
        {/* </Dialog> */}
      </Form>
    </FormikProvider>
  );
};

export default UpdateStatusPopup;
