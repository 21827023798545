import React from 'react'
import backrrLogo from '../assets/Backrr_logo_symbol_white_new.webp'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@emotion/react'

const BackrrLogoImage = ({alignCenter, style = {}, onClick}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  return (
    <img style={{ height: "4.5rem", maxWidth: "4.5rem", aspectRatio: 1, marginInline:  isSmallScreen || isMediumScreen || alignCenter ? "auto" : 0, cursor: onClick ? 'pointer' : 'initial' , ...style}} src={backrrLogo} onClick={onClick ? onClick : () => {}} />
  )
}

export default BackrrLogoImage