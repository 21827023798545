import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import { Box, Typography, Avatar, Modal, Grid, InputLabel, Divider } from '@mui/material';
import CustomLabel from '../CustomLabel';
import { useField, useFormikContext } from 'formik';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { B300, BLUEBORDER, WHITEBG } from '../CustomButton';
import theme from '../../themes/theme';
import { ReactComponent as UploadDocumentsIcon } from "../../assets/rounds_icons/upload_documents_icon.svg";
import { Close } from '@mui/icons-material';
import CustomValidationText from '../CustomValidationText';

const blobToFile = (blob, fileName) => {
    return new File([blob], fileName, { type: blob.type });
};

const CustomUploadButton = ({ label, handleUpdateFile, initials = '', name, value, Pwidth = 150, Pheight = 150, Pmultiplier = 0.95, placeholder }) => {
    const [selectedFile, setSelectedFile] = useState(value || null);
    const [imagePreview, setImagePreview] = useState(value || null);
    const [previousImage, setPreviousImage] = useState(value || null);
    const [isRemoveButtonPressed, setIsRemoveButtonPressed] = useState(true)
    const [isUploadButtonPressed, setIsUploadButtonPressed] = useState(true)
    const [field, meta] = useField(name);
    const { setFieldValue, submitCount } = useFormikContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const cropperRef = useRef(null);

    const handleFileChange = (file) => {
        setPreviousImage(imagePreview);
        if (file?.type?.includes("image")) {
            setSelectedFile(file);
            const imageUrl = URL.createObjectURL(file);
            setImagePreview(imageUrl);
            setIsModalOpen(true);
        }
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            handleFileChange(file);
            event.target.value = null; // Reset the input value to allow the same file to be selected again
        }
    };

    const handleCropButton = async (event) => {
        event.preventDefault();
        try {
            const cropper = cropperRef.current.cropper;
            const croppedCanvas = cropper.getCroppedCanvas();
            if (croppedCanvas) {
                croppedCanvas.toBlob((blob) => {
                    const fileName = selectedFile ? selectedFile.name : 'cropped-image.png';
                    const croppedFile = blobToFile(blob, fileName);
                    const croppedImageUrl = URL.createObjectURL(croppedFile);
                    // setFieldValue(name, croppedFile).then(() => {
                    //     event.preventDefault();
                    // });
                    handleUpdateFile(croppedFile);
                    setIsModalOpen(false);
                    setImagePreview(croppedImageUrl);
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleCancelButton = () => {
        setIsModalOpen(false);
        setSelectedFile(null);
        setImagePreview(previousImage);
    };

    const handlePreviewClick = () => {
        if (imagePreview) {
            setTimeout(() => {
                setIsModalOpen(true);
            }, 1000);
        }
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '8px',
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '90vw',   // Set a responsive width
        maxWidth: '500px',  // Set a maximum width
        height: '100%',
        maxHeight: 600,  // Set a maximum height to prevent overflow
        overflow: 'hidden',  // Prevent scrolling and overflow
    };

    const cropperContainerStyle = {
        width: '100%',
        height: '100%',
    };

    const buttonContainerStyle = {
        display: 'flex',
        gap: '16px',  // Space between buttons
        marginTop: '16px',
        justifyContent: 'center', // Center align the buttons
        width: '100%'
    };

    React.useEffect(() => {
        if (value && (value instanceof Blob || value instanceof File)) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImagePreview(e.target.result);
            };
            reader.readAsDataURL(value);
        } else if (typeof value === 'string') {
            setImagePreview(value);
        } else {
            setImagePreview(null);
        }
    }, [value]);


    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const file = event.dataTransfer.files[0];
        if (file) {
            handleFileChange(file);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <Box
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            <CustomLabel>{label}</CustomLabel>
            <input
                accept=".png, .jpg, .jpeg"
                style={{ display: 'none' }}
                id={name ? name : "upload-button-file"}
                type="file"
                onChange={handleFileInputChange}
            />

            <Grid
                variant="outlined"
                component="span"
                fullWidth
                sx={{
                    borderRadius: '8px',
                    display: 'grid',
                    placeItems: 'center',
                    padding: '1rem',
                    color: '#667085',
                    backgroundColor: '#FFF',
                    textTransform: 'none',
                    border: '1px solid rgb(102, 112, 133,0.5)',
                    ":hover": {
                        backgroundColor: "rgba(255,255,255,1)",
                        border: '1px solid rgb(102, 112, 133,0.5)',
                    }
                }}
            >
            {/* {console.log(typeof imagePreview,"imagePreview")} */}
                <> <label htmlFor={name ? name : "upload-button-file"} style={{ display: 'grid', placeItems: 'center', width: '100%' }}>{
                    imagePreview ? (
                        <>
                            <Box
                                component="img"
                                src={typeof imagePreview !== "object" && !imagePreview?.includes("data:image") && !imagePreview?.includes("blob:") ? imagePreview + `?cacheBuster=${new Date().getTime()}` : imagePreview}
                                alt="Uploaded Preview"
                                loading='eager'
                                rel='preload'
                                sx={{
                                    borderRadius: '10px',
                                    objectFit: 'cover',
                                    border: `1px solid rgb(102, 112, 133,0.5)`,
                                    background: theme.palette.customColors.g50,
                                    width: Pwidth,
                                    height: Pheight,
                                    marginInline: 'auto'
                                }}
                                onClick={handlePreviewClick} // Trigger modal on preview click
                            />
                            <Grid item container xs={12} display={"flex"} alignItems="center" flexWrap={"nowrap"} justifyContent={"center"} gap={1} mt={"1.5rem"} >

                                <Grid xs={5.5} item sx={{ display: 'flex', justifyContent: 'center', maxWidth: 'max-content' }}>
                                    <InputLabel onMouseDown={() => setIsUploadButtonPressed(false)} onMouseUp={() => setIsUploadButtonPressed(true)} htmlFor={name ? name : "upload-button-file"} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', opacity: isUploadButtonPressed ? 1 : 0.2, cursor: 'pointer', gap: 5, width: '100%' }}>
                                        <UploadDocumentsIcon
                                            style={{
                                                width: 16,
                                                height: 16,
                                                stroke: 'rgb(105, 65, 198)'
                                            }}
                                        />
                                        <span className='highlight' style={{ color: "#6941C6", textAlign: 'center' }}>Update</span>
                                    </InputLabel>
                                </Grid>
                                <Divider orientation="vertical" flexItem />
                                {<Grid xs={5.5} item sx={{ maxWidth: 'max-content' }}>
                                    <InputLabel onClick={() => handleUpdateFile(null)} onMouseDown={() => setIsRemoveButtonPressed(false)} onMouseUp={() => setIsRemoveButtonPressed(true)} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: isRemoveButtonPressed ? 1 : 0.2, cursor: 'pointer', gap: 0.5, maxWidth: 'max-content', width: '100%' }}>
                                        <Close sx={{ width: 16, height: 16, color: 'rgb(239, 83, 80)', margin: 0 }} />
                                        <span className='' style={{ color: "rgb(239, 83, 80)", textAlign: 'center' }}>Remove</span>
                                    </InputLabel>
                                </Grid>}
                            </Grid>
                        </>
                    ) : (
                        <>
                            {/* {console.log(placeHolderImage,"placeHolderImage")} */}
                            {placeholder ? <Box
                                sx={{
                                    borderRadius: '10px',
                                    border: `1px solid rgb(102, 112, 133,0.5)`,
                                    // bgcolor: theme.palette.customColors.g75,
                                    width: Pwidth,
                                    height: Pheight,
                                    display: 'grid',
                                    placeContent: 'center'
                                }}
                            >
                                <Box
                                    component="img"
                                    src={placeholder}
                                    alt="Uploaded Preview"
                                    loading='eager'
                                    rel='preload'
                                    sx={{
                                        objectFit: 'contain',
                                        width: Pwidth * Pmultiplier,
                                        height: Pheight * Pmultiplier,
                                    }}
                                    onClick={handlePreviewClick} // Trigger modal on preview click
                                />
                            </Box> :
                                <Avatar sx={{ width: Pwidth, height: Pheight, borderRadius: '10px' }} />}
                            <div style={{ marginTop: "0.5rem", marginBottom: "0.25rem" }}>
                                <span className='highlight' style={{ color: "#6941C6", fontSize: '0.875rem' }}>Click to upload </span>
                                <span style={{ fontSize: '0.875rem' }}>or drag and drop</span>
                            </div>
                            {/* <small>SVG, PNG, JPG or GIF (max 512 × 512px)</small> */}
                            <small style={{ fontSize: '12px' }}>PNG, JPG or JPEG</small>
                        </>
                    )}
                </label>
                </>
            </Grid>
            {
                meta && meta.error && (
                    <CustomValidationText value={meta.error} />
                )
            }

            <Modal
                open={isModalOpen}
                onClose={() => { }}
                aria-labelledby="modal-crop-title"
                aria-describedby="modal-crop-description"
            >
                <Box sx={modalStyle}>
                    <Typography
                        id="modal-crop-title"
                        variant="h6"
                        component="h2"
                        sx={{ textAlign: 'center', mb: 2 }}
                    >
                        Crop Logo
                    </Typography>
                    <Box sx={cropperContainerStyle}>
                        <Cropper
                            src={imagePreview}
                            style={{ width: '100%', height: '100%' }}
                            aspectRatio={1}
                            guides={true}
                            width={500}
                            height={200}
                            ref={cropperRef}
                            viewMode={2}
                        />
                    </Box>
                    <Box sx={buttonContainerStyle}>
                        <BLUEBORDER type='button' onClick={(e) => { handleCancelButton(e); }} sx={{ borderWidth: 1, padding: 2, maxWidth: '8rem' }} fullWidth>
                            Back
                        </BLUEBORDER>
                        <B300 type='button' onClick={(e) => { handleCropButton(e); }} sx={{ padding: 2, maxWidth: '8rem' }} fullWidth>
                            Crop
                        </B300>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default CustomUploadButton;
