import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as NameTypeIcon } from "../../assets/rounds_icons/name_type_round_icon.svg";
import { ReactComponent as FundUtilizationIcon } from "../../assets/rounds_icons/fund_util_round_icon.svg";
import { ReactComponent as CurrentTractionIcon } from "../../assets/rounds_icons/current_traction_round_icon.svg";
import { ReactComponent as CurrentInvestorsIcon } from "assets/rounds_icons/current_investors_round_icon.svg";
import { ReactComponent as DocumentsIcon } from "../../assets/rounds_icons/documents_round_icon.svg";
import AddIcon from "../../assets/add_button.png"
import { ReactComponent as SavingIcon } from "../../assets/saving_icon.svg";
import marketplaceIcon from '../../assets/marketplace.png'
import mobileShoppingIcon from '../../assets/mobile-shopping.png'
import digitalServiceIcon from '../../assets/digital-services.png'
import laptopScreenIcon from '../../assets/laptop-screen.png'
import whatsapp_icon from "../../assets/whatsapp_main_icon.png"
import { ReactComponent as ArrowDown } from "../../assets/table-chevron-down.svg"
import {
    Box,
    CircularProgress,
    Divider,
    Grid,
    Typography,
    useMediaQuery,
    IconButton,
    Tooltip,
    Select,
    MenuItem,
    Switch,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControlLabel,
    Slider
} from "@mui/material";
import {
    BLUEBORDER,
    SECONDARY,
    B300 as CustomButton,
} from "../../ui-components/CustomButton";
import { useTheme } from "@emotion/react";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import CustomInputField from "../../ui-components/formik/CustomInputField";
import CustomLabel from "../../ui-components/CustomLabel";
import { useNavigate, useSearchParams } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
    UploadRoundDocuments,
    FetchAllStages,
    FetchAllSecurityTypes,
    FetchAllRoundStatus,
    FetchProfileDetails,
    SavePersonalInfo,
    SaveProfileImage,
    SaveStartupInfo,
    SendWhatsappOTP,
    FetchAllInvestmentSize,
    VerifyWhatsAppOTP,
    FetchAllCategories,
    FetchAllCities,
    FetchAllCountries,
    FetchAllCountriesWithPhoneCodes,
    FetchAllStates,
    FetchAllSubCategories,
    FetchAllBusinessModel,
    AddPartners
} from "../../api";

import * as Yup from "yup";
import ThemeBGWrapper from "../../ui-components/ThemeBGWrapper";
import toast from "react-hot-toast";
import moment from "moment";
import CustomToast from "../../ui-components/CustomToast";
import { containerRef, currencyInputProps, getMarks, handleFormSubmit, isRequired, linkedInTestURL, notEmpty, websiteTestURL } from "../../utils";
import { ReactComponent as InfoIcon } from '../../assets/help-circle.svg';
import CustomValidationText from "../../ui-components/CustomValidationText";
import BreadcrumbHeader from "../../layout/BreadcrumbsHeader";
import CustomAutoComplete from "../../ui-components/CustomAutoComplete";
import { ReactComponent as gpsIcon } from "../../assets/gps.svg"
// import placeHolderImage from "../../assets/placeholder_image.png"
import placeHolderImage from "assets/no_data_images/placeholder_startup.png"
import CustomLogoButton from '../../ui-components/formik/CustomLogoButton'
import CustomUrlField from "../../ui-components/CustomUrlField";
import VerticalScrollableTabs from "../../ui-components/VerticalScrollableTabs";
import { useConfig } from "../../dynamic-configuration/configContext";
import CustomInputFieldOTP from '../../ui-components/CustomInputField'
import eye_icon from "../../assets/eye.png"
import InvestorTypes from "views/onboarding/InvestorTypes";
import OrganizationDetails from "views/onboarding/components/OrganizationDetails";
import PartnersDetail from "views/onboarding/components/PartnersDetail";
import UploadOrgPhoto from "views/onboarding/components/UploadOrgPhoto";
import NeedHelpButton from "ui-components/NeedHelpButton";
import InvestmentRange from "views/onboarding/components/InvestmentRange";
import Cookies from "js-cookie";
import { UpdateInvestorImage, UpdateInvestorPartners, UpdateInvestorPersonalInfo } from "api/backrr_investor";
import CustomPhone from "ui-components/CustomPhone";
import { Close } from "@mui/icons-material";

const title = [
    {
        value: "Mr.",
        label: "Mr.",
    },
    {
        value: "Mrs.",
        label: "Mrs.",
    },
    {
        value: "Ms.",
        label: "Ms.",
    },
    {
        value: "Dr.",
        label: "Dr.",
    },
    {
        value: "Prof.",
        label: "Prof.",
    },
    {
        value: "CA",
        label: "CA",
    },
];

const inputProps = {
    maxLength: 1,
    inputMode: 'numeric',
    pattern: "[0-9]*",
    style: {
        textAlign: 'center',
    },
    onKeyDown: (event) => {
        if (event.key === '-' || event.keyCode === 189) {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
        if (!allowedKeys.includes(event.key)) {
            event.preventDefault();
        }
    },
    onInput: (event) => {
        const inputValue = event.target.value.replace(/[^0-9]/g, '');
        event.target.value = inputValue;
    }
};


const UpdateProfile = () => {
    const theme = useTheme();
    const { config } = useConfig()
    const [shiftSteps, setShiftSteps] = React.useState(false);
    const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));
    const matchUpXl = useMediaQuery(theme.breakpoints.up('xl'));
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const navigate = useNavigate();
    let [investor_id, setInvestorID] = React.useState(Cookies.get("X-INV-ID") || null);

    let stepIndex = new URLSearchParams(window.location.search).get("page");
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const [searchParams, setSearchParams] = useSearchParams()
    const [currentIndex, setCurrentIndex] = useState(Number(stepIndex) || 0);
    const [amountRanges, setAmountRanges] = useState([])
    // const [currency, setCurrency] = useState("INR");
    // const [currencyOptions, setCurrencyOptions] = useState([]);
    const [selectedRange, setSelectedRange] = useState(null); // Track selected range
    const [allAmountRanges, setAllAmountRanges] = useState([])
    const [selectedStages, setSelectedStages] = useState([])
    const [isWhatsAppEnabled, setIsWhatsAppEnabled] = useState(false);
    const [isPublicEnabled, setIsPublicEnabled] = useState(false);
    const [isVerify, setIsVerify] = useState(false);
    const [otpValues, setOtpValues] = useState(Array(4).fill(''));
    const [error, setError] = useState(null)
    const [countDown, setCountDown] = useState(30);
    const [search, setSearch] = useState("")
    const [searchState, setSearchState] = useState("")
    const [searchCountry, setSearchCountry] = useState("")
    const [currentSubCategory, setCurrentSubCategory] = useState([]);
    const [searchSubCategory, setSearchSubCategory] = useState('');
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [currentBusinessModel, setCurrentBusinessModel] = React.useState([]);
    const [searchBusinessModel, setSearchBusinessModel] = React.useState([]);


    const number_investment = [
        {
            value: "01",
            label: "01",
        },
        {
            value: "02",
            label: "02",
        },
        {
            value: "03",
            label: "03",
        },
        {
            value: "04",
            label: "04",
        },
        {
            value: "05",
            label: "05",
        },
        {
            value: "06",
            label: "06",
        },
        {
            value: "07",
            label: "07",
        },
        {
            value: "08",
            label: "08",
        },
        {
            value: "09",
            label: "09",
        },
        {
            value: "10",
            label: "10",
        },
        {
            value: "11",
            label: "Invested in more than 10 startups",
        },
        {
            value: "12",
            label: config?.dynamicText?.newToInvestment,
        },

    ];

    const { data: profileDetailsData, isRefetching: isProfileDetailsRefetching, isLoading: isProfileDetailsLoading, refetch: refetchProfileDetails } = FetchProfileDetails({ investor_id })
    const { data: investmentSizeList, isRefetching: isRefetchingInvestmentSizeList, isLoading: isLoadingInvestmentSizeList, refetch: refetchInvestmentSizeList } = FetchAllInvestmentSize()
    const { mutateAsync: verifyWhatsAppOTPAsync, isLoading: isOTPLoading, data: verifyWhatsAppOTPResponse } = VerifyWhatsAppOTP();
    const { data: startup_stages, isRefetching: isRefetchingRoundTypes, isLoading: isLoadingRoundTypes, refetch: refetchRoundTypes } = FetchAllStages();
    const { data: countries, isRefetching: isRefetchingCountries, isLoading: isLoadingCountries, refetch: refetchCountries } = FetchAllCountries({ search: searchCountry })
    const { data: startup_categories, isRefetching: isRefetchingCategories, isLoading: isLoadingCategories, refetch: refetchCategories } = FetchAllCategories()
    const { data: startup_sub_categories, isRefetching: isRefetchingSubCategories, isLoading: isLoadingSubCategories, refetch: refetchSubCategories } = FetchAllSubCategories({ category: selectedCategory })
    const { data: startup_business_model, isRefetching: isRefetchingBusinessModel, isLoading: isLoadingBusinessModel, refetch: refetchBusinessModel } = FetchAllBusinessModel({})
    const [isSectorAgnostic, setIsSectorAgnostic] = useState(false);
    const [isBusinessModelSelectAll, setIsBusinessModelSelectAll] = useState(false);
    const {
        mutateAsync: SavePersonalInfoAsync,
        status: savePersonalInfoStatus,
        error: savePersonalInfoError,
        isLoading: isUpdatingsavePersonalInfo,
        data: savePersonalInfoResponse,
    } = UpdateInvestorPersonalInfo();
    const {
        mutateAsync: SaveProfileImageAsync,
        status: saveProfileImageStatus,
        error: saveProfileImageError,
        isLoading: isUpdatingsaveProfileImage,
        data: saveProfileImageResponse,
    } = UpdateInvestorImage();
    const {
        mutateAsync: SaveStartupInfoAsync,
        status: saveStartupInfoStatus,
        error: saveStartupInfoError,
        isLoading: isUpdatingsaveStartupInfo,
        data: saveStartupInfoResponse,
    } = SaveStartupInfo();
    const {
        mutateAsync: SendWhatsappOTPAsync,
        status: sendWhatsappOTPStatus,
        error: sendWhatsappOTPError,
        isLoading: isUpdatingSendWhatsappOTP,
        data: sendWhatsappOTPResponse,
    } = SendWhatsappOTP();

    const {
        mutateAsync: AddPartnersAsync,
        status: AddPartnersStatus,
        error: AddPartnersError,
        isLoading: isUpdatingAddPartners,
        data: AddPartnersResponse,
    } = UpdateInvestorPartners();

    const nameValidations = {
        title: isRequired(config?.validationFields, "title") ? Yup.string(config?.validationFields?.title?.error).required(config?.validationFields?.title?.error).trim() : Yup.string(),
        first_name: isRequired(config?.validationFields, "first_name") ? Yup.string(config?.validationFields?.first_name?.error).required(config?.validationFields?.first_name?.error).trim() : Yup.string(),
        last_name: isRequired(config?.validationFields, "last_name") ? Yup.string(config?.validationFields?.last_name?.error).required(config?.validationFields?.last_name?.error).trim() : Yup.string(),
        dd:
            // isRequired(config?.validationFields, "dob") ?
            Yup.string()
                // .required(config?.validationFields?.dob?.dateError)
                .matches(/^(0[1-9]|[12][0-9]|3[01])$/, config?.validationFields?.dob?.dateError)
        // : Yup.string()
        ,
        mm:
            // isRequired(config?.validationFields, "dob") ?
            Yup.string()
                // .required(config?.validationFields?.dob?.monthError)
                .matches(/^(0[1-9]|1[0-2])$/, config?.validationFields?.dob?.monthError)
        // : Yup.string()
        ,
        yyyy:
            // isRequired(config?.validationFields, "dob") ?
            Yup.number()
                // .required(config?.validationFields?.dob?.yearError)
                .min(1900, config?.validationFields?.dob?.yearError)
                .max(new Date().getFullYear(), config?.validationFields?.dob?.futureError)
        // : Yup.number()
        ,
    }

    const organizationDetailsValidations = {
        organization_name: Yup.string("Organization name is required").required("Organization name is required").trim(),
        phone_number: Yup.string("Please enter phone number").required("Please enter phone number").trim(),
        organization_website: Yup.string("Organization website is required").required("Organization website is required").trim().matches(websiteTestURL, "Please enter a valid website URL")
    }

    // console.log(currentStep, "Stepo")


    const profileImageValidations = {
        custom_image_url: Yup.mixed()
            // .nullable()
            .test('file-or-url', config?.validationFields?.profile_pic?.error, function (value) {
                const { path, createError } = this; // `this` gives access to the path and createError function
                // If the value is a file, validate its type and size
                if (value && value instanceof File) {
                    const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
                    // <<<<<<< HEAD
                    // return validFileTypes.includes(value.type) && value.size <= 10000000; // File type & size check
                    // =======

                    // Check file type
                    if (!validFileTypes.includes(value.type)) {
                        return createError({
                            path,
                            message: 'Invalid file type. Only JPG, PNG, or JPEG are allowed.',
                        });
                    }

                    // Check file size (10MB max)
                    if (value.size > 10000000) {  // 10MB
                        return createError({
                            path,
                            message: 'File size exceeds 10MB.',
                        });
                    }

                    return true; // If file type and size are valid
                    // >>>>>>> 8861aead221b47f2e25ede6b4fb2890c777d5947
                }

                // If the value is a string (URL), validate it as a URL
                // if (typeof value === 'string') {
                //   if (!Yup.string().url().isValidSync(value)) {
                //     return createError({
                //       path,
                //       message: 'Invalid URL format.',
                //     });
                //   }
                //   return true; // If URL is valid
                // }

                return true; // For other cases, if there's no error, it's valid
            }).nullable()
        ,
        // avatar_selected: Yup.string().nullable()
    }

    const organizationImageValidations = {
        website_logo: Yup.mixed()
            // .nullable()
            .test('file-or-url', config?.validationFields?.profile_pic?.error, function (value) {
                const { path, createError } = this; // `this` gives access to the path and createError function
                // If the value is a file, validate its type and size
                if (value && value instanceof File) {
                    const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];

                    // Check file type
                    if (!validFileTypes.includes(value.type)) {
                        return createError({
                            path,
                            message: 'Invalid file type. Only JPG, PNG, or JPEG are allowed.',
                        });
                    }

                    // Check file size (10MB max)
                    if (value.size > 10000000) {  // 10MB
                        return createError({
                            path,
                            message: 'File size exceeds 10MB.',
                        });
                    }

                    return true; // If file type and size are valid
                }

                // If the value is a string (URL), validate it as a URL
                // if (typeof value === 'string') {
                //   if (!Yup.string().url().isValidSync(value)) {
                //     return createError({
                //       path,
                //       message: 'Invalid URL format.',
                //     });
                //   }
                //   return true; // If URL is valid
                // }

                return true; // For other cases, if there's no error, it's valid
            }).nullable()
        ,
        // avatar_selected: Yup.string().nullable()
    }

    const countryValidations = {
        country_id: isRequired(config?.validationFields, "country") ? Yup.string(config?.validationFields?.country?.error).matches(/^[a-fA-F0-9]{24}$/, config?.validationFields?.country?.error).required(config?.validationFields?.country?.error).trim() : Yup.string(),
    }

    const stateValidations = {
        state_id: isRequired(config?.validationFields, "state") ? Yup.string(config?.validationFields?.state?.error).matches(/^[a-fA-F0-9]{24}$/, config?.validationFields?.state?.error).required(config?.validationFields?.state?.error).trim() : Yup.string(),
        city_id: isRequired(config?.validationFields, "city") ? Yup.string(config?.validationFields?.city?.error).matches(/^[a-fA-F0-9]{24}$/, config?.validationFields?.city?.error).required(config?.validationFields?.city?.error).trim() : Yup.string(),
    }

    const sectorValidations = {
        // category_id: Yup.string("Please select your preferred category").required("Please select your preferred category").trim(),
        category_id: Yup.array()
            .of(Yup.string().trim())
            .min(1, "Please select at least one category")
            .required("Please select your preferred category"),
        sector_id: isSectorAgnostic ? Yup.array().nullable() : Yup.array().min(1, "Please select your sub sector").required("Please select your sub sector"),
        business_model_id: isBusinessModelSelectAll ? Yup.array().nullable() : Yup.array().required("Business Model is required.").min(1, "Business Model is required."),

    }

    const sizeValidations = {
        min: isRequired(config?.validationFields, "size") ? Yup.string(config?.validationFields?.size?.error).required(config?.validationFields?.size?.error) : Yup.string(),
        max: isRequired(config?.validationFields, "size") ? Yup.string(config?.validationFields?.size?.error).required(config?.validationFields?.size?.error) : Yup.string(),
    }

    const stageValidations = {
        stage_id: isRequired(config?.validationFields, "stages") ? Yup.array().min(1, config?.validationFields?.stages?.error).required(config?.validationFields?.stages?.error) : Yup.array(),
    }

    const investmentCountValidations = {
        // investment_count: isRequired(config?.validationFields, "investment_count") ? Yup.string(config?.validationFields?.investment_count?.error).required(config?.validationFields?.investment_count?.error).trim() : Yup.string(),
    }

    const investmentValidations = {
        // startups: isRequired(config?.validationFields, "investments") ? Yup.array(
        //   Yup.object({
        //     startup_name: Yup.string(config?.validationFields?.startup_name?.error).required(config?.validationFields?.startup_name?.error).trim(),
        //     startup_url: Yup.string(config?.validationFields?.startup_website_url?.error).required(config?.validationFields?.startup_website_url?.error).trim()
        //   })
        // ) : Yup.string(),
    }

    const whatsappValidations = {
        whatsapp_number: isRequired(config?.validationFields, "mobile_number") ? Yup.string(config?.validationFields?.mobile_number?.error).required(config?.validationFields?.mobile_number?.error).trim() : Yup.string(),
    }

    const linkedInValidations = {
        linkedIn_profile_url: isRequired(config?.validationFields, "linkedin_profile_url") ? Yup.string(config?.validationFields?.linkedin_profile_url?.error).required(config?.validationFields?.linkedin_profile_url?.error).trim().matches(linkedInTestURL, config?.validationFields?.startup_website_url?.formaterror) : Yup.string(),
    }

    // const investorTypeValidations = {
    //     investor_type: Yup.string("Please select investor type").required("Please select investor type").trim(),
    // }

    const personalInfoValidation = { ...nameValidations, ...countryValidations, ...stateValidations }
    const investmentInfoValidation = { ...sizeValidations, ...investmentCountValidations, ...investmentValidations }
    const socialInfoValidation = { ...linkedInValidations }

    const getValidations = () => {
        let validations = Yup.object().shape({})
        if (shiftSteps) {
            switch (currentIndex) {
                case 0: {
                    validations = Yup.object().shape({ ...personalInfoValidation, ...profileImageValidations, ...socialInfoValidation }).test('is-valid-date', config?.validationFields?.dob?.error, function (value) {
                        const { dd, mm, yyyy } = value || {};
                        if (!isValidDate(parseInt(dd), parseInt(mm), yyyy)) {
                            return false; // Invalid date
                        }

                        // Check if the date is in the future
                        const inputDate = new Date(yyyy, mm - 1, dd);
                        const today = new Date();
                        // console.log("inputDate", inputDate)
                        today.setHours(0, 0, 0, 0); // Set to the start of the day
                        return inputDate <= today; // Allow dates that are today or earlier
                    });
                    break
                }
                case 1: {
                    validations = Yup.object().shape(sectorValidations)
                    break
                }
                // case 2: {
                //     validations = Yup.object().shape(investmentInfoValidation)
                //     break
                // }
                case 2: {
                    validations = Yup.object().shape(stageValidations)
                    break
                }
                // case 4: {
                //     validations = Yup.object().shape(socialInfoValidation)
                //     break
                // }
            }
        }
        else {
            switch (currentIndex) {
                case 0: {
                    validations = Yup.object().shape({ ...personalInfoValidation, ...organizationImageValidations, ...socialInfoValidation }).test('is-valid-date', config?.validationFields?.dob?.error, function (value) {
                        const { dd, mm, yyyy } = value || {};
                        if (!isValidDate(parseInt(dd), parseInt(mm), yyyy)) {
                            return false; // Invalid date
                        }

                        // Check if the date is in the future
                        const inputDate = new Date(yyyy, mm - 1, dd);
                        const today = new Date();
                        // console.log("inputDate", inputDate)
                        today.setHours(0, 0, 0, 0); // Set to the start of the day
                        return inputDate <= today; // Allow dates that are today or earlier
                    });
                    break
                }

                case 1: {
                    validations = Yup.object().shape(organizationDetailsValidations)
                    break
                }
                case 2: {
                    validations = Yup.object().shape(sectorValidations)
                    break
                }
                // case 2: {
                //     validations = Yup.object().shape(investmentInfoValidation)
                //     break
                // }
                case 3: {
                    validations = Yup.object().shape(stageValidations)
                    break
                }
                // case 4: {
                //     validations = Yup.object().shape(socialInfoValidation)
                //     break
                // }
            }
        }
        // console.log("validations", validations)
        return validations
    }

    const formik = useFormik({
        initialValues: !isProfileDetailsLoading && !isLoadingCountries && profileDetailsData ? profileDetailsData?.data : {
            // step 1
            first_name: '',
            last_name: '',
            title: '',
            dd: '',
            mm: '',
            yyyy: '',
            custom_image_url: null,
            avatar_selected: null,
            profile_path: null,
            country_id: null,
            state_id: null,
            city_id: null,
            category_id: null,
            sector_id: [],
            business_model_id: [],
            investment_size: null,
            min: null,
            max: null,
            investment_amount_type: null,
            stage_id: [],
            investment_count: null,
            startups: [],
            enabled: false,
            whatsapp_number: null,
            profile_url: null,
            linkedin_public_profile: false,
            otp_verified: false,
            step: 1
        },
        validationSchema: getValidations,
        // validate: (values) => { console.log(values, "valueseferfe"); console.log(formik.errors, "err") },
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        enableReinitialize: true,
        onSubmit: (values) => {
            const formData = new FormData();
            let request = {
                investor_id
            }
            let requestArray = []


            if (shiftSteps) {
                switch (currentIndex) {
                    case 0:
                        let dob = null;
                        if (values.mm != null && values.dd != null && values.yyyy && values.yyyy?.length != 0) {
                            let combinedDateString = values.mm + "-" + values.dd + "-" + values.yyyy
                            let combinedDate = moment(combinedDateString, "MM-DD-YYYY")
                            dob = combinedDate
                        }
                        request = {
                            ...request, ...{
                                title: values.title,
                                first_name: values.first_name,
                                last_name: values.last_name,
                                dob: dob,
                                bio: values.bio,
                                country_id: values.country_id,
                                state_id: values.state_id,
                                city_id: values.city_id,
                                linkedin_profile_url: values?.linkedIn_profile_url,
                                country_code: values?.country_code,
                                phone_number: values?.phone_number,
                                phone_code: values?.phone_code,
                                whatsapp_number: values?.whatsapp_number
                            }
                        }

                        if (values.profile_path != null) {
                            if (typeof values.profile_path != 'string') {
                                formData.append("profile_picture", values.profile_path);
                            }

                            else {
                                formData.append("is_profile_image", true);
                            }
                        } else {
                            formData.append("is_profile_image", true);
                        }
                        break;
                    case 1:
                        request = {
                            ...request, ...{
                                category_id: values.category_id,
                                sector_ids: values.sector_id,
                                sector_type: values.sector_id?.length > 0 ? "Multiple" : "All",
                                business_model_id: values.business_model_id,
                                business_model_type: values.business_model_id?.length > 0 ? "Multiple" : "All",
                                investment_size: values.investment_size,
                                min: values.min,
                                max: values.max,
                                investment_amount_type: values.investment_amount_type,
                                investment_count: values.investment_count,
                            }
                        }
                        break;

                    case 2:
                        request = {
                            ...request, ...{
                                stage_ids: values.stage_id,
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
            else {
                switch (currentIndex) {
                    case 0:
                        let dob = null;
                        if (values.mm != null && values.dd != null && values.yyyy && values.yyyy?.length != 0) {
                            let combinedDateString = values.mm + "-" + values.dd + "-" + values.yyyy
                            let combinedDate = moment(combinedDateString, "MM-DD-YYYY")
                            dob = combinedDate
                        }
                        request = {
                            ...request, ...{
                                title: values.title,
                                first_name: values.first_name,
                                last_name: values.last_name,
                                dob: dob,
                                // bio: values.bio,
                                country_id: values.country_id,
                                state_id: values.state_id,
                                city_id: values.city_id,
                                linkedin_profile_url: values?.linkedIn_profile_url,
                                phone_code: values?.phone_code,
                                whatsapp_number: values?.whatsapp_number
                            }
                        }

                        if (values.website_logo != null) {
                            if (typeof values.website_logo != 'string') {
                                formData.append("website_logo", values.website_logo);
                            }
                            else {
                                formData.append("is_profile_image", true);
                            }
                        } else {
                            formData.append("is_profile_image", true);
                        }
                        break;
                        break;
                    case 1:
                        request = {
                            ...request, ... {
                                organization_name: values?.organization_name,
                                country_code: values?.country_code,
                                phone_number: values?.phone_number,
                                organization_website: values?.organization_website,
                                bio: values.bio,
                            }
                        }
                        break;
                    case 2:
                        request = {
                            ...request, ...{
                                category_id: values.category_id,
                                sector_ids: values.sector_id,
                                sector_type: values.sector_id?.length > 0 ? "Multiple" : "All",
                                business_model_id: values.business_model_id,
                                business_model_type: values.business_model_id?.length > 0 ? "Multiple" : "All",
                                investment_size: values.investment_size,
                                min: values.min,
                                max: values.max,
                                investment_amount_type: values.investment_amount_type,
                                investment_count: values.investment_count,
                            }
                        }
                        break;

                    case 3:
                        request = {
                            ...request, ...{
                                stage_ids: values.stage_id,
                            }
                        }
                        break;

                    case 4:
                        values?.partners?.filter(item => {
                            return notEmpty(item?.name) && notEmpty(item?.linkedin_url)
                        })?.forEach((element, index) => {
                            formData.append(`partner[${index}][name]`, element?.name);
                            formData.append(`partner[${index}][linkedin_url]`, "https://" + element?.linkedin_url);
                            if (typeof element?.image === "string") {
                                formData.append(`partner[${index}][flag]`, "true");
                            }
                            else {
                                formData.append(`partner${index}`, element?.image);
                            }
                            formData.append(`partner[${index}][bio]`, element?.bio);
                        });

                        break;
                    default:
                        break;
                }
            }
            // console.log("values", values)

            // switch (currentIndex) {
            //     case 0:
            //         let dob = null;
            //         if (values.mm != null && values.dd != null && values.yyyy && values.yyyy?.length != 0) {
            //             let combinedDateString = values.mm + "-" + values.dd + "-" + values.yyyy
            //             let combinedDate = moment(combinedDateString, "MM-DD-YYYY")
            //             dob = combinedDate
            //         }
            //         request = {
            //             ...request, ...{
            //                 title: values.title,
            //                 first_name: values.first_name,
            //                 last_name: values.last_name,
            //                 dob: dob,
            //                 bio: values.bio,
            //                 country_id: values.country_id,
            //                 state_id: values.state_id,
            //                 city_id: values.city_id,
            //                 linkedin_profile_url: values?.linkedIn_profile_url,
            //             }
            //         }

            //         if (values.profile_path != null) {
            //             if (typeof values.profile_path != 'string') {
            //                 formData.append("profile_picture", values.profile_path);
            //             }
            //         } else {
            //             formData.append("avatar_id", values.avatar_selected);
            //         }
            //         break;
            //     case 1:
            //         request = {
            //             ...request, ...{
            //                 category_id: values.category_id,
            //                 sector_ids: values.sector_id,
            //                 sector_type: values.sector_id?.length > 0 ? "Multiple" : "All",
            //                 business_model_id: values.business_model_id,
            //                 sector_type: values.business_model_id?.length > 0 ? "Multiple" : "All",
            //                 investment_size: values.investment_size,
            //                 min: values.min,
            //                 max: values.max,
            //                 investment_amount_type: values.investment_amount_type,
            //                 investment_count: values.investment_count,
            //             }
            //         }
            //         break;

            //     case 2:
            //         request = {
            //             ...request, ...{
            //                 stage_ids: values.stage_id,
            //             }
            //         }
            //         break;
            //     default:
            //         break;
            // }

            // if (currentIndex == 4) {
            //     if (values?.otp_verified) {
            //         SavePersonalInfoAsync(request).catch(err => {
            //             // console.log(err?.response?.data?.message, "response herer")

            //             toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
            //                 duration: 4000,
            //                 position: "top-right",
            //                 style: {
            //                     padding: "15px 30px",
            //                 },
            //             });
            //         });
            //     } else {
            //         if (values.enabled) {
            //             SendWhatsappOTPAsync(request).catch(err => {
            //                 // console.log(err?.response?.data?.message, "response herer")

            //                 toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
            //                     duration: 4000,
            //                     position: "top-right",
            //                     style: {
            //                         padding: "15px 30px",
            //                     },
            //                 });
            //             });
            //         } else {
            //             SavePersonalInfoAsync(request).catch(err => {
            //                 // console.log(err?.response?.data?.message, "response herer")

            //                 toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
            //                     duration: 4000,
            //                     position: "top-right",
            //                     style: {
            //                         padding: "15px 30px",
            //                     },
            //                 });
            //             });
            //         }
            //     }
            // } else {
            if (currentIndex == 0) {
                // console.log(values?.custom_image_url , values?.custom_image_url !== null , typeof values?.custom_image_url !== "string","dd", shiftSteps)
                if (shiftSteps ? (values?.profile_path && values?.profile_path !== null && typeof values?.profile_path !== "string") : (values?.website_logo && values?.website_logo !== null && typeof values?.website_logo != "string")) {
                    formData.append("investor_id", investor_id)
                    SaveProfileImageAsync(formData).catch(err => {
                        // console.log(err?.response?.data?.message, "response herer")

                        toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                            duration: 4000,
                            position: "top-right",
                            style: {
                                padding: "15px 30px",
                            },
                        });
                    }).then(() => {
                        SavePersonalInfoAsync(request).catch(err => {
                            // console.log(err?.response?.data?.message, "response herer")

                            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                                duration: 4000,
                                position: "top-right",
                                style: {
                                    padding: "15px 30px",
                                },
                            });
                        });
                    });
                }
                else {
                    SavePersonalInfoAsync(request).catch(err => {
                        // console.log(err?.response?.data?.message, "response herer")

                        toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                            duration: 4000,
                            position: "top-right",
                            style: {
                                padding: "15px 30px",
                            },
                        });
                    });
                }
            }
            else if (currentIndex === 4) {
                formData.append("investor_id", investor_id)
                AddPartnersAsync(formData).catch(err => {
                    // console.log(err?.response?.data?.message, "response herer")

                    toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                        duration: 4000,
                        position: "top-right",
                        style: {
                            padding: "15px 30px",
                        },
                    });
                })
            }
            else if (currentIndex > 4) {

            }
            else {
                SavePersonalInfoAsync(request).catch(err => {
                    // console.log(err?.response?.data?.message, "response herer")

                    toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                        duration: 4000,
                        position: "top-right",
                        style: {
                            padding: "15px 30px",
                        },
                    });
                });
            }
            // if (currentIndex == 1) {
            //     SaveStartupInfoAsync(requestArray).catch(err => {
            //         // console.log(err?.response?.data?.message, "response herer")

            //         toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
            //             duration: 4000,
            //             position: "top-right",
            //             style: {
            //                 padding: "15px 30px",
            //             },
            //         });
            //     });
            // }
            // }
        },
    });

    const [currentCountry, setCurrentCountry] = useState(countries?.find(item => item?._id == formik?.values?.country_id))
    const { data: states, isRefetching: isRefetchingStates, isLoading: isLoadingStates, refetch: refetchStates } = FetchAllStates({ search: searchState, countryID: currentCountry !== null ? countries?.find(item => item?._id == formik.values?.country_id)?.value : currentCountry?.value })
    const [currentState, setCurrentState] = useState(states?.find(item => item?.value === formik?.values?.state_id)?.label || '')
    const { data: cities, isRefetching: isRefetchingCities, isLoading: isLoadingCities, refetch: refetchCities } = FetchAllCities({ search, stateID: currentState !== null ? states?.find(item => item?._id == formik.values?.state_id)?.value : currentState?.value, isPopular: 0 });
    const [currentCity, setCurrentCity] = useState(cities?.find(item => item?.value === formik?.values?.city_id)?.label || '');
    const { data: countriesWithPhoneCodes, isRefetching: isRefetchingCountriesPC, isLoading: isLoadingCountriesPC, refetch: refetchCountriesPC } = FetchAllCountriesWithPhoneCodes({})
    const handleToggle = () => {
        setIsWhatsAppEnabled(!isWhatsAppEnabled);
        formik.setFieldValue("enabled", !isWhatsAppEnabled)
    };

    const handleTogglePublic = () => {
        setIsPublicEnabled(!isPublicEnabled);
        formik.setFieldValue("linkedin_public_profile", !isPublicEnabled)
    };

    // const handleCurrencyChange = (event) => {
    //     setCurrency(event.target.value);
    //     // console.log(event.target.value,"event.target.value")
    //     formik.setFieldValue("investment_amount_type", event.target.value)

    //     setValue1([0, 3]);
    //     formik.setFieldValue('min', getMarks(event.target.value)[0].selectedValue);
    //     formik.setFieldValue('max', getMarks(event.target.value)[3].selectedValue);
    // };

    const handleRangeSelect = (range) => {
        setSelectedRange(range.title);
        formik.setFieldValue("investment_size", range.title)
        formik.setFieldValue("min", range.min)
        formik.setFieldValue("max", range?.max)
    };

    const handleVerify = () => {
        if (formik?.values?.whatsapp_number?.trim()?.length > 0) {
            setIsVerify(true);
        }
        formik?.handleSubmit()
    };

    const isValidDate = (day, month, year) => {
        const date = new Date(year, month - 1, day);
        return (
            date.getFullYear() === year &&
            date.getMonth() === month - 1 &&
            date.getDate() === day
        );
    };

    const handleInputChange = (event, index) => {
        const { value } = event.target;
        if (value.length === 1 && index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }
        const newOtpValues = [...otpValues];
        newOtpValues[index] = value;
        setOtpValues(newOtpValues);
        setError(null);
    };

    const handleKeyDown = (event, index) => {
        // Get the reference to the current input element
        // const currentRef = inputRefs[index];

        if (event.key === 'Backspace' && !event.target.value && index > 0) {
            inputRefs[index - 1].current.focus();
        }
        if (event.key === 'Enter') {
            handleVerifyOTP();
        }

        // Check if the arrow keys are pressed
        if (event.key === 'ArrowRight') {
            // Move focus to the next input if it's not the last one
            if (index < inputRefs.length - 1) {
                inputRefs[index + 1]?.current?.focus();
            }
        } else if (event.key === 'ArrowLeft') {
            // Move focus to the previous input if it's not the first one
            if (index > 0) {
                inputRefs[index - 1]?.current?.focus();
            }
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();

        // Get the pasted data and filter out non-numeric characters
        const pastedData = event.clipboardData
            .getData('text')
            .replace(/\D/g, '') // Remove all non-numeric characters
            .slice(0, 4); // Ensure only the first 4 characters are processed

        const newOtpValues = [...otpValues];

        pastedData.split('').forEach((char, index) => {
            if (inputRefs[index]) {
                inputRefs[index].current.value = char;
                newOtpValues[index] = char;
            }
        });

        setOtpValues(newOtpValues);
    };

    const handleResendingOTP = () => {
        SendWhatsappOTPAsync({ mobile_number: formik?.values.whatsapp_number }).then(() => {
            setCountDown(30);
        });
    };

    const handleVerifyOTP = () => {
        const OTP = otpValues.join('');
        if (OTP?.trim()?.length > 0) {
            verifyWhatsAppOTPAsync({ enabled: formik?.values?.enabled, otp: OTP });
        }
        else {
            setError("Please enter OTP");
        }
    };

    const handleSearchCityChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearch(value);
        // refetchCities();
    };
    const handleSearchCountryChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchCountry(value);
        // refetchCities();
    };
    const handleSearchStateChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchState(value);
        // refetchCities();
    };
    const handleSelectionCityChange = (event, value) => {
        // console.log(value, "value")

        formik.setFieldValue("city_id", value ? value?._id : '');
        setCurrentCity(value)
        event.preventDefault();
    };
    const handleSelectionCountryChange = (event, value) => {
        // console.log(value, "value")
        formik.setFieldValue("country_id", value ? value?._id : '');
        setCurrentCountry(value)
        formik.setFieldValue("state_id", null);
        setCurrentState(null)
        setCurrentCity(null);
        formik.setFieldValue("city_id", null)
        setSearchState("")
        setSearch("")
        refetchData()
        event.preventDefault();
    };
    const handleSelectionStateChange = (event, value) => {
        // console.log(value, "value")
        formik.setFieldValue("state_id", value ? value?._id : null);
        setCurrentState(value)
        setCurrentCity(null);
        formik.setFieldValue("city_id", null)
        setSearch("")
        refetchData('city')
        event.preventDefault();
    };
    const handleSearchUpdateCity = () => {
        refetchCities();
    }
    const handleSearchUpdateCountry = () => {
        refetchCountries();
    }
    const handleSearchUpdateState = () => {
        refetchStates();
    }
    const refetchData = (type) => {
        if (type === "city") {
            refetchCities().then(response => {
                setTimeout(() => {
                    setCurrentCity(response.data?.find(item => item?._id == formik?.values?.city_id)?.label || '')
                    setSearch("");
                    // setSearch(response.data?.find(item => item?.value == formik?.values?.city_id)?.label || '')
                }, 0)
            })
        }
        else {
            // console.log("country", formik.values?.country_id)
            formik.values?.country_id != undefined && refetchStates().then(res => {
                setCurrentState(res?.data?.filter(item => item?._id == formik.values.state_id)[0]?.label || '');
                setSearchState("");
                // setSearchState(res?.data?.find(item => item?.value == formik.values.state_id)?.label || '');
            })

            formik.values?.state_id != undefined && refetchCities().then(response => {
                setTimeout(() => {
                    setCurrentCity(response?.data?.find(item => item?._id == formik?.values?.city_id)?.label || '')
                    setSearch("");
                    // setSearch(response.data?.find(item => item?.value == formik?.values?.city_id)?.label || '')
                }, 200)
            })

        }
        // refetchPopularCities()
    }

    // useEffect(() => {
    //     setCurrencyOptions(investmentSizeList?.currencies)
    //     setAllAmountRanges(investmentSizeList?.amount)
    // }, [investmentSizeList])

    // useEffect(() => {
    //     if (currency != null) {
    //         let modifiedOptions = allAmountRanges.filter(option => { return option.currency == currency })
    //         setAmountRanges(modifiedOptions)
    //     }
    // }, [currency])

    // useEffect(() => {
    //     if (currentIndex == 2) {
    //         if (formik?.values?.investment_amount_type) {
    //             // setCurrency(formik?.values?.investment_amount_type ? formik?.values?.investment_amount_type : "INR")
    //             // formik.setFieldValue("investment_amount_type", formik?.values?.investment_amount_type ? formik?.values?.investment_amount_type : "INR")
    //         } else {
    //             // setCurrency(investmentSizeList?.currencies[0]?.name)
    //             // formik.setFieldValue("investment_amount_type", investmentSizeList?.currencies[0]?.name)
    //         }
    //         if (formik?.values?.investment_size) {
    //             // formik.setFieldValue("investment_size", formik?.values?.investment_size)
    //             // formik.setFieldValue("min", formik?.values?.min)
    //             // formik.setFieldValue("max", formik?.values?.max)
    //             // setSelectedRange(formik?.values?.investment_size)
    //         } else {
    //             setSelectedRange(null)
    //         }
    //     } else if (currentIndex == 4) {
    //         setIsWhatsAppEnabled(formik?.values?.enabled)
    //         setIsPublicEnabled(formik?.values?.linkedin_public_profile)
    //     }
    // }, [currentIndex])

    // useEffect(() => {
    //     if (countDown > 0) {
    //         const timer = setTimeout(() => setCountDown(prev => prev - 1), 1000);
    //         return () => clearTimeout(timer);
    //     }
    // }, [countDown]);

    // useEffect(() => {
    //     if (otpValues?.join("")?.trim()?.length > 0) {
    //         if (verifyWhatsAppOTPResponse?.data?.statusCode === 200) {
    //             setTimeout(() => {
    //                 setError(null)
    //                 navigate('/profile');
    //             }, 100);
    //         } else if (verifyWhatsAppOTPResponse?.data?.statusCode !== 200) {
    //             verifyWhatsAppOTPResponse?.data?.message && setError(verifyWhatsAppOTPResponse?.data?.message || null)

    //         }
    //     }
    // }, [verifyWhatsAppOTPResponse?.data?.statusCode]);

    const contactInformation = [{
        name: "CONTACT INFORMATION",
        sub_title: "This information will be used to contact you.",
        image_url: NameTypeIcon,
    }]

    const investorTypesDetails = [{
        name: "INVESTOR TYPE",
        sub_title: 'What describes you best?',
        image_url: CurrentInvestorsIcon,
    }]

    const investmentFocusDetails = [{
        name: "INVESTMENT FOCUS",
        sub_title: "Provide information about your investment focus.",
        image_url: FundUtilizationIcon,
    }]

    const preferredStagesDetails = [{
        name: "PREFERRED STAGES",
        sub_title: "Select which stage have you invested in the companies.",
        image_url: CurrentTractionIcon,
    }]

    const organizationInformation = [
        {
            name: "Organization Details",
            sub_title: 'Please enter your organization details.',
            image_url: DocumentsIcon,
        },
    ]

    const partnersInformation = [
        {
            name: "Partners",
            sub_title: 'Tell us about your partners',
            image_url: CurrentInvestorsIcon,
        },
    ]


    const round_steps_angels = [
        ...contactInformation,
        // ...investorTypesDetails,
        ...investmentFocusDetails,
        ...preferredStagesDetails
    ];

    const round_steps_other = [
        ...contactInformation,
        // ...investorTypesDetails,
        ...organizationInformation,
        ...investmentFocusDetails,
        ...preferredStagesDetails,
        ...partnersInformation
    ];

    const bottomRef = useRef(null);

    const Actions = () => {
        return (
            <>
                {(isUpdatingsavePersonalInfo, isUpdatingAddPartners) && matchesUpMd && <Typography sx={{ marginRight: "1rem" }} display={"flex"} alignItems={'center'} gap={1} fontSize={"14px"} fontWeight={600} color={theme.palette.customColors.g200}>
                    <SavingIcon width={24} height={24} className="saving-icon" />
                    <span>Saving...</span>
                </Typography>}
                {/* <SECONDARY
                    sx={{
                        background: theme?.palette?.customColors.indigo50,
                        color: theme?.palette?.customColors?.indigo700,
                        fontWeight: 600,
                        fontSize: "14px",
                        padding: "10px 16px",
                        display: { xs: "none", md: 'inline-block' }
                    }}
                >
                    Need help?
                </SECONDARY> */}
                <NeedHelpButton />
                {/* <IconButton sx={{ display: { xs: "inline-block", md: 'none' }, padding: "5px" }}><InfoIcon style={{ width: '1.5rem', height: '1.5rem', display: 'flex', alignItems: 'center', gap: "4px", marginBlock: 'auto', stroke: '#444CE7' }} /></IconButton> */}
            </>
        );
    };

    useEffect(() => {
        if (savePersonalInfoResponse && savePersonalInfoResponse?.data?.statusCode == 200) {
            if (currentIndex === round_steps?.length - 1) {
                navigate("/profile");
                refetchProfileDetails()
            }
            else {
                refetchProfileDetails()
                setCurrentIndex(currentIndex + 1);
                setSearchParams({ page: currentIndex + 1 });
            }
        }
        window.document.getElementsByClassName("scrollbar-container")[0]?.scroll(0, 0);
    }, [savePersonalInfoResponse?.data?.statusCode]);

    useEffect(() => {
        if (AddPartnersResponse && AddPartnersResponse?.data?.statusCode == 200) {
            if (currentIndex === round_steps?.length - 1) {
                navigate("/profile")
                refetchProfileDetails()
            }
            else {
                refetchProfileDetails()
                setCurrentIndex(currentIndex + 1);
                setSearchParams({ page: currentIndex + 1 });
            }
        }
        window.document.getElementsByClassName("scrollbar-container")[0]?.scroll(0, 0);
    }, [AddPartnersResponse?.data?.statusCode]);

    const isLoading = (isProfileDetailsLoading) || isLoadingRoundTypes;
    // Effect to set selected category based on formik values
    useEffect(() => {
        if (!isProfileDetailsLoading && !isLoadingCategories && profileDetailsData?.data && Array.isArray(startup_categories)) {
            const category = startup_categories?.filter(item => formik?.values?.category_id?.includes(item?._id));

            if (category.length) {
                setSelectedCategory(category.map(item => item?.name).join(","));  // Or whichever property you want to use
            }
        }
    }, [isProfileDetailsLoading, isLoadingCategories, profileDetailsData?.data, startup_categories, formik?.values?.category_id]);

    // Effect to refetch subcategories when selectedCategory changes
    useEffect(() => {
        if (!isRefetchingCategories && !isLoadingCategories && selectedCategory) {
            refetchSubCategories();
        }
    }, [selectedCategory, isRefetchingCategories || isLoadingCategories,]);

    useEffect(() => {
        if (formik.values.sector_id?.length > 0 && startup_sub_categories) {
            const matchedSubCategories = startup_sub_categories.filter(subCat => formik.values?.sector_id?.includes(subCat?._id));
            if (matchedSubCategories) {
                setCurrentSubCategory(
                    matchedSubCategories.map(subCat => ({
                        label: subCat.sub_category,
                        value: subCat._id,
                        description: subCat.description,
                    }))
                );
            }
        }
    }, [startup_sub_categories]);

    // console.log(formik,"ffff")

    const handleSearchSubCategoryChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchSubCategory(value);
        // refetchCities();
    };
    const handleSelectionSubCategoryChange = (event, sector) => {
        // console.log(sector, "sector")
        formik.setFieldValue("sector_id", sector ? sector.map(item => item.value) : []);
        setCurrentSubCategory(sector)
        event.preventDefault();
    };
    const handleSearchUpdateSubCategory = () => {
        refetchSubCategories();
    }

    const availableOptions = startup_sub_categories
        ?.filter(item => {
            const isSelected = currentSubCategory?.some(
                selected => selected.value === item._id
            );
            const matchesSearch = item.sub_category
                .toLowerCase()
                .includes(searchSubCategory.toLowerCase());
            return !isSelected && matchesSearch;
        }) || [];

    React.useEffect(() => {
        if (!isLoading && !isLoadingBusinessModel && formik?.values && Array.isArray(startup_business_model) && Array.isArray(formik?.values.business_model_id)) {
            const businessModel = startup_business_model?.filter(item => formik?.values?.business_model_id?.includes(item?._id));
            if (businessModel.length) {
                setCurrentBusinessModel(
                    businessModel.map(item => ({
                        label: item?.name,
                        value: item?._id,
                        description: item?.description,
                    }))
                );
            }
        }
    }, [isLoading, isLoadingBusinessModel, formik?.values, startup_business_model, formik?.values?.business_model_id]);

    const handleSearchBusinessModelChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchBusinessModel(value);
        // refetchCities();
    };
    const handleSelectionBusinessModelChange = (event, businessModel) => {
        formik.setFieldValue("business_model_id", businessModel ? businessModel?.map(item => item?.value) : []);
        setCurrentBusinessModel(businessModel)
        event.stopPropagation();
        // console.log(businessModel ,"businessModelbusinessModelbusinessModel")
    };
    const handleSearchUpdateBusinessModel = () => {
        refetchBusinessModel();
    }
    const availableOptionsBusinessModel = startup_business_model?.filter(
        item =>
            !(currentBusinessModel || []).some(selected => selected.value === item._id)
    ) || [];
    useEffect(() => {
        if (!isLoading) {
            refetchCountries().then(respo => {
                if (profileDetailsData?.data?.country_id != undefined) {
                    refetchStates().then(res => {
                        setCurrentState(res?.data?.filter(item => item?._id == profileDetailsData?.data?.state_id)[0])
                    }).then(() => {
                        if (profileDetailsData?.data?.state_id != undefined) {
                            refetchCities().then(response => {
                                // console.log(response?.data?.find(item => item?._id == profileDetailsData?.data?.city_id))
                                setCurrentCity(response?.data?.find(item => item?._id == profileDetailsData?.data?.city_id))
                            })
                        }
                    })
                }
            })
        }
    }, [isLoading])

    // const [value1, setValue1] = React.useState(
    //     !isLoading && formik?.values?.min !== undefined && formik?.values?.max !== undefined
    //         ? [
    //             getMarks(currency).find(item => item.selectedValue === formik.values.min)?.value || 0,
    //             getMarks(currency).find(item => item.selectedValue === formik.values.max)?.value || 3
    //         ]
    //         : [0, 3]
    // );

    // React.useEffect(() => {
    //     if (!isLoading && formik.values?.min !== undefined && formik.values?.max !== undefined) {
    //         const minIndex = getMarks(currency).findIndex(item => item.selectedValue === formik.values?.min);
    //         const maxIndex = getMarks(currency).findIndex(item => item.selectedValue === formik.values?.max);

    //         setValue1([
    //             minIndex !== -1 ? minIndex : 0,
    //             maxIndex !== -1 ? maxIndex : 3
    //         ]);

    //         formik.setFieldValue('min', getMarks(currency)[minIndex !== -1 ? minIndex : 0]?.selectedValue);
    //         formik.setFieldValue('max', getMarks(currency)[maxIndex !== -1 ? maxIndex : 3]?.selectedValue);
    //         formik.setFieldValue('investment_amount_type', currency);

    //     }
    // }, [formik.values, isLoading, currency]);


    // React.useEffect(() => {
    //     if (!isLoading && investmentSizeList?.currencies && Array.isArray(investmentSizeList?.currencies)) {
    //         setCurrency(investmentSizeList?.currencies?.find(item => item?.name === formik?.values?.investment_amount_type)?.name || "INR")
    //         // setCurrency()
    //     }
    // }, [investmentSizeList?.currencies, isLoading, formik.values])


    // const handleChange1 = (event, newValue, activeThumb) => {
    //     if (!Array.isArray(newValue)) return;

    //     const minValue = Math.min(newValue[0], value1[1] - 1);
    //     const maxValue = Math.max(newValue[1], value1[0] + 1);

    //     setValue1([minValue, maxValue]);
    //     formik.setFieldValue('min', getMarks(currency)[minValue].selectedValue);
    //     formik.setFieldValue('max', getMarks(currency)[maxValue].selectedValue);
    // };

    React.useEffect(() => {
        !isLoading && profileDetailsData?.data?.sector_type === "All" && setIsSectorAgnostic(true)
        !isLoading && profileDetailsData?.data?.business_model_type === "All" && setIsBusinessModelSelectAll(true)
    }, [isLoading])

    React.useEffect(() => {
        setShiftSteps(formik?.values?.investor_type ? String(formik?.values?.investor_type)?.toLowerCase()?.includes("angel") : true);
    }, [formik.values?.investor_type])

    const [round_steps, setRoundSteps] = React.useState([]);
    const [isRoundStepsLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        setIsLoading(true);
        if (shiftSteps) {
            setRoundSteps(round_steps_angels)
        }
        else {
            setRoundSteps(round_steps_other)
        }
        setTimeout(() => {
            setIsLoading(false)
        }, 500)
    }, [shiftSteps])

    // React.useEffect(() => {
    //     if (!isLoading) {
    //         !(profileDetailsData?.data?.investor_type) && formik.setFieldValue("investor_type", "angel")
    //     }
    // }, [isLoading])

    const handleMobileChange = (event) => {
        const value = event.target.value;
        // setIsMobileEdited(value.length > 0);
        // formik.setFieldValue("otp_verified", false);
        // formik.setFieldValue("isVerify", false)
        formik.handleChange(event);
    };

    return (
        <ThemeBGWrapper>
            <BreadcrumbHeader
                title={isProfileDetailsLoading || (isRoundStepsLoading) ? "Loading..." : `Update`}
                breadcrumbTitle="Profile"
                Actions={Actions}
            />
            {(isLoading || (isRoundStepsLoading)) ?
                <Grid container xs={12} display={"flex"} height={"100%"} maxHeight={"85vh"} sx={{ padding: { xs: "0 1rem", sm: '0 1rem 1rem 1rem', md: "0 2rem 1rem 2rem" }, width: '100%' }}>
                    <Grid item xs={12} className="skeleton" height={"100%"} width={"100%"} borderRadius={"24px"}>

                    </Grid>
                </Grid> :
                <>
                    <Grid
                        container
                        xs={12}
                        sx={{
                            // height: "calc(100vh - 118px)",
                            height: '100%',
                            maxHeight: { xs: "90%", sm: "93%", md: '85vh' },
                            overflow: "hidden",
                            padding: { xs: "0 1rem", sm: '0 1rem 1rem 1rem', md: "0 2rem 1rem 2rem" },
                        }}
                    >
                        <Grid
                            xs={12}
                            item
                            container
                            sx={{
                                backgroundColor: "#FFFAF5",
                                borderRadius: { xs: "24px", sm: "24px" },
                                height: "100%",
                            }}
                        >
                            <Grid
                                item
                                sx={{
                                    position: "relative",
                                    backgroundColor: "#ADA2ED",
                                    borderRadius: `24px ${currentIndex === 0 ? "0" : "24px"} 24px 24px`,
                                    overflow: "hidden",
                                    height: "100%",
                                    width: { xs: '0%', md: '40%', lg: '40%', xl: '37.9%' },
                                }}
                            >
                                <VerticalScrollableTabs data={round_steps} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} setSearchParams={setSearchParams} />
                            </Grid>
                            <Grid
                                item
                                container
                                sx={{ width: { xs: "100%", md: '60%', lg: '60%', xl: '62.1%' }, padding: { xs: '1rem', md: '2rem', lg: "2.5rem" } }}
                                gap={3}
                                marginTop={0}
                                display={"flex"}
                                alignItems={"flex-start"}
                                alignContent={"space-around"}
                                justifyContent={"space-between"}
                                // padding={"2.5rem"}
                                paddingBottom={0}
                                position={"relative"}
                            ><Box sx={{ position: 'absolute', right: 0, top: 0, }}>
                                    <Tooltip title="Close">
                                        <IconButton onClick={() => {
                                            navigate('/profile')
                                        }}>
                                            <Close />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <FormikProvider value={formik}>
                                    <Form
                                        onSubmit={(event) => event.preventDefault()}
                                        style={{ width: "100%", height: "90%", maxHeight: matchUpXl ? "calc(100vh - 295px)" : matchUpLg ? "calc(100vh - 290px)" : matchesUpMd ? "calc(100vh - 285px)" : "calc(-220px + 100vh)", overflow: "hidden", paddingBottom: '1rem' }}
                                    >
                                        {!matchesUpMd &&
                                            <Box width={"100%"}>
                                                {round_steps &&
                                                    round_steps?.length > 0 ?
                                                    <>
                                                        <Typography marginInline={"auto"} flexWrap={"wrap"} variant="h6"
                                                            fontSize="1rem"
                                                            color={theme.palette.customColors.g300}
                                                            fontWeight={700} textAlign={"center"} marginBottom={1}>
                                                            <span>{round_steps[currentIndex]?.name} ({`${currentIndex + 1}/${round_steps?.length}`})</span>
                                                            <Divider sx={{ marginTop: 1, marginBottom: 2, marginInline: 'auto', maxWidth: '50%' }} />
                                                        </Typography>
                                                    </>
                                                    : ''}
                                            </Box>
                                        }
                                        <PerfectScrollbar
                                            ref={bottomRef}
                                            containerRef={containerRef}
                                            style={{
                                                minHeight: currentIndex == 5 ? "56vh" : "60vh",
                                                height: "100%",
                                                width: "98%",
                                                maxHeight: currentIndex == 5 ? "calc(-330px + 100vh)" : "calc(100vh - 285px)",
                                                paddingRight: "1rem",
                                                marginTop: 0,
                                                paddingBottom: "1rem",
                                                // paddingLeft: "1rem",
                                                overflow: 'hidden',
                                                marginInline: 'auto'
                                            }}
                                            className="scrollbar-container"
                                            id="scrollable-form"
                                        >
                                            <Grid
                                                item
                                                container
                                                xs={12}
                                                paddingBottom={{ xs: 1, md: currentIndex == 0 || currentIndex == 7 ? 2 : 0 }}
                                                // sx={{ width: "100%" }}
                                                justifyContent={"space-between"}
                                                columnSpacing={"0.5rem"}
                                            >
                                                {currentIndex == 0 && (
                                                    <>
                                                        <Grid item xs={12} gap={0} sx={{ overflowX: "hidden", marginBottom: "10px" }}>
                                                            {shiftSteps ? <Grid item xs={12} sm={12}>
                                                                <CustomLogoButton label={config?.dynamicText?.uploadPhoto} handleUpdateFile={(file) => { formik.setFieldValue("profile_path", file); formik.setFieldValue("avatar_selected", null) }} name={"profile_path"} value={formik.values.profile_path} placeholder={placeHolderImage} Pheight={100} Pwidth={100} />
                                                            </Grid> : <UploadOrgPhoto label={"Organization Logo"} />}
                                                        </Grid>
                                                        <Grid item container xs={12} display={"flex"} gap={0} justifyContent={"space-between"} flexDirection={"row"}>
                                                            <CustomLabel>{config?.validationFields?.title?.label}  {formik.errors.title && formik.touched.title && (
                                                                <CustomValidationText value={formik.errors.title} />
                                                            )}</CustomLabel>
                                                            <Grid
                                                                display={"flex"}
                                                                flexWrap={"wrap"}
                                                                justifyContent={"space-between"}
                                                                item
                                                                container
                                                                xs={12}
                                                                gap={{ xs: 0.5, sm: 1, md: 2 }}
                                                                mb={2}
                                                            >
                                                                {title?.map((referral, index) => {
                                                                    return (
                                                                        <Grid
                                                                            role={"button"}
                                                                            item
                                                                            // xs={12}
                                                                            xs={3.8}
                                                                            sm={1.65}
                                                                            md={1.6}
                                                                            lg={1.65}

                                                                            onClick={() => {
                                                                                formik.setFieldValue(
                                                                                    "title",
                                                                                    referral?.value
                                                                                );
                                                                            }}
                                                                            sx={{
                                                                                background:
                                                                                    formik.values.title ==
                                                                                        referral?.value
                                                                                        ? theme?.palette?.customColors?.y50
                                                                                        : theme?.palette?.customColors?.whiteBG,
                                                                                padding: "12px 16px",
                                                                                height: 55,
                                                                                borderRadius: "8px",
                                                                                display: "grid",
                                                                                placeItems: "center",
                                                                                border: `1px solid ${formik.values.title ==
                                                                                    referral?.value
                                                                                    ? theme?.palette?.customColors?.y300
                                                                                    : theme?.palette?.customColors?.g75
                                                                                    }`,
                                                                                boxShadow: "0px 1px 2px 0px #1018280D",
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                color={theme.palette.customColors.g200}
                                                                                fontSize="0.875rem"
                                                                            >
                                                                                {referral?.value}
                                                                            </Typography>
                                                                        </Grid>
                                                                    );
                                                                })}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid height={"min-content"} item xs={12} sm={6}>
                                                            <CustomInputField name={`first_name`} label={"FIRST NAME"} placeholder={"Enter first name"} value={formik.values?.first_name} onChange={(e) => formik.setFieldValue(`first_name`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
                                                        </Grid>
                                                        <Grid height={"min-content"} item xs={12} sm={6}>
                                                            <CustomInputField name={`last_name`} label={'LAST NAME'} placeholder={"Enter last name"} value={formik.values?.last_name} onChange={(e) => formik.setFieldValue(`last_name`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
                                                        </Grid>
                                                        <Grid item container gap={{ xs: 0.5, sm: 1, md: 2 }} display={'flex'} justifyContent={"space-between"} alignItems={"flex-end"}>
                                                            <Grid height={"min-content"} item xs={3.8} sm={3.8} md={3.65} lg={3.65}>
                                                                <CustomInputField name={`mm`} label={!isMobile ? (
                                                                    <>
                                                                        {config?.validationFields?.dob?.label}
                                                                        <span
                                                                            style={{
                                                                                color: theme?.palette?.customColors?.g100,
                                                                                textTransform: "none",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            (Optional)
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    config?.validationFields?.dob?.label
                                                                )
                                                                } placeholder={config?.validationFields?.dob?.monthPlaceholder} fullWidth />
                                                            </Grid>
                                                            <Grid height={"min-content"} item xs={3.8} sm={3.8} md={3.65} lg={3.65}>
                                                                <CustomInputField name={`dd`} label={'dd'} placeholder={config?.validationFields?.dob?.datePlaceholder} fullWidth
                                                                    labelStyle={{ opacity: 0 }} />
                                                            </Grid>
                                                            <Grid height={"min-content"} item xs={3.8} sm={3.8} md={3.65} lg={3.65}>
                                                                <CustomInputField name={`yyyy`} label={'yyyy'} placeholder={config?.validationFields?.dob?.yearPlaceholder} fullWidth labelStyle={{ opacity: 0 }} />
                                                            </Grid>
                                                        </Grid>
                                                        {shiftSteps && <Grid height={"min-content"} item xs={12} sm={12} md={12} lg={12}>
                                                            <CustomInputField name={`bio`} placeholder={"One liner like your linkedin headline."} label={<>{"BIO"}<span style={{ color: theme?.palette?.customColors?.g100, textTransform: "none" }}> (Optional)</span></>} fullWidth />
                                                        </Grid>}
                                                        <Grid item xs={12}>
                                                            {!isLoadingCountries && !isRefetchingCountries ? <CustomAutoComplete
                                                                options={!isLoadingCountries && !isRefetchingCountries ? countries : []}
                                                                label="SELECT COUNTRY"
                                                                name={"country_id"}
                                                                noOptionsText={!isLoadingCountries && !isRefetchingCountries ? "No Options" : "Searching..."}
                                                                placeholder="Type a country name"
                                                                icon={gpsIcon}
                                                                handleSearchChange={handleSearchCountryChange}
                                                                handleSelectionChange={handleSelectionCountryChange}
                                                                search={searchCountry}
                                                                value={currentCountry}
                                                                defaultValue={countries?.find(item => item?._id == formik?.values?.country_id)}
                                                                loading={isRefetchingCountries}
                                                                handleSearchUpdate={handleSearchUpdateCountry}
                                                            /> : <>
                                                                <Box className="skeleton" sx={{ height: 17.6, width: '100%', marginBottom: 1 }}></Box>
                                                                <Box className="skeleton" sx={{ height: 50.6, width: '100%', marginBottom: 2 }}></Box>
                                                            </>}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {!isLoadingStates && !isRefetchingStates ? <CustomAutoComplete
                                                                options={!isLoadingStates && !isRefetchingStates && states?.length > 0 ? states : []}
                                                                label={`SELECT STATE`}
                                                                placeholder="Type a state name"
                                                                name="state_id"
                                                                noOptionsText={!isLoadingStates && !isRefetchingStates ? "No Options" : "Searching..."}
                                                                icon={gpsIcon}
                                                                handleSearchChange={handleSearchStateChange}
                                                                handleSelectionChange={handleSelectionStateChange}
                                                                search={searchState}
                                                                value={currentState?.label}
                                                                defaultValue={states?.find(item => item?._id === formik?.values?.state_id)?.label}
                                                                loading={false}
                                                                handleSearchUpdate={handleSearchUpdateState}
                                                            /> : <>
                                                                <Box className="skeleton" sx={{ height: 17.6, width: '100%', marginBottom: 1 }}></Box>
                                                                <Box className="skeleton" sx={{ height: 50.6, width: '100%', marginBottom: 2 }}></Box>
                                                            </>}
                                                        </Grid>
                                                        {/* <Grid item xs={12}>
                                                            {(currentState !== null
                                                                ? states?.find(item => item?._id == formik.values?.state_id)?.value
                                                                : currentState?.value) &&
                                                                <CustomAutoComplete
                                                                    options={!isLoadingCities && !isRefetchingCities && cities?.length > 0 ? cities : []}
                                                                    noOptionsText={!isLoadingCities && !isRefetchingCities ? "No Options" : "Searching..."}
                                                                    label={`SELECT CITY`}
                                                                    placeholder="Type a city name"
                                                                    name="city_id"
                                                                    icon={gpsIcon}
                                                                    handleSearchChange={handleSearchCityChange}
                                                                    handleSelectionChange={handleSelectionCityChange}
                                                                    search={search}
                                                                    value={currentCity?.label}
                                                                    defaultValue={cities?.find(item => item?._id === formik?.values?.city_id)?.label}
                                                                    handleSearchUpdate={handleSearchUpdateCity}
                                                                />}
                                                        </Grid> */}
                                                        <Grid item xs={12}>
                                                            {(currentState !== null
                                                                ? states?.find(item => item?._id == formik.values?.state_id)?.value
                                                                : currentState?.value) && (
                                                                    <CustomAutoComplete
                                                                        options={!isLoadingCities && !isRefetchingCities && cities?.length > 0 ? cities : []}
                                                                        noOptionsText={!isLoadingCities && !isRefetchingCities ? "No Options" : "Searching..."}
                                                                        label="SELECT CITY"
                                                                        placeholder="Type a city name"
                                                                        name="city_id"
                                                                        icon={gpsIcon}
                                                                        handleSearchChange={handleSearchCityChange}
                                                                        handleSelectionChange={handleSelectionCityChange}
                                                                        search={search}
                                                                        value={cities?.find(item => item?._id === formik.values?.city_id) || null} // Synchronize with formik.values
                                                                        handleSearchUpdate={handleSearchUpdateCity}
                                                                    />
                                                                )}
                                                        </Grid>
                                                        <Grid item xs={12} position="relative" pt={0}>
                                                            <CustomUrlField
                                                                label={config?.validationFields?.linkedIn_profile_url?.label}
                                                                name={'linkedIn_profile_url'}
                                                                value={
                                                                    String(formik?.values?.linkedIn_profile_url)?.startsWith("https://")
                                                                        ? String(formik?.values?.linkedIn_profile_url)?.replace("https://", "")
                                                                        : formik?.values?.linkedIn_profile_url
                                                                }
                                                                placeholder={config?.validationFields?.linkedIn_linkedIn_profile_url?.placeholder}
                                                                onChange={(evt) => {

                                                                    formik.setFieldValue("linkedIn_profile_url", String(evt.target.value)?.startsWith("https://")
                                                                        ? String(evt.target.value)?.replace("https://", "")
                                                                        : evt.target.value)
                                                                }}
                                                            />
                                                        </Grid>
                                                        {shiftSteps && <Grid height={"min-content"} item xs={12}>
                                                            <CustomPhone name={`phone_number`} selectedCode={formik.values?.country_code ? formik.values?.country_code : ""} handleChange={(event) => formik.setFieldValue("country_code", event.target.value)} countryCodes={countriesWithPhoneCodes ? countriesWithPhoneCodes : []} label='Contact Number' placeholder="Enter your contact number" onChange={(e) => formik.setFieldValue(`phone_number`, e.target.value)} />
                                                        </Grid>}
                                                        {<Grid height="min-content" item xs={12} sm={12} md={12} lg={12} pt={0}>
                                                            <CustomPhone
                                                                name="whatsapp_number"
                                                                label="WHATSAPP NUMBER"
                                                                selectedCode={formik.values?.phone_code ? formik.values?.phone_code : ""} handleChange={(event) => formik.setFieldValue("phone_code", event.target.value)} countryCodes={countriesWithPhoneCodes ? countriesWithPhoneCodes : []}
                                                                fullWidth
                                                                InputProps={{
                                                                    ...currencyInputProps,
                                                                }}
                                                                onChange={handleMobileChange}
                                                                value={formik.values.whatsapp_number}
                                                            />
                                                        </Grid>}
                                                    </>
                                                )}
                                                {currentIndex === 1 && !shiftSteps && (
                                                    <Grid container item xs={12} gap={0} paddingBottom={0} paddingRight={1} marginTop={'0rem'} >
                                                        {/* <InvestorTypes isSticky={false} /> */}
                                                        <OrganizationDetails investor_type={formik.values?.investor_type} />
                                                        {/* <UploadOrgPhoto /> */}
                                                        {/* <PartnersDetail /> */}
                                                    </Grid>
                                                )}
                                                {/* {currentIndex === 1 && (
                                                    <Grid container item xs={12} gap={0} paddingBottom={0} paddingRight={1} marginTop={'0rem'} >
                                                        <InvestorTypes isSticky={false} />
                                                    </Grid>
                                                )} */}
                                                {(shiftSteps ? currentIndex == 1 : currentIndex == 2) && (
                                                    <>
                                                        <Grid container item xs={12} gap={0} paddingBottom={0} paddingRight={1} marginTop={'0rem'} >
                                                            <Grid item xs={12}>
                                                                <CustomLabel sx={{ marginTop: "0rem" }}>Select Category (Multi Select) {formik.errors?.category_id && (
                                                                    <CustomValidationText value={formik.errors?.category_id} style={{ textTransform: 'none' }} />
                                                                )}</CustomLabel>
                                                            </Grid>
                                                            <FieldArray
                                                                name="category_id"
                                                                render={(arrayHelpers) => (
                                                                    <>
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            style={{
                                                                                display: 'flex',
                                                                                flexWrap: 'wrap',
                                                                                padding: 0,
                                                                                paddingBottom: 1,
                                                                                marginTop: 0,
                                                                                justifyContent: 'space-between',
                                                                            }}
                                                                            gap={2}
                                                                        >
                                                                            {Array.isArray(startup_categories) &&
                                                                                startup_categories?.map((category, index) => {
                                                                                    const isSelected = formik?.values?.category_id?.includes(category?._id);

                                                                                    const handleCheckboxChange = () => {
                                                                                        if (isSelected) {
                                                                                            const idx = formik.values.category_id.indexOf(category?._id);
                                                                                            arrayHelpers.remove(idx);
                                                                                        } else {
                                                                                            arrayHelpers.push(category?._id);
                                                                                        }
                                                                                    };

                                                                                    return (
                                                                                        <Grid
                                                                                            item
                                                                                            xs={12}
                                                                                            sm={5.8}
                                                                                            md={5.75}
                                                                                            key={category?._id}
                                                                                            onClick={handleCheckboxChange}
                                                                                            style={{
                                                                                                display: 'flex',
                                                                                                alignItems: 'center',
                                                                                                gap: '0.5rem',
                                                                                                background: isSelected ? '#FFFAE6' : '#FFF',
                                                                                                fontSize: '0.875rem',
                                                                                                border: `1px solid ${isSelected ? '#FFC900' : '#A1A3A7'}`,
                                                                                                borderRadius: '8px',
                                                                                                paddingRight: '1rem',
                                                                                                paddingLeft: '1rem',
                                                                                                paddingBlock: '1.5rem',
                                                                                                height: '2.5rem',
                                                                                                cursor: 'pointer',
                                                                                                textTransform: 'none',
                                                                                                color: 'inherit',
                                                                                            }}
                                                                                        >
                                                                                            {index === 0 && (
                                                                                                <img
                                                                                                    src={laptopScreenIcon}
                                                                                                    style={{ height: '1.6rem', width: '1.6rem', marginRight: '0.5rem' }}
                                                                                                    alt="icon"
                                                                                                />
                                                                                            )}
                                                                                            {index === 1 && (
                                                                                                <img
                                                                                                    src={mobileShoppingIcon}
                                                                                                    style={{ height: '1.4rem', width: '1.4rem', marginRight: '0.5rem' }}
                                                                                                    alt="icon"
                                                                                                />
                                                                                            )}
                                                                                            {index === 2 && (
                                                                                                <img
                                                                                                    src={marketplaceIcon}
                                                                                                    style={{ height: '1.4rem', width: '1.4rem', marginRight: '0.5rem' }}
                                                                                                    alt="icon"
                                                                                                />
                                                                                            )}
                                                                                            {index === 3 && (
                                                                                                <img
                                                                                                    src={digitalServiceIcon}
                                                                                                    style={{
                                                                                                        height: '1.4rem',
                                                                                                        width: '1.4rem',
                                                                                                        marginRight: '0.5rem',
                                                                                                        transform: 'rotateY(180deg)',
                                                                                                    }}
                                                                                                    alt="icon"
                                                                                                />
                                                                                            )}

                                                                                            <span>{category?.name}</span>
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                checked={isSelected}
                                                                                                readOnly
                                                                                                style={{ marginLeft: 'auto' }}
                                                                                            />

                                                                                        </Grid>
                                                                                    );
                                                                                })}
                                                                        </Grid>
                                                                    </>
                                                                )}
                                                            />
                                                            <Grid item xs={12} mt={'1rem'}>
                                                                <CustomAutoComplete
                                                                    label={<Box display="flex" alignItems="center" gap={1}>
                                                                        <span>SELECT SECTORS (MULTI SELECT)</span>
                                                                        <FormControlLabel
                                                                            label="SECTOR AGNOSTIC"
                                                                            labelPlacement="start"
                                                                            control={
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={isSectorAgnostic}
                                                                                    onChange={(e) => setIsSectorAgnostic(e.target.checked)}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />

                                                                            }
                                                                            sx={{
                                                                                marginLeft: 'auto',
                                                                                marginRight: '0px',
                                                                                gap: "6px",
                                                                                '& .MuiFormControlLabel-label': {
                                                                                    fontSize: '0.875rem',
                                                                                    color: '#0f1319',
                                                                                },
                                                                            }}
                                                                            onClick={() => { formik.setFieldValue('sector_id', []); setCurrentSubCategory([]) }}
                                                                        />
                                                                    </Box>}
                                                                    options={availableOptions.map(item => ({
                                                                        label: item.sub_category,
                                                                        value: item._id,
                                                                        description: item.description,
                                                                    }))}
                                                                    // sx={{ ":disabled": {bgcolor: "white"} }}
                                                                    disabled={isSectorAgnostic}
                                                                    //   label="Type a sector name"
                                                                    name="sector_id"
                                                                    multiple={true}
                                                                    noOptionsText={
                                                                        !isLoadingSubCategories && !isRefetchingSubCategories
                                                                            ? 'No Options'
                                                                            : 'Searching...'
                                                                    }
                                                                    placeholder={isSectorAgnostic ? "Sector Agnostic" : "Type a sector name"}
                                                                    onInputChange={(event, value) =>
                                                                        handleSearchSubCategoryChange(event, value)
                                                                    }
                                                                    handleSelectionChange={handleSelectionSubCategoryChange}
                                                                    value={currentSubCategory}
                                                                    renderOption={(props, option) => (
                                                                        <li {...props} style={{ display: 'grid' }}>
                                                                            <span style={{ fontWeight: 600 }}>{option.label}</span>
                                                                            <p style={{ fontSize: '0.8rem' }}>{option.description}</p>
                                                                        </li>
                                                                    )}
                                                                    loading={isRefetchingSubCategories}
                                                                    handleSearchUpdate={handleSearchUpdateSubCategory}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {/* {console.log(isBusinessModelSelectAll,"isBusinessModelSelectAllisBusinessModelSelectAll")} */}

                                                                <CustomAutoComplete
                                                                    label={<Box display="flex" alignItems="center" gap={1}>
                                                                        <span>SELECT BUSINESS MODEL (MULTI SELECT)</span>
                                                                        <FormControlLabel
                                                                            label="SELECT ALL"
                                                                            labelPlacement="start"
                                                                            control={
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={isBusinessModelSelectAll}
                                                                                    onChange={(e) => setIsBusinessModelSelectAll(e.target.checked)}
                                                                                    style={{ cursor: 'pointer' }}

                                                                                />

                                                                            }

                                                                            sx={{
                                                                                marginLeft: 'auto',
                                                                                marginRight: '0px',
                                                                                gap: "6px",
                                                                                '& .MuiFormControlLabel-label': {
                                                                                    fontSize: '0.875rem',
                                                                                    color: '#0f1319',
                                                                                },
                                                                            }}
                                                                            onClick={() => { formik.setFieldValue('business_model_id', []); setCurrentBusinessModel([]) }}
                                                                        />
                                                                    </Box>

                                                                    }
                                                                    options={!isLoadingBusinessModel && !isRefetchingBusinessModel && Array.isArray(availableOptionsBusinessModel) ? availableOptionsBusinessModel?.map(item => ({ label: item?.name, value: item?._id, description: item?.description })) : []}
                                                                    name={"business_model_id"}
                                                                    disabled={isBusinessModelSelectAll}

                                                                    multiple={true}
                                                                    noOptionsText={!isLoadingBusinessModel && !isRefetchingBusinessModel ? "No Options" : "Searching..."}
                                                                    placeholder={isBusinessModelSelectAll ? "All" : "Business model"}
                                                                    // icon={null}
                                                                    handleSearchChange={handleSearchBusinessModelChange}
                                                                    handleSelectionChange={handleSelectionBusinessModelChange}
                                                                    search={searchBusinessModel}
                                                                    renderOption={(props, option) => (
                                                                        <li {...props} style={{ display: 'grid' }}>
                                                                            <span style={{ fontWeight: 600 }}>{option.label}</span>
                                                                            <p style={{ fontSize: '0.8rem' }}>{option?.description}</p>
                                                                            {/* {option.isSpecial && <span className="special-badge">Special</span>} */}
                                                                        </li>
                                                                    )}
                                                                    value={currentBusinessModel || []}
                                                                    defaultValue={startup_business_model?.filter(item => item?._id == formik?.values?.business_model_id)}
                                                                    loading={isRefetchingBusinessModel}
                                                                    handleSearchUpdate={handleSearchUpdateBusinessModel}
                                                                />
                                                            </Grid>
                                                            <InvestmentRange isLoading={isLoading} />
                                                            {/* <Grid container item alignItems="center" justifyContent="space-between">
                                                                <Grid item>
                                                                    <CustomLabel>{config?.validationFields?.size?.label}</CustomLabel>
                                                                </Grid>

                                                                <Grid item display="flex" alignItems="center">
                                                                    <Select
                                                                        labelId="currency-label"
                                                                        id="currency-select"
                                                                        value={currency}
                                                                        defaultValue={currency}
                                                                        onChange={handleCurrencyChange}
                                                                        sx={{
                                                                            border: 'none',
                                                                            color: '#344054',
                                                                            fontSize: '0.875rem',
                                                                            borderRadius: 0,
                                                                            padding: 0,
                                                                            margin: 0,
                                                                            minWidth: '80px',
                                                                            boxShadow: 'none',
                                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                                border: 'none',
                                                                            },
                                                                            '&:focus': {
                                                                                outline: 'none',
                                                                            }
                                                                        }}
                                                                    >
                                                                        {
                                                                            currencyOptions?.map(item => {
                                                                                return (
                                                                                    <MenuItem value={item.name}>{item.name}</MenuItem>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Slider
                                                                    sx={{
                                                                        paddingBlock: '3rem 3rem',
                                                                        marginLeft: currency === "INR" ? '1rem' : "1.5rem",
                                                                        marginRight: '2rem',
                                                                        maxWidth: '95%',
                                                                        color: theme?.palette?.customColors.indigo600,
                                                                        '& .MuiSlider-thumb': {
                                                                            width: 20,
                                                                            height: 20,
                                                                            backgroundColor: '#ffffff',
                                                                            border: `2px solid ${theme?.palette?.customColors.indigo600}`,
                                                                            '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                                boxShadow: '0px 0px 0px 8px rgba(25, 118, 210, 0.16)',
                                                                            },
                                                                        },
                                                                        '& .MuiSlider-track': {
                                                                            height: 8,
                                                                            borderRadius: 4,
                                                                        },
                                                                        '& .MuiSlider-rail': {
                                                                            height: 8,
                                                                            borderRadius: 4,
                                                                            opacity: 0.3,
                                                                            backgroundColor: '#bfbfbf',
                                                                        },
                                                                        '& .MuiSlider-markLabel': {
                                                                            fontSize: '0.875rem',
                                                                            color: '#333',
                                                                            top: '15px !important'
                                                                        },
                                                                        '& .MuiSlider-valueLabel': {
                                                                            backgroundColor: '#1976d2',
                                                                            fontSize: '0.75rem',
                                                                            top: -10,
                                                                            display: "none",
                                                                        },
                                                                    }}
                                                                    getAriaLabel={() => 'Investment Size'}
                                                                    value={value1}
                                                                    min={0}
                                                                    max={getMarks(currency)?.length - 1}
                                                                    step={1}
                                                                    marks={getMarks(currency)}
                                                                    onChange={handleChange1}
                                                                    valueLabelDisplay="auto"
                                                                    disableSwap
                                                                />
                                                            </Grid>  */}

                                                        </Grid>
                                                    </>
                                                )}

                                                {(shiftSteps ? currentIndex == 2 : currentIndex == 3) && (
                                                    <>
                                                        <Grid item>
                                                            <CustomLabel sx={{ marginTop: "0rem" }}>{config?.validationFields?.stages?.label} (MULTI SELECT) {formik.errors.stage_id && (
                                                                <CustomValidationText value={formik.errors.stage_id} />
                                                            )}</CustomLabel>
                                                        </Grid>
                                                        <Grid item container xs={12} gap={2} display={"flex"} paddingBottom={4}>
                                                            {startup_stages?.map((stage, index) => {
                                                                const isSelected = formik.values.stage_id?.includes(stage?._id);
                                                                return (
                                                                    <Grid key={index}
                                                                        onClick={() => {
                                                                            if (isSelected) {
                                                                                selectedStages.splice(formik.values.stage_id.indexOf(stage?._id), 1);
                                                                            } else {
                                                                                selectedStages.push(stage?._id);
                                                                            }
                                                                            formik.setFieldValue("stage_id", selectedStages)
                                                                        }
                                                                        } item xs={12} sm={5.8} md={5.6} lg={5.75} flex={1} >
                                                                        <button key={index} onClick={(e) => e.preventDefault()} style={{
                                                                            minHeight: "2.5rem",
                                                                            height: "100%",
                                                                            maxHeight: '4rem',
                                                                            width: "100%",
                                                                            padding: "1rem 0rem 6rem 0.5rem",
                                                                            background: isSelected ? "#FFFAE6" : "#FFF",
                                                                            fontSize: "0.875rem",
                                                                            cursor: 'pointer',
                                                                            border: `1px solid ${isSelected ? "#FFC900" : "#A1A3A7"}`,
                                                                            borderRadius: "8px",
                                                                            overflow: "hidden",
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                            position: 'relative'
                                                                        }}>
                                                                            <span style={{ position: 'absolute', fontWeight: 600, top: "5px" }}>
                                                                                {stage?.name}
                                                                            </span>
                                                                            <img src={stage?.image_url} rel='preload' style={{ ...stage?.size, marginLeft: 'auto' }} />
                                                                        </button>
                                                                    </Grid>
                                                                )
                                                            }
                                                            )}
                                                        </Grid>
                                                    </>
                                                )}

                                                {!shiftSteps && currentIndex === 4 && (
                                                    <>
                                                        <Grid container item xs={12} gap={0} paddingBottom={0} paddingRight={1} marginTop={'0rem'} >
                                                            {/* <InvestorTypes isSticky={false} /> */}
                                                            {/* <OrganizationDetails /> */}
                                                            {/* <UploadOrgPhoto /> */}
                                                            <PartnersDetail />
                                                        </Grid>
                                                    </>
                                                )}

                                                {/* {currentIndex == 2 && (
                                                    <>
                                                        <Grid container item alignItems="center" justifyContent="space-between">
                                                            <Grid item>
                                                                <CustomLabel>{config?.validationFields?.size?.label}</CustomLabel>
                                                            </Grid>

                                                            <Grid item display="flex" alignItems="center">
                                                                <Select
                                                                    labelId="currency-label"
                                                                    id="currency-select"
                                                                    value={currency}
                                                                    onChange={handleCurrencyChange}
                                                                    sx={{
                                                                        border: 'none',
                                                                        color: '#344054',
                                                                        fontSize: '0.875rem',
                                                                        borderRadius: 0,
                                                                        padding: 0,
                                                                        margin: 0,
                                                                        minWidth: '80px',
                                                                        boxShadow: 'none',
                                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                                            border: 'none',
                                                                        },
                                                                        '&:focus': {
                                                                            outline: 'none',
                                                                        }
                                                                    }}
                                                                >
                                                                    {
                                                                        currencyOptions?.map(item => {
                                                                            return (
                                                                                <MenuItem value={item.name}>{item.name}</MenuItem>
                                                                            )
                                                                        })
                                                                    }
                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            display={"flex"}
                                                            justifyContent={"space-between"}
                                                            flexWrap={"wrap"}
                                                            item
                                                            container
                                                            xs={12}
                                                            gap={"1rem"}
                                                        >
                                                            {amountRanges?.map((item, index) => {
                                                                return (
                                                                    <Grid
                                                                        // role={"button"}
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={12}
                                                                        lg={12}
                                                                    >
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => handleRangeSelect(item)}
                                                                            style={{
                                                                                background: selectedRange === item.title ? theme?.palette?.customColors?.y50
                                                                                    : theme?.palette?.customColors
                                                                                        ?.whiteBG,
                                                                                padding: "12px 16px",
                                                                                height: 55,
                                                                                borderRadius: "8px",
                                                                                display: "grid",
                                                                                placeItems: "center",
                                                                                border: `1px solid ${selectedRange === item.title
                                                                                    ? theme?.palette?.customColors?.y300
                                                                                    : theme?.palette?.customColors?.g75
                                                                                    }`,

                                                                                boxShadow: "0px 1px 2px 0px #1018280D",
                                                                                cursor: "pointer",
                                                                                fontSize: "0.875rem",
                                                                                color: theme.palette.customColors.g200,
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            {item.title}
                                                                        </button>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                        <Grid item>
                                                            <CustomLabel sx={{ marginTop: "1.5rem" }}>{config?.validationFields?.investment_count?.label} </CustomLabel>
                                                        </Grid>
                                                        <Grid container item xs={12} gap={0} marginTop={0} display={"flex"} alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"space-between"}>
                                                            <Grid item container xs={12} display={"flex"} gap={0} justifyContent={"space-between"} flexDirection={"row"}>
                                                                <Grid
                                                                    display={"flex"}
                                                                    flexWrap={"wrap"}
                                                                    justifyContent={"space-between"}
                                                                    item
                                                                    container
                                                                    xs={12}
                                                                    gap={{ xs: 0.5, sm: 1, md: 2 }}
                                                                    mb={2}
                                                                >
                                                                    {number_investment?.map((num, index) => {
                                                                        const isSecondLast = index === number_investment.length - 2;
                                                                        const isLast = index === number_investment.length - 1;
                                                                        return (
                                                                            <Grid
                                                                                role={"button"}
                                                                                item
                                                                                xs={isSecondLast || isLast ? 12 : 2}
                                                                                sm={isSecondLast || isLast ? 12 : 2}
                                                                                md={isSecondLast || isLast ? 12 : 2}
                                                                                lg={isSecondLast || isLast ? 12 : 2.1}
                                                                                onClick={() => {
                                                                                    formik.setFieldValue(
                                                                                        "investment_count",
                                                                                        num?.value
                                                                                    );
                                                                                }}
                                                                                sx={{
                                                                                    background:
                                                                                        formik.values.investment_count ==
                                                                                            num?.value
                                                                                            ? theme?.palette?.customColors?.y50
                                                                                            : theme?.palette?.customColors?.whiteBG,
                                                                                    padding: "12px 16px",
                                                                                    height: 55,
                                                                                    borderRadius: "8px",
                                                                                    display: "grid",
                                                                                    placeItems: "center",
                                                                                    border: `1px solid ${formik.values.investment_count ==
                                                                                        num?.value
                                                                                        ? theme?.palette?.customColors?.y300
                                                                                        : theme?.palette?.customColors?.g75
                                                                                        }`,
                                                                                    boxShadow: "0px 1px 2px 0px #1018280D",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            >
                                                                                <Typography
                                                                                    variant="subtitle2"
                                                                                    color={theme.palette.customColors.g200}
                                                                                    fontSize="0.875rem"
                                                                                >
                                                                                    {num?.label}
                                                                                </Typography>
                                                                            </Grid>
                                                                        );
                                                                    })}
                                                                </Grid>

                                                            </Grid>

                                                        </Grid>
                                                        <Grid item>
                                                            <CustomLabel sx={{ marginTop: "0.5rem" }}>{config?.dynamicText?.investmentSubHeading}</CustomLabel>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            container
                                                            xs={12}
                                                            display="flex"
                                                            gap={0}
                                                            justifyContent="space-between"
                                                            flexDirection="column"
                                                            paddingBottom={2}
                                                        >
                                                            <FieldArray
                                                                name="startups"
                                                                render={(arrayHelpers) => (
                                                                    <>
                                                                        {formik.values?.startups?.length > 0 &&
                                                                            formik.values?.startups?.map((current, index) => (
                                                                                <Box key={index}>
                                                                                    <Accordion defaultExpanded={formik.values?.startups?.length - 1 === index} sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                                                                                        <AccordionSummary
                                                                                            expandIcon={<ArrowDown height={20} width={20} stroke={theme?.palette?.customColors?.g100} />}
                                                                                            aria-controls="panel1-content"
                                                                                            id="panel1-header"
                                                                                            sx={{ fontWeight: '700' }}
                                                                                        >
                                                                                            Startup {index + 1}
                                                                                        </AccordionSummary>
                                                                                        <AccordionDetails>
                                                                                            <>
                                                                                                <Grid item xs={12} position="relative" pt={1}>
                                                                                                    <CustomInputField
                                                                                                        label="STARTUP NAME"
                                                                                                        name={`startups.${index}.startup_name`}
                                                                                                        value={current?.startup_name}
                                                                                                        placeholder="Startup Name"
                                                                                                        onChange={(e) =>
                                                                                                            formik.setFieldValue(
                                                                                                                `startups.${index}.startup_name`,
                                                                                                                e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1)
                                                                                                            )
                                                                                                        }
                                                                                                    />
                                                                                                </Grid>
                                                                                                <Box>
                                                                                                    <Grid item xs={12} display="flex">
                                                                                                        <CustomUrlField
                                                                                                            label="STARTUP WEBSITE URL"
                                                                                                            name={`startups.${index}.startup_url`}
                                                                                                            value={current?.startup_url}
                                                                                                            onChange={(e) =>
                                                                                                                formik.setFieldValue(
                                                                                                                    `startups.${index}.startup_url`,
                                                                                                                    String(e.target.value)?.startsWith("https://")
                                                                                                                        ? String(e.target.value)?.replace("https://", "")
                                                                                                                        : e.target.value
                                                                                                                )
                                                                                                            }
                                                                                                        />
                                                                                                    </Grid>
                                                                                                </Box>
                                                                                                {formik.values?.startups?.length > 1 && <Box>
                                                                                                    <Typography variant="caption" color="#000" sx={{ textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => {
                                                                                                        formik.setFieldValue(`startups.${index}`, { ...current, edit: false }).then(() =>
                                                                                                            arrayHelpers.remove(index)
                                                                                                        );
                                                                                                    }}>
                                                                                                        Remove startup
                                                                                                    </Typography>
                                                                                                </Box>}
                                                                                            </>
                                                                                        </AccordionDetails>
                                                                                    </Accordion>
                                                                                    {formik.values?.startups?.length - 1 === index && (
                                                                                        <>
                                                                                            <Box
                                                                                                sx={{
                                                                                                    display: "flex",
                                                                                                    alignItems: "center",
                                                                                                    gap: 1,
                                                                                                    justifyContent: "space-between",
                                                                                                }}
                                                                                            >
                                                                                                <Box
                                                                                                    sx={{ cursor: "pointer" }}
                                                                                                    onClick={() => {
                                                                                                        if (current?.startup_name !== "" && current?.startup_url !== "") {
                                                                                                            formik.setFieldValue(`startups.${index}`, { ...current, edit: false }).then(() =>
                                                                                                                arrayHelpers.insert(index + 1, {
                                                                                                                    startup_name: "",
                                                                                                                    startup_url: "",
                                                                                                                    edit: true,
                                                                                                                })
                                                                                                            );
                                                                                                        } else {
                                                                                                            formik.validateForm()
                                                                                                        }
                                                                                                    }}
                                                                                                >
                                                                                                    <IconButton>
                                                                                                        <Tooltip title="Add next startup">
                                                                                                            <img src={AddIcon} rel="preload" width={32} height={32} />
                                                                                                        </Tooltip>
                                                                                                    </IconButton>
                                                                                                    <Typography marginLeft="1rem" variant="caption" color="#000">
                                                                                                        Add another startup
                                                                                                    </Typography>
                                                                                                </Box>
                                                                                            </Box>
                                                                                            <Box sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent: "space-between" }}>
                                                                                                <Typography
                                                                                                    color={theme?.palette.customColors.b300}
                                                                                                    fontSize="1rem"
                                                                                                >
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        </>
                                                                                    )}
                                                                                    {formik.values?.startups?.length > 1 && <Divider sx={{ marginTop: 1, marginBottom: 1 }} />}
                                                                                </Box>
                                                                            ))}
                                                                    </>
                                                                )}
                                                            />
                                                        </Grid>
                                                    </>

                                                )} */}

                                                {/* {currentIndex == 4 && (
                                                    <>
                                                        <Grid item>
                                                            <CustomLabel sx={{ marginTop: "1.5rem" }}>{config?.dynamicText?.whatsappSettingSubHeading}</CustomLabel>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            // role="button"
                                                            alignItems="center"
                                                            xs={12}
                                                            sx={{
                                                                background: `${theme?.palette?.customColors?.y50}`,
                                                                padding: "0px 16px",
                                                                height: 55,
                                                                borderRadius: "8px",
                                                                display: "grid",
                                                                gridTemplateColumns: "auto 1fr auto",
                                                                border: `1px solid ${theme?.palette?.customColors?.y300}`,
                                                                boxShadow: "0px 1px 2px 0px #1018280D",
                                                                cursor: "pointer",
                                                                marginLeft: '0.5rem'
                                                            }}
                                                        >
                                                            <img src={whatsapp_icon} alt='whats' width={20} height={20} style={{ marginRight: '0.5rem' }}></img>
                                                            <Typography
                                                                variant="subtitle2"
                                                                color={theme.palette.customColors.g200}
                                                                fontSize="0.875rem"
                                                            >
                                                                Enable WhatsApp notification
                                                            </Typography>
                                                            <Switch checked={isWhatsAppEnabled} onChange={handleToggle} />
                                                        </Grid>
                                                        {isWhatsAppEnabled && (
                                                            <>
                                                                <Grid height="min-content" item xs={12} sm={12} md={12} lg={12} pt={4}>
                                                                    <CustomInputField name="whatsapp_number" label="MOBILE NUMBER" fullWidth />
                                                                </Grid>
                                                                <Typography
                                                                    item
                                                                    color={theme.palette.customColors.b300}
                                                                    fontSize="1rem"
                                                                    sx={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        textDecoration: 'underline',
                                                                        textUnderlineOffset: '5px',
                                                                        marginTop: '0.5rem',
                                                                        cursor: 'pointer',
                                                                        marginLeft: '0.5rem'
                                                                    }}
                                                                    onClick={handleVerify}
                                                                >
                                                                    <span>Verify mobile number</span>
                                                                </Typography>
                                                                {isVerify && (
                                                                    <>
                                                                        <Grid container item xs={12} display={'grid'} sx={{ placeItems: "flex-start", placeContent: "stretch", gap: "1rem" }}>
                                                                            <Typography color={"gray"} variant="subtitle2" mt={"2rem"} mb={"0.5rem"}>ENTER OTP</Typography>
                                                                            <Grid container xs={12} gap={2}>
                                                                                {inputRefs?.map((ref, index) => (
                                                                                    <Grid item flex={1} key={index}>
                                                                                        <CustomInputFieldOTP
                                                                                            inputProps={inputProps}
                                                                                            required
                                                                                            sx={{ marginBottom: 0 }}
                                                                                            inputRef={ref}
                                                                                            onChange={(event) => handleInputChange(event, index)}
                                                                                            onKeyDown={(event) => handleKeyDown(event, index)}
                                                                                            onPaste={index === 0 ? handlePaste : undefined}
                                                                                        />
                                                                                    </Grid>
                                                                                ))}
                                                                            </Grid>
                                                                            {error !== null && <CustomValidationText style={{ marginTop: "-0.5rem" }} value={error} />}
                                                                            {isLoading ? (
                                                                                <Typography color={theme.palette.customColors.b300} sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: '1rem' }} fontSize="1rem">
                                                                                    <CircularProgress sx={{ color: theme.palette.customColors.b300 }} size="1rem" /> <span>Resending OTP</span>
                                                                                </Typography>
                                                                            ) : (
                                                                                <Typography color={theme.palette.customColors.b300} fontSize="1rem" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'underline', textUnderlineOffset: '5px', marginTop: '1rem' }}>
                                                                                    <span onClick={countDown === 0 ? handleResendingOTP : undefined} style={{ color: countDown > 0 ? theme.palette.customColors.b75 : theme.palette.customColors.b300, cursor: 'pointer' }}>{config?.dynamicText?.resend}</span>
                                                                                    <span>&nbsp;{countDown > 0 && `(${countDown} Sec)`}</span>
                                                                                </Typography>
                                                                            )}
                                                                        </Grid>
                                                                    </>
                                                                )}

                                                            </>
                                                        )}
                                                        <Grid item xs={12}>
                                                            <CustomLabel sx={{ marginTop: "1.5rem" }}>{config?.dynamicText?.linkedInSubHeading}</CustomLabel>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            item
                                                            xs={12}
                                                            gap={1}
                                                            paddingBottom={2}

                                                        >

                                                            <Grid item xs={12} position="relative" pt={1}>
                                                                <CustomInputField
                                                                    label={config?.validationFields?.linkedIn_profile_url?.label}
                                                                    name={'profile_url'}
                                                                    value={formik?.values?.profile_url}
                                                                    placeholder={config?.validationFields?.linkedIn_profile_url?.placeholder}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                role="button"
                                                                alignItems="center"
                                                                display={'flex'}
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                sx={{
                                                                    background: `${theme?.palette?.customColors?.y50}`,
                                                                    padding: "1px 16px",
                                                                    height: 55,
                                                                    borderRadius: "8px",
                                                                    display: "grid",
                                                                    gridTemplateColumns: "auto 1fr auto",
                                                                    border: `1px solid ${theme?.palette?.customColors?.y300}`,
                                                                    boxShadow: "0px 1px 2px 0px #1018280D",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <img src={eye_icon} alt='whats' width={20} height={20} style={{ marginRight: '0.5rem' }} />

                                                                <Typography
                                                                    variant="subtitle2"
                                                                    fontWeight={400}
                                                                    color={theme.palette.customColors.g300}
                                                                    fontSize="1rem"
                                                                >
                                                                    {config?.dynamicText?.enableDiscover}
                                                                </Typography>
                                                                <Switch checked={isPublicEnabled} onChange={handleTogglePublic} />
                                                            </Grid>
                                                            <Typography variant='caption' fontWeight={400} pt={1} fontSize={'14px'}>
                                                                {config?.dynamicText?.dicoverMessage}
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                )} */}
                                            </Grid>
                                        </PerfectScrollbar>
                                    </Form>
                                </FormikProvider>
                                <Grid
                                    item
                                    container
                                    display={"flex"}
                                    xs={12}
                                    sm={12}
                                    height={"min-content"}
                                    gap={"1.5rem"}
                                    justifyContent={"center"}
                                    padding={"0 0rem 1rem 0rem"}
                                >
                                    {currentIndex !== 0 && <Grid xs={5} sm={5} md={5.5} lg={5.7} item>
                                        <BLUEBORDER
                                            sx={{
                                                textTransform: "none",
                                                padding: 2,
                                                borderWidth: 1,
                                                borderRadius: "8px",
                                            }}
                                            type="no usetr"
                                            onClick={() => {
                                                if (currentIndex === 0) {
                                                    // navigate(-1);
                                                } else {
                                                    setCurrentIndex(currentIndex - 1);
                                                }
                                                window.document
                                                    .getElementById("scrollable-form")
                                                    .scrollTo(0, 0);
                                                // window.scrollTo(0, 0);
                                            }}
                                            fullWidth
                                        >
                                            Back
                                        </BLUEBORDER>
                                    </Grid>}
                                    <Grid xs={5} sm={5} md={5.5} lg={5.7} item>
                                        <CustomButton
                                            sx={{ padding: 2, borderRadius: "8px", ":disabled": { color: '#d9d9d9' } }}
                                            onClick={(e) => {

                                                handleFormSubmit(e, formik);

                                            }}
                                            // disabled={currentIndex === 5 && formik.values?.documents?.filter(item => item?.t}
                                            // onClick={() => { setCurrentIndex(currentIndex + 1) }}
                                            fullWidth
                                        >
                                            {(isUpdatingsavePersonalInfo || isUpdatingAddPartners) ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.5rem'} /> : currentIndex == round_steps?.length - 1 ? "Submit" : "Next"}
                                        </CustomButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </ThemeBGWrapper>
    );
};

export default UpdateProfile;