import { Done } from '@mui/icons-material';
import axios from 'axios';
import { FieldArray, useFormikContext } from 'formik'
import React from 'react'
import CustomUrlField from 'ui-components/CustomUrlField';
import CustomInputField from 'ui-components/formik/CustomInputField'
// import { load } from "cheerio"
import { Box, Button, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { B300 } from 'ui-components/CustomInputButton';
import CustomLogoButton from "ui-components/formik/CustomLogoButton"
import AddIcon from "assets/add_button.png"
import { handleFormSubmit, notEmpty } from 'utils/index';
import DeleteIcon from "assets/delete.png"
import placeHolderImage from "assets/user_profile.png"


async function createFileFromUrl(url, fileName) {
    try {
        // Fetch the file data
        const response = await fetch(url);

        // Check if the response is successful
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // console.log(response, "response")

        // Convert the response into a Blob
        const blob = await response.blob();

        // Create a File object from the Blob (optional)
        const file = new File([blob], fileName, { type: blob.type });

        return file;
    } catch (error) {
        console.error('Error creating file from URL:', error);
        throw error;
    }
}

const fetchHTML = async (url) => {
    const proxyUrl = "https://api.allorigins.win/get?url=";
    try {
        const response = await fetch(proxyUrl + url);
        if (!response.ok) {
            throw new Error("Failed to fetch the webpage");
        }
        const html = await response.text();
        // console.log(html, "html")
        return html;
    } catch (error) {
        console.error("Error fetching HTML:", error);
        return null;
    }
};



const PartnersDetail = () => {
    const formik = useFormikContext();
    let [file, setFile] = React.useState(null);
    const [url, setURL] = React.useState("")
    const [JSONData, setJSONData] = React.useState(null);
    // React.useEffect(() => {
    //     (async () => {
    //         const file_url = "https://media.licdn.com/dms/image/v2/D4D03AQFFkXF5ODmApg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690885224648?e=2147483647&v=beta&t=EefIs8z4SRP21C6vhBV82R1X9J3VglSM1WbuCk_meeY"
    //         let currentfile = await createFileFromUrl(file_url, "downloaded-file.jpg");
    //         setFile(currentfile);
    //         // console.log(file, "file")
    //         if (currentfile) {
    //             let last_url = await URL.createObjectURL(file)
    //             if (last_url) await setURL(last_url);
    //         }
    //     })()
    // }, [])


    // const fetchMetaData = async (url, index) => {
    //     // const proxyUrl = `https://api.scrape.do/?token=05177208b4014043989bd12dff5296b2e87a8c9f3e1&render=true&particularScreenShot=h1&returnJSON=true&url=`;

    //     // const proxyUrl = `https://api.scrape.do/?token=05177208b4014043989bd12dff5296b2e87a8c9f3e1&render=true&particularScreenShot=h1&returnJSON=true&url=`;

    //     // const proxyUrl = "http://api.scraperapi.com?api_key=2763b641d27212c2e0ef3b521197619a&url=";

    //     const proxyUrl = "https://api.allorigins.win/get?url=";
    //     const apiToken =
    //         "673f3313b2f8bbd505e77031-cb8959195cb9";

    //     const fetchOpenGraphData = async (url) => {
    //         // const proxyUrl = "https://cors-anywhere.herokuapp.com/";

    //         try {
    //             // Fetch the webpage through the proxy
    //             // const proxyUrl = "https://api.allorigins.win/raw?url=";
    //             const { data } = await axios.get(proxyUrl + (url), {
    //                 // headers: {
    //                 //   "x-api-key": apiToken,
    //                 // }
    //                 // headers: { 'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64)' }
    //             });
    //             // if (!response.ok) {
    //             //   throw new Error("Failed to fetch the webpage");
    //             // }

    //             // const html = await data.text();

    //             // console.log(data?.content, "html");

    //             if (data?.contents) {
    //                 // console.log(data?.contents)
    //                 // const $ = data;
    //                 const $ = load(data?.contents);

    //                 const getMetaTag = (name) =>
    //                     $(`meta[property='${name}']`).attr("content") ||
    //                     $(`meta[name='${name}']`).attr("content");

    //                 // console.log({
    //                 //     title: getMetaTag("og:title") || $("title").text(),
    //                 //     description:
    //                 //         getMetaTag("og:description") ||
    //                 //         $('meta[name="description"]').attr("content"),
    //                 //     image: getMetaTag("og:image"),
    //                 // });
    //                 // let currentImageURl = await getMetaTag("og:image") || null;

    //                 // if (currentImageURl) {
    //                 //     setURL(currentImageURl);
    //                 // }


    //                 // let currentfile = await createFileFromUrl(getMetaTag("og:image"), "downloaded-file.jpg");
    //                 // setFile(currentfile);
    //                 // console.log(currentfile, "file")
    //                 // if (currentfile) {
    //                 //     let last_url = await URL.createObjectURL(file)
    //                 //     if (last_url) await setURL(last_url);
    //                 // }

    //                 await formik.setFieldValue(`partners.${index}.name`, getMetaTag("og:title") || $("title").text())
    //                 await formik.setFieldValue(`partners.${index}.image`, getMetaTag("og:image"))
    //                 await formik.setFieldValue(`partners.${index}.bio`, getMetaTag("og:description") ||
    //                     $('meta[name="description"]').attr("content"))
    //             }


    //             // return {
    //             //     title: getMetaTag("og:title") || $("title").text(),
    //             //     description:
    //             //         getMetaTag("og:description") ||
    //             //         $('meta[name="description"]').attr("content"),
    //             //     image: getMetaTag("og:image"),
    //             // };

    //             // const parser = new DOMParser();
    //             // const doc = parser.parseFromString(html, "text/html");

    //             // const ArrayValues: Array<any> = [];

    //             // const metaTag = doc
    //             //   .querySelectorAll("meta")
    //             //   .forEach((element, index) => (ArrayValues[index] = element));

    //             // const IndexOfOGIMage = ArrayValues?.findIndex(
    //             //   (item) => item?.getAttribute("property")?.includes("image")
    //             // );

    //             // const ogImage = ArrayValues?.find((item, index) => index === IndexOfOGIMage)?.getAttribute("content")
    //             // const IndexOfOGDescription = ArrayValues?.findIndex(
    //             //   (item) => item?.getAttribute("property")?.includes("description")

    //             // );

    //             // const ogDescription = ArrayValues?.find((item, index) => index === IndexOfOGDescription)?.getAttribute("content")
    //             // console.log(ogImage,"f",ogDescription)

    //             // const nodeData =

    //             // Use Open Graph Extractor to parse metadata
    //             // const metadata = await extractOpenGraph(html, {
    //             // customMetaTags: [
    //             //   { multiple: true, property: "og:image", fieldName: "ogImage" },
    //             //   { multiple: true, property: "og:description", fieldName: "ogDescription" },
    //             // ],
    //             // });

    //             // const ogImage = metadata?.ogImage || "Default Image URL";
    //             // const ogDescription = metadata?.ogDescription || "Default Description";

    //             // console.log("Open Graph Metadata:", {...metadata})
    //             // console.log("Open Graph Metadata:", metaTag, ArrayValues?.map(
    //             //   (item) => item?.getAttribute("property")
    //             // ));
    //             // return metaTag;
    //         } catch (error) {
    //             console.error("Error fetching Open Graph metadata:", error);
    //             return null;
    //         }
    //     };

    //     await setJSONData(await fetchOpenGraphData(url))

    //     return await fetchOpenGraphData(url);
    // }


    return (
        <Grid item container xs={12} marginTop={"0.5rem"}>
            {/* <img src={url} width={100} height={100} /> */}
            <FieldArray
                name="partners"
                render={(arrayHelpers) => (
                    <>
                        {formik?.values?.partners ? formik?.values?.partners?.length > 0 ?
                            formik?.values?.partners?.map((item, index) => {
                                return <>
                                    {/* <Grid item xs={12} display={"flex"} flexWrap={"nowrap"} gap={1} alignItems={"center"}>
                                        
                                        <Grid item xs={12}>
                                            <CustomUrlField
                                                name={`partners.${index}.linkedin_url`}
                                                label={"Linkedin URL"}
                                                placeholder={"Please enter linkedin URL"}
                                                value={
                                                    String(formik?.values?.partners[index]?.linkedin_url)?.startsWith("https://")
                                                        ? String(formik?.values?.partners[index]?.linkedin_url)?.replace("https://", "")
                                                        : formik?.values?.partners[index]?.linkedin_url

                                                }
                                                onChange={(evt) => {

                                                    formik.setFieldValue(`partners.${index}.linkedin_url`, String(evt.target.value)?.startsWith("https://")
                                                        ? String(evt.target.value)?.replace("https://", "")
                                                        : evt.target.value)
                                                }}
                                            />
                                        </Grid>
                                    </Grid> */}
                                    <Grid item xs={12} mb={2} container spacing={{ xs: 0, sm: 1 }} position={"relative"}>
                                        <Grid item xs={12} sm={6} mb={{ xs: 2, sm: 0 }}>
                                            <CustomLogoButton placeholder={placeHolderImage} label={"Photo"} handleUpdateFile={(file) => { formik.setFieldValue(`partners.${index}.image`, file); }} name={`partners.${index}.image`} value={item?.image} Pheight={70} Pwidth={70} Pmultiplier={0.8} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} mt={{ xs: 0, sm: "auto" }}>
                                            <Grid item xs={12}>
                                                <CustomInputField name={`partners.${index}.name`} label={"Name"} placeholder={"Please enter partner name"} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomInputField name={`partners.${index}.title`} label={"Title"} placeholder={"Please enter partner title"} sx={{ marginBottom: '0px' }} />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} mt={{ xs: 2, sm: 1 }}>
                                            <CustomUrlField
                                                name={`partners.${index}.linkedin_url`}
                                                label={"Linkedin URL"}
                                                placeholder={"Please enter linkedin URL"}
                                                value={
                                                    String(formik?.values?.partners[index]?.linkedin_url)?.startsWith("https://")
                                                        ? String(formik?.values?.partners[index]?.linkedin_url)?.replace("https://", "")
                                                        : formik?.values?.partners[index]?.linkedin_url

                                                }
                                                onChange={(evt) => {

                                                    formik.setFieldValue(`partners.${index}.linkedin_url`, String(evt.target.value)?.startsWith("https://")
                                                        ? String(evt.target.value)?.replace("https://", "")
                                                        : evt.target.value)
                                                }}
                                            />
                                            <CustomInputField name={`partners.${index}.bio`} label={"BIO"} placeholder={"Please enter partner bio"} />
                                        </Grid><IconButton
                                            sx={{ position: "absolute", top: { xs: "-0.5rem", sm: "0rem" }, right: "1.2rem", padding: 0 }}
                                            onClick={() => {
                                                if (window.confirm("Are you sure you want to delete?") === true) {
                                                    arrayHelpers.remove(index);
                                                }
                                            }}
                                        >
                                            <img src={DeleteIcon} rel="preload" width={30} height={30} />
                                        </IconButton>
                                    </Grid>


                                    {formik.values?.partners?.length > 1 && (formik?.values?.partners?.length - 1 > index) && <Grid item xs={12}> <Divider sx={{ marginTop: 2, marginBottom: 2 }} /> </Grid>}
                                    {(formik?.values?.partners?.length - 1 === index) && <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 1,
                                                justifyContent: "space-between",
                                            }}

                                        >
                                            <Box
                                                component={"button"}

                                                disabled={!(String(item?.name)?.trim()?.length > 0 && // Check if name is not empty
                                                    (typeof item?.image !== "string" ? item?.image != null : String(item?.image)?.length > 0) && // Check if image is not null
                                                    String(item?.linkedin_url)?.trim()?.length > 0)}
                                                sx={{ cursor: "pointer", background: 'transparent' }}
                                                onClick={(e) => {
                                                    // console.log(item, "item", formik?.errors, "ferr")
                                                    // formik.validateForm()

                                                    if (
                                                        String(item?.name)?.trim()?.length > 0 && // Check if name is not empty
                                                        (typeof item?.image !== "string" ? item?.image != null : String(item?.image)?.length > 0) && // Check if image is not null
                                                        String(item?.linkedin_url)?.trim()?.length > 0
                                                    ) {
                                                        // Clear errors for the current partner
                                                        formik.setFieldError(`partners.${index}.linkedin_url`, null);
                                                        formik.setFieldError(`partners.${index}.image`, null);
                                                        formik.setFieldError(`partners.${index}.name`, null);

                                                        // Insert a new empty partner
                                                        arrayHelpers.insert(index + 1, {
                                                            name: "",
                                                            image: null,
                                                            bio: "",
                                                            linkedin_url: "",
                                                        });
                                                    } else {
                                                    }

                                                }}
                                            >
                                                <IconButton disabled={!(String(item?.name)?.trim()?.length > 0 && // Check if name is not empty
                                                    (typeof item?.image !== "string" ? item?.image != null : String(item?.image)?.length > 0) && // Check if image is not null
                                                    String(item?.linkedin_url)?.trim()?.length > 0)}>
                                                    <Tooltip title="Add another partner">
                                                        <img src={AddIcon} rel="preload" width={32} height={32} />
                                                    </Tooltip>
                                                </IconButton>
                                                <Typography marginLeft="1rem" variant="caption" color="#000">
                                                    Add another partner
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>}
                                </>
                            })
                            : <Grid item xs={12}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                        justifyContent: "space-between",
                                    }}

                                >
                                    <Box
                                        component={"button"}
                                        sx={{ cursor: "pointer", background: 'transparent' }}
                                        onClick={(e) => {

                                            // Insert a new empty partner
                                            arrayHelpers.insert(0, {
                                                name: "",
                                                image: null,
                                                bio: "",
                                                linkedin_url: "",
                                            });
                                        }}
                                    >
                                        <IconButton>
                                            <Tooltip title="Add another partner">
                                                <img src={AddIcon} rel="preload" width={32} height={32} />
                                            </Tooltip>
                                        </IconButton>
                                        <Typography marginLeft="1rem" variant="caption" color="#000">
                                            Add another partner
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid> : <Grid item xs={12}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                    justifyContent: "space-between",
                                }}

                            >
                                <Box
                                    component={"button"}
                                    sx={{ cursor: "pointer", background: 'transparent' }}
                                    onClick={(e) => {

                                        // Insert a new empty partner
                                        arrayHelpers.insert(0, {
                                            name: "",
                                            image: null,
                                            bio: "",
                                            linkedin_url: "",
                                        });
                                    }}
                                >
                                    <IconButton>
                                        <Tooltip title="Add another partner">
                                            <img src={AddIcon} rel="preload" width={32} height={32} />
                                        </Tooltip>
                                    </IconButton>
                                    <Typography marginLeft="1rem" variant="caption" color="#000">
                                        Add another partner
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>}

                    </>
                )}
            />
            {/* {
                JSON.stringify(JSONData)
            } */}
        </Grid>
    )
}

export default PartnersDetail