import { useTheme } from '@emotion/react'
import { Grid, Paper, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { G300, } from '../../ui-components/CustomButton'
import { useNavigate } from 'react-router-dom';
import AuthWrapper1 from './AuthWrapper1';
import BackrrLogoImage from '../../ui-components/BackrrLogoImage'
import { useConfig } from '../../dynamic-configuration/configContext';

const RedirectionScreen = () => {
    const { config } = useConfig()
    const theme = useTheme();
    const navigate = useNavigate();
    const isTableorLargerScreen = useMediaQuery(theme.breakpoints.up('sm'))
    //theme.palette.background.paper
    // "#FFFFFF"
    return (
        <AuthWrapper1>
            <Grid sx={{ display: "grid", placeContent: 'center', height: "100vh" }}>
                <Paper sx={{
                    backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '2rem', flex: 1, overflowY: "auto", overflowX: "hidden", maxHeight: "578px", borderRadius: "48px",
                    // scale: {xs: "0.8", sm: "0.9", lg: "1"},
                    scale: "0.9",
                    maxWidth: "450px"
                }}>
                    <Grid container xs={12} display={'grid'} sx={{ placeItems: "center", placeContent: "center", gap: "2rem" }}>
                        {/* Header */}
                        <Grid item display={"grid"} sx={{ placeItems: "center", placeContent: "center", gap: 2 }}>
                            <BackrrLogoImage />
                            <Typography variant='caption' sx={{ fontSize: "1rem", fontWeight: 700, textAlign: 'center' }}>{config?.dynamicText.thankyouMessage}</Typography>
                        </Grid>
                        {/* Content */}
                        <Grid sx={{ border: '1px solid #ADA2ED', background: "#EBE8FB", padding: "1.5rem", borderRadius: "8px" }}>
                            <Typography variant='subtitle1' sx={{ fontWeight: 700 }}>
                                {config?.dynamicText?.benifitTitle}
                            </Typography>
                            <ol class="MuiBreadcrumbs-ol" style={{ margin: 0, paddingLeft: isTableorLargerScreen ? "1.5rem" : "1rem" }}>
                                {
                                    config?.benifits?.length > 0 && config?.benifits?.map((item, index) => {
                                        return (
                                            <li key={item} class="MuiBreadcrumbs-li css-1rqbcrs-MuiBreadcrumbs-ol" style={{ fontSize: '1rem', fontWeight: 400, marginBlock: index == config?.benifits?.length - 1 ? 0 : "10px" }}>
                                                {item}
                                            </li>
                                        )
                                    })
                                }
                                {/* <li class="MuiBreadcrumbs-li" style={{ fontSize: '1rem', fontWeight: 400, marginBlock: "10px" }}>
                                    Curated Deals Matching Your Preferences
                                </li>
                                <li class="MuiBreadcrumbs-li css-1rqbcrs-MuiBreadcrumbs-ol" style={{ fontSize: '1rem', fontWeight: 400 }}>
                                    Network with like-minded angel investors
                                </li> */}
                            </ol>
                        </Grid>
                        {/* Footer */}
                        <Grid display={"grid"} gap={1} xs={12} sx={{ width: "100%" }}>
                            <G300 fullWidth={true} sx={{ background: "#000", padding: 2, textTransform: "none" }} onClick={() => navigate("/onboarding")}>
                                {config?.dynamicText?.completeProfile}
                            </G300>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </AuthWrapper1>
    )
}

export default RedirectionScreen