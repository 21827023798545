import { useTheme } from '@emotion/react'
import { Description, Edit, EditOutlined } from '@mui/icons-material'
import { Box, Button, Grid, Typography } from '@mui/material'
import { FetchAllStartupFeedbacks } from 'api/profile'
import { FetchFunding, FetchRoundViewDetails } from 'api/startups'
import Cookies from 'js-cookie'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AsyncTableListing from 'ui-components/AsyncTableListing'
import { handleDateValues } from 'utils/index'
import placeHolderImageFunding from 'assets/no_data_images/Startups/funding_startups.svg'


const Fundings = ({ startup_id, isAdmin }) => {
    const theme = useTheme()
    const details = useSelector((state) => state?.startupDetails?.data);
    const navigate = useNavigate()
    let cookieStartupId = Cookies.get("startup_id");

    const onlyColumnForAdmin = Cookies.get("permissions") && isAdmin ? [{ Header: 'Actions', accessor: 'actions', minWidth: 80, align: 'left' }] : [];

    const columns = [
        { Header: 'Round Name', accessor: 'round_name', minWidth: 100, align: 'start', fixed: true },
        { Header: 'Date', accessor: 'date', minWidth: 150, align: 'center' },
        { Header: 'Stage', accessor: 'round_type_info', minWidth: 150, align: 'center' },
        { Header: 'Funding Amount', accessor: 'total_allocated_amount_final', minWidth: 150, align: 'center' },
        // { Header: 'Attachments', accessor: 'attachments', minWidth: 150, align: 'center' },
        ...onlyColumnForAdmin
    ];

    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [sort, setSort] = React.useState(-1);
    const [search, setSearch] = React.useState(-1);
    const { data: fundingData, isLoading: isLoadingFunding, refetch: refetchListing } = FetchFunding({ id: cookieStartupId ? cookieStartupId : '' })

    // console.log(fundingData,"datatdfaa")

    const formatedFundingData = fundingData?.data?.map(item => ({
        ...item,
        round_type_info: item.round_type_info?.name,
        date:
            <Box width={"100%"}>
                <Typography
                    bgcolor={"#E2F0FF"}
                    color={"#0075FF"}
                    marginInline={'auto'}
                    fontWeight={700}
                    fontSize={"0.75rem"}
                    padding={"4px 6px"}
                    mt={0}
                    width={"max-content"}
                    borderRadius={"4px"}
                >
                    {handleDateValues(item?.final_round_start_date, "No Start Date")} - {handleDateValues(item?.final_round_closing_date, "No Closing Date")}
                </Typography>
            </Box>


    }))

    // formatedFundingData = []
    // console.log(feedbacksData, 'feedbacksData')
    // React.useEffect(() => {
    //     details && mutateAsyncFeedbacks({ startup_id: details ? details?._id : '', page, limit, sort, search })
    // }, [details])

    // const formattedFeedbacksData = feedbacksData?.data && feedbacksData?.data?.length ? feedbacksData?.data?.map(item => ({
    //     // ...item,
    //     ...item?.startup_feedbacks?.ratings,
    //     name: item?.name ? item?.name?.length > 0 ? item?.name : item?.email : item?.email,
    //     feedback: item?.startup_feedbacks?.feedback,
    //     investible: item?.startup_feedbacks?.investible ? "Yes" : "No",
    //     formatted_date: item?.formatted_date,
    //     location: `${item?.city ? item?.city + ', ' : ''}${item?.state ? item?.state + ', ' : ''}${item?.country ? item?.country : ''}`
    // })) : []


    const formattedFeedbacksData = [
        {
            round_name: 'Pre Seed Round',
            formatted_date: '15-Jan-2023',
            stage: 'Pre-seed',
            funding_amount: '₹50L',
            investors: <Typography sx={{ cursor: 'pointer' }} textAlign={"center"} color={"#0075FF"} fontSize={"0.875rem"}> 2 </Typography>,
            attachments: '4 Documents',
        },
        {
            round_name: 'Series A',
            formatted_date: '22-May-2023',
            stage: 'Seed',
            funding_amount: '₹2CR',
            investors: <Typography sx={{ cursor: 'pointer' }} textAlign={"center"} color={"#0075FF"} fontSize={"0.875rem"}> 5 </Typography>,
            attachments: '2 Documents',
        },
        {
            round_name: 'Bridge',
            formatted_date: '21-Jul-2024',
            stage: 'Bridge',
            funding_amount: '₹75L',
            investors: <Typography sx={{ cursor: 'pointer' }} textAlign={"center"} color={"#0075FF"} fontSize={"0.875rem"}> 3 </Typography>,
            attachments: '3 Documents',
        }
    ]

    const RowActions = ({ row, cell }) => {
        // console.log(row,"rrrrrrrrrrrrr");

        return <Box width={"100%"} onClick={() => {

        }} display={"flex"} alignItems={"center"} justifyContent={"left"} gap={0.5} sx={{ textDecoration: "underline", textUnderlineOffset: '5px', textDecorationColor: theme.palette.customColors.indigo600, cursor: 'pointer' }} >
            <Button sx={{ minWidth: "1rem" }}>
                <EditOutlined
                    sx={{ color: theme.palette.customColors.indigo700 }}
                    onClick={() => {
                        // console.log(row?.original?._id,"orororororo")
                        Cookies.set("round_id", row?.original?._id)
                        navigate("/backrr_startups/edit-round")
                        // navigate("edit_performance")
                    }}
                />
            </Button>
        </Box>
    }


    return (
        <>
            <Grid container xs={12} pt={2} pb={0} spacing={2} display={"flex"} justifyContent={"space-between"} marginLeft={0} alignContent={"flex-start"} >
                {isLoadingFunding ?
                    <Grid item xs={12} height={"100vh"} sx={{ width: '100%', padding: "2rem", paddingLeft: "2rem !important", paddingTop: "0rem !important", bgColor: theme.palette.customColors.yellow_bg }} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }}>
                        <Box className='skeleton' minHeight={"70vh"} sx={{ borderRadius: "8px", border: `1px solid ${theme.palette.customColors.b50}` }} marginTop={0}>

                        </Box>
                    </Grid>
                    :
                    <Box width={"100%"} paddingLeft={"2rem"} maxWidth={"100%"} overflow={"hidden"} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }}>
                        <AsyncTableListing
                            // isLoading={isLoadingFeedbacks}
                            columns={columns}
                            data={formatedFundingData}
                            noDataPlaceHolderImage={placeHolderImageFunding}
                            noDataTitle='No Funding Rounds'
                            noDataSubTitle='It seems like there are no funding rounds available at the moment.'
                            page={page}
                            setPage={setPage}
                            limit={limit}
                            setLimit={setLimit}
                            count={10}
                            // tabValue={value}
                            // title={"Investors"}
                            // chipText={`${data?.length}${isSmallScreen ? "" : " Investors"}`}
                            // actions={Actions}
                            pagination={false}
                            RowActions={RowActions}
                            sx={{ borderRadius: '8px', border: formatedFundingData?.length > 0 ? `1px solid ${theme.palette.customColors.b50}` : "none", bgcolor: formatedFundingData?.length > 0 ? "white" : "transparent" }}

                        // refetchListing={refetchListing}
                        />
                    </Box>
                }

                {/* </Grid>} */}
                {/* <Grid item xs={2} lg={2}> */}

            </Grid>
        </>
    )
}

export default Fundings
