import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { RetryOnError, verifyToken } from "../utils/VerifyToken";

export const AddConfiguration = () => {
    return useMutation((payload) => {
        return axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}/performance/save-metrics-config`,
            headers: {
                'Authorization': `Bearer ${Cookies.get('jwt')}`,
                'Content-type': 'application/json'
            },
            data: payload
        })
    }, {
        // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
        // retry: (failureCount, error)=>{
        //   return RetryOnError(failureCount, error)
        // },
        retry: 0,
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchSavedConfigurationTemplates = (id) => {
    return useQuery(
        ['master-listing-template-FetchSavedConfigurationTemplates-using-id-fetch' + id, id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/performance/get-startup-config/${id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data?.data?.data,'values new')
            const savedData = data?.data?.data[0];
            // console.log(savedData,'values new')

            return {
                data: savedData?.configuredMetrics && Array.isArray(savedData?.configuredMetrics) ? savedData?.configuredMetrics?.map((item, index) => {
                    return ({
                        ...item,
                        display_order: index
                    })
                }) : [], ...savedData
            }
        },
        refetchOnWindowFocus: false,
        enabled: !!id,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchAllConfigurationTemplates = () => {
    return useQuery(
        ['FetchAllConfigurationTemplates-FetchAllConfigurationTemplates-FetchAllConfigurationTemplates-FetchAllConfigurationTemplates'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/performance/get-metrics-template`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data?.data?.data,'values new')
            const savedData = data?.data?.data;
            return savedData
        },
        refetchOnWindowFocus: false,
        // enabled: !!id,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchAllMetricsData = ({ startup_id, filterKeys, fromDate, toDate
    // = "6756cc84a5e4ff87c82f430e"
}) => {
    return useQuery(
        ['FetchAllConfigurationTemplates-FetchAllMetricsData-FetchAllMetricsData-FetchAllConfigurationTemplates', filterKeys, fromDate + toDate],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/performance/get-metrics-table/${startup_id}?${filterKeys?.includes("all") || filterKeys?.length === 0 ? "" : `key=${filterKeys}`}` + (fromDate && toDate ? `&from=${fromDate}` : "") + (fromDate && toDate ? `&to=${toDate}` : ""),
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            const savedData = data?.data;
            const savedTableData = savedData?.tableData?.map(item => {
                return (
                    {
                        ...item,
                        "Month": item?.month
                    }
                )
            })
            // console.log(data?.data,'values new')
            return { ...savedData, tableData: savedTableData }
        },
        refetchOnWindowFocus: false,
        enabled: !!startup_id,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const ExportFetchAllMetricsData = () => {
    return useMutation(
        async ({ startup_id, filterKeys, fromDate, toDate }) => {
            const response = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/performance/get-metrics-table/${startup_id}?${filterKeys?.includes("all") || filterKeys?.length === 0 ? "" : `key=${filterKeys}`}` +
                    (fromDate && toDate ? `&from=${fromDate}` : "") +
                    (fromDate && toDate ? `&to=${toDate}` : "") +
                    "&excel=true",
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            });
            return response.data; // Return only the relevant data
        },
        {
            // Process and transform the response data
            onSuccess: (data) => {
                // const tableData = data?.tableData?.map(item => ({
                //     ...item,
                //     "Month": item?.month,
                // }));
                // return { ...data, tableData }; // Transform data if needed
                return data
            },
            // Retry logic for failed requests
            retry: (failureCount, error) => RetryOnError(failureCount, error),
            // Handle errors globally
            onError: (err) => verifyToken(err),
        }
    );
};

export const SaveMetricPerformanceData = () => {
    return useMutation(({ payload, isEditMode }) => {
        return axios({
            method: isEditMode ? 'put' : 'post',
            url: `${process.env.REACT_APP_BASE_URL}/performance/${isEditMode ? "edit" : "save"}-metrics-data`,
            headers: {
                'Authorization': `Bearer ${Cookies.get('jwt')}`,
                'Content-type': 'application/json'
            },
            data: payload
        })
    }, {
        // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
        // retry: (failureCount, error)=>{
        //   return RetryOnError(failureCount, error)
        // },
        retry: 0,
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const DeleteMetricDataByID = () => {
    return useMutation(
        ({ metric_id }) => {
            return axios({
                method: 'delete',
                url: `${process.env.REACT_APP_BASE_URL}/performance/delete-metrics-data/${metric_id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                // data: payload
            });
        },
        {
            retry: 0,
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const FetchSavedMetricDataByID = ({ metric_id }) => {
    return useQuery(
        ['master-FetchSavedMetricDataByID-FetchSavedMetricDataByID-FetchSavedMetricDataByID-using-id-FetchSavedMetricDataByID' + metric_id, metric_id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/performance/get-metrics-detail/${metric_id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data?.data?.data,'values new')
            const savedData = data?.data?.data;
            // console.log(savedData,'values new')
            return savedData;

            // return { data: savedData?.configuredMetrics ? savedData?.configuredMetrics : [], ...savedData }
        },
        refetchOnWindowFocus: false,
        enabled: !!metric_id,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const ExportMetricsTemplateFile = () => {
    return useMutation(
        async ({ startup_id }) => {
            const response = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/performance/export-template/${startup_id}?sample=true`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            });
            return response.data; // Return only the relevant data
        },
        {
            // Process and transform the response data
            onSuccess: (data) => {
                // const tableData = data?.tableData?.map(item => ({
                //     ...item,
                //     "Month": item?.month,
                // }));
                // return { ...data, tableData }; // Transform data if needed
                return data
            },
            // Retry logic for failed requests
            retry: (failureCount, error) => RetryOnError(failureCount, error),
            // Handle errors globally
            onError: (err) => verifyToken(err),
        }
    );
};

export const ImportMetricsDataFile = () => {
    return useMutation((payload) => {
        return axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}/performance/import-metrics`,
            headers: {
                'Authorization': `Bearer ${Cookies.get('jwt')}`,
                'Content-Type': 'multipart/form-data'
            },
            data: payload
        })
    }, {
        retry: 0,
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchAllGraphs = ({startup_id, fromDate, toDate}) => {
    return useQuery(
        ['FetchAllConfigurationTemplates-FetchAllGraphs-FetchAllConfigurationTemplates-FetchAllGraphs',startup_id,fromDate + toDate],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/performance/charts/${startup_id}?${(fromDate && toDate ? `&from=${fromDate}` : "")}${(fromDate && toDate ? `&to=${toDate}` : "")}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data?.data,'values new')
            const savedData = data?.data?.charts;
            return savedData
        },
        refetchOnWindowFocus: false,
        enabled: !!startup_id,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

// export const DeleteReportDocument = () => {
//     return useMutation(
//         ({ payload, document_id }) => {
//             return axios({
//                 method: 'delete',
//                 url: `${ process.env.REACT_APP_BASE_URL } / reports / delete -document / ${ document_id } `,
//                 headers: {
//                     Authorization: `Bearer ${ Cookies.get('jwt') }`,
//                     'Content-type': 'application/json'
//                 },
//                 data: payload
//             });
//         },
//         {
//             retry: 0,
//             // onSuccess: (data)=>console.log("API successfull"),
//             // retry: (failureCount, error)=>{
//             //   return RetryOnError(failureCount, error)
//             // },
//             onError: (err) => {
//                 return verifyToken(err);
//             }
//         }
//     );
// };