import { useTheme } from '@emotion/react';
import { capitalize, Grid, Typography, useMediaQuery } from '@mui/material';
import { useConfig } from 'dynamic-configuration/configContext';
import { useFormikContext } from 'formik';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import CustomLabel from 'ui-components/CustomLabel';
import CustomValidationText from 'ui-components/CustomValidationText';
import CustomInputField from 'ui-components/formik/CustomInputField';
import { currencyInputProps } from '../../../utils';
import CustomPhone from 'ui-components/CustomPhone';
import { FetchAllCountriesWithPhoneCodes } from 'api/utils';
import CustomUrlField from 'ui-components/CustomUrlField';

const titles = [
    {
        value: "Mr.",
        label: "Mr.",
    },
    {
        value: "Mrs.",
        label: "Mrs.",
    },
    {
        value: "Ms.",
        label: "Ms.",
    },
    {
        value: "Dr.",
        label: "Dr.",
    },
    {
        value: "Prof.",
        label: "Prof.",
    },
    {
        value: "CA",
        label: "CA",
    },
];

const PersonalDetails = ({ investor_type }) => {
    const { data: countriesWithPhoneCodes, isRefetching: isRefetchingCountriesPC, isLoading: isLoadingCountriesPC, refetch: refetchCountriesPC } = FetchAllCountriesWithPhoneCodes({})
    const shiftSteps = investor_type ? String(investor_type)?.toLowerCase()?.includes("angel") : true;
    const formik = useFormikContext();
    const { config } = useConfig()
    const theme = useTheme();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const isTablet = useMediaQuery(theme.breakpoints.up('sm'))
    return (
        <Grid container xs={12} gap={0} marginTop={0} display={"flex"} alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"space-between"}>
            <Grid item container xs={12} display={"flex"} gap={0} justifyContent={"space-between"} flexDirection={"row"}>
                <CustomLabel>{config?.validationFields?.title?.label}  {formik.errors.title && formik.touched.title && (
                    <CustomValidationText value={formik.errors.title} />
                )}</CustomLabel>
                <Grid
                    display={"flex"}
                    flexWrap={"wrap"}
                    justifyContent={"space-between"}
                    item
                    container
                    xs={12}
                    gap={{ xs: 0.5, sm: 1, md: 2 }}
                    mb={2}
                >
                    {titles?.map((title, index) => {
                        return (
                            <Grid
                                role={"button"}
                                item
                                // xs={12}
                                xs={3.8}
                                sm={1.65}
                                md={1.6}
                                lg={1.65}

                                onClick={() => {
                                    formik.setFieldValue(
                                        "title",
                                        title?.value
                                    );
                                }}
                                sx={{
                                    background:
                                        formik.values.title ==
                                            title?.value
                                            ? theme?.palette?.customColors?.y50
                                            : theme?.palette?.customColors?.whiteBG,
                                    padding: "12px 16px",
                                    height: 55,
                                    borderRadius: "8px",
                                    display: "grid",
                                    placeItems: "center",
                                    border: `1px solid ${formik.values.title ==
                                        title?.value
                                        ? theme?.palette?.customColors?.y300
                                        : theme?.palette?.customColors?.g75
                                        }`,
                                    boxShadow: "0px 1px 2px 0px #1018280D",
                                    cursor: "pointer",
                                }}
                            >
                                <Typography
                                    variant="subtitle2"
                                    color={theme.palette.customColors.g200}
                                    fontSize="0.875rem"
                                >
                                    {title?.value}
                                </Typography>
                            </Grid>
                        );
                    })}
                </Grid>
                {formik.values.title === "Other" && <Grid item xs={12} display={"flex"} gap={0} marginTop={2}>
                    <CustomInputField value={formik.values.title} name="title" label={"ENTER REFERRER NAME"} placeholder="Enter referrer name" onChange={(e) => formik.setFieldValue("title", e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} />
                </Grid>}
            </Grid>
            <Grid height={"min-content"} item xs={12} sm={12} md={5.5} lg={5.65}>
                <CustomInputField name={`first_name`} label={config?.validationFields?.first_name?.label} onChange={(e) => { formik.setFieldValue('first_name', capitalize(e.target.value)?.trimStart()) }} fullWidth />
            </Grid>
            <Grid height={"min-content"} item xs={12} sm={12} md={5.5} lg={5.65}>
                <CustomInputField name={`last_name`} label={config?.validationFields?.last_name?.label} onChange={(e) => { formik.setFieldValue('last_name', capitalize(e.target.value)?.trimStart()) }} fullWidth />
            </Grid>
            {shiftSteps && <Grid height={"min-content"} item xs={12}>
                <CustomPhone name={`phone_number`} selectedCode={formik.values?.country_code ? formik.values?.country_code : ""} handleChange={(event) => formik.setFieldValue("country_code", event.target.value)} countryCodes={countriesWithPhoneCodes ? countriesWithPhoneCodes : []} label='Contact Number' placeholder="Enter your contact number" onChange={(e) => formik.setFieldValue(`phone_number`, e.target.value)} />
            </Grid>}
            {shiftSteps && <Grid container gap={{ xs: 0.5, sm: 1, md: 2 }} display={'flex'} justifyContent={"space-between"} alignItems={'flex-end'}>
                <Grid height={"min-content"} item xs={3.8} sm={3.8} md={3.65} lg={3.65}>
                    <CustomInputField InputProps={{ ...currencyInputProps, inputProps: { maxLength: 2 }, }}
                        name={`mm`}
                        label={!isMobile ? (
                            <>
                                {config?.validationFields?.dob?.label}
                                <span
                                    style={{
                                        color: theme?.palette?.customColors?.g100,
                                        textTransform: "none",
                                    }}
                                >
                                    {" "}
                                    (Optional)
                                </span>
                            </>
                        ) : (
                            config?.validationFields?.dob?.label
                        )
                        }
                        placeholder={config?.validationFields?.dob?.monthPlaceholder}
                        fullWidth
                    />
                </Grid>

                <Grid height={"min-content"} item xs={3.8} sm={3.8} md={3.65} lg={3.65}>
                    <CustomInputField
                        InputProps={{
                            ...currencyInputProps,
                            inputProps: { maxLength: 2 },
                        }}
                        name={`dd`}
                        label={
                            isMobile ? (
                                <>
                                    <span
                                        style={{
                                            color: theme?.palette?.customColors?.g100,
                                            textTransform: "none",
                                        }}
                                    >
                                        {" "}
                                        (Optional)
                                    </span>
                                </>
                            ) : (
                                'dd'
                            )
                        }
                        placeholder={config?.validationFields?.dob?.datePlaceholder}
                        fullWidth
                        labelStyle={{ opacity: isMobile ? 1 : 0 }}
                    />
                </Grid>
                <Grid height={"min-content"} item xs={3.8} sm={3.8} md={3.65} lg={3.65}>
                    <CustomInputField InputProps={{ ...currencyInputProps, inputProps: { maxLength: 4 } }} name={`yyyy`} label={'yyyy'} placeholder={config?.validationFields?.dob?.yearPlaceholder} fullWidth labelStyle={{ opacity: 0 }} />
                </Grid>
            </Grid>}
            {shiftSteps && <Grid height={"min-content"} item xs={12} sm={12} md={12} lg={12}>
                <CustomInputField name={`bio`} placeholder={"One liner like your linkedin headline."} label={<>{"BIO"}<span style={{ color: theme?.palette?.customColors?.g100, textTransform: "none" }}> (Optional)</span></>} fullWidth />
            </Grid>}
            <Grid item xs={12} position="relative" pt={1}>
                <CustomUrlField
                    label={config?.validationFields?.linkedIn_profile_url?.label}
                    name={'linkedIn_profile_url'}
                    value={
                        String(formik?.values?.linkedIn_profile_url)?.startsWith("https://")
                            ? String(formik?.values?.linkedIn_profile_url)?.replace("https://", "")
                            : formik?.values?.linkedIn_profile_url

                    }
                    placeholder={config?.validationFields?.linkedIn_linkedIn_profile_url?.placeholder}
                    onChange={(evt) => {

                        formik.setFieldValue("linkedIn_profile_url", String(evt.target.value)?.startsWith("https://")
                            ? String(evt.target.value)?.replace("https://", "")
                            : evt.target.value)
                    }}
                />
                {/* {console.log(formik?.values?.linkedIn_profile_url,"hhhhhhhhhhhh")} */}
            </Grid>
        </Grid>
    )
}

export default PersonalDetails