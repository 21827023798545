import { makeStyles, styled } from '@mui/styles';
import { ReactComponent as ReportsIcon } from '../../assets/nav_icons/reports.svg';
import CustomValidationText from '../../ui-components/CustomValidationText';
import { Box, capitalize, Card, Checkbox, CircularProgress, Divider, FormControlLabel, FormGroup, Grid, IconButton, MenuItem, Select, Tab, Tabs, Tooltip, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import PerfectScrollbar from "react-perfect-scrollbar";
import RoundStepsBackground from "../../assets/rounds_icons/round_list_bottom.png";
import TopIcon from "../../assets/rounds_icons/TopIcon.svg";
// import TopIcon from "../../assets/rounds_icons/top_icon.svg";
import BottomIcon from "../../assets/rounds_icons/BottomIcon.svg";
import { useTheme } from '@emotion/react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { containerRef, handleFormSubmit, mergePermissions, adminDefaultPermissions, defaultPermissions, convertToPermissionOnly, collaboratorDefaultPermissions, adminPermission, currencyInputProps, versionInputProps } from 'utils/index';
import { TabContext, TabPanel } from '@mui/lab';
import { AddReport, DeleteReportDocument, FetchReportDetails } from 'api/report';
import ThemeBGWrapper from 'ui-components/ThemeBGWrapper';
import BreadcrumbHeader from 'layout/BreadcrumbsHeader';
import NeedHelpButton from 'ui-components/NeedHelpButton';
import { BLUEBORDER, B300 as CustomButton, WHITEBG } from 'ui-components/CustomButton';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { FetchAllDocumentTypes } from 'api/utils';
import * as Yup from "yup"
import toast from "react-hot-toast";
import CustomToast from "ui-components/CustomToast";
import CustomInputField from "ui-components/formik/CustomInputField";
import CustomLabel from "ui-components/CustomLabel";
import { DateRangePicker } from "rsuite";
import moment from "moment";
import CustomMenu from "ui-components/CustomMenu";
import { VisuallyHiddenInput } from "ui-components/VisuallyHiddenInput";
import Cookies from "js-cookie";
import CustomRichTextEditor from "ui-components/formik/CustomRichTextEditor";
import { AddVersionData, FetchVersionDataByID } from 'api/admin_settings';
import toast_message from "utils/toast_message.json"
import { useDispatch } from 'react-redux';
import { SET_USER_ACCESS } from 'store/actions';
import { FetchUserPermissionsForCurrentInvestor } from 'api/permission';
import { InfoOutlined } from '@mui/icons-material';


const useStyles = makeStyles((theme) => ({
  scrollTabs: {
    '& > .Mui-disabled.MuiTabs-scrollButtons': {
      display: 'none',
      transition: 'all 1s ease'
    },
  },
  DateRangePicker: {
    '& > .rs-picker-input-group > input': {
      fontSize: "1rem",
      padding: '13.2px 15px',
    },
    '& .rs-input-group:focus-within': {
      borderColor: theme.palette.customColors.g75,
      boxShadow: 'none',
      outline: 'none',
      borderRadius: '4px'


    },
    '& .rs-input-group': {
      borderColor: theme.palette.customColors.g75 + " !important",
      // borderRadius:'8px'
    }


  }
}));

const CardHeader = ({ title, action, actionText, actionStyle = {}, CardHeaderStyle = {} }) => {
  return <>
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={"0.75rem"} sx={{ ...CardHeaderStyle }}>
      <Typography fontWeight={700} fontSize={"1.25rem"}>
        {title}
      </Typography>
      {/* {action && <WHITEBG
          component="label"
          role={undefined}
          tabIndex={-1}
          sx={{
              minWidth: "0 !important",
              border: `1px solid #D0D5DD !important`,
              display: "flex",
              gap: '0.5rem',
              paddingInline: '0.5rem 0.875rem',
              ...actionStyle,
          }}
          onClick={action}
      >
          <EditIcon
              style={{
                  width: 20,
                  height: 20,
              }} />
          <Typography>
              {actionText ? actionText : "Edit"}
          </Typography>
      </WHITEBG>} */}
    </Box>
    <Divider />
  </>
}

const PermissionsSkeletonLayout = () => {
  return (
    <Box className="permissions-skeleton-layout" style={{ paddingBlock: "0.5rem" }}>
      <Typography variant="h6" gutterBottom sx={{ display: "flex", flexWrap: 'nowrap', alignItems: 'center', gap: "0.5rem" }}>
        <div className="skeleton skeleton-checkbox" style={{}}></div> <div className="skeleton skeleton-label" style={{ width: '150px' }}></div> {/* Mimics Module Label */}
      </Typography>
      <Grid container spacing={0} marginLeft={'2rem'} paddingRight={"1.5rem"} marginTop={1}>
        {[...Array(4)].map((_, index) => ( // Assume 4 actions for skeleton loading
          <Grid item xs={12} sm={3} key={index}>
            <div className="checkbox-item" style={{ marginBottom: "0.5rem" }}>
              <div className="skeleton skeleton-checkbox"></div>
              <div className="skeleton skeleton-label" style={{ width: `${80 + index * 10}px` }}></div> {/* Varying label widths */}
            </div>
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ my: "10px", marginRight: "0.5rem" }} />
    </Box>
  );
};


const AddUser = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const permissions_template = useSelector((state) => state?.permissions?.template);
  const navigate = useNavigate();
  const scrollbarRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const version_id = Cookies.get("settings_version_id") || null;
  const isEditMode = !!version_id;
  const {
    mutateAsync: addVersionAsync,
    isLoading: isAddingVerionData,
    status: addVersionStatus,
    error: addVersionError,
    data: addVersionResponse
  } = AddVersionData();

  // const investor_id = useSelector((state) => state.startupDetails.data)?._id;
  let [investor_id, setInvestorID] = React.useState(Cookies.get("X-INV-ID") || null);
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  const validationSchema = Yup.object({
    version: Yup.string().required('Version name is required').matches(/^\d+\.\d+\.\d+$/, "Version name must follow the pattern x.x.x"),
    type: Yup.string().required("App type is required").test("validate-app-type", "Please select a valid app type.", (value) => {
      return value !== "select_app_type";
    }),
    release_notes: Yup.string().required("Release notes is required").test(
      'no-html-tags',
      'Release notes is required',
      (value) => {
        const plainText = value?.replace(/<[^>]*>/g, "");
        return plainText && plainText.trim().length > 0;
      }
    ),
    remark: Yup.string()
    // .required("Remarks is required")
    ,
    smoke_test: Yup.boolean().required("Smoke test is required"),
  });

  const initialValues = {
    version: '',
    release_notes: '',
    type: 'select_app_type',
    remark: '',
    smoke_test: null
  };

  const { data: versionDetailsData, isRefetching: isRefetchingUserDetail, isLoading: isLoadingUserDetail, refetch: refetchUserDetail } = FetchVersionDataByID({ version_id: version_id });
  // const { data: user_access_info, isLoading: isLoadingUserAccessInfo, refetch: refetchUserAccessInfo } = FetchUserPermissionsForCurrentInvestor({ investor_id });

  const savedValues = {
    version: versionDetailsData?.version,
    release_notes: versionDetailsData?.release_notes,
    type: versionDetailsData?.type,
    remark: versionDetailsData?.remark,
    smoke_test: versionDetailsData?.smoke_test
  }

  const matchesUpXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchUpXl = useMediaQuery(theme.breakpoints.up('xl'));
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentIndex, setCurrentIndex] = React.useState(0);

  // console.log(userDetailsData,"userDetailsData")

  const addUserTabs = [
    {
      label: isEditMode ? "Edit User" : "Add User",
      icon: ReportsIcon,
      // useStroke: true,
      value: "report",
    },
  ]
  const typeOptions = [
    { label: "Investor", value: "investor" },
    { label: "Startup", value: "startup" },
    { label: "Main Website", value: "main_website" },
  ]

  const BreadCrumbActions = () => {
    return (
      <>
        <NeedHelpButton />
      </>
    );
  };
  const [step, setStep] = React.useState(1);
  const handlePermissionChange = (module, permission) => {
    formik.setFieldValue("permissions", {
      ...formik?.values?.permissions,
      [module]: {
        ...formik?.values?.permissions[module],
        actions: {
          ...formik?.values?.permissions[module].actions,
          [permission]: !formik?.values?.permissions[module].actions[permission],
        },
      },
    });
  };


  const renderPermissions = (module, label, actions, actionLabels) => {
    const permissionsArray = Object.entries(actions);
    const hasModulePermission = Object?.values(actions)?.some(value => value === true);
    return (
      (formik?.values?.type === "collaborator") && module === "settings" ? <></>
        : <Box>
          <Typography variant="h6" gutterBottom>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasModulePermission}
                  disabled={module === "profile"}
                  onChange={() => {
                    const updatedActions = Object.keys(actions)?.map(key1 => {
                      return {
                        [key1]: !hasModulePermission
                      }
                    })?.reduce((acc, obj) => {
                      return { ...acc, ...obj };
                    }, {});

                    const updatedActionsTrue = Object.keys(actions)?.map(key1 => {
                      return {
                        [key1]: true
                      }
                    })?.reduce((acc, obj) => {
                      return { ...acc, ...obj };
                    }, {});

                    formik.setFieldValue(`permissions.${module}.actions`, (module === "profile") ? { ...updatedActionsTrue } : { ...updatedActions });

                    // setHasModulePermission(!hasModulePermission);

                    // updateModulePermission(module,  )
                  }}
                  // onChange={() => handlePermissionChange(module, key)}
                  sx={{
                    color: theme.palette.customColors.b300,
                    "&.Mui-checked": {
                      color: theme.palette.customColors.b300,
                    },
                  }}
                />
              }
              label={label}
            />
          </Typography>
          <Grid container spacing={0} marginLeft={'2rem'} paddingRight={"1.5rem"}>
            {permissionsArray.map(([key, value], index) => (
              <Grid item xs={12} sm={3} key={key}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={module === "profile" && key === "view" ? true : value}
                      disabled={module === "profile" && key === "view"}
                      onChange={() => {
                        if (module === "profile" && key === "view") {
                        }
                        else {
                          handlePermissionChange(module, key)
                        }
                      }}
                      sx={{
                        color: theme.palette.customColors.b300,
                        "&.Mui-checked": {
                          color: theme.palette.customColors.b300,
                        },
                      }}
                    />
                  }
                  label={actionLabels[key]}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
    );
  };

  const handleSubmit = (values) => {
    // const user = isEditMode ? {} : {
    //   // version: values?.version,
    //   // release_notes: values?.release_notes,
    //   // email: values?.email,
    // }

    // const includes_id = isEditMode ? { _id: userDetailsData?._id } : { investor_id: investor_id }

    // if (values?.type === "collaborator") {
    //   if (savedPermissions?.settings) {
    //     delete savedPermissions.settings;
    //   }
    // }
    // console.log(values, "values", formik?.errors)
    addVersionAsync(
      {
        payload: {
          ...values
        },
        isEditMode
      },
      {
        onSuccess: (successResponse) => {
          // console.log("onSuccess",successResponse?.data?.statusCode) 
          if (successResponse?.data?.statusCode === 200) {
            toast.success(isEditMode ? toast_message?.users?.edit : "Version Added!");
            navigate(-1)
          }
          else if (successResponse?.data?.statusCode === 401) {
            toast.error(successResponse?.data?.message);
          }
          // refetchUserAccessInfo().then((response) => {
          // const permissionsData = user_access_info?.is_owner ? (adminPermission({ permissionsTemplate: permissions_template })) : {
          //   ...response?.data?.permission
          // }
          // dispatch({
          //   type: SET_USER_ACCESS, permissions: permissionsData, isOwner: user_access_info?.is_owner,
          //   userName: user_access_info?.name
          // });
          // console.log(response?.data?.permission,"response")
          // });
          // navigate(-1)
        }
      }
    );
  };



  const formik = useFormik({
    initialValues: !isLoadingUserDetail && isEditMode ? savedValues : initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    enableReinitialize: true,
  })


  return (
    <ThemeBGWrapper>
      <BreadcrumbHeader
        // title={isLoadingReportDetail ? "Loading..." : `${isEditMode ? `Edit ${reportDetailData?.report?.name?.length > 0 ? "(" + reportDetailData?.report?.name + ")" : "Report"}` : "Add"}`}
        title={isEditMode ? "View" : "Add"}
        breadcrumbTitle='Release Notes'
        Actions={BreadCrumbActions}
      />
      {isLoadingUserDetail && isEditMode ?
        <Grid container xs={12} display={"flex"} height={"100%"} maxHeight={"85vh"} sx={{ padding: { xs: "0 1rem", sm: '0 1rem 1rem 1rem', md: "0 2rem 1rem 2rem" }, width: '100%' }}>
          <Grid item xs={12} className="skeleton" height={"100%"} width={"100%"} borderRadius={"24px"}>

          </Grid>
        </Grid>
        :
        <>
          <Grid
            container
            xs={12}
            sx={{
              // height: "calc(100vh - 118px)",
              height: '100%',
              maxHeight: { xs: "90%", sm: "93%", md: '85vh' },
              overflow: "hidden",
              padding: { xs: "0 1rem", sm: '0 1rem 1rem 1rem', md: "0 2rem 1rem 2rem" },
            }}
          >
            <Grid
              xs={12}
              item
              container
              sx={{
                backgroundColor: "#FFFAF5",
                borderRadius: { xs: "24px", sm: "24px" },
                height: "100%",
              }}
            >
              <TabContext value={currentIndex}>

                <Grid
                  item
                  container
                  // xs={12}
                  // // sm={5}
                  // md={6}
                  // lg={7.4}
                  sx={{
                    width: { xs: "100%", md: '60%', lg: '60%', xl: '70%' },
                    padding: { xs: '1rem', md: '2rem', lg: "2.5rem" },
                    paddingBlock: "0.5rem !important"
                    // border: `1px solid ${theme.palette.customColors.g75}`, bgcolor: theme.palette.customColors.whiteBG, borderRadius: "4px"
                  }}
                  // gap={3}
                  gap={"1rem"}
                  marginTop={0}
                  marginInline={"auto"}
                  display={"flex"}
                  alignItems={"flex-start"}
                  // alignContent={"space-around"}
                  alignContent={"space-between"}
                  justifyContent={"space-between"}
                  // padding={"2.5rem"}
                  paddingBottom={0}
                >
                  {/* {!matchesUpMd && */}
                  {/* <Box width={"100%"}>
                      {addUserTabs &&
                        addUserTabs?.length > 0 ?
                        <>
                          <Typography marginInline={"auto"} flexWrap={"wrap"} variant="h6"
                            fontSize="1rem"
                            color={theme.palette.customColors.g300}
                            fontWeight={700} textAlign={"center"} marginBottom={1}>
                            <span>{addUserTabs[currentIndex]?.label} 
                            </span>
                            <Divider sx={{ marginTop: 1, marginBottom: 2, marginInline: 'auto', maxWidth: '50%' }} />
                          </Typography>
                        </>
                        : ''}
                    </Box> */}
                  {/* } */}
                  <PerfectScrollbar containerRef={containerRef} style={{
                    minHeight: "58vh",
                    height: "100%",
                    width: "100%",
                    maxHeight: "calc(-210px + 100vh)",
                    paddingRight: matchesUpMd ? "1rem" : "0rem",
                    marginTop: 0,
                    paddingBottom: "1rem",
                  }}
                    ref={scrollbarRef}
                    id='add-user-scroller'
                    className="scrollbar-container"
                  >
                    {/* <TabPanel sx={{ paddingInline: 0 }} value={0}> */}
                    <FormikProvider value={formik}>
                      <Form onSubmit={e => e.preventDefault()}>
                        <Grid container columnSpacing={2} rowSpacing={1}>
                          <Grid item xs={12} container>
                            <Card sx={{ borderRadius: "10px", border: `1px solid ${theme.palette.customColors.b50}`, overflow: 'hidden', boxShadow: 'none', paddingTop: "1rem", width: "100%" }}>
                              <CardHeader title={"Version Details"} CardHeaderStyle={{ paddingInline: "1rem" }} />
                              <Grid container columnSpacing={2} rowSpacing={0} sx={{ padding: "1rem" }}>
                                <Grid item xs={12} sm={6}>
                                  <CustomInputField
                                    fullWidth
                                    name="version"
                                    label="VERSION NUMBER"
                                    disabled={isEditMode}
                                    InputProps={{ ...versionInputProps }}
                                    placeholder="Enter version number"
                                    value={formik?.values?.version}
                                    onChange={(e) => formik?.setFieldValue("version", capitalize(e.target.value))}
                                  // sx={{
                                  //   marginBottom: "0.5rem"
                                  // }}
                                  />
                                </Grid>

                                {/* <Grid item xs={12} sm={6} height={"min-content"}>
                                  <CustomInputField
                                    fullWidth
                                    name="email"
                                    label="Email"
                                    disabled={isEditMode}
                                    type="email"
                                    placeholder="Enter email"
                                    value={formik?.values?.email}
                                    onChange={(e) => formik?.setFieldValue("email", e.target.value)}
                                  //   onChange={(e) => formik?.setFieldValue("email", e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))}
                                  />
                                </Grid> */}
                                <Grid item xs={12} sm={6} height={"min-content"} >
                                  <CustomLabel>App Type</CustomLabel>
                                  <Select
                                    size="small"
                                    fullWidth
                                    name={`type`}
                                    placeholder='Select app type'
                                    value={formik?.values?.type}
                                    disabled={isEditMode}
                                    // onChange={(e) => {}
                                    //   // formik.setFieldValue(
                                    //   //   "runway_left",
                                    //   //   e.target.value
                                    //   // )
                                    // }
                                    onChange={formik?.handleChange}
                                    sx={{
                                      background: theme.palette.customColors.whiteBG,
                                      paddingBlock: "6px",
                                      margin: 0,
                                    }}
                                  >
                                    <MenuItem value={"select_app_type"} disabled>Select app type</MenuItem>
                                    {/* <MenuItem key={index} value={type?.value}>{type?.name}
                                </MenuItem> */}
                                    {/* {console.log(adminDefaultPermissions, "adminDefaultPermissions")} */}
                                    {typeOptions?.map((app_type) => (
                                      <MenuItem key={app_type?.value} value={app_type?.value} onClick={() => {
                                      }} >
                                        {app_type?.label}
                                      </MenuItem>))}

                                  </Select>
                                  {formik?.errors?.type && <CustomValidationText value={formik?.errors?.type} />}
                                </Grid>
                                <Grid item xs={12} sm={12} className='release_notes_editor'>
                                  {/* <CustomInputField
                                    fullWidth
                                    multiline
                                    name="release_notes"
                                    disabled={isEditMode}
                                    label="Release Notes"
                                    placeholder="Enter release notes"
                                    minRows={4}
                                    value={formik?.values?.release_notes}
                                    onChange={(e) => formik?.setFieldValue("release_notes", capitalize(e.target.value))}
                                  /> */}
                                  <CustomLabel>
                                    Release Notes
                                  </CustomLabel>
                                  <CustomRichTextEditor
                                    name="release_notes"
                                    label={
                                      <>
                                        {/* Release Notes */}
                                        {/* <img src="" alt="" /> */}
                                      </>
                                    }
                                    readOnly={isEditMode}
                                    placeholder={"Enter release notes"}
                                    value={formik.values?.release_notes}
                                    handleChange={(newValue) =>
                                      formik.setFieldValue(
                                        "release_notes",
                                        newValue
                                      )
                                    }
                                    maxLength={2000}
                                  // editorMinHeight='180px'
                                  />
                                  {formik.errors.release_notes && formik.touched.release_notes && (
                                    <CustomValidationText value={formik.errors.release_notes} />
                                  )}
                                </Grid>
                                <Grid item xs={12} sm={12} mt={2}>
                                  <CustomInputField
                                    fullWidth
                                    multiline
                                    name="remark"
                                    disabled={isEditMode}
                                    label="Remarks"
                                    placeholder="Enter remarks"
                                    minRows={4}
                                    sx={{ marginBottom: 0 }}
                                    value={formik?.values?.remark}
                                    onChange={(e) => formik?.setFieldValue("remark", capitalize(e.target.value))}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} key={"smoke_test"} mt={1}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={formik?.values?.smoke_test}
                                        disabled={isEditMode}
                                        onChange={(e) => {
                                          formik.setFieldValue("smoke_test", e.target.checked);
                                        }}
                                        sx={{
                                          color: theme.palette.customColors.b300,
                                          "&.Mui-checked": {
                                            color: theme.palette.customColors.b300,
                                          },
                                        }}
                                      />
                                    }
                                    label={<Box display={"flex"} alignItems={"center"}>
                                      <span>Smoke Test</span>
                                      <Tooltip title={<Box style={{ fontSize: '0.8rem' }}>
                                        <span>Smoke Test</span>
                                        <ul style={{ paddingLeft: '1rem' }}>
                                          <li>Login</li>
                                          <li>Sign up</li>
                                          <li>Email Check</li>
                                          <li>Profile page</li>
                                          <li>Release module</li>
                                        </ul>
                                      </Box>} placement='top'>
                                        <InfoOutlined sx={{ marginLeft: "0.5rem", color: theme.palette.customColors.indigo700, cursor: "pointer" }} />
                                      </Tooltip>
                                    </Box>}
                                  />
                                  {formik.errors.smoke_test && formik.touched.smoke_test && (
                                    <CustomValidationText value={formik.errors.smoke_test} />
                                  )}
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                          {/* <Grid item xs={12} height={"min-content"} >
                            <Card sx={{ borderRadius: "10px", border: `1px solid ${theme.palette.customColors.b50}`, overflow: 'hidden', boxShadow: 'none', paddingTop: "1rem" }}>
                              <CardHeader title={"Permissions"} CardHeaderStyle={{ paddingInline: "1rem" }} />
                              <PerfectScrollbar containerRef={containerRef} style={{
                                // minHeight: "58vh",
                                height: "100%",
                                width: "100%",
                                maxHeight: "calc(-300px + 100vh)",
                                paddingRight: "0.8rem",
                                marginTop: 0,
                                // paddingBottom: "1rem",
                              }}
                                id='permissions-scrollbar'
                              >
                                <Grid container columnSpacing={2} rowSpacing={0}>
                                  <Grid item xs={12} p={1} marginLeft={2} sx={{
                                    // paddingTop: "1rem 1rem 1rem 1rem",
                                    //  border: `1px solid ${theme.palette.customColors.g75}`, 
                                    bgcolor: theme.palette.customColors.whiteBG, borderRadius: "4px"
                                  }}>
                                    

                                    {isLoadingUserDetail && Object.keys(formik.values?.permissions)?.length === 0 ?
                                      <>
                                        <PermissionsSkeletonLayout />
                                        <PermissionsSkeletonLayout />
                                        <PermissionsSkeletonLayout />
                                        <PermissionsSkeletonLayout />
                                      </> :
                                      Object.entries(formik.values?.permissions)?.map(([module, { label, actions, actionLabels }], index) => {
                                        return <React.Fragment key={module}>
                                          {index !== 0 && <Divider sx={{ my: "10px", marginRight: "0.5rem" }} />}
                                          {renderPermissions(module, label, actions, actionLabels)}
                                        </React.Fragment>
                                      })}
                                  </Grid>
                                </Grid>
                              </PerfectScrollbar>
                            </Card>
                          </Grid> */}
                          {/* <Grid item mt={-1} xs={12}> */}


                        </Grid>
                      </Form>
                    </FormikProvider>
                    {/* </TabPanel> */}
                  </PerfectScrollbar>
                  <Grid
                    item
                    container
                    display={"flex"}
                    xs={12}
                    sm={12}
                    height={"min-content"}
                    gap={"1.5rem"}
                    justifyContent={"center"}
                    padding={{ xs: "0 0rem 1rem 0rem", md: "0 1rem 0rem 0rem" }}
                  // paddingRight={matchesUpMd ? "1rem" : "0rem"}
                  >
                    <Grid xs={5} sm={5} md={4} lg={4} item>
                      <BLUEBORDER
                        sx={{
                          textTransform: "none",
                          padding: 2,
                          borderWidth: 1,
                          borderRadius: "8px",
                        }}
                        disabled={isAddingVerionData}
                        // sx={{ padding: 2, borderRadius: "8px", ":disabled": { color: '#d9d9d9' } }}
                        onClick={(e) => {
                          navigate(-1)
                          // formik.resetForm();
                          // handleFormSubmit(e, formik);
                          // formik.setValues({ ...initialValues, month: savedMetricDataByID?.month });
                        }}
                        fullWidth
                      >
                        Close
                      </BLUEBORDER>
                    </Grid>
                    {!isEditMode && <Grid xs={5} sm={5} md={4} lg={4} item>
                      <CustomButton
                        sx={{ padding: 2, borderRadius: "8px", ":disabled": { color: '#d9d9d9' } }}
                        onClick={(e) => {
                          // formik.handleSubmit();
                          // handleSubmit(e)
                          handleFormSubmit(e, formik)
                        }}
                        // onClick={() => { setCurrentIndex(currentIndex + 1) }}
                        fullWidth
                      >
                        {(isAddingVerionData) ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.5rem'} /> : currentIndex == addUserTabs?.length - 1 ? "Submit" : "Next"}
                      </CustomButton>
                    </Grid>}
                  </Grid>
                </Grid>
              </TabContext>
            </Grid>
          </Grid>
        </>
      }
    </ThemeBGWrapper>
  )
}

export default AddUser