import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { FetchPermissionsTemplate, FetchUserPermissionsForCurrentInvestor } from 'api/permission';
import { SET_PERMISSION_TEMPLATE, SET_USER_ACCESS } from 'store/actions';
import { adminPermission, hasPermissionForRoute } from 'utils/index';

const PermissionsHandler = ({ investor_id }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const userPermissions = useSelector((state) => state?.permissions?.userPermissions);

    const {
        data: permissions_template,
        isLoading: isLoadingPermissionsTemplate,
        refetch: refetchPermissionsTemplate
    } = FetchPermissionsTemplate();

    const {
        data: user_access_info,
        isLoading: isLoadingUserAccessInfo,
        refetch: refetchUserAccessInfo
    } = FetchUserPermissionsForCurrentInvestor({ investor_id });

    // Effect to set permissions template and user access info
    useEffect(() => {
        if (!isLoadingPermissionsTemplate && permissions_template) {


            // console.log(permissions_template, "permissions_template_invesstor")
            dispatch({ type: SET_PERMISSION_TEMPLATE, data: permissions_template });

            if (!isLoadingUserAccessInfo && user_access_info) {
                const permissionsData = user_access_info?.is_owner
                    ? adminPermission({ permissionsTemplate: permissions_template })
                    : { ...user_access_info.permission };
                dispatch({
                    type: SET_USER_ACCESS, permissions: permissionsData, isOwner: user_access_info?.is_owner,
                    userName: user_access_info?.name
                });
            }
        }
    }, [permissions_template, user_access_info, isLoadingPermissionsTemplate, isLoadingUserAccessInfo]);

    // Effect to check and validate route permissions
    useEffect(() => {
        if (investor_id && userPermissions) {
            // refetchUserAccessInfo().then((response) => {
            if (!hasPermissionForRoute(userPermissions, location.pathname)) {
                navigate('/profile', { replace: true });
            }
            // });
        }
    }, [investor_id, userPermissions, navigate]);

    return null; // Render nothing; this component just handles logic
};

export default PermissionsHandler;
