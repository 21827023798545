import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { RetryOnError, verifyToken } from "../utils/VerifyToken";
import css_sprites from '../assets/sector_icons/combined.png'
import { popularCountrySort } from "../utils";
import India from "../assets/india.png"
import Canada from "../assets/canada.png"
import Singapore from "../assets/singapore.png"
import Uae from "../assets/uae.png"
import Uk from "../assets/uk.png"
import Usa from "../assets/usa.png"

import Round1 from "assets/stage_icons/preSeed.png";
import Round2 from "assets/stage_icons/seed.png";
import Round3 from "assets/stage_icons/seriesA.png";
import Round4 from "assets/stage_icons/seriesB.png";
import Round5 from "assets/stage_icons/ipo.png";

const roundImages = [
    Round1, Round2, Round3, Round4, Round5
]

const sectorStyles = [
    {
        name: "Transport", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -10px -15px`
        }
    },
    {
        name: "Transport", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -346px -99px`
        }
    },
    {
        name: "Transport", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -346px -183px`
        }
    }, {
        name: "Transport", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -94px -183px`
        }
    }, {
        name: "Transport", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -178px -183px`
        }
    },
    {
        name: "Transport", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -94px -267px`
        }
    },
    {
        name: "Media", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -10px -351px`
        }
    }, {
        name: "Property tech", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -94px -351px`
        }
    }, {
        name: "Service tech", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -346px -267px`
        }
    }, {
        name: "Social Com tech", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -262px -183px`
        }
    }, {
        name: "Beauty", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -262px -15px`
        }
    }
    , {
        name: "D2C Consumer Tech", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -178px -351px`
        }
    }, {
        name: "Ed Tech", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -262px -267px`
        }
    }, {
        name: "Ecom & Sm", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -10px -267px`
        }
    },
    {
        name: "FinTech", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -262px -351px`
        }
    },
    {
        name: "Gaming Startups", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -346px -15px`
        }
    }, {
        name: "Gaming Startups", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -10px -99px`
        }
    }, {
        name: "Health", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -94px -15px`
        }
    }, {
        name: "Law", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -94px -99px`
        }
    }, {
        name: "Real Estate", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -178px -15px`
        }
    }, {
        name: "agriculture", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -178px -99px`
        }
    }, {
        name: "Sports", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -10px -183px`
        }
    }, {
        name: "Agree", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -178px -267px`
        }
    }, {
        name: "AgriTech & Farming Startup", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -262px -99px`
        }
    },
    {
        name: "Health Care", style: {
            width: '64px',
            height: '64px',
            transform: "scale(0.4)",
            background: `url(${css_sprites}) -346px -351px`
        }
    }
]

export const FetchAllCountries = () => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-countries-fetch'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings/countries`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data.data.data,"countries")
            return data.data.data && data.data.data?.length > 0 ? data.data.data?.map(country => ({ label: country?.name, value: country?.id, _id: country?._id })) : []
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

// export const FetchAllCountries = ({ search }) => {
//     // console.log(page.page,page.search,"page")
//     return useQuery(
//         ['master-listing-template-all-countries-fetch', search],
//         () => {
//             return axios({
//                 method: 'get',
//                 url: `${process.env.REACT_APP_BASE_URL}/settings/countries?searchText=${search}`,
//                 headers: {
//                     'Authorization': `Bearer ${Cookies.get('jwt')}`
//                 },
//             })
//         }, {
//         // select: (data) => data,
//         select: (data) => {
//             // console.log(data.data.data,"countries")
//             return data.data.data && data.data.data?.length > 0 ? data.data.data?.map(country => ({ label: country?.name, value: country?.id})) : []
//         },
//         refetchOnWindowFocus: false,
//         retry: (failureCount, error) => {
//             return RetryOnError(failureCount, error)
//         },
//         onError: (err) => {
//             return verifyToken(err)
//         },
//     })
// }

export const FetchAllRoundTypes = (type = "") => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-round-typesss-fetch-all-rounds_type'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings?config_type=rounds_type`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {

            const startup_stages = [
                { name: "1", size: { width: "6rem", height: "2.649rem", paddingTop: "3.5rem" } },
                { name: "2", size: { width: "6rem", height: "3.649rem", paddingTop: "2.5rem" } },
                { name: "3", size: { width: "6rem", height: "4.649rem", paddingTop: "1.5rem" } },
                { name: "4", size: { width: "6rem", height: "6.7rem", paddingTop: "0rem" } },
                { name: "5", size: { width: "6rem", paddingTop: "1rem" } }];
            return data.data.data[0]?.config_values?.map((item, index) => {
                return ({
                    ...item,
                    image_url: roundImages[index],
                    name: item?.name,
                    value: item?._id,
                    size: startup_stages[index]?.size
                    // size: type == "round" ? roundTypeImages[index]?.style : stagesImages[index]?.style
                })
            })
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}
export const FetchAllCountriesWithPhoneCodes = ({ search = "" }) => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-countries-phone-code-fetch', search],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings/countries?searchText=${search}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data.data.data,"countries")
            return data.data.data && data.data.data?.length > 0 ? data.data.data?.map(country => ({ value: country?._id, label: country?.name, code: country?.phone_code, ...country })) : []
        },
        refetchOnWindowFocus: false,
        enabled: search != undefined,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}
export const FetchAllPopularCountries = () => {
    // console.log(page.page,page.search,"page")
    let countryID = 101;
    return useQuery(
        ['master-listing-template-all-populaefefefefefr-countries'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings/countries?is_popular=1`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data, 'Data')
            const filterData = data.data.data && data.data.data?.length > 0 ? data.data.data?.map(country => {
                return {
                    label: country?.name,
                    value: country?.id,
                    _id: country?._id,
                    size: { width: "6rem", height: "6rem", paddingTop: "0rem" },
                    // image_url: country?.image_url,
                    image_url: getCountryImage(country),
                    is_popular: country?.is_popular
                }
            }) : []
            let popularFilterData = filterData
            if (filterData?.length > 0) {
                popularFilterData = filterData.filter(country => {
                    return country.is_popular == "1"
                })
                popularFilterData = popularFilterData.sort(function (a, b) {
                    return popularCountrySort.indexOf(a.name) - popularCountrySort.indexOf(b.name);
                });
            }
            return popularFilterData
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

const getCountryImage = (country) => {
    switch (country?.name) {
        case "India": {
            return India
        }
        case "Canada": {
            return Canada
        }
        case "Singapore": {
            return Singapore
        }
        case "United Arab Emirates": {
            return Uae
        }
        case "United Kingdom": {
            return Uk
        }
        case "United States": {
            return Usa
        }
        default: {
            return country?.image_url
        }
    }
}

export const FetchAllStates = ({ isPopular = 0, countryID }) => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-cities-fetch' + countryID, countryID],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings/states/${countryID}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data.data.data,'values new')
            return data.data.data && data.data.data?.length > 0 ? data.data.data?.map(state => ({ label: state?.name, value: state?.id, _id: state?._id })) : []
        },
        refetchOnWindowFocus: false,
        enabled: !!countryID,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}
export const FetchAllCities = ({ isPopular = 0, stateID }) => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-cities-fetch' + stateID, stateID],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings/cities/${stateID}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => data.data.data && data.data.data?.length ? data.data.data?.map(city => ({ label: city?.name, value: city?.id, _id: city?._id })) : [],
        refetchOnWindowFocus: false,
        enabled: !!stateID,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}
export const FetchAllPopularCities = ({ countryID = 101 }) => {

    return useQuery(
        ['master-listing-template-all-popular-cities'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings/cities/${countryID}?is_popular=1`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => data.data.data?.length > 0 ? data.data.data?.map(city => {
            const insertSize = () => {
                switch (city?.name) {
                    case "Delhi":
                        return { width: "4rem", height: "7.813rem" }

                    case "Ahmedabad":
                        return { width: "5.188rem", height: "5.313rem", paddingTop: "1rem" }

                    case "Mumbai":
                        return { width: "6rem", height: "6.3rem" }

                    case "Jaipur":
                        return { width: "4.75rem", height: "6.75rem" }

                    case "Kolkata":
                        return { width: "3.938rem", height: "6.1rem" }

                    case "Hyderabad":
                        return { width: "4rem", height: "6.063rem" }
                }
            }
            return (
                { _id: city?._id, name: city?.name, image_url: city?.image_url, size: insertSize(city?.name) }
            )
        }) : [],
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}
export const FetchAllStages = () => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-stages-fetch-all'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings?config_type=stage`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {

            const startup_stages = [
                { name: "Pre-Seed", size: { width: "6rem", height: "2.649rem", paddingTop: "3.5rem" } },
                { name: "Seed", size: { width: "6rem", height: "3.649rem", paddingTop: "2.5rem" } },
                { name: "Bridge", size: { width: "6rem", height: "4.649rem", paddingTop: "1.5rem" } },
                { name: "Series", size: { width: "6rem", height: "6.7rem", paddingTop: "0rem" } },
                { name: "Pre-IPO", size: { width: "6rem", paddingTop: "1rem" } }];
            return data.data.data[0]?.config_values?.map((item, index) => {
                return ({
                    ...item,
                    name: item?.name,
                    value: item?._id,
                    size: startup_stages[index]?.size
                })
            })
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}
export const FetchAllSectors = () => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-sectors-fetch-all'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings?config_type=sector`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {

            return data.data.data[0]?.config_values
                ?.map((item, index) => {
                    return ({
                        ...item,
                        name: item?.name,
                        style: { ...sectorStyles[index]?.style }
                        // image_url: String(item?.image_url).replace("startups", "investors")
                    })
                })
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}
export const FetchAllRoundsSteps = () => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-rounds-fetch--all'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings?config_type=rounds`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data.data.data[0]?.config_values, "data.data.data[0]")
            let listing = data.data.data[0]?.config_values?.map(item => ({ name: item?.name, sub_title: item?.sub_title })) || [];
            return listing
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchAllCurrencyLists = () => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-currency-fetch-all'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings?config_type=investement_amount`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data.data.data[0]?.config_values, "data.data.data[0]")
            return data.data.data[0]?.config_values
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchAllRoundStatus = () => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-round-status-fetch-all'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings?config_type=round_status`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data.data.data[0]?.config_values, "data.data.data[0]")
            return data.data.data[0]?.config_values?.map(item => {
                return ({
                    ...item,
                    value: item?._id
                })
            })
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchAllSecurityTypes = () => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-security-types-fetch-all'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings?config_type=security_types`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data.data.data[0]?.config_values, "data.data.data[0]")
            return data.data.data[0]?.config_values?.map(item => {
                return ({
                    ...item,
                    value: item?._id
                })
            })
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}
export const FetchAllDocumentTypes = () => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-documentsssss-types-fetch-all'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings?config_type=document_type`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data.data.data[0]?.config_values, "data.data.data[0]")
            return data.data.data[0]?.config_values?.map(item => {
                return ({
                    ...item,
                    value: item?._id
                })
            })
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}
export const FetchAllBankAccountTypes = () => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-bankacccountstypes-types-fetch-all'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings?config_type=account_type`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data.data.data[0]?.config_values, "data.data.data[0]")
            return data.data.data[0]?.config_values?.map(item => {
                return ({
                    ...item,
                    label: item?.name,
                    value: item?._id
                })
            })
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}
export const FetchAllBanks = () => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-banksssss-fetch-all'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings/get-banks`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data, "data.data.data[0]")
            return data.data.data?.map(item => {
                return ({
                    ...item,
                    label: item?.name,
                    value: item?._id
                })
            })
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const GetConfigurations = () => {
    return useQuery(
        ['Fetch-all-dynamic-configurations'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/validation?validation_type=investor_details`,
                // headers: {
                //     'Authorization': `Bearer ${Cookies.get('jwt')}`
                // },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            let response = data.data.data
            // console.log("response", response)
            return response
            // return data.data.data?.map(item => {
            //     return ({
            //         ...item,
            //         label: item?.name,
            //         value: item?._id
            //     })
            // })
        },
        refetchOnWindowFocus: false,
        onError: (err) => {
            console.error(err);
        },
    })
}

export const FetchAllAvatars = () => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-populaefefefefefr-avatar'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings?config_type=avatar`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            const filterData = data.data.data && data.data.data?.length > 0 ? data.data.data[0]?.config_values?.map(avatar => {
                return {
                    _id: avatar?._id,
                    image_url: avatar?.image_url
                }
            }) : []
            return filterData
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchAllInvestmentSize = () => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-populaefefefefefr-amount'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings?config_type=investement_amount`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            let amountArray = []
            const filterData = data.data.data && data.data.data?.length > 0 ? data.data.data[0]?.config_values?.map(sizes => {
                return sizes.values.map(item => {
                    return {
                        id: item.string,
                        title: item.string,
                        currency: sizes.name,
                        min: item.min,
                        max: item.max
                    }
                })
            }
            ) : []

            filterData.forEach(element => {
                amountArray = [...amountArray, ...element]
            });

            const filterCurrencies = data.data.data && data.data.data?.length > 0 ? data.data.data[0]?.config_values?.map(sizes => {
                return {
                    name: sizes.name
                }
            }) : []
            let modifiedData = {
                amount: amountArray,
                currencies: filterCurrencies
            }
            return modifiedData
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchAllCategories = () => {
    return useQuery(
        ['master-listing-template-all-categories-fetch-all'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings?config_type=category`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {

            return data.data.data[0]?.config_values
                ?.map((item, index) => {
                    return ({
                        ...item,
                        name: item?.name,
                    })
                })
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}
export const FetchAllSubCategories = ({ category = "Marketplace" }) => {
    // console.log(page.page,page.search,"page")
    let categories = category?.length > 0 ? category : "Marketplace"
    return useQuery(
        ['master-listing-template-all-sub-sub-sub-categories-fetch-all' + categories, categories],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings?config_type=${categories}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
    //     select: (data) => {
    // // console.log(data, "data")
    //         return data.data.data[0]?.config_values
    //             ?.map((item, index) => {
    //                 return ({
    //                     ...item,
    //                     name: item?.name,
    //                 })
    //             })
    //     },
    select: (data) => {
        // Merge config_values from all sections
        return data.data.data
            ?.flatMap(section => 
                section?.config_values?.map(item => ({
                    ...item,
                    name: item?.name,
                }))
            );
    },
    
        refetchOnWindowFocus: false,
        enabled: categories?.length > 0,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchAllBusinessModel = () => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-startup_business_model-fetch-all'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/settings?config_type=startup_business_model`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {

            return data.data.data[0]?.config_values
                ?.map((item, index) => {
                    return ({
                        ...item,
                        name: item?.name,
                        // style: { ...sectorStyles[index]?.style }
                        // image_url: String(item?.image_url).replace("startups", "investors")
                    })
                })
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}