import { useTheme } from '@emotion/react'
import { Grid } from '@mui/material'
import { FetchAllStages } from 'api/utils'
import { useConfig } from 'dynamic-configuration/configContext'
import { useFormikContext } from 'formik'
import React from 'react'
import CustomLabel from 'ui-components/CustomLabel'
import CustomValidationText from 'ui-components/CustomValidationText'

const PreferredStages = () => {
  const formik = useFormikContext();
  const theme = useTheme();
  const { config } = useConfig();
  const [selectedStages, setSelectedStages] = React.useState(formik?.values?.stage_id || [])
  const { data: startup_stages, isRefetching: isRefetchingStages, isLoading: isLoadingStages, refetch: refetchStages } = FetchAllStages()
  return (
    <>
      <CustomLabel sx={{ marginTop: "0rem", textTransform: 'none', position: 'sticky', top: "-1px", bgcolor: theme.palette.customColors.yellow_bg, width: '100%', zIndex: 4 }} >{config?.validationFields?.stages?.label} (MULTI SELECT) {formik.errors.stage_id && (
        <CustomValidationText value={formik.errors.stage_id} />
      )}</CustomLabel>
      <Grid item container xs={12} gap={2} mt={1} display={"flex"} paddingBottom={4}>
        {startup_stages?.map((stage, index) => {
          const isSelected = formik.values.stage_id?.includes(stage?._id);
          return (
            <Grid key={index}
              onClick={() => {
                if (isSelected) {
                  selectedStages.splice(formik.values.stage_id.indexOf(stage?._id), 1);
                } else {
                  selectedStages.push(stage?._id);
                }
                formik.setFieldValue("stage_id", selectedStages)
              }
              } item xs={12} sm={5.8} md={5.6} lg={5.75} flex={1} >
              <button key={index} onClick={(e) => e.preventDefault()} style={{
                minHeight: "2.5rem",
                height: "100%",
                maxHeight: '4rem',
                width: "100%",
                padding: "1rem 0rem 6rem 0.5rem",
                background: isSelected ? "#FFFAE6" : "#FFF",
                fontSize: "0.875rem",
                cursor: 'pointer',
                border: `1px solid ${isSelected ? "#FFC900" : "#A1A3A7"}`,
                borderRadius: "8px",
                overflow: "hidden",
                display: "flex",
                justifyContent: "space-between",
                position: 'relative'
              }}>
                <span style={{ position: 'absolute', fontWeight: 600, top: "5px" }}>
                  {stage?.name}
                </span>
                <img src={stage?.image_url} rel='preload' style={{ ...stage?.size, marginLeft: 'auto' }} />
              </button>
            </Grid>
          )
        }
        )}
      </Grid>
    </>
  )
}

export default PreferredStages