import { Grid } from '@mui/material';
import { useConfig } from '../../../dynamic-configuration/configContext'
import { useFormikContext } from 'formik';
import React from 'react'
import CustomLogoButton from 'ui-components/formik/CustomLogoButton'
import placeHolderImage from "assets/user_profile.png"

const UploadPhoto = () => {
    const { config } = useConfig();
    const formik = useFormikContext();
    return (
        <Grid container xs={12} gap={0} sx={{ overflowX: "hidden" }}>
            <Grid item xs={12} sm={12}>
                <CustomLogoButton label={config?.dynamicText?.uploadPhoto} handleUpdateFile={(file) => { formik.setFieldValue("custom_image_url", file); formik.setFieldValue("avatar_selected", null) }} name={"custom_image_url"} value={formik.values.custom_image_url} placeholder={placeHolderImage} Pheight={100} Pwidth={100} />
                {/* <CustomUploadButton label="STARTUP LOGO" handleUpdateFile={(evt) => { formik.setFieldValue("startup_logo", evt.target.files[0]) }} selectedFile={formik.values.startup_logo} /> */}
            </Grid>
        </Grid>
    )
}

export default UploadPhoto