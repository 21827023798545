import { Avatar, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";

const getAvatarProps = (name, style) => {
    const initials = name && name
        ? name?.split(' ')
            ?.map((n) => n[0])
            ?.join('')
            ?.toUpperCase()
        : '';
    return {
        sx: {
            bgcolor: '#F9F5FF',
            color: '#3538CD',
            width: 48,
            height: 48,
            fontSize: 16,
            fontWeight: 500,
            borderRadius: "4px",
            cursor: "pointer",
            position: "relative",
            ...style
        },
        children: initials?.slice(0, 2),
    };
};

function ImageAvatar({ src, alt, width = 48, height = 48, style, tooltip = false, toolTipPlacement = "bottom" }) {
    const [imageError, setImageError] = useState(src?.includes("undefined") || false);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        if (!src || src.includes("undefined")) {
            setImageError(true);
            return;
        }

        const img = new Image();
        img.src = src;

        img.onload = () => {
            setImageLoaded(true);
            setImageError(false);
        };

        img.onerror = () => {
            setImageError(true);
            setImageLoaded(false);
        };
    }, [src]); // Runs when `src` changes

    return (
        <div style={{ position: "relative", display: "inline-block", width, height }}>
            {/* Avatar Background (Always Visible) */}
            {imageError && (tooltip ? (
                <Tooltip title={alt} placement={toolTipPlacement}>
                    <Avatar {...getAvatarProps(alt)} />
                </Tooltip>
            ) : (
                <Avatar {...getAvatarProps(alt)} />
            ))}

            {/* Image on Top (Hidden until loaded) */}
            {!imageError && (tooltip ?
                <Tooltip title={alt} placement={toolTipPlacement}>
                    <img
                        src={src}
                        alt={alt}
                        width={width}
                        height={height}
                        style={{
                            ...style,
                            cursor: "pointer",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            // borderRadius: "inherit",
                            display: imageLoaded ? "block" : "none",
                        }}
                    />
                </Tooltip> : <img
                    src={src}
                    alt={alt}
                    width={width}
                    height={height}
                    style={{
                        ...style,
                        cursor: "pointer",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        // borderRadius: "inherit",
                        display: imageLoaded ? "block" : "none",
                    }}
                />
            )}
        </div>
    );
}

export default ImageAvatar;
