import React from 'react'
import packageJSON from "../../package.json"
import { IconButton, Snackbar } from '@mui/material';
import { Close, RefreshOutlined } from '@mui/icons-material';
import { versionCheck } from 'utils/index';

const ReloadPopup = ({ newVersion }) => {
    // const [openSnack, setOpenSnack] = React.useState(false)
    const [openSnack, setOpenSnack] = React.useState(true)
    // console.log(currentBackground[0].style.backgroundColor === "#3538CD","currentBackground")
    const handleClose = () => {
        setOpenSnack(false); // Close the snackbar
    };
    const handleReload = () => {
        // window.location.reload(true); // Force reload to clear old cache
        window.location.href = window.location.href; // More reliable alternative
    };
    return (
        <Snackbar
            className="snackccc"
            // open={openSnack}
            open={(newVersion && 
                versionCheck({ latestVersion: newVersion, currentVersion: process.env.REACT_APP_MODE === "production" ? process.env.REACT_APP_BASE_PROD_VERSION : process.env.REACT_APP_BASE_DEV_VERSION })
            ) && openSnack}
            message={
                <div className="snackbar-content">
                    <div>
                        <strong>A new version is available!</strong>
                        <div className="snackbar-details">
                            {/* Your current version is <strong>{packageJSON?.version}</strong>. */}
                            Reload your page to get the latest features to version.
                        </div>
                    </div>
                    <button onClick={handleReload} className="snackbar-action" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <RefreshOutlined /> Reload
                    </button>
                </div>
            }
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            // action={
            //     <IconButton
            //         size="small"
            //         aria-label="close"
            //         color="inherit"
            //         onClick={handleClose}
            //         sx={{ position: 'absolute', top: '0px', right: '0px', opacity: 0.3, transition: 'all 250ms ease', ":hover": { opacity: 1 } }}
            //     >
            //         <Close fontSize="small" />
            //     </IconButton>
            // }
        />
    )
}

export default ReloadPopup