import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { RetryOnError, verifyToken } from "../utils/VerifyToken"
import { capitalize } from "@mui/material";
import { capitalizedSentence } from "utils/index";

export const AddVersionData = () => {
    return useMutation(({ payload, isEditMode }) => {
        return axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}/users/add-version`,
            headers: {
                'Authorization': `Bearer ${Cookies.get('jwt')}`,
                'Content-type': 'application/json'
            },
            data: payload
        })
    }, {
        retry: 0,
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchAllVersionsData = ({ investor_id, page = 1, limit = 10, searchText = "", enabled, app_type }) => {
    // console.log(id,"id")
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['FetchAllVersionsData-FetchAllVersionsData-template-for-all-FetchAllVersionsData-FetchAllVersionsData-fetch' + investor_id, investor_id, page, limit],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/users/get-all-version/?search=${searchText}&page=${page}&limit=${limit}${app_type === "all" ? "" : `&type=${app_type}`}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data,'values new')
            return {
                data: data?.data?.data?.length > 0 ? data?.data?.data?.map(item => {
                    // console.log(item,"item")
                    return {
                        ...item,
                        app_type: item?.type ? capitalizedSentence(String(item?.type)?.replaceAll("_", " ")) : "-",
                        created_by: item?.user_details?.first_name + " " + item?.user_details?.last_name,
                    }
                }) : [], count: data?.data?.count, limit: Number(data?.data?.limit)
            }
        },
        enabled: !!investor_id && enabled,
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchVersionDataByID = ({ version_id }) => {
    return useQuery(
        ['master-FetchUserDataByID-version_id-FetchUserDataByID-using-id-version_id' + version_id, version_id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/users/version-detail/${version_id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data?.data?.data,'values new')
            const savedValues = data?.data?.data;

            if (Object.keys(savedValues)?.length > 0) {
                const savedData = { ...savedValues, ...savedValues?.data }
                delete savedData.data;
                return savedData;
            }
            else {
                return null;
            }

            // console.log(savedData,'values new')

            // return { data: savedData?.configuredMetrics ? savedData?.configuredMetrics : [], ...savedData }
        },
        refetchOnWindowFocus: false,
        enabled: !!version_id,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const DeleteUserDataByID = () => {
    return useMutation(
        ({ user_id }) => {
            return axios({
                method: 'delete',
                url: `${process.env.REACT_APP_BASE_URL}/investors/delete-collaborator/${user_id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                // data: payload
            });
        },
        {
            retry: 0,
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};
