import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { B300 } from '../../ui-components/CustomButton';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Add, Create } from '@mui/icons-material';
import placeHolderImageReport from 'assets/no_data_images/Startups/funding_startups.svg';

const NoReports = () => {
    const navigate = useNavigate();
    const theme = useTheme()
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            // minHeight="calc(100% - 164px)"
            height="100%"
            maxHeight="calc(100vh - 250px)"
            textAlign="center"
            padding={3}
        >
            {<img
                src={placeHolderImageReport} // Replace with your image path
                // alt="No Data"
                style={{ width: '200px', height: 200, marginBottom: '20px', background: '', borderRadius: 8, }} // Adjust the size as needed
            />}
            <Typography variant="title" gutterBottom>
                No Reports
            </Typography>
            <Typography variant="body1" marginBottom={2} gutterBottom>
                It seems like there are no reports available at the moment.
            </Typography>
            {/* <B300 onClick={() => navigate("/reports/create_report")} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 600 }}
            // startIcon={<Create fontSize='1.25rem' />}
            >
                Create a New Report
            </B300> */}
        </Box>
    );
};

export default NoReports;
