import styled from '@emotion/styled';
import { alpha, Switch } from '@mui/material';
import React from 'react'

const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#e0e0e0",
        '&:hover': {
            backgroundColor: alpha("#3538CD", theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#3538CD",
    },
}));

export default CustomSwitch