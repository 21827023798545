import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { RetryOnError, verifyToken } from "../utils/VerifyToken"

export const AddUserCollaborator = () => {
    return useMutation(({ payload, isEditMode }) => {
        return axios({
            method: isEditMode ? "put" : 'post',
            url: `${process.env.REACT_APP_BASE_URL}/investors/${isEditMode ? "edit" : "add"}-collaborator`,
            headers: {
                'Authorization': `Bearer ${Cookies.get('jwt')}`,
                'Content-type': 'application/json'
            },
            data: payload
        })
    }, {
        retry: 0,
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchAllUsersData = ({ investor_id, page = 1, limit = 10, searchText = "", enabled }) => {
    // console.log(id,"id")
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['FetchAllUsersData-FetchAllUsersData-template-for-all-FetchAllUsersData-FetchAllUsersData-fetch' + investor_id, investor_id, page, limit],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/collaborator-listing/${investor_id}?search=${searchText}&page=${page}&limit=${limit}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data,'values new')
            return { data: data?.data?.data, count: data?.data?.count, limit: Number(data?.data?.limit) }
        },
        enabled: !!investor_id && enabled,
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchUserDataByID = ({ user_id }) => {
    return useQuery(
        ['master-FetchUserDataByID-FetchUserDataByID-FetchUserDataByID-using-id-FetchUserDataByID' + user_id, user_id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/collaborator-detail/${user_id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data?.data?.data,'values new')
            const savedValues = data?.data?.data;

            if (Object.keys(savedValues)?.length > 0) {
                const savedData = { ...savedValues, ...savedValues?.data }
                delete savedData.data;
                return savedData;
            }
            else {
                return null;
            }

            // console.log(savedData,'values new')

            // return { data: savedData?.configuredMetrics ? savedData?.configuredMetrics : [], ...savedData }
        },
        refetchOnWindowFocus: false,
        enabled: !!user_id,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const DeleteUserDataByID = () => {
    return useMutation(
        ({ user_id }) => {
            return axios({
                method: 'delete',
                url: `${process.env.REACT_APP_BASE_URL}/investors/delete-collaborator/${user_id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                // data: payload
            });
        },
        {
            retry: 0,
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};