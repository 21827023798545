import { Grid, } from "@mui/material";
import React, { useRef } from "react";
import CustomAutoComplete from "ui-components/CustomAutoComplete";
import CustomPhone from "ui-components/CustomPhone";
import CustomUrlField from "ui-components/CustomUrlField";
import CustomInputField from "ui-components/formik/CustomInputField";
import { AddStartup, FetchAllCategories, FetchAllCities, FetchAllCountries, FetchAllCountriesWithPhoneCodes, FetchAllStates, FetchAllSubCategories, FetchStartupDetails } from "api";
import { ReactComponent as gpsIcon } from "assets/gps.svg"
import { useFormikContext } from "formik";
import { useTheme } from "@emotion/react";
import { FetchEditStartupDetails } from "api/backrr_startups";
import Cookies from "js-cookie";



const ContactInformation = ({ isStartupDetailsLoading = false }) => {
  const formik = useFormikContext()
  const theme = useTheme()
  const [search, setSearch] = React.useState("")
  const [searchState, setSearchState] = React.useState("")
  const [searchCountry, setSearchCountry] = React.useState("")
  const cookieStartupId = Cookies.get("startup_id")
  const { data: startupDetailsData, isLoading: isStartupEditDetailsLoading, refetch: refetchStartupDetails } = FetchEditStartupDetails({ startup_id: cookieStartupId })
  const { data: countries, isRefetching: isRefetchingCountries, isLoading: isLoadingCountries, refetch: refetchCountries } = FetchAllCountries({ search: searchCountry })
  const { data: countriesWithPhoneCodes, isRefetching: isRefetchingCountriesPC, isLoading: isLoadingCountriesPC, refetch: refetchCountriesPC } = FetchAllCountriesWithPhoneCodes({})
  // const [savedCountriesList,setSavedCountriesList] = React.useState([])
  const [currentCountry, setCurrentCountry] = React.useState(countries?.find(item => item?._id == formik?.values?.country_id) || null)
  const { data: states, isRefetching: isRefetchingStates, isLoading: isLoadingStates, refetch: refetchStates } = FetchAllStates({ search: searchState, countryID: currentCountry !== null ? countries?.find(item => item?._id == formik.values?.country_id)?.value : currentCountry?.value })
  const [currentState, setCurrentState] = React.useState(states?.find(item => item?.value === formik?.values?.state_id)?.label || null)
  const { data: cities, isRefetching: isRefetchingCities, isLoading: isLoadingCities, refetch: refetchCities } = FetchAllCities({ search, stateID: currentState !== null ? states?.find(item => item?._id == formik.values?.state_id)?.value : currentState?.value, isPopular: 0 });
  // const [savedCitiesList,setSavedCitiesList] = React.useState([])
  const [currentCity, setCurrentCity] = React.useState(cities?.find(item => item?.value === formik?.values?.city_id)?.label || null);
  const handleSearchCityChange = (event, value) => {
    // console.log(event.target.value, value,"ca")
    setSearch(value);
    // refetchCities();
  };
  const handleSearchCountryChange = (event, value) => {
    // console.log(event.target.value, value,"ca")
    setSearchCountry(value);
    // refetchCities();
  };
  const handleSearchStateChange = (event, value) => {
    // console.log(event.target.value, value,"ca")
    setSearchState(value);
    // refetchCities();
  };
  const handleSelectionCityChange = (event, value) => {
    // console.log(value, "value")

    formik.setFieldValue("city_id", value ? value?._id : '');
    setCurrentCity(value)
    event.preventDefault();
  };
  const handleSelectionCountryChange = (event, value) => {
    // console.log(value, "value")
    formik.setFieldValue("country_id", value ? value?._id : '');
    setCurrentCountry(value)
    formik.setFieldValue("state_id", null);
    setCurrentState(null)
    setCurrentCity(null);
    formik.setFieldValue("city_id", null)
    setSearchState("")
    setSearch("")
    refetchData()
    event.preventDefault();
  };
  const handleSelectionStateChange = (event, value) => {
    // console.log(value, "value")
    formik.setFieldValue("state_id", value ? value?._id : null);
    setCurrentState(value)
    setCurrentCity(null);
    formik.setFieldValue("city_id", null)
    setSearch("")
    refetchData('city')
    event.preventDefault();
  };
  const handleSearchUpdateCity = () => {
    refetchCities();
  }
  const handleSearchUpdateCountry = () => {
    refetchCountries();
  }
  const handleSearchUpdateState = () => {
    refetchStates();
  }
  const refetchData = (type) => {
    if (type === "city") {
      refetchCities().then(response => {
        setTimeout(() => {
          setCurrentCity(response.data?.find(item => item?._id == formik?.values?.city_id)?.label || '')
          setSearch("");
          // setSearch(response.data?.find(item => item?.value == formik?.values?.city_id)?.label || '')
        }, 0)
      })
    }
    else {
      formik.values?.country_id != undefined && refetchStates().then(res => {
        setCurrentState(res?.data?.filter(item => item?._id == formik.values.state_id)[0]?.label || '');
        setSearchState("");
        // setSearchState(res?.data?.find(item => item?.value == formik.values.state_id)?.label || '');
      })

      formik.values?.state_id != undefined && refetchCities().then(response => {
        setTimeout(() => {
          setCurrentCity(response?.data?.find(item => item?._id == formik?.values?.city_id)?.label || '')
          setSearch("");
          // setSearch(response.data?.find(item => item?.value == formik?.values?.city_id)?.label || '')
        }, 200)
      })

    }
    // refetchPopularCities()
  }

  React.useEffect(() => {
    refetchCountries().then(respo => {
      setCurrentCountry(respo?.data?.find(item => item?._id === startupDetailsData?.data?.country_id) || null);

      // console.log(respo?.data?.find(item => item?._id === startupDetailsData?.data?.country_id), "respo?.data?.find(item => item?._id === startupDetailsData?.data?.country_id)")
      if (startupDetailsData?.data?.country_id != undefined) {
        refetchStates().then(res => {
          setCurrentState(res?.data?.find(item => item?._id == startupDetailsData?.data?.state_id) || null)
          // console.log(res?.data?.find(item => item?._id == startupDetailsData?.data?.state_id),"dddddd")
        }).then(() => {
          // if (startupDetailsData?.data.state_id != undefined) {
          refetchCities().then(response => {
            // console.log(response, "response")
            setCurrentCity(response?.data?.find(item => item?._id == startupDetailsData?.data?.city_id))
          })
          // }
        })
      }
    })
  }, [isStartupDetailsLoading || isStartupEditDetailsLoading])
  return (
    <>
      <Grid height={"min-content"} item xs={12} sm={6}>
        <CustomInputField name={`first_name`} label={"FIRST NAME"} placeholder={"Enter first name"} value={formik.values?.first_name} onChange={(e) => formik.setFieldValue(`first_name`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
      </Grid>
      <Grid height={"min-content"} item xs={12} sm={6}>
        <CustomInputField name={`last_name`} label={'LAST NAME'} placeholder={"Enter last name"} value={formik.values?.last_name} onChange={(e) => formik.setFieldValue(`last_name`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
      </Grid>
      {/* {isLoadingCountriesPC ? <CircularProgress sx={{ color: "#3538CD" }} /> :  */}
      {formik.values?.phone_code && <Grid height={"min-content"} item xs={12}>
        <CustomPhone name={`mobile_number`} selectedCode={formik.values.phone_code ? formik.values.phone_code : ""} handleChange={(event) => formik.setFieldValue("phone_code", event.target.value)} countryCodes={countriesWithPhoneCodes ? countriesWithPhoneCodes : []} label='MOBILE' placeholder="Enter your mobile number" onChange={(e) => formik.setFieldValue(`mobile_number`, e.target.value)} />
      </Grid>}
      {!isStartupEditDetailsLoading && <Grid item xs={12}>
        <CustomAutoComplete
          // open={true}
          options={!isLoadingCountries && !isRefetchingCountries ? countries : []}
          label="SELECT COUNTRY"
          name={"country_id"}
          noOptionsText={!isLoadingCountries && !isRefetchingCountries ? "No Options" : "Searching..."}
          placeholder="Type a country name"
          icon={gpsIcon}
          handleSearchChange={handleSearchCountryChange}
          handleSelectionChange={handleSelectionCountryChange}
          search={searchCountry}
          value={currentCountry}
          // defaultValue={countries?.find(item => item?._id == formik?.values?.country_id)}
          loading={isRefetchingCountries}
          handleSearchUpdate={handleSearchUpdateCountry}
        />
      </Grid>}
      {!isStartupEditDetailsLoading && <Grid item xs={12}>
        <CustomAutoComplete
          options={!isLoadingStates && !isRefetchingStates && states?.length > 0 ? states : []}
          label={`SELECT STATE`}
          placeholder="Type a state name"
          name="state_id"
          noOptionsText={!isLoadingStates && !isRefetchingStates ? "No Options" : "Searching..."}
          icon={gpsIcon}
          handleSearchChange={handleSearchStateChange}
          handleSelectionChange={handleSelectionStateChange}
          search={searchState}
          value={currentState}
          // defaultValue={states?.find(item => item?._id === formik?.values?.state_id)}
          loading={false}
          handleSearchUpdate={handleSearchUpdateState}
        />
      </Grid>}
      {!isStartupEditDetailsLoading && <Grid item xs={12}>
        {<CustomAutoComplete
          options={!isLoadingCities && !isRefetchingCities && cities?.length > 0 ? cities : []}
          noOptionsText={!isLoadingCities && !isRefetchingCities ? "No Options" : "Searching..."}
          label={`SELECT CITY`}
          placeholder="Type a city name"
          name="city_id"
          icon={gpsIcon}
          handleSearchChange={handleSearchCityChange}
          handleSelectionChange={handleSelectionCityChange}
          search={search}
          value={currentCity}
          // defaultValue={cities?.find(item => item?._id === formik?.values?.city_id)}
          handleSearchUpdate={handleSearchUpdateCity}
        />}
      </Grid>}
      <Grid item xs={12} display={"flex"} mt={0}>
        <CustomUrlField type="text" value={formik.values.website_url} name="website_url" label={"WEBSITE URL"} placeholder="www.backrr.com" onChange={(e) => formik.setFieldValue("website_url", String(e.target.value)?.startsWith("https://") ? String(e.target.value)?.replace("https://", "") : e.target.value)} />
      </Grid>
      <Grid xs={12} item container style={{ display: 'flex', alignItems: 'center' }}>
        <CustomUrlField label={<>STARTUP LINKEDIN PAGE <span style={{ color: theme?.palette?.customColors?.g100 }}>(Optional)</span> </>} name="linkedin_url" value={formik.values?.linkedin_url} onChange={(e) => formik.setFieldValue("linkedin_url", String(e.target.value)?.startsWith("https://") ? String(e.target.value)?.replace("https://", "") : e.target.value)} placeholder='www.linkedin.com' />
      </Grid>

    </>
  )
}

export default ContactInformation
