import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Grid, useMediaQuery, } from '@mui/material'
import { makeStyles } from "@mui/styles"
import NeedHelpButton from 'ui-components/NeedHelpButton'
import Cookies from 'js-cookie'
import BreadcrumbHeader from 'layout/BreadcrumbsHeader'
import ThemeBGWrapper from 'ui-components/ThemeBGWrapper';
import CustomTabs from 'ui-components/CustomTabs';
import Versions from './Versions';
import { useToast } from 'context/ToastContext';

// import RoundInvestorsNew from './RoundInvestorsNew'

const useStyles = makeStyles((theme) => ({
    scrollTabs: {
        '& > .Mui-disabled.MuiTabs-scrollButtons': {
            display: 'none',
            transition: 'all 1s ease'
        }
    }
}));

const ViewSettingsInformation = () => {
    // const { id: round_id } = useParams();
    const round_id = Cookies.get("round_id")
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
    const [page, setPage] = React.useState(1);
    const parsedCookieForQueryParams = Cookies.get("admin_settings_query") ? JSON.parse(Cookies.get("admin_settings_query")) : {}
    const [queryParams, setQueryParams] = React.useState(parsedCookieForQueryParams);
    const [search, setSearch] = React.useState(Cookies.get("search_admin_settings") || "");
    const [reset, setReset] = React.useState(false);
    const [searchStatus, setSearchStatus] = React.useState(false);
    const refetchListing = () => {

    }
    const classes = useStyles();
    const Actions = () => {
        return (
            <>
                <NeedHelpButton />
            </>
        );
    };

    let tabValue = new URLSearchParams(window.location.search).get("page");
    // console.log("tabl",tabValue)
    const [value, setValue] = React.useState(tabValue || "versions");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const settingsTabs = [
        {
            label: "Release Notes",
            value: "versions",
        },
    ]
    const { toasts, Toast, removeToast, resetToast } = useToast()
    return (
        <ThemeBGWrapper bgColor={theme.palette.customColors.yellow_bg}>
            <BreadcrumbHeader
                title="Admin Settings"
                sx={{ paddingBottom: 0 }}
            />

            <>
                <Grid xs={12} container bgColor={theme.palette.customColors.yellow_bg} height={"100%"}>
                    {/* {toasts?.length> 0 && <div className="toast-container">
                        {toasts.map((toast) => (
                            <Toast
                                key={toast.id}
                                message={toast.message}
                                type={toast.type}
                                duration={toast.duration}
                                onClose={() => removeToast(toast.id)}
                            />
                        ))}
                    </div>} */}
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box px={isSmallScreen ? "1rem" : "2rem"} bgColor={theme.palette.customColors.yellow_bg}>
                                <CustomTabs data={settingsTabs} value={value} isSmallScreen={isSmallScreen} className={classes} handleChange={handleChange} key={"Tabs Tabs"} sx={{ paddingTop: '1rem' }} bgColor={theme.palette.customColors.yellow_bg} />
                            </Box>
                            <TabPanel value="versions" sx={{ paddingInline: 0, height: '100%' }}>
                                <Versions
                                    refetchListing={refetchListing}
                                    searchStatus={searchStatus}
                                    setSearchStatus={setSearchStatus}
                                    search={search}
                                    reset={reset}
                                    setSearch={setSearch}
                                    setReset={setReset}
                                    queryParams={queryParams}
                                    setQueryParams={setQueryParams}
                                />
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Grid>
            </>
            {/* } */}
        </ThemeBGWrapper>
    )
}

export default ViewSettingsInformation