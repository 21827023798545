import React from "react";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import { chartsColorsTheme, convertCurrencyAmountRange } from "utils/index";

ChartJS.register(PointElement, LineElement, Title, Tooltip, Legend);

const LineChart = ({
  graphData,
  width = "330px",
  height = "calc(100vh - 25rem)",
  chartMaxWidth = 330,
  formattingUnit
}) => {
  const theme = useTheme();
  const totalPercentage = graphData?.reduce((total, item) => {
    return total + Number(item?.percentage || 0);
  }, 0);

  // old theme 
  // const colorsTheme = [
  //   "#4285F4", // Blue
  //   "#AA46BB", // Purple
  //   "#0F9D58", // Green
  //   "#DB4437", // Red
  //   "#FF6F00", // Orange
  //   "#A5733C", // Brown
  //   "#FFCC00", // Yellow
  //   "#FF00FF", // Pink
  //   "#00C3E3", // Cyan
  // ];

  // const colorsTheme = [
  //   "#68d4cd",
  //   "#cff67b",
  //   "#94dafb",
  //   "#fd8080",
  //   "#6d848e",
  //   "#26a0fc",
  //   "#26e7a6",
  //   "#fbb938",
  //   "#fab1b2",
  //   "#8b75d7",
  // ]

  // const colorsTheme = [
  //   "#2a9d8f", // Dark Teal
  //   "#aacc00", // Lime Green
  //   "#3778c2", // Dark Sky Blue
  //   "#e63946", // Crimson Red
  //   "#4a4e69", // Slate Gray
  //   "#1e6091", // Deep Blue
  //   "#119d68", // Rich Green
  //   "#f4a261", // Muted Orange
  //   "#d68189", // Soft Coral
  //   "#7b2cbf", // Rich Violet
  // ];

  // const colorsTheme = [
  //   "#8fb9aa", // Muted Teal
  //   "#cfe0c3", // Soft Sage Green
  //   "#a0bcd1", // Gentle Sky Blue
  //   "#e4a8a8", // Blush Pink
  //   "#a7a7c9", // Lavender Gray
  //   "#d1c4e9", // Light Purple
  //   "#b8d9d5", // Misty Green
  //   "#f5d5a5", // Pale Sand
  //   "#f4bfbf", // Light Coral
  //   "#c2b2d6", // Soft Amethyst
  // ];

  // const colorsTheme = [
  //   "#6a8eae", // Muted Blue
  //   "#a2b29f", // Soft Olive Green
  //   "#c5a880", // Warm Beige
  //   "#d7b9d5", // Lavender Blush
  //   "#8d99ae", // Slate Gray
  //   "#a8c5ba", // Misty Green
  //   "#e3c9c9", // Blush Rose
  //   "#b4c8d2", // Powder Blue
  //   "#f4d7b7", // Light Sand
  //   "#c4b7cb", // Soft Lilac
  // ];


  const colorsTheme = [...chartsColorsTheme]

  const remainingPercentage = 100 - totalPercentage;

  const data = {
    labels: graphData?.map(item => { return item.label }),
    datasets: [
      {
        data: graphData
          ? [
            ...graphData?.map((item) => Number(item?.percentage))
          ].filter(Boolean) // filter out null values
          : [],
        backgroundColor: [...colorsTheme],
        borderColor: '#4285F4',
        borderWidth: 1,
        tension: 0.1
      },
    ],
  };
  // console.log(formattingUnit, "formattingUnit")

  const options = {
    // cutout: "40%", // Adjust the size of the center hole
    responsive: true,
    width: 330,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          // title: (tooltipItems) => `Month: ${tooltipItems[0].label}`,
          label: (tooltipItem) => {
            if (Math.abs(tooltipItem.raw) !== null && Math.abs(tooltipItem.raw) !== 0) {
              return `${Math.abs(tooltipItem.raw) < 0 ? "-" : ""} ${convertCurrencyAmountRange(Math.abs(tooltipItem.raw), String(formattingUnit), 1)} `
            }
            return "";
          }
        }
      },
      datalabels: {
        display: false,
        align: "center",
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            family: '"Lato", sans-serif'
          }
        },
        grid: {
          color: "rgba(235, 232, 251, 0.32)" // Change grid line color to a subtle gray
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => {
            if (value !== null && value !== 0) {
              return `${value < 0 ? "-" : ""}${convertCurrencyAmountRange(value, String(formattingUnit), 0)} `;
            }
            return "";
          },
          font: {
            family: '"Lato", sans-serif'
          }
        },
        grid: {
          color: "rgba(235, 232, 251, 0.32)" // Change grid line color to a subtle gray
        }
      },
    },
  };

  return (
    <div
      style={{
        width: width,
        height: height,
        marginInline: "auto",
      }}
    >
      {totalPercentage != 0 ? (
        <>
          <Box style={{
            marginBottom: '1rem', marginInline: "auto", "& > canvas": {
              marginInline: "auto"
            }
          }}>
            <Line data={data} options={options} />
          </Box>
        </>
      ) : (
        <Grid
          display={"grid"}
          sx={{ placeItems: "center", height: "calc(100vh - 5rem)" }}
        >
          No Data Available
        </Grid>
      )}
    </div>
  );
};

export default LineChart;
