import React from 'react'
// import RoundStepsBackground from "assets/rounds_icons/round_list_bottom.png";
import RoundStepsBackground from "../assets/rounds_icons/steps_backgroud.png";
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import PerfectScrollbar from "react-perfect-scrollbar";
import { containerRef, getFileIcon } from "../utils";
import { TabContext } from '@mui/lab';
import { useTheme } from '@emotion/react';

const VerticalScrollableTabs = ({ data = [], currentIndex, setCurrentIndex, setSearchParams, scrollToTop = () => { }, handleChangeValue }) => {
  const theme = useTheme();
  return (
    <>
      <PerfectScrollbar
        containerRef={containerRef}
        style={{
          minHeight: "80vh",
          height: "100%",
          width: "100%",
          // maxHeight: "calc(100vh - 285px)",
          maxHeight: "82.5vh",
          paddingRight: "1rem",
          marginTop: 0,
          paddingBottom: "1rem",
          // background: "#ADA2ED",
          background: `url(${RoundStepsBackground})`,
          backgroundSize: "cover",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: "0 100%"
        }}
        className="scrollbar-container"
      >
        <Box width={"100%"} height={"2rem"} sx={{ backgroundColor: "#FFFAF5", zIndex: 3, position: 'relative' }}>
          <Box width={"100%"} height={"2rem"} sx={{ backgroundColor: "#ADA2ED", borderBottomRightRadius: currentIndex === 0 ? "35px" : 0 }}>

          </Box>
        </Box>
        <TabContext value={currentIndex}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            scrollButtons={false}
            value={currentIndex}
            onChange={(event, newValue) => {
              // (roundID != null && newValue !== currentIndex) || isEditMode
              // ? 
              setCurrentIndex(newValue);
              if(handleChangeValue){
                handleChangeValue(newValue)
              }
              else{
                setSearchParams({ page: newValue });
              }
              scrollToTop();
              // : toast.error(
              //   "Kindly provide the necessary details for the current step in order to proceed to the next one.",
              //   {
              //     icon: (
              //       <WarningOutlined sx={{ fill: "orange !important" }} />
              //     ),
              //     duration: 5000,
              //     position: "top-right",
              //   }
              // );
            }}
            sx={{
              overflow: 'visible',
              "& .MuiTabs-flexContainer": {
                // padding: "1.5rem 1rem",
                padding: 0,
                paddingLeft: '1rem',
                // background: "#ADA2ED"
              },
              "& .MuiTabs-indicator": {
                display: "none",
              },
              "& .MuiTab-root": {
                position: "relative",
                background: '#FFFAF5',
                padding: 0,
                // display: "flex",
                // padding: "0.3rem 1rem",
                paddingRight: 0,
                minHeight: 60,
                maxHeight: 100,
                alignItems: "stretch",
                width: "100%",
                // maxWidth: 500,
                maxWidth: '100%',
                // gap: "0.5rem",
                justifyContent: "flex-start",
                textAlign: "start",
                // borderRadius: "128px 0 0 128px",
                overflow: 'hidden',
                // paddingRight: '1rem',
                display: 'flex', flexDirection: 'row'
              },
              "& .MuiTab-root > div": {
                background: "#ADA2ED"
              },
              "& .MuiTab-root.Mui-selected": {
                borderRadius: "128px 0 0 128px",
              },
              "& .MuiTab-root.Mui-selected > div": {
                backgroundColor: "#FFFAF5",
                zIndex: 3,
              },
              width: "100%",
              // paddingBottom: '2rem'
            }}
          >
            {data &&
              data?.length > 0 &&
              data
                ?.map((currentEl, index) => {
                  return (
                    <Tab
                      key={index}
                      // disabled={window.location.pathname?.includes("create_round")}
                      disableRipple
                      label={
                        <Box sx={{
                          display: "flex", alignItems: "center", gap: 1,
                          borderRadius: `0px ${index === currentIndex + 1 ? "35px" : 0} ${index === currentIndex - 1 ? "35px" : 0} 0px`,
                          width: '100%', height: "100%", padding: "0.5rem 1rem", alignItems: "center",
                          // background: 
                        }}>
                          {/* {console.log(index === currentIndex - 1, currentIndex, index, index === currentIndex + 1, "value")} */}
                          {/* {console.log(currentEl?.image_url,"currentE?.image_url")} */}
                          {currentEl?.image_url ? <Box
                            sx={{
                              height: "40px",
                              width: "40px",
                            }}
                          >
                            <currentEl.image_url
                              style={{
                                fill:
                                  index === currentIndex
                                    ? "#F79009"
                                    : theme.palette.customColors.g300,
                                width: "20px",
                                height: "20px",
                                padding: "10px",
                              }}
                            />
                          </Box> : currentEl?.icon ? <img src={currentEl?.icon} style={{
                            height: "40px",
                            width: "40px",
                          }} /> : <></>}
                          <Box sx={{ display: "flex", alignItems: "center", gap: 1, height: '100%' }}>
                            <Box>
                              <Typography
                                variant="h6"
                                fontSize={{ xs: "0.9rem", lg: "1rem" }}
                                color={theme.palette.customColors.g300}
                                fontWeight={700}
                                textAlign={"start"}
                                display="flex"
                                alignItems="center"
                                textTransform={"uppercase"}
                                gap='4px'
                              >
                                {currentEl?.name}
                                {/* {index === 3 && (
                                          <>
                                            <CustomInfoIcon defaultTitle="FUND UTILIZATION" position="bottom-center" />
                                          </>
                                        )
                                        } */}
                                {/* {index === 4 &&(
                               <>
                               <CustomInfoIcon defaultTitle="Current Traction & Runway" />
                             </>
                              )
                              } */}

                              </Typography>
                              <Typography
                                variant="subtitle2"
                                color={theme.palette.customColors.g200}
                                fontSize="0.875rem"
                                // noWrap
                                // wra
                                textTransform={"none"}
                              >
                                {currentEl?.sub_title}
                              </Typography>
                            </Box>
                            {index === currentIndex && (
                              <>
                                {/* {currentIndex != 0 && */}
                                {/* <Box
                                            sx={{
                                              position: "absolute",
                                              top: "-1.87rem",
                                              right: 0,
                                              width: "30px",
                                              height: "30px",
                                              overflow: "hidden",
                                              background: `url(${TopIcon})`,
                                              backgroundSize: "cover",
                                              backgroundBlendMode: "multiply",
                                            }}
                                          /> */}
                                {/* } */}
                                {/* {currentIndex != data?.length - 1 &&  */}
                                {/* <Box
                                            sx={{
                                              position: "absolute",
                                              bottom: "-1.87rem",
                                              right: 0,
                                              width: "30px",
                                              height: "30px",
                                              overflow: "hidden",
                                              background: `url(${BottomIcon})`,
                                              backgroundSize: "cover",
                                              backgroundBlendMode: "multiply",
                                            }}
                                          /> */}
                                {/* } */}
                              </>
                            )}
                          </Box>
                        </Box>

                      }
                      sx={{
                        zIndex: index === 3 ? 3 : 2,
                        display: 'grid',
                        // paddingRight: 0
                        // "&:hover": {
                        //   backgroundColor: "#FFFAF5",
                        // },
                      }}
                    />
                  )
                })}
          </Tabs>
        </TabContext>
        <Box width={"100%"} height={"2rem"} sx={{ backgroundColor: currentIndex === data?.length - 1 ? "#FFFAF5" : 'transparent', zIndex: 3, position: "relative" }}>
          <Box width={"100%"} height={"2rem"} sx={{ backgroundColor: currentIndex === data?.length - 1 ? "#ADA2ED" : 'transparent', borderTopRightRadius: currentIndex === data?.length - 1 ? "35px" : 0 }}>

          </Box>
        </Box>
      </PerfectScrollbar>
      {/* <img
        src={RoundStepsBackground}
        style={{
          width: "100%",
          height: "240px",
          position: "absolute",
          background: "#ADA2ED",
          bottom: 0,
          left: 0,
        }}
      /> */}

    </>
  )
}

export default VerticalScrollableTabs