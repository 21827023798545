import React from 'react'
import Cookies from "js-cookie"
import { useDispatch } from 'react-redux';
import { RESET_STATE, SET_MENU, SET_STARTUP_DETAILS } from 'store/actions';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Grid, Typography } from '@mui/material';

const LogoutScreen = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const currentIP = window.location.host;
    React.useEffect(() => {
        const handleLogOut = () => {
            dispatch({ type: RESET_STATE });
            // dispatch({ type: SET_MENU, opened: false });
            Cookies.remove('jwt', { path: '/' });
            Cookies.remove('jwt', { domain: currentIP?.includes('backrr.com') ? ".backrr.com" : ".wedighq.com" });
            Cookies.remove('is_profile_complete', { path: '/' });
            Cookies.remove('email', { path: '/' });
            Cookies.remove('userDetails', { path: '/' });
            // Cookies.remove('ref', { path: '/' });
            Cookies.remove('step', { path: '/' });
            const cookies = document.cookie.split(";");
            // dispatch({ type: SET_STARTUP_DETAILS, data: null });
            const providerCookies = Cookies.get("provider")
            if (providerCookies == "linkedin") {
                const url = 'https://www.linkedin.com/m/logout';
                const win = window.open(url
                    , '_blank', 'toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=10000, top=10000, width=1, height=1, visible=none'
                );
                setTimeout(() => {
                    win.close();
                }, 3000);
            }

            cookies.forEach(cookie => {
                const eqPos = cookie.indexOf("=");
                const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
            });
            navigate('/login');
        };
        handleLogOut()
    }, [])
    return (
        <Grid
            container
            xs={12}
            height="100vh"
            display="grid"
            sx={{ placeItems: 'center', backgroundColor: '#f5f5f5' }} // Light background for better visibility
        >
            <Grid item textAlign="center">
                <CircularProgress color="primary" size={40} thickness={4.5} />
                <Typography
                    variant="h5"
                    sx={{ mt: 2, fontWeight: 500, color: 'text.secondary' }}
                >
                    Logging Out...
                </Typography>
            </Grid>
        </Grid>
    )
}

export default LogoutScreen