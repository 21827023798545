import React from 'react'
import AsyncTableListing from '../../ui-components/AsyncTableListing'
import { useTheme } from '@emotion/react'
import { Autocomplete, Box, Dialog, Grid, IconButton, Menu, MenuItem, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material'
import CustomTabs from '../../ui-components/CustomTabs'
import { TabContext } from '@mui/lab'
import { Add, CancelRounded, Description, DoneRounded, FiberNew, FiberNewRounded, FilterAltOutlined, FilterAltRounded, LanguageOutlined, LinkedIn, More, NewReleasesOutlined, Notes, PublishedWithChanges, RateReview, RocketLaunch, Search, ThumbDown, ThumbUp } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { amountInWords, convertCurrencyAmountRange, getFileIcon } from '../../utils'
import { Link, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { FetchAllCategories, FetchAllStages, FetchAllSubCategories } from 'api/utils'
import { UpdateStartupStatus } from 'api/backrr_startups'
import { useSelector } from 'react-redux'
import { ReactComponent as MoreOptionsIcon } from "assets/rounds_icons/more_solid_icon.svg";
import { FetchAllStartupsListing, FetchInterestFeedbackStatus, InviteStartupPOST, SaveFeedback, SaveInterest } from 'api/backrr_startups'
import { FetchDuplicateInvestorList, UpdateInvestorStatus } from 'api/backrr_investor'
import ModalComponent from 'ui-components/ModalComponent'
import ImportInvestorPopup from './popups/ImportInvestorPopup'
import UpdateStatus from './popups/UpdateStatus'
import toast from 'react-hot-toast'
const useStyles = makeStyles((theme) => ({
  scrollTabs: {
    "&": {
      padding: "3rem"
    }
  }

}));

const DuplicateInvestor = ({ setCount, page, limit, count, setLimit, setPage, search, category, searchStatus, sector, reset, stage, openImportInvestorModal, setOpenImportInvestorModal }) => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.investorDetails?.data);
  const parsedCookieForQueryParams = Cookies.get("backrr_investors_query") ? JSON.parse(Cookies.get("backrr_investors_query")) : {}
  const [queryParams, setQueryParams] = React.useState(parsedCookieForQueryParams);
  // const [stage, setStage] = React.useState(null);
  // const [category, setCategory] = React.useState(null);
  // const [sector, setSector] = React.useState(null);
  const parsedCookieForStatus = Cookies.get("backrr_investors_status") || "all"
  const [value, setValue] = React.useState(parsedCookieForStatus || "all")
  // const [search, setSearch] = React.useState("" || Cookies.get("search_backrr_startups"));
  // const [reset, setReset] = React.useState(false);
  // const [searchStatus, setSearchStatus] = React.useState(false);
  const handleImportInvestorClose = () => {
    setOpenImportInvestorModal(false)
  }
  const handleImportInvestorSubmit = () => {
    setOpenImportInvestorModal(false)
  }
  const [openFilter, setOpenFilter] = React.useState(false);
  const [duplicateData, setDuplicateData] = React.useState(null);



  const { mutateAsync: fetchDuplicateInvestor, isLoading: isLoadingfetchDuplicateInvestor, status: fetchDuplicateInvestorStatus, error: fetchDuplicateInvestorError } = FetchDuplicateInvestorList();
  const { mutateAsync: fetchStatusInvestor } = UpdateInvestorStatus();
  // const { data: startup_stages, isRefetching: isRefetchingAllStages, isLoading: isLoadingAllStages, refetch: refetchAllStages } = FetchAllStages();
  // const { data: startup_categories, isRefetching: isRefetchingAllCategories, isLoading: isLoadingAllCategories, refetch: refetchAllCategories } = FetchAllCategories();
  // const { data: startup_sub_categories, isRefetching: isRefetchingAllSubcategories, isLoading: isLoadingAllSubcategories, refetch: refetchAllSubcategories } = FetchAllSubCategories({ category: queryParams?.category });
  const refetchListing = () => {

    // const selectedCategory = startup_categories?.find(item => item?.name === stage?.value)?.value || null

    // let payload = category && sector ?  {
    //     "select_stages": [selectedStage],
    //     "category": [category?.value],
    //     "sub_sector": [sector?.value]
    // } : {};
    // Get the _id for each based on the selected value
    const selectedStageId = queryParams?.stage
      ? queryParams?.stage?.split(",")
      : [];
    const selectedCategoryId = queryParams?.category
      ? queryParams?.category?.split(",")
      : [];
    const selectedSectorId = queryParams?.sector
      ? queryParams?.sector?.split(",")
      : [];
    // console.log(selectedSectorId, "sector")
    // console.log(selectedCategoryId, "sector")
    // console.log(selectedStageId, "sector")

    // Construct the payload
    const payload = {
      ...(selectedStageId && { "select_stages": selectedStageId }),
      ...(selectedCategoryId && { "category": selectedCategoryId }),
      ...(selectedSectorId && { "sub_sector": selectedSectorId }),
    };
    fetchDuplicateInvestor({
      "page": page,
      "limit": limit,
      "name": search,
      ...payload
    }, {
      onSuccess: (res) => {
        setDuplicateData(res.data.data.duplicates)
        setCount(res.data.data.count)
      },
      onError: () => {

      }

    })


  }
  React.useEffect(() => {
    refetchListing();
  }, [searchStatus])

  React.useEffect(() => {
    // let payload = {
    //     "select_stages": ["Early Stage"],
    //     "category": ["Services"],
    //     "sub_sector": ["3D Tech"]
    // };
    // fetchDuplicateInvestor({
    //   "page": page,
    //   "limit": limit
    // }, {
    //     onSuccess: (res) => {
    //         setDuplicateData(res.data.data.duplicates.investor)
    //         setCount(res.data.data.count)
    //     },
    //     onError: () => {

    //     }

    // })
    refetchListing()
  }, [page, limit, queryParams])

  const DuplicateColumns = [
    { Header: 'Investor Name', accessor: 'name', minWidth: 200, align: 'start', fixed: true },
    { Header: 'Type', accessor: 'type', minWidth: 70, align: 'center' },
    { Header: 'Location', accessor: 'location', minWidth: 150, align: 'center' },
    { Header: 'Stage', accessor: 'focused_stage', minWidth: 90, align: 'start' },
    { Header: 'Categories', accessor: 'focused_categories', minWidth: 200, align: 'start', tooltip: "focused_categories" },
    { Header: 'Sectors', accessor: 'focused_sectors', minWidth: 200, align: 'start', tooltip: "focused_sectors" },
    { Header: 'Business Models', accessor: 'focused_business_models', minWidth: 200, align: 'start', tooltip: "focused_business_models" },
    { Header: 'Cheque Size', accessor: 'cheque_size', minWidth: 150, align: 'center' },
    { Header: 'Status', accessor: 'investor_status', minWidth: 100, align: 'center' },
    { Header: 'Actions', accessor: 'actions', minWidth: 100, align: 'center' },
  ]

  const StatusColumn = ({ row }) => {
    const investor_id = row?.original?._id;
    const adminProfileView = row?.original?.admin_profile_view;
    const [openStatus, setOpenStatus] = React.useState(false);
    const [currentRow, setCurrentRow] = React.useState(false);

    const handleOpenStatusModal = ({ row }) => {
      // console.log(row,"row")
      setCurrentRow(row?.original);
      setOpenStatus(true);
    };

    const handleCloseStatusModal = () => {
      setOpenStatus(false);
    };

    const handleUpdateStatus = ({ payload = {} }) => {
      fetchStatusInvestor(
        {
          payload: {
            ...payload, // Adjust payload if needed
          },
          investor_id: investor_id,
        },
        {
          onSuccess: (res) => {
            toast.success(res?.data?.message);
            setOpenStatus(false);
            refetchListing();
          },
          onError: (err) => {
            console.error("Error moving to shortlisted:", err);
          },
        }
      );
    };

    return (
      <>
        <Dialog
          open={Boolean(openStatus)}
          onClose={handleCloseStatusModal}
          aria-labelledby="update-status-dialog"
          sx={{
            "& > .MuiDialog-container > .MuiPaper-root": { minWidth: 400 },
          }}
        >
          <UpdateStatus
            currentRow={currentRow}
            handleClose={handleCloseStatusModal}
            handleSubmit={handleUpdateStatus}
            isLoading={false}
            open={Boolean(openStatus)}
          />
        </Dialog>
        <Box>
          <Typography
            sx={{
              fontSize: "0.8rem",
              bgcolor: adminProfileView ? "#D2F2E7" : "#FDECEA",
              color: adminProfileView ? "#1B8662" : "#B00020",
              fontWeight: 700,
              padding: "6px 8px",
              borderRadius: "4px",
              textTransform: "uppercase",
              lineHeight: "10px",
              ":hover": { bgcolor: adminProfileView ? "#C8EEDC" : "#FCD3D3" },
              cursor: "pointer",
            }}
            onClick={() => handleOpenStatusModal({ row })}
          >
            {adminProfileView ? "Active" : "Inactive"}
          </Typography>
        </Box>
      </>
    );
  };

  const RowActions = ({ row, iconsColor = theme.palette.customColors.indigo700, index }) => {
    const investor_id = row?.original?._id;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (event, index) => {
      setAnchorEl(event.currentTarget);
      // setCurrentIndex(index)
      // console.log(event.currentTarget,"re")
    };
    const handleCloseMenu = (event) => {
      // console.log(event,"event")
      setAnchorEl(null);
      // setCurrentIndex(null);
    };

    return (
      <>
        <IconButton
          sx={{ borderRadius: "4px" }}
          id={`more-details-button-${index}`}
          size="small"
          aria-controls={openMenu ? 'more-details-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? 'true' : undefined}
          onClick={(e) => handleClickMenu(e)}
          centerRipple={false}
        >
          <MoreOptionsIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={openMenu}
          aria-controls={openMenu ? 'fade-menu' : 'fade-menu'}
          aria-haspopup="true"
          aria-expanded={openMenu ? 'true' : 'false'}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              padding: "12px",
              borderRadius: "8px",
              "& > ul.MuiList-root": { padding: "0 !important" },
              bgcolor: theme.palette.customColors.g400,
              color: theme.palette.customColors.whiteBG,
              // '&::before': {
              //     content: '""',
              //     display: 'block',
              //     position: 'absolute',
              //     top: 1,
              //     right: 5,
              //     width: 10,
              //     height: 10,
              //     bgcolor: theme.palette.customColors.g900,
              //     transform: 'translateY(-50%) rotate(45deg)',
              //     zIndex: 0,
              // },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
        >
          {row?.original?.backrr_base_url && row?.original?.backrr_base_url != null && (String(row?.original?.backrr_base_url)?.length > 0) && <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => window.open(row?.original?.backrr_base_url)}>
            View Public Profile
          </MenuItem>}
          <MenuItem disableRipple sx={{ borderBottom: `0px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => {
            if (row?.original?._id) {
              Cookies.set("backrr_investor_id", row?.original?._id);

              navigate(`/backrr_investors/edit`)
            }
          }}>
            Edit Profile
          </MenuItem>
        </Menu>
      </>
    );
  };

  // Memoized Data Transformation
  const useFormattedData = (transformedData) => {
    return React.useMemo(() => {
      return transformedData && transformedData?.length > 0
        ? transformedData?.map((item) => {
          //   console.log(item,"gyhgghgyu")
          return ({
            ...item,
            investor_status: <StatusColumn row={{ original: item }} />,
            image: item?.investor_profile,
            name: `${item?.investor?.personal_information?.first_name || ""} ${item?.investor?.personal_information?.last_name || ""}`.trim(),
            // formatted_interested_amount: Array.isArray(item?.startup_rounds_info) && item?.startup_rounds_info?.length > 0 ? <Tooltip title={amountInWords(item?.startup_rounds_info[0]?.interested_amount, item?.currency_info ? item?.currency_info[0]?.code : null)}> {item?.startup_rounds_info[0]?.formatted_interested_amount} </Tooltip> : null,
            location: item?.investor?.personal_information?.city_id?.name && item?.investor?.personal_information?.country_id
              ?.name ? `${item?.investor?.personal_information?.city_id?.name}, ${item?.investor?.personal_information?.country_id?.name}` : "-",
            // focused_categories: Array.isArray(item?.category) && item?.category.length > 0 ? item.category.map((cat) => cat.name).join(", ") : "-",
            focused_stage: item?.preferences?.startup_stages?.map(stag => stag.name),
            focused_categories: item?.preferences?.category_id?.map(cat => cat.name).join(", "),
            focused_sectors: item?.preferences?.preferred_sectors?.map(sec => sec.name).join(", "),
            // focused_business_models:item?.preferences?.business_model_id?.map(bmodel => bmodel.name).join(", "),
            focused_business_models: item?.preferences?.business_model_id?.name,
            cheque_size:
              `${item?.investor?.investment_size_preference?.currency === "INR"
                ? "₹" :
                item?.investor?.investment_size_preference?.currency === "USD"
                  ? "$" :
                  ""}${convertCurrencyAmountRange(item?.investor?.investment_size_preference?.min_amount, item?.investor?.investment_size_preference?.currency, 0)} - 
                ${item?.investor?.investment_size_preference?.currency === "INR" ? "₹" : item?.investor?.investment_size_preference?.currency === "USD" ? "$" : ""}${convertCurrencyAmountRange(item?.investor?.investment_size_preference?.max_amount, item?.investor?.investment_size_preference?.currency, 0)}`.trim(),
          })
        })
        : [];
    }, [transformedData]);
  };


  React.useEffect(() => {
    refetchListing();
  }, [reset]);

  const FormattedData = useFormattedData(duplicateData);

  return (
    <>
      <ModalComponent open={openImportInvestorModal} onClose={handleImportInvestorClose} style={{ maxWidth: 640, width: "100%" }}>
        <ImportInvestorPopup value={value} handleClose={handleImportInvestorClose} handleSubmit={handleImportInvestorSubmit} refetchListing={refetchListing} />
      </ModalComponent>
      {isLoadingfetchDuplicateInvestor ?
        <Grid item xs={12} height={"100vh"} sx={{ width: '100%', bgColor: theme.palette.customColors.yellow_bg }}>
          <Box className='skeleton' minHeight={"60vh"} sx={{ borderRadius: "0 0 8px 8px", border: `1px solid ${theme.palette.customColors.b50}` }} marginTop={0}>

          </Box>
        </Grid> :
        (<AsyncTableListing
          columns={DuplicateColumns}
          tabValue={value}
          data={FormattedData}
          RowActions={RowActions}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          count={count}
          sx={{ borderRadius: '0 0 8px 8px', border: `1px solid ${theme.palette.customColors.b50}` }}
          refetchListing={refetchListing}
        />
        )}

    </>
  )
}

export default DuplicateInvestor