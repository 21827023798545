import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { RetryOnError, verifyToken } from "../utils/VerifyToken";
import moment from "moment";
import { capitalize } from "@mui/material";

export const FetchImportInvestorList = () => {
  return useMutation(
    (payload) => {
      return axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/investors/importInvestor`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt")}`,
          "Content-Type": "multipart/form-data",
        },
        data: payload,
      });
    },
    {
      retry: 0,
      onError: (err) => {
        return verifyToken(err);
      },
    }
  );
};
export const FetchRegisteredList = () => {
  return useMutation(
    (payload) => {
      return axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/investors/getAllInvestorList`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt")}`,
          "Content-Type": "application/json",
        },
        data: payload,
      });
    },
    {
      // onSuccess: (data)=>console.log("API successfull"),
      // retry: (failureCount, error)=>{
      //   return RetryOnError(failureCount, error)
      // },
      retry: 0,
      onError: (err) => {
        return verifyToken(err);
      },
    }
  );
};
export const FetchBackrrList = () => {
  return useMutation(
    (payload) => {
      return axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/investors/getBackrrInvestorList`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt")}`,
          "Content-Type": "application/json",
        },
        data: payload,
      });
    },
    {
      // onSuccess: (data)=>console.log("API successfull"),
      // retry: (failureCount, error)=>{
      //   return RetryOnError(failureCount, error)
      // },
      retry: 0,
      onError: (err) => {
        return verifyToken(err);
      },
    }
  );
};
export const FetchAllInvestorList = () => {
  return useMutation(
    (payload) => {
      return axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/investors/getAllMergeInvestorList`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt")}`,
          "Content-Type": "application/json",
        },
        data: payload,
      });
    },
    {
      // onSuccess: (data)=>console.log("API successfull"),
      // retry: (failureCount, error)=>{
      //   return RetryOnError(failureCount, error)
      // },
      retry: 0,
      onError: (err) => {
        return verifyToken(err);
      },
    }
  );
};
export const FetchDuplicateInvestorList = () => {
  return useMutation(
    (payload) => {
      return axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/investors/getDuplicateInvestorEmails`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt")}`,
          "Content-Type": "application/json",
        },
        data: payload,
      });
    },
    {
      // onSuccess: (data)=>console.log("API successfull"),
      // retry: (failureCount, error)=>{
      //   return RetryOnError(failureCount, error)
      // },
      retry: 0,
      onError: (err) => {
        return verifyToken(err);
      },
    }
  );
};

export const UpdateInvestorStatus = () => {
  return useMutation(
    ({ payload, investor_id }) => {
      // console.log("Payload:", payload);
      // console.log("Startup ID:", startup_id);
      return axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/investors/admin-status-view/${investor_id}`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt")}`,
          "Content-Type": "application/json",
        },
        data: payload,
      });
    },
    {
      // onSuccess: (data)=>console.log("API successfull"),
      // retry: (failureCount, error)=>{
      //   return RetryOnError(failureCount, error)
      // },
      retry: 0,
      onError: (err) => {
        return verifyToken(err);
      },
    }
  );
};

export const FetchEditInvestorDetails = ({ investor_id }) => {
  // console.log(page.page,page.search,"page")
  return useQuery(
    [
      "master-FetchProfileDetails-FetchProfileDetails-FetchProfileDetails-fetch" +
      investor_id,
      investor_id,
    ],
    () => {
      return axios({
        method: "get",
        url: `${process.env.REACT_APP_BASE_URL}/investors/get-investor-list/${investor_id}`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      });
    },
    {
      // select: (data) => data,
      select: (data) => {
        const savedData = data.data.data[0];
        const personal_information = savedData?.personal_information;
        const email = savedData?.email ? { email: savedData?.email } : {};
        const profile_picture = savedData?.profile_picture;
        const preferences = savedData?.preferences;
        const investment_size_preference =
          savedData?.investment_size_preference;
        const previous_investment_experience =
          savedData?.previous_investment_experience;
        const startups = savedData?.startups;
        const whatsapp_notifications = savedData?.whatsapp_notifications;
        const linkedIn_profile = savedData?.linkedIn_profile;
        let date = "";
        let month = "";
        let year = "";
        if (personal_information?.dob) {
          let dateOfBirth = moment(personal_information?.dob).format(
            "MM-DD-YYYY"
          );
          month = dateOfBirth.split("-")[0];
          date = dateOfBirth.split("-")[1];
          year = dateOfBirth.split("-")[2];
        }
        let starupArray = [];
        if (startups) {
          let modifiedStartups = [];
          if (startups?.length > 0) {
            startups.map((item, index) => {
              modifiedStartups.push({
                id: index + 1,
                startup_name: item.startup_name,
                startup_url: item.startup_url,
                edit: false,
              });
            });
          } else {
            modifiedStartups.push({
              id: 0,
              startup_name: null,
              startup_url: null,
              edit: true,
            });
          }
          starupArray = [...starupArray, ...modifiedStartups];
        } else {
          starupArray.push({
            id: 0,
            startup_name: null,
            startup_url: null,
            edit: true,
          });
        }
        const savedValues = {
          // step 1
          invite_link: savedData?.invite_link,
          investor_type: savedData?.investor_type
            ? String(savedData?.investor_type)?.toLowerCase()?.replaceAll(" ", "_")
            : "",
          first_name: personal_information?.first_name
            ? String(personal_information?.first_name)
            : "",
          last_name: personal_information?.last_name
            ? String(personal_information?.last_name)
            : "",
          title: personal_information?.title
            ? capitalize(String(personal_information?.title)?.toLowerCase())
            : "",
          dd: date,
          mm: month,
          yyyy: year,
          organization_name:
            savedData?.personal_information?.organization_name || "",
          phone_number: savedData?.personal_information?.phone_number || "",
          country_code: savedData?.personal_information?.country_code || "91",
          organization_website: savedData?.personal_information
            ?.organization_website
            ? String(
              savedData?.personal_information?.organization_website
            )?.startsWith("https://")
              ? String(
                savedData?.personal_information?.organization_website
              )?.slice(8)
              : savedData?.personal_information?.organization_website
            : "",
          bio: personal_information?.bio || "",
          date_of_birth: personal_information?.dob
            ? moment(personal_information?.dob).format("DD-MMM-YYYY")
            : null,
          custom_image_url:
            savedData?.custom_profile_picture_url?.trim()?.length > 0
              ? savedData?.custom_profile_picture_url
              : null,
          website_logo:
            savedData?.website_upload_url?.trim()?.length > 0
              ? savedData?.website_upload_url
              : null,
          avatar_selected:
            profile_picture?.custom_image_url?.trim()?.length > 0
              ? null
              : profile_picture?.avatar_selected
                ? profile_picture?.avatar_selected
                : null,
          profile_path:
            savedData?.custom_profile_picture_url?.trim()?.length > 0
              ? savedData?.custom_profile_picture_url
              : null,
          country_id: personal_information?.country_id
            ? personal_information?.country_id?._id
            : null,
          state_id: personal_information?.state_id
            ? personal_information?.state_id?._id
            : null,
          city_id: personal_information?.city_id
            ? personal_information?.city_id?._id
            : null,
          country_name: savedData?.country ? savedData?.country : null,
          state_name: savedData?.state ? savedData?.state : null,
          city_name: savedData?.city ? savedData?.city : null,
          category_id: preferences?.category_id
            ? preferences?.category_id?.map((item) => item?._id)
            : null,
          sector_id: preferences?.preferred_sectors
            ? preferences?.preferred_sectors?.map((item) => item?._id)
            : [],
          sector_type: preferences?.sector_type
            ? preferences?.sector_type
            : "Multiple",
          investment_size: investment_size_preference?.investment_size
            ? investment_size_preference?.investment_size
            : null,
          min: investment_size_preference?.min_amount
            ? Number(investment_size_preference?.min_amount)
            : null,
          max: investment_size_preference?.max_amount
            ? Number(investment_size_preference?.max_amount)
            : null,
          investment_amount_type: investment_size_preference?.currency
            ? investment_size_preference?.currency
            : null,
          stage_id: preferences?.startup_stages
            ? preferences?.startup_stages?.map((item) => item?._id)
            : [],
          business_model_id: preferences?.business_model_id
            ? preferences?.business_model_id?.map((item) => item?._id)
            : [],
          business_model_type: preferences?.business_model_type
            ? preferences?.business_model_type
            : "Multiple",
          investment_count: previous_investment_experience?.investment_count
            ? previous_investment_experience?.investment_count
            : null,
          startups: starupArray,
          enabled: whatsapp_notifications?.enabled
            ? whatsapp_notifications?.enabled
            : false,
          whatsapp_number: whatsapp_notifications?.whatsapp_number
            ? whatsapp_notifications?.whatsapp_number
            : null,
          phone_code: whatsapp_notifications?.phone_code
            ? whatsapp_notifications?.phone_code
            : "91",
          otp_verified: whatsapp_notifications?.otp_verified
            ? whatsapp_notifications?.otp_verified
            : false,
          profile_url: linkedIn_profile?.profile_url
            ? String(linkedIn_profile?.profile_url)?.startsWith("https://")
              ? String(linkedIn_profile?.profile_url)?.slice(8)
              : linkedIn_profile?.profile_url
            : null,
          linkedIn_profile_url: linkedIn_profile?.profile_url
            ? linkedIn_profile?.profile_url
            : null,
          public_url: savedData?.slug
            ? savedData?.slug
            : String(
              personal_information?.first_name +
              personal_information?.last_name
            )
              ?.toLowerCase()
              ?.replaceAll(" ", "")
              ?.replace(/[^a-zA-Z0-9]/g, ""),
          isPublicURLVerify: !!savedData?.slug,
          backrr_base_url: savedData?.backrr_base_url || "",
          linkedin_public_profile: savedData?.linkedin_public_profile
            ? savedData?.linkedin_public_profile
            : false,
          sector_info: preferences?.preferred_sectors
            ? preferences?.preferred_sectors
            : [],
          stage_info: preferences?.startup_stages
            ? preferences?.startup_stages
            : [],
          business_model_info: savedData?.business_model_id
            ? savedData?.business_model_id
            : [],
          category_info: savedData?.category_id ? savedData?.category_id : null,
          step: savedData?.step || 1,
          _id: savedData?._id,
          partners: savedData?.partner
            ? savedData?.partner?.length > 0
              ? savedData?.partner?.map((item) => {
                return {
                  ...item,
                  name: item?.name || "",
                  linkedin_url: item?.linkedin_url
                    ? String(item?.linkedin_url)?.startsWith("https://")
                      ? String(item?.linkedin_url)?.slice(8)
                      : item?.linkedin_url
                    : null,
                  image: item?.partner_image_url || null,
                  bio: item?.bio || "",
                };
              })
              : [
                {
                  name: "",
                  linkedin_url: "",
                  image: null,
                  bio: "",
                },
              ]
            : [
              {
                name: "",
                linkedin_url: "",
                image: null,
                bio: "",
              },
            ],
            ...email
        };
        // console.log(savedData?.partners, "savedData?.partners")
        return { data: savedValues, status: savedData?.success };
      },
      refetchOnWindowFocus: false,
      enabled: !!investor_id,
      cacheTime: 0,
      retry: (failureCount, error) => {
        return RetryOnError(failureCount, error);
      },
      onError: (err) => {
        return verifyToken(err);
      },
    }
  );
};

export const UpdateInvestorPersonalInfo = () => {
  return useMutation(
    (payload) => {
      return axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/investors/edit-investor`,
        headers: {
          Authorization: `Bearer ${Cookies.get('jwt')}`,
        },
        data: payload,
      });
    },
    {
      retry: 0,
      // onSuccess: ()=>console.log("API successfull"),
      retry: (failureCount, error) => {
        return RetryOnError(failureCount, error)
      },
      onError: (err) => {
        // console.error(err);
        return verifyToken(err);
      }
    }
  );
};

export const UpdateInvestorImage = () => {
  return useMutation(
    (payload) => {
      return axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/investors/edit_user_profile_image`,
        headers: {
          Authorization: `Bearer ${Cookies.get('jwt')}`,
          'Content-type': 'multipart/form-data'
        },
        data: payload,
      });
    },
    {
      retry: 0,
      // onSuccess: ()=>console.log("API successfull"),
      retry: (failureCount, error) => {
        return RetryOnError(failureCount, error)
      },
      onError: (err) => {
        // console.error(err);
        return verifyToken(err);
      }
    }
  );
};

// export const SaveStartupInfo = () => {
//   return useMutation(
//       (payload) => {
//           return axios({
//               method: 'post',
//               url: `${process.env.REACT_APP_BASE_URL}/save_startup_info`,
//               headers: {
//                   Authorization: `Bearer ${Cookies.get('jwt')}`,
//               },
//               data: payload,
//           });
//       },
//       {
//           retry: 0,
//           retry: (failureCount, error) => {
//               return RetryOnError(failureCount, error)
//           },
//           onError: (err) => {
//               return verifyToken(err);
//           }
//       }
//   );
// };

// export const SendWhatsappOTP = () => {
//   return useMutation(
//       (payload) => {
//           return axios({
//               method: 'post',
//               url: `${process.env.REACT_APP_BASE_URL}/investors/whatsapp-notification`,
//               headers: {
//                   Authorization: `Bearer ${Cookies.get('jwt')}`,
//               },
//               data: payload,
//           });
//       },
//       {
//           retry: 0,
//           retry: (failureCount, error) => {
//               return RetryOnError(failureCount, error)
//           },
//           onError: (err) => {
//               return verifyToken(err);
//           }
//       }
//   );
// };

// export const VerifyWhatsAppOTP = () => {
//   return useMutation((payload) => {
//       return axios({
//           method: 'post',
//           url: `${process.env.REACT_APP_BASE_URL}/investors/verify-whatsapp-otp`,
//           headers: {
//               Authorization: `Bearer ${Cookies.get('jwt')}`,
//               'Access-Control-Allow-Origin': '*',
//               'Content-Type': 'application/json'
//           },
//           data: payload
//       });
//   }, {
//       retry: 0,
//       onError: (err) => {
//           // Handle the error
//           console.error(err);
//       },
//   });
// };
// export const VerifyPublicURL = () => {
//   return useMutation((payload) => {
//       return axios({
//           method: 'post',
//           url: `${process.env.REACT_APP_BASE_URL}/investors/slug`,
//           headers: {
//               Authorization: `Bearer ${Cookies.get('jwt')}`,
//               'Access-Control-Allow-Origin': '*',
//               'Content-Type': 'application/json'
//           },
//           data: payload
//       });
//   }, {
//       retry: 0,
//       onError: (err) => {
//           // Handle the error
//           console.error(err);
//       },
//   });
// };

export const UpdateInvestorPartners = () => {
  return useMutation(
    (payload) => {
      return axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/investors/edit-partner`,
        headers: {
          Authorization: `Bearer ${Cookies.get('jwt')}`,
          'Content-type': 'multipart/form-data'
        },
        data: payload,
      });
    },
    {
      retry: 0,
      // onSuccess: ()=>console.log("API successfull"),
      retry: (failureCount, error) => {
        return RetryOnError(failureCount, error)
      },
      onError: (err) => {
        // console.error(err);
        return verifyToken(err);
      }
    }
  );
};

export const DeleteInvestorByID = () => {
  return useMutation(
      (payload) => {
          return axios({
              method: 'delete',
              url: `${process.env.REACT_APP_BASE_URL}/investors/delete-investor/${payload?.investor_id}`,
              headers: {
                  Authorization: `Bearer ${Cookies.get('jwt')}`,
                  'Content-type': 'application/json'
              },
          });
      },
      {
          retry: 0,
          // onSuccess: (data)=>console.log("API successfull"),
          // retry: (failureCount, error)=>{
          //   return RetryOnError(failureCount, error)
          // },
          onError: (err) => {
              return verifyToken(err);
          }
      }
  );
};